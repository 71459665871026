<template>
  <div>
    <Breadcrumbs main="Balon Rezervasyonu" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
            <li class="nav-item" v-on:click="changetab('tur')" v-if="isTurShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'tur' }" id="top-tur" data-toggle="tab" role="tab"
                aria-controls="tur" aria-selected="true">Rezervasyon
                <b-badge variant="primary">{{ detay.Kod }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetab('misafir')" v-if="isMisafirShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'misafir' }" id="top-misafir" data-toggle="tab"
                role="tab" aria-controls="misafir" aria-selected="false">Misafirler</a>
            </li>
            <li class="nav-item" v-on:click="changetab('islem')" v-if="isIslemShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'islem' }" id="top-islem" data-toggle="tab"
                role="tab" aria-controls="islem" aria-selected="false">İşlemler</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div class="tab-pane fade" :class="{ 'show active': showtab == 'tur' }" id="tur" role="tabpanel"
          aria-labelledby="tur">
          <div class="row">
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Rezervasyon Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Kod</th>
                        <td>
                          <b-badge variant="primary">{{ detay.Kod }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Tarih</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="detay.Tarih" :rowKey="detay.Id"></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th colspan="2">Açıklama</th>
                      </tr>
                      <tr>
                        <td colspan="2">{{ detay.Aciklama }}</td>
                      </tr>
                      <tr>
                        <th>Otel Adı</th>
                        <td>{{ detay.OtelAdi }}</td>
                      </tr>
                      <tr>
                        <th>Otel Adresi</th>
                        <td>{{ detay.OtelAdresi }}</td>
                      </tr>
                      <tr>
                        <th>Balon Firması</th>
                        <td>{{ detay.BalonFirmaAdi }}</td>
                      </tr>
                      <tr>
                        <th>Kişi Sayısı</th>
                        <td><b-badge variant="info">{{ detay.KisiSayisi }}</b-badge></td>
                      </tr>
                      <tr>
                        <th>Durum</th>
                        <td><b-badge variant="primary">{{ detay.DurumStr }}</b-badge></td>
                      </tr>
                      <tr>
                        <th>İptal Sebebi</th>
                        <td>{{ detay.IptalSebebi }}</td>
                      </tr>
                      <tr>
                        <th>Son İşlem Yapan Kullanıcı</th>
                        <td>{{ detay.KullaniciAdi }}</td>
                      </tr>
                      <tr>
                        <th>Kayıt Tarihi</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="detay.KayitTarihi" :rowKey="detay.Id"></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Güncelleme Tarihi</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="detay.GuncelTarih" :rowKey="detay.Id"></c-tarih-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <CBalonRezervasyonTutarlar :detay="detay" v-if="detay !== undefined" />
            </div>
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="success" class="card-absolute">
                <h5 slot="header">Acente</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Acente Adı</th>
                        <td>{{ detay.AcenteAdi }}</td>
                      </tr>
                      <tr>
                        <th>Operasyon Telefonu</th>
                        <td>0530 010 81 11</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    Misafirler veya tur ile ilgili herhangi bir durumda
                    iletişime geçiniz.
                  </p>
                  <router-link :to="`/acente/detay/${detay.AcenteId}`" v-if="isBtnAcenteDetay"
                    class="btn btn-info pull-right">Acente Detayı</router-link>
                </b-card-body>
              </b-card>

              <b-list-group>
                <b-list-group-item router-component-name="router-link" v-if="isBtnDuzelt"
                  :disabled="disable_duzelt_state" :to="'/balonRezervasyon/duzelt/' + detay.Id">
                  <feather type="edit-3"></feather> Tur / Opsiyon Değiştir
                  <span>{{
      disable_duzelt_state
        ? "(Düzeltmek için uygun değildir.)"
        : ""
    }}</span>
                </b-list-group-item>
                <b-list-group-item router-component-name="router-link" v-if="isBtnDuzelt"
                  :disabled="disable_duzelt_state" :to="'/balonRezervasyon/duzelt/' + detay.Id + '?tab=1'">
                  <feather type="edit-3"></feather> Bilgi Değiştir
                  <span>{{
      disable_duzelt_state
        ? "(Düzeltmek için uygun değildir.)"
        : ""
    }}</span>
                </b-list-group-item>
                <b-list-group-item router-component-name="router-link" v-if="isBtnFiyat" :disabled="disable_fiyat_state"
                  tag="a" href="#" @click="fiyatAc()">
                  <feather type="credit-card"></feather> Fiyat Güncelle
                  {{
      disable_iptal_state ? "(Fiyat güncellemek için uygun değildir.)" : ""
    }}
                </b-list-group-item>
                <b-list-group-item v-if="isBtnYonetim && showBtnOnayla" tag="a" href="#" @click="onaylandi()">
                  <feather type="check"></feather> Onayla
                </b-list-group-item>
                <b-list-group-item v-if="isBtnYonetim && showBtnTamamlandi" router-component-name="router-link" tag="a"
                  href="#" @click="tamamlandi()">
                  <feather type="check-circle"></feather> Tamamlandı
                </b-list-group-item>
                <b-list-group-item router-component-name="router-link" v-if="isBtnIptal" :disabled="disable_iptal_state"
                  tag="a" href="#" @click="iptalAc()">
                  <feather type="slash"></feather> İptal
                  {{
      disable_iptal_state ? "(İptal için uygun değildir.)" : ""
    }}
                </b-list-group-item>
                <b-list-group-item v-if="isBtnYonetim" router-component-name="router-link" tag="a" href="#"
                  @click="sil()">
                  <feather type="delete"></feather> Sil
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'misafir' }" id="misafir" role="tabpanel"
          aria-labelledby="misafir">
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Misafirler</h5>
                <b-button variant="primary" class="pull-right mb-3" @click="misafirListAc()">Misafir Listesi
                  Yazdır</b-button>
                <b-table striped hover :fields="misafirFields" :items="detay.Misafirler"></b-table>
              </b-card>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'islem' }" id="islem" role="tabpanel"
          aria-labelledby="islem">
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="dark" class="card-absolute">
                <h5 slot="header">İşlemler</h5>
                <b-card-body>
                  <b-table striped hover :fields="islemFields" :items="detay.Islemler">
                    <template #cell(isYonetimGorsun)="data">
                      <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                    </template>
                    <template #cell(Tarih)="row">
                      <c-tarih-cell :kayitTarihi="row.item.Tarih" :rowKey="row.item.Id"></c-tarih-cell>
                    </template>
                  </b-table>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="iptalForm" size="lg" centered title="Rezervasyonu İptal Et" @ok="iptalSubmit" ok-title="İptal Et"
        cancel-title="Kapat" class="theme-modal">
        <b-form @submit="iptalSubmit">
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label for="kayit_iptalsebep">İptal Sebebi</label>
              <b-form-textarea type="text" id="kayit_iptalsebep" v-model="iptalForm.Sebep" :state="iptal_sebep_state"
                placeholder="İptal Sebebi"></b-form-textarea>
              <b-form-invalid-feedback :state="iptal_sebep_state">İptal sebebini yazınız.</b-form-invalid-feedback>
            </div>
          </div>
        </b-form>
      </b-modal>

      <b-modal id="fiyatForm" size="lg" centered title="Rezervasyon Fiyat Güncelle" @ok="fiyatSubmit" ok-title="Güncelle"
        cancel-title="Kapat" class="theme-modal">
        <b-form @submit="fiyatSubmit">
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label for="kayit_alisfiyat">Alış Toplam Fiyatı</label>
              <b-form-input type="text" id="kayit_alisfiyat" v-model="fiyatForm.AlisFiyati"
                placeholder="Alış Toplam Fiyat"></b-form-input>
              <b-form-invalid-feedback :state="fiyat_alistoplamfiyat_state">Alış toplam fiyatı yazınız.</b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label for="kayit_satisfiyat">Satış Toplam Fiyatı</label>
              <b-form-input type="text" id="kayit_satisfiyat" v-model="fiyatForm.SatisFiyati"
                placeholder="Satış Toplam Fiyat"></b-form-input>
              <b-form-invalid-feedback :state="fiyat_satistoplamfiyat_state">Satış toplam fiyatı yazınız.</b-form-invalid-feedback>
            </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import CBalonRezervasyonTutarlar from "@/components/c_balonRezervasyon_tutarlar";
import CBalonRezervasyonMisafirPrint from "@/components/c_balonRezervasyon_misafir_print";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Detayı",

      breadLinks: [
        {
          path: "/balonRezervasyon/list",
          title: "Balon Rezervasyonları",
          icon: "chevron-left",
        },
      ],

      isTurShow: false,
      isMisafirShow: false,
      isIslemShow: false,
      isBtnDuzelt: false,
      isBtnKismiDuzelt: false,
      isBtnAcenteDetay: false,
      isBtnYonetim: false,
      isBtnIptal: false,
      showBtnOnayla: false,
      showBtnTamamlandi: false,
      isBtnFiyat: false,

      showtab: "tur",

      misafirFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "AdSoyad", label: "Ad Soyad", sortable: false },
        { key: "Uyruk", label: "Uyruk", sortable: false },
        { key: "Telefon", label: "Telefon", sortable: false },
        { key: "PasaportNo", label: "Pasaport / T.C. Kimlik No", sortable: false, },
      ],

      islemFields: [
        { key: "Islem", label: "İşlem", sortable: false },
        { key: "Aciklama", label: "Açıklama", sortable: false },
        { key: "Tarih", label: "Tarih", sortable: false },
        { key: "AcenteId", label: "Acente", sortable: false },
        { key: "isYonetimGorsun", label: "Yön. Görsün", sortable: false },
      ],

      detay: {},

      iptalForm: {
        Sebep: "",
      },

      fiyatForm: {
        AlisFiyati: 0,
        SatisFiyati: 0,
      },
    };
  },
  components: {
    CBalonRezervasyonTutarlar,
    CBalonRezervasyonMisafirPrint
  },
  computed: {
    iptal_sebep_state() {
      if (this.iptalForm.Sebep == null || this.iptalForm.Sebep == "")
        return false;
      else return true;
    },
    disable_duzelt_state() {
      if (this.detay.Durum == 0 || this.detay.Durum == 1) {
        //var rezTarih = this.$moment(String(this.detay.Tarih)).format("DD.MM.YYYY");
        if (new Date(this.detay.Tarih) > new Date()) return false;
      }

      return true;
    },
    disable_iptal_state() {
      if (new Date(this.detay.Tarih) > new Date() || this.detay.Durum == 2) {
        //tamamlandı ya da süre geçmişse
        //var rezTarih = this.$moment(String(this.detay.Tarih)).format("DD.MM.YYYY");
        // if (new Date(this.detay.Tarih) > new Date())
        //    return false;
        return false;
      }

      return true;
    },
    disable_fiyat_state() {
      if (this.detay.Durum == 0 || this.detay.Durum == 1) {
        //yeni veya onaylandı ise değiştirebilir
        return false;
      }

      return true;
    },
    fiyat_alistoplamfiyat_state(){
      if (this.fiyatForm.AlisFiyati == null || this.fiyatForm.AlisFiyati == "")
        return false;
      else return true;
    },
    fiyat_satistoplamfiyat_state(){
      if (this.fiyatForm.SatisFiyati == null || this.fiyatForm.SatisFiyati == "")
        return false;
      else return true;
    }
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    onDetay() {
      let vm = this;
      let user = this.$user.getUser();

      this.$connection.get(
        "/balonRezervasyonlari/" + this.id,
        function (response) {
          if (response.Success) {
            switch (user.Kullanici.KullaniciTipi) {
              //Acente Yönetici
              case 10:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isBtnDuzelt = true;
                vm.isBtnIptal = true;
                break;

              //Acente Kullanıcı
              case 15:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isBtnDuzelt = true;
                vm.isBtnIptal = true;
                break;

              //Merkez
              case 80:
              case 85:
              case 100:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isIslemShow = true;
                vm.isBtnAcenteDetay = true;
                vm.isBtnYonetim = true;
                vm.isBtnDuzelt = true;
                vm.isBtnKismiDuzelt = true;
                vm.isBtnIptal = true;
                vm.isBtnFiyat = true;
                break;

              default:
                break;
            }

            vm.detay = response.Data;

            vm.fiyatForm = {
              AlisFiyati: vm.detay.AlisToplamTutar,
              SatisFiyati: vm.detay.SatisToplamTutar,
            };

            switch (vm.detay.Durum) {
              case 2:
                //Tamamlandı
                vm.showBtnOnayla = false;
                vm.showBtnTamamlandi = false;
                vm.isBtnFiyat = false;
                break;

              case 1:
              case 0:
                //Yeni veya onaylandı
                vm.showBtnOnayla = false;
                vm.showBtnTamamlandi = true;
                break;

              case 100:
                //İptal
                vm.isBtnIptal = false;
                vm.isBtnFiyat = false;
                break;

              default:
                break;
            }
          } else {
            vm.$alert.messageWithTitle("Balon Rezervasyon Detay", response.Message, "error");
          }
        }
      );
    },
    tamamlandi() {
      let vm = this;
      this.$alert.confirm(
        "Tamamlandı",
        "Rezervasyon tamamlandı olarak işaretlenecektir. Emin misiniz?",
        "success",
        function () {
          vm.$connection.get(
            "/balonRezervasyonlari/tamamlandi/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Tamamlandı",
                "Rezervasyon tamamlandı olarak işaretlendi.",
                "success",
                function () {
                  vm.onDetay();
                }
              );
            }
          );
        }
      );
    },
    onaylandi() {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Rezervasyon onaylanacaktır. Emin misiniz?",
        "info",
        function () {
          vm.$connection.get(
            "/balonRezervasyonlari/onaylandi/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Onayla",
                "Rezervasyon onaylandı.",
                "success",
                function () {
                  vm.onDetay();
                }
              );
            }
          );
        }
      );
    },
    iptalAc() {
      this.$bvModal.show("iptalForm");
    },
    iptalSubmit(event) {
      event.preventDefault();

      let isValid = this.iptal_sebep_state;

      if (isValid) {
        let vm = this;
        this.$alert.confirm(
          "İptal",
          "Rezervasyon iptal edilecektir. Emin misiniz?",
          "warning",
          function () {
            vm.$connection.post(
              "/balonRezervasyonlari/iptal/" + vm.detay.Id,
              vm.iptalForm,
              "put",
              function () {
                vm.$alert.messageWithFunction(
                  "İptal",
                  "Rezervasyon iptal edildi.",
                  "success",
                  function () {
                    vm.onDetay();
                    vm.$bvModal.hide("iptalForm");
                  }
                );
              }
            );
          }
        );
      }
    },
    fiyatAc(){
      this.$bvModal.show("fiyatForm");
    },
    fiyatSubmit(event) {
      event.preventDefault();

      let isValid = this.fiyat_alistoplamfiyat_state && this.fiyat_satistoplamfiyat_state;

      if (isValid) {
        let vm = this;
        this.$alert.confirm(
          "Fiyat",
          "Rezervasyon fiyatı güncellenecektir. Emin misiniz?",
          "warning",
          function () {
            vm.$connection.post(
              "/balonRezervasyonlari/fiyatDegistir/" + vm.detay.Id,
              vm.fiyatForm,
              "put",
              function () {
                vm.$alert.messageWithFunction(
                  "Fiyat",
                  "Rezervasyon fiyatı güncellendi.",
                  "success",
                  function () {
                    vm.onDetay();
                    vm.$bvModal.hide("fiyatForm");
                  }
                );
              }
            );
          }
        );
      }
    },
    sil() {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Rezervasyon silinecektir. Emin misiniz?",
        "error",
        function () {
          vm.$connection.delete(
            "/balonRezervasyonlari/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Sil",
                "Rezervasyon silindi.",
                "success",
                function () {
                  vm.$router.push("/balonRezervasyon/list");
                }
              );
            }
          );
        }
      );
    },
    misafirListAc() {
      this.$bvModal.show("misafirListeDetay");
    },
    misafirListYazdir() {
      window.print();
    },
  },
};
</script>