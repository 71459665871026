<template>
  <div>
    <Breadcrumbs main="Şehirler" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Şehir Kaydı</h5>
              <span
                >Yeni şehir veya şehir kaydında değişiklikler için aşağıdaki
                formu kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_adi">Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_adi"
                      v-model="kayit.Adi"
                      :state="kayit_adi_state"
                      placeholder="Şehir Adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adi_state"
                      >Şehir adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_plaka">Plaka</label>
                    <b-form-input
                      type="text"
                      id="kayit_plaka"
                      v-model="kayit.Plaka"
                      :state="kayit_plaka_state"
                      placeholder="Plaka"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_plaka_state"
                      >Şehrin plaka kodunu giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <b-form-input
                      type="text"
                      id="kayit_sira"
                      v-model="kayit.Sira"
                      :state="kayit_sira_state"
                      placeholder="Sıra"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sira_state"
                      >Sıra alanını doldurunuz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <router-link :to="'/sehir/list'" class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Şehir",

      breadLinks: [
        { path: "/sehir/list", title: "Şehirler", icon: "chevron-left" },
      ],

      kayit: {
        Adi: "",
        UlkeId: 0,
        Plaka: 0,
        Sira: 0,
      },
    };
  },
  mounted() {
    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Şehir Düzelt";

      this.$connection.get("/sehirler/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle("Yeni Şehir", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_adi_state() {
      if (this.kayit.Adi == null || this.kayit.Adi == "") return false;
      else return true;
    },
    kayit_plaka_state() {
      if (this.kayit.Plaka == "") return false;
      else return true;
    },
    kayit_sira_state() {
      if (this.kayit.Sira == "") return false;
      else return true;
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adi_state && this.kayit_plaka_state && this.kayit_sira_state;

      if (isValid) {
        let vm = this;

        let url = "/sehirler";
        let method = "post";
        let messageTitle = "Yeni Şehir";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Şehir Düzelt";
        }

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/sehir/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
