<template>
  <div>
    <Breadcrumbs main="Yorum" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid tur-rezervasyon-detay">

      <div class="row">
        <div class="col-md-12" v-if="detay.Durum !== 2">
          <b-alert show variant="warning"><i class="fa fa-warning"></i> Rezervasyon tamamlanmadığı için şuan yorum ve puan alamıyoruz.</b-alert>
        </div>
      </div>

      <div class="row" v-if="detay.Durum === 2">
        <div class="col-md-6" v-if="detay.RehberId > 0">
          <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
            <h5 slot="header">Rehberi Yorumla</h5>
            <table class="product-page-width">
              <tbody>
                <tr>
                  <td class="text-center">
                    <img :src="detay.RehberResim" class="img-thumbnail" style="max-width: 150px;" /><br>
                    <h5 class="mt-2">{{ detay.RehberAdi }}</h5>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-form @submit="onRehberYorumSubmit">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="kayit_adi">Puanınız <b-badge variant="info">{{ rehberYorumForm.Puan }}</b-badge></label>
                  <div class="rating-container digits">
                    <star-rating v-model="rehberYorumForm.Puan" :star-size="40" :max-rating="5"
                      :show-rating="false"></star-rating>
                  </div>
                  <b-form-invalid-feedback :state="kayit_rehber_puan_state">Lütfen 1 ile 10 arasında puan
                    seçiniz.</b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="kayit_rehber_yorum">Yorumunuz <span
                      v-if="kayit_rehber_yorum_state">(Opsiyonel)</span></label>
                  <b-form-textarea type="text" id="kayit_rehber_yorum" v-model="rehberYorumForm.Yorum"
                    :state="kayit_rehber_yorum_state" placeholder="Araç ve Şoför İçin Yorumunuz"></b-form-textarea>
                  <b-form-invalid-feedback :state="kayit_rehber_yorum_state">Lütfen yorumunuzu giriniz.</b-form-invalid-feedback>
                </div>
              </div>
              <b-button type="submit" variant="primary" class="mb-1">Gönder</b-button><br>
              <span v-if="rehberYorum !== null">Not: {{ $moment(String(rehberYorum.KayitTarihi)).format("DD.MM.YYYY") }}
                tarihinde aracı yorumladınız.</span>
            </b-form>
          </b-card>
        </div>

        <div class="col-md-6" v-if="detay.AracId > 0">
          <b-card header-tag="div" header-bg-variant="info" class="card-absolute">
            <h5 slot="header">Araç ve Şoförü Yorumla</h5>
            <table class="product-page-width">
              <tbody>
                <tr>
                  <td class="text-center">
                    <img :src="detay.AracResim" class="img-thumbnail" style="max-width: 150px;" /><br>
                    <h5 class="mt-2">{{ detay.SoforAdi }}</h5>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-form @submit="onAracYorumSubmit">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="kayit_adi">Puanınız <b-badge variant="info">{{ aracYorumForm.Puan }}</b-badge></label>
                  <div class="rating-container digits">
                    <star-rating v-model="aracYorumForm.Puan" :star-size="40" :max-rating="5"
                      :show-rating="false"></star-rating>
                  </div>
                  <b-form-invalid-feedback :state="kayit_arac_puan_state">Lütfen 1 ile 10 arasında puan
                    seçiniz.</b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="kayit_yorum">Yorumunuz <span v-if="kayit_arac_yorum_state">(Opsiyonel)</span></label>
                  <b-form-textarea type="text" id="kayit_yorum" v-model="aracYorumForm.Yorum"
                    :state="kayit_arac_yorum_state" placeholder="Araç ve Şoför İçin Yorumunuz"></b-form-textarea>
                  <b-form-invalid-feedback :state="kayit_arac_yorum_state">Lütfen yorumunuzu giriniz.</b-form-invalid-feedback>
                </div>
              </div>
              <b-button type="submit" variant="primary" class="mb-1">Gönder</b-button><br>
              <span v-if="aracYorum !== null">Not: {{ $moment(String(aracYorum.KayitTarihi)).format("DD.MM.YYYY") }}
                tarihinde aracı yorumladınız.</span>
            </b-form>
          </b-card>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import StarRating from 'vue-star-rating';

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Private Tur Rezervasyonu",

      detay: {},

      aracYorum: null,
      rehberYorum: null,

      aracYorumForm: {
        Puan: 0,
        Yorum: '',
        TurRezervasyonId: this.$route.params.id
      },

      rehberYorumForm: {
        Puan: 0,
        Yorum: '',
        TurRezervasyonId: this.$route.params.id
      },

      breadLinks: [
        {
          path: "/turRezervasyon/detay/" + this.$route.params.id,
          title: "Rezervasyon Detayı",
          icon: "chevron-left",
        },
      ]
    };
  },
  components: {
    StarRating
  },
  computed: {
    kayit_arac_yorum_state() {
      if (this.aracYorumForm.Puan < 3 && (this.aracYorumForm.Yorum == null || this.aracYorumForm.Yorum == '')) return false;
      else return true;
    },
    kayit_arac_puan_state() {
      if (this.aracYorumForm.Puan == 0) return false;
      else return true;
    },
    kayit_rehber_yorum_state() {
      if (this.rehberYorumForm.Puan < 3 && (this.rehberYorumForm.Yorum == null || this.rehberYorumForm.Yorum == '')) return false;
      else return true;
    },
    kayit_rehber_puan_state() {
      if (this.rehberYorumForm.Puan == 0) return false;
      else return true;
    }
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/" + this.id,
        function (response) {
          if (response.Success) {
            vm.detay = response.Data;

            if(vm.detay.AracId > 0){
              vm.onAracYorum();
            }
            if(vm.detay.RehberId > 0){
              vm.onRehberYorum();
            }
          } else {
            vm.$alert.messageWithTitle("Tur Yorum Detay", response.Message, "error");
          }
        }
      );
    },
    onAracYorum() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/AracYorum/" + this.id,
        function (response) {
          if (response.Success) {
            vm.aracYorum = response.Data;

            vm.aracYorumForm.Puan = response.Data.Puan;
            vm.aracYorumForm.Yorum = response.Data.Yorum;
          }
        }, function (error) {

        }
      );
    },
    onAracYorumSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_arac_yorum_state &&
        this.kayit_arac_puan_state;

      if (isValid) {
        let vm = this;

        let url = "/TurRezervasyonlari/AracYorum/" + vm.id;
        let method = "post";
        let messageTitle = "Yeni Araç Yorumu";
        if (vm.aracYorum !== null) {
          url = "/TurRezervasyonlari/AracYorum/" + vm.aracYorum.Id;
          method = "put";
          messageTitle = "Araç Yorum Güncellendi";
        }

        vm.$connection.post(
          url,
          vm.aracYorumForm,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.onDetay();
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
    onRehberYorum() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/RehberYorum/" + this.id,
        function (response) {
          if (response.Success) {
            vm.rehberYorum = response.Data;

            vm.rehberYorumForm.Puan = response.Data.Puan;
            vm.rehberYorumForm.Yorum = response.Data.Yorum;
          }
        }, function (error) {

        }
      );
    },
    onRehberYorumSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_rehber_yorum_state &&
        this.kayit_rehber_puan_state;

      if (isValid) {
        let vm = this;

        let url = "/TurRezervasyonlari/RehberYorum/" + vm.id;
        let method = "post";
        let messageTitle = "Yeni Rehber Yorumu";
        if (vm.rehberYorum !== null) {
          url = "/TurRezervasyonlari/RehberYorum/" + vm.rehberYorum.Id;
          method = "put";
          messageTitle = "Rehber Yorum Güncellendi";
        }

        vm.$connection.post(
          url,
          vm.rehberYorumForm,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.onDetay();
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    }
  },
};
</script>
