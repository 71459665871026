<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-input-group class="datatable-btn">
          <b-form-input v-model="filter" placeholder="Aranacak kelime"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Temizle</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-cols="2" label="Her sayfa" class="mb-0 datatable-select">
          <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="table-responsive datatable-vue">
      <b-table show-empty responsive stacked="md" :items="items" :fields="tablefields" :filter="filter"
        :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
        <template #empty="">
          <h6 class="text-center">Kayıt yok</h6>
        </template>
        <template #emptyfiltered="">
          <h6 class="text-center">Kayıt bulunamadı</h6>
        </template>
        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key"
            :style="{ width: field.key === 'Islem' ? '5%' : '180px' }" />
        </template>
        <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
          <slot :name="slotName" v-bind="scope" />
        </template>
      </b-table>
    </div>

    <b-col md="6" class="my-1">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    tablefields: [],
    items: [],
  },
  data() {
    return {
      filter: null,

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 50, 100],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  watch: {
    items(newValue, oldValue) {
      this.totalRows = newValue.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
