<template>
  <div>
    <div class="row">
      <div class="col-md-9">
        <Breadcrumbs main="Mağaza" title="Kontrol Paneli" />
      </div>
      <div class="col-md-3 text-right mt-4 pr-5">
        <Ipucu tip="mekan" />
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-form @submit="onFiltreSubmit" class="pull-right">
            <b-input-group prepend="Tarih">
              <template #prepend>
                <b-button @click="gun(-1)" variant="light"><i class="fa fa-angle-left"></i></b-button>
                <b-button @click="gun(+1)" variant="light"><i class="fa fa-angle-right"></i></b-button>
              </template>
              <datepicker input-class="datepicker-here form-control digits" v-model="filtre.Tarih" :language="tr"
                placeholder="Tarih" :format="date_format">
              </datepicker>
              <b-input-group-append>
                <b-button type="submit" variant="outline-success">Listele</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </div>
      </div>

      <TurRezervasyonListMekan :tarih="filtre.Tarih" v-if="filtre.Tarih != null" />
    </div>

    <DuyuruGosterimModal />
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import TurRezervasyonListMekan from "@/components/c_tur_rezervasyon_dashboard_mekan.vue";
import DuyuruGosterimModal from "@/components/c_duyuru_gosterim";
import DuyuruGosterimSurekliModal from "@/components/c_duyuru_gosterim_surekli";
import Ipucu from "@/components/c_ipucu";

export default {
  data() {
    return {
      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre: {
        Tarih: null,
      },
    };
  },
  components: {
    Datepicker,
    TurRezervasyonListMekan,
    DuyuruGosterimModal,
    DuyuruGosterimSurekliModal,
    Ipucu
  },
  mounted() {
    let user = this.$user.getUser();
    if (user.Kullanici.RedirectUrl !== "/dashboard/mekan")
      this.$router.push(user.Kullanici.RedirectUrl);

    let bugun = new Date();
    this.filtre.Tarih = bugun;
  },
  methods: {
    onFiltreSubmit(target) {
      target.preventDefault();
    },
    gun(sayi) {
      this.filtre.Tarih = this.$formatHelper.addDay(
        new Date(this.filtre.Tarih),
        sayi
      );
    }
  }
};
</script>
