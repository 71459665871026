<template>
  <div>
    <Breadcrumbs main="Rehber Yoğunluk" title="Yoğunluk Takvimi" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
            <h5 slot="header">Yoğunluk Takvimi</h5>
            <b-card-body class="p-1">
              <vc-calendar class="custom-calendar max-w-full" @update:from-page="updatepage" :masks="masks" :attributes="attributes" disable-page-swipe
                is-expanded>
                <template v-slot:day-content="{ day, attributes }">
                  <div class="flex flex-col h-full z-10 overflow-hidden">
                    <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                    <div class="flex-grow overflow-y-auto overflow-x-auto text-center">
                      <span v-for="attr in attributes" :key="attr.key"
                        class="text-xs leading-tight rounded-sm p-1 mt-1 mr-1" :class="attr.customData.class">
                        {{ attr.customData.title }}
                      </span>
                    </div>
                  </div>
                </template>
              </vc-calendar>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();

    return {
      masks: {
        weekdays: 'WWW',
      },
      attributes: [],
      filtre: {
        Ay: 0,
        Yil: 2024
      }
    };
  },
  mounted() {
    let bugun = new Date();
    this.doldur(bugun.getMonth() + 1, bugun.getFullYear());
  },
  components: {},
  methods: {
    doldur(ay, yil) {
      let vm = this;

      this.$connection.get("/rehberler/yogunlukTakvimi/" + ay + "/" + yil, function (response) {
        vm.attributes = [];
        for (let index = 0; index < response.length; index++) {
          const item = response[index];
          vm.attributes.push({
            key: 1,
            customData: {
              title: item.Acik,
              class: 'bg-success text-white',
            },
            dates: item.Tarih
          });
          vm.attributes.push({
            key: 2,
            customData: {
              title: item.Kapali,
              class: 'bg-danger text-white',
            },
            dates: item.Tarih
          });
        }
      });
    },
    updatepage(page) {
      this.doldur(page.month, page.year);
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%
}

.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0
}

.custom-calendar.vc-container .vc-weeks {
  padding: 0
}

.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0
}

.custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: #fff
}

.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff
}

.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border)
}

.custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: var(--day-border-highlight)
}

.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border)
}

.custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px
}

.custom-calendar.vc-container .day-label {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  display: block;
}
</style>