<template>
  <div class="default-according style-1 mb-3" v-if="yorumlar.length > 0">
    <b-card no-body>
      <b-card-header header-tag="div" role="tab" header-bg-variant="warning">
        <h5 class="mb-0">
          <b-button v-b-toggle.accordion-yorum><b-badge variant="info">{{ yorumlar.length }}</b-badge> Görüşünüzü
            Bekleyen Geri Bildiriminiz Var</b-button>
        </h5>
      </b-card-header>
      <b-collapse id="accordion-yorum" accordion="my-accordion-yorum" role="tabpanel">
        <b-card-body class="row pricing-content">
          <div class="col-xl-6 col-sm-12 mb-3" v-for="yorum in yorumlar" :key="yorum.Id">
            <div class="card pricing-simple">
              <div class="card-body">
                <h6>Rezervasyon Kodu : {{ yorum.TurRezervasyonKodu }}</h6>
                <p><strong>Geri Bildirim : </strong> {{ yorum.Yorum }}</p>
              </div>
              <b-button @click="savunmaFormAc(yorum.Id)" class="btn btn-lg btn-primary btn-block text-center"><h5 class="mb-0">Görüşünüzü Gönderin</h5></b-button>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-modal id="yorumSavunma" centered title="Geri Bildiriminiz" @ok="savunmaSubmit" ok-title="Gönder"
      cancel-title="Kapat" class="theme-modal">
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="kayit_kullaniciadi">Geri Bildirim</label>
          <b-form-textarea rows="7" id="kayit_kullaniciadi" v-model="savunmaForm.Savunma"
            :state="yorumsavunma_savunma_state"></b-form-textarea>
          <b-form-invalid-feedback :state="yorumsavunma_savunma_state">Lütfen en az 50 en fazla 2000 karakterden oluşan geri
            bildiriminizi giriniz.</b-form-invalid-feedback>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
  },
  data() {
    return {
      yorumlar: [],

      aktifYorumId: null,
      savunmaForm: {
        Savunma: null
      }
    };
  },
  mounted() {
    this.savunmaBekleyenList();
  },
  computed: {
    yorumsavunma_savunma_state() {
      if (this.savunmaForm.Savunma == null || this.savunmaForm.Savunma == "" || this.savunmaForm.Savunma.length < 50 || this.savunmaForm.Savunma.length > 2000)
        return false;
      else return true;
    }
  },
  methods: {
    savunmaBekleyenList() {
      let vm = this;
      this.$connection.get("/araclar/savunmaBekleyenler", function (response) {
        vm.yorumlar = response;
      });
    },
    savunmaFormAc(id){
      this.aktifYorumId = id;
      this.savunmaForm.Savunma = null;
      this.$bvModal.show("yorumSavunma");
    },
    savunmaSubmit(target) {
      target.preventDefault();

      let isValid = this.yorumsavunma_savunma_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/araclar/yorumlari/" + this.aktifYorumId + "/savunmaYap",
          vm.savunmaForm,
          "post",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Geri Bildirim",
                "Geri bildiriminiz alındı. Görüşleriniz değerlendirilecektir. Teşekkür ederiz.",
                "success",
                function () {
                  vm.$bvModal.hide("yorumSavunma");
                  vm.savunmaBekleyenList();
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Geri Bildirim",
              error.Message,
              "error"
            );
          }
        );
      }
    },
  },
};
</script>
