<template>
  <div>
    <Breadcrumbs main="Mekanlar" title="Liste" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <b-form @submit="onFiltreSubmit" inline>
                <label class="sr-only" for="inline-form-input-name">Kategori</label>
                <b-form-select id="filtre_kategoriId" v-model="filtre.KategoriId" :options="filtre_kategoriler"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <label class="sr-only" for="inline-form-input-name">Kelime</label>
                <b-form-input type="text" id="filtre_kelime" v-model="filtre.Kelime" placeholder="Kelime"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>

                <b-button type="submit" class="pull-right" variant="warning">Listele</b-button>
              </b-form>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link :to="`/mekan/detay/${row.item.Id}`" class="btn btn-info btn-xs">Detay</router-link>
                    <router-link :to="`/mekan/kayit/${row.item.Id}`" class="btn btn-success btn-xs">Düzelt</router-link>
                    <b-button size="xs" @click="sil(row.item)" variant="danger">Sil</b-button>
                  </b-btn-group>
                </template>
                <template #cell(GSM)="data">
                  <a :href="'tel:0090' + data.value">{{ data.value }}</a>
                </template>
                <template #cell(Email)="data">
                  <a :href="'mailto:' + data.value">{{ data.value }}</a>
                </template>
                <template #cell(ToplamBorc)="data">
                  <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                </template>
                <template #cell(Aktif)="data">
                  <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      breadLinks: [{ path: "/mekan/kayit", title: "Ekle", icon: "plus" }],
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "KategoriAdi", label: "Kategori", sortable: true },
        { key: "Adi", label: "Mekan Adı", sortable: true },
        { key: "YetkiliKisi", label: "Yetkili", sortable: true },
        { key: "GSM", label: "GSM", sortable: true, thClass: "sabitKol" },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol" },
        { key: "KomisyonOrani", label: "Komisyon O.", sortable: true },
        { key: "ToplamBorc", label: "Bakiye", sortable: true },
        { key: "Aktif", label: "Aktif", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],

      filtre_kategoriler: [
        {
          value: null,
          text: "Hepsi",
        },
      ],
      filtre: {
        KategoriId: null,
        Kelime: null,
      },
    };
  },
  mounted() {
    this.onKategoriList();
    this.onList();
  },
  methods: {
    onFiltreSubmit(evt) {
      evt.preventDefault();
      this.onList();
    },
    onKategoriList() {
      let vm = this;

      this.$connection.get("/kategoriler/select/1", function (response) {
        vm.filtre_kategoriler = vm.filtre_kategoriler.concat(response);
      });
    },
    onList() {
      let vm = this;

      this.$connection.post(
        "/mekanlar/filtre",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Mekan kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/mekanlar/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Mekan kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
  },
};
</script>
