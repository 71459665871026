<template>
  <div>
    <Breadcrumbs main="Transfer Rezervasyonu" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
            <li class="nav-item" v-on:click="changetab('bolge')">
              <a class="nav-link" :class="{ 'show active': showtab == 'bolge' }" id="top-bolge" data-toggle="tab"
                role="tab" aria-controls="bolge" aria-selected="true">Bölgeler</a>
            </li>
            <li class="nav-item" v-on:click="changetab('aracTipi')">
              <a class="nav-link" :class="{ 'show active': showtab == 'aracTipi' }" id="top-aracTipi" data-toggle="tab"
                role="tab" aria-controls="aracTipi" aria-selected="true">Araç Tipleri
              </a>
            </li>
            <li class="nav-item" v-on:click="changetab('fiyat')">
              <a class="nav-link" :class="{ 'show active': showtab == 'fiyat' }" id="top-fiyat" data-toggle="tab"
                role="tab" aria-controls="fiyat" aria-selected="false">Fiyatlar</a>
            </li>
            <li class="nav-item" v-on:click="changetab('ulke')">
              <a class="nav-link" :class="{ 'show active': showtab == 'ulke' }" id="top-ulke" data-toggle="tab"
                role="tab" aria-controls="ulke" aria-selected="false">Ülkeler</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div class="tab-pane fade" :class="{ 'show active': showtab == 'bolge' }" id="bolge" role="tabpanel"
          aria-labelledby="bolge">

          <b-button variant="success" :disabled="loading" class="mb-3" @click="bolgeGuncelle()">{{ loading ?
      'İşleniyor' : 'Bölgeleri Laal Sunucusundan Güncelle' }}</b-button>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1" v-for="bolge in bolgeler" :key="'bolge_' + bolge.Id">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle="'bolge' + bolge.Id" variant="info">{{ bolge.Adi }}</b-button>
              </b-card-header>
              <b-collapse :id="'bolge' + bolge.Id" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p v-html="bolge.Aciklama"></p>
                  <h6>Hedef Bölgeleri</h6>
                  <b-table striped hover :fields="hedefBolgeFields" :items="bolge.HedefBolgeler"></b-table>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'aracTipi' }" id="aracTipi" role="tabpanel"
          aria-labelledby="aracTipi">
          <b-button variant="success" :disabled="loading" class="mb-3" @click="aracTipiGuncelle()">{{ loading ?
      'İşleniyor' : 'Araç Tiplerini Laal Sunucundan Güncelle' }}</b-button>

          <b-table striped hover :fields="aracTipiFields" :items="aracTipleri"></b-table>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'fiyat' }" id="fiyat" role="tabpanel"
          aria-labelledby="fiyat">
          <b-button variant="success" :disabled="loading" class="mb-3" @click="fiyatlariGuncelle()">{{ loading ?
      'İşleniyor' : 'Fiyatları Laal Sunucundan Güncelle' }}</b-button>

          <b-table striped hover :fields="fiyatFields" :items="fiyatlar">
            <template #cell(Fiyat)="data">
              <c-money-cell :fiyat="data.value" simge="₺"></c-money-cell>
            </template>
            <template #cell(SatisFiyat)="row">
              <b-form-input type="text" v-model="row.item.SatisFiyat" placeholder="Satış Fiyatı"></b-form-input>
            </template>
            <template #cell(Islem)="row">
              <b-btn-group>
                <b-button @click="fiyatGuncelle(row.item)" variant="success">Güncelle</b-button>
              </b-btn-group>
            </template>
          </b-table>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'ulke' }" id="ulke" role="tabpanel"
          aria-labelledby="ulke">
          <b-button variant="success" :disabled="loading" class="mb-3" @click="ulkeGuncelle()">{{ loading ?
      'İşleniyor' : 'Ülkeleri Laal Sunucundan Güncelle' }}</b-button>

          <b-table striped hover :fields="ulkeFields" :items="ulkeler"></b-table>
        </div>
      </div>

    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      pageTitle: "Laal Tanımlar",

      breadLinks: [],

      loading: false,

      bolgeler: [],
      aracTipleri: [],
      fiyatlar: [],
      ulkeler: [],

      hedefBolgeFields: [
        { key: "Id", label: "Id", sortable: false },
        { key: "Tanim", label: "Tanım", sortable: false }
      ],
      aracTipiFields: [
        { key: "Id", label: "Id", sortable: false },
        { key: "Tanim", label: "Tanım", sortable: false },
        { key: "MaxKisi", label: "Maksimum Kişi", sortable: false }
      ],
      fiyatFields: [
        { key: "BolgeAdi", label: "Bölge", sortable: false },
        { key: "HedefBolgeAdi", label: "Hedef Bölge", sortable: false },
        { key: "AracTipiAdi", label: "Araç Tipi", sortable: false },
        { key: "Fiyat", label: "Laal Fiyat", sortable: false },
        { key: "SatisFiyat", label: "Satış Fiyat", sortable: false },
        { key: "Islem", label: "Güncelle", sortable: false }
      ],
      ulkeFields: [
        { key: "Id", label: "Id", sortable: false },
        { key: "Ulke", label: "Ülke", sortable: false },
        { key: "UzunAdi", label: "Ülke Uzun Adı", sortable: false },
        { key: "Kod", label: "Ülke Kodu", sortable: false }
      ],

      showtab: "bolge"
    };
  },
  components: {},
  computed: {},
  mounted() {
    this.onBolgeList();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;

      switch (tab) {
        case "bolge":
          this.onBolgeList();
          break;
        case "aracTipi":
          this.onAracTipiList();
          break;
        case "ulke":
          this.onUlkeList();
          break;
        case "fiyat":
          this.onFiyatList();
          break;
      }
    },
    onBolgeList() {
      let vm = this;
      this.$connection.get(
        "/laalTransferler/BolgeHedefBolgeler",
        function (response) {
          vm.bolgeler = response;
        }
      );
    },
    bolgeGuncelle() {
      let vm = this;
      
      vm.loading = true;
      
      this.$connection.get(
        "/laalTransferler/bolgeleriAktar",
        function (response) {
          if (response.Success) {

            vm.$connection.get(
              "/laalTransferler/HedefBolgelerAktar",
              function (response) {
                if (response.Success) {
                  vm.loading = false;
                  vm.$alert.messageWithTitle("Bölge Güncelleme", "Bölge ve hedef bölgeleri güncellendi", "success");
                  vm.onBolgeList();
                } else {
                  vm.loading = false;
                  vm.$alert.messageWithTitle("Hedef Bölge Güncelleme", response.Message, "error");
                }
              },
              function (error) {
                vm.loading = false;
                vm.$alert.messageWithTitle("Hedef Bölge Güncelleme", error, "error");
              }
            );

          } else {
            vm.loading = false;
            vm.$alert.messageWithTitle("Bölge Güncelleme", response.Message, "error");
          }
        },
        function (error) {
          vm.loading = false;
          vm.$alert.messageWithTitle("Hedef Bölge Güncelleme", error, "error");
        }
      );
    },
    onAracTipiList() {
      let vm = this;
      this.$connection.get(
        "/laalTransferler/AracTipleri",
        function (response) {
          vm.aracTipleri = response;
        }
      );
    },
    aracTipiGuncelle() {
      let vm = this;
      
      vm.loading = true;

      this.$connection.get(
        "/laalTransferler/aracTipleriAktar",
        function (response) {
          if (response.Success) {
            vm.loading = false;
            vm.$alert.messageWithTitle("Araç Tipi Güncelleme", "Araç tipleri güncellendi", "success");
            vm.onAracTipiList();
          } else {
            vm.loading = false;
            vm.$alert.messageWithTitle("Araç Tipi Güncelleme", response.Message, "error");
          }
        }
      );
    },
    onFiyatList() {
      let vm = this;
      this.$connection.get(
        "/laalTransferler/Fiyatlar",
        function (response) {
          vm.fiyatlar = response;
        }
      );
    },
    fiyatlariGuncelle() {
      let vm = this;
      
      vm.loading = true;

      this.$connection.get(
        "/laalTransferler/FiyatlarAktar",
        function (response) {
          if (response.Success) {
            vm.loading = false;
            vm.$alert.messageWithTitle("Fiyatlar Güncelleme", "Fiyatlar güncellendi", "success");
            vm.onFiyatList();
          } else {
            vm.loading = false;
            vm.$alert.messageWithTitle("Fiyatlar Güncelleme", response.Message, "error");
          }
        }
      );
    },
    fiyatGuncelle(fiyat) {
      let vm = this;

      let form = {
        BolgeId: fiyat.BolgeId,
        HedefBolgeId: fiyat.HedefBolgeId,
        AracTipiId: fiyat.AracTipiId,
        SatisFiyat: fiyat.SatisFiyat
      };

      vm.$connection.post(
        "/laalTransferler/fiyatGuncelle",
        form,
        "put",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithTitle("Fiyat Güncelle", "Fiyat Güncellendi.", "success");
          }
        },
        function (error) {
          vm.$alert.messageWithTitle("Fiyat Güncelle", error.Message, "error");
        }
      );
    },
    onUlkeList() {
      let vm = this;
      this.$connection.get(
        "/laalTransferler/Ulkeler",
        function (response) {
          vm.ulkeler = response;
        }
      );
    },
    ulkeGuncelle() {
      let vm = this;

      vm.loading = true;

      this.$connection.get(
        "/laalTransferler/UlkelerAktar",
        function (response) {
          if (response.Success) {
            vm.loading = false;
            vm.$alert.messageWithTitle("Ülke Güncelleme", "Ülkeler güncellendi", "success");
            vm.onUlkeList();
          } else {
            vm.loading = false;
            vm.$alert.messageWithTitle("Ülke Güncelleme", response.Message, "error");
          }
        }
      );
    }
  }
};
</script>
