<template>
  <div>
    <Breadcrumbs main="Acenteler" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Acente Kaydı</h5>
              <span>Yeni acente veya acente kaydında değişiklikler için aşağıdaki
                formu kullanabilirsiniz</span>
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_tipi">Tipi</label>
                    <b-form-select id="kayit_tipi" v-model="kayit.Tipi" :options="kayit_tipi_options"></b-form-select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Acente Adı</label>
                    <b-form-input type="text" id="kayit_adi" v-model="kayit.Adi" :state="kayit_adi_state"
                      placeholder="Acente Adı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adi_state">Acente adını giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_unvan">Unvan</label>
                    <b-form-input type="text" id="kayit_unvan" v-model="kayit.Unvan" :state="kayit_unvan_state"
                      placeholder="Acente Unvanı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_unvan_state">Acente unvanını
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adres">Adres</label>
                    <b-form-textarea type="text" id="kayit_adres" v-model="kayit.Adres" :state="kayit_adres_state"
                      placeholder="Acente Adresi"></b-form-textarea>
                    <b-form-invalid-feedback :state="kayit_adres_state">Acenteın adresini
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_resim">Logo</label>
                    <div class="row">
                      <div class="col-md-9">
                        <b-form-file class="form-control" v-model="kayit.LogoFl" plain></b-form-file>
                        <p>
                          500px X 500px boyutlarında logonuzu atabilirsiniz.
                        </p>
                      </div>
                      <div class="col-md-3">
                        <div class="avatar" v-if="kayit.Logo !== '' && kayit.Logo !== undefined">
                          <img class="img-40 rounded-circle" :src="kayit.Logo" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sehirid">Şehir</label>
                    <b-form-select id="kayit_sehirid" v-model="kayit.SehirId" :options="kayit_sehir_options"
                      :state="kayit_sehirid_state"></b-form-select>
                    <b-form-invalid-feedback :state="kayit_sehirid_state">Şehrini seçiniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tursabbelgeno">TÜRSAB İşletme Belge No</label>
                    <b-form-input type="text" id="kayit_tursabbelgeno" v-model="kayit.TursabBelgeNo"
                      placeholder="TÜRSAB İşletme Belge No"></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_vergidairesi">Vergi Dairesi</label>
                    <b-form-input type="text" id="kayit_vergidairesi" v-model="kayit.VergiDairesi"
                      placeholder="Vergi Dairesi"></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_vergiNo">Vergi No</label>
                    <b-form-input type="text" id="kayit_vergiNo" v-model="kayit.VergiNo" :state="kayit_vergino_state"
                      placeholder="T.C. Kimlik No veya Vergi No"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_vergino_state">Bireyselse 11 haneli T.C. kimlik numarası
                      kurumsal ise 10
                      haneli vergi numarasını giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_yetkilikisi">Yetkili Kişi</label>
                    <b-form-input type="text" id="kayit_yetkilikisi" v-model="kayit.YetkiliKisi"
                      :state="kayit_yetkilikisi_state" placeholder="Yetkili Kişi"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_yetkilikisi_state">Yetkili kişi adını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_yetkili_unvan">Yetkili Kişi Unvanı</label>
                    <b-form-input type="text" id="kayit_yetkili_unvan" v-model="kayit.YetkiliUnvan"
                      placeholder="Yetkili Kişi Unvanı"></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_gsm">Cep Telefonu</label>
                    <b-form-input type="text" id="kayit_gsm" v-model="kayit.GSM" v-mask="'5## ### ## ##'"
                      placeholder="Cep Telefonu" :state="kayit_gsm_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_gsm_state">Cep telefonunu giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_telefon">Telefon</label>
                    <b-form-input type="text" id="kayit_telefon" v-model="kayit.Telefon"
                      placeholder="Telefon"></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_email">Email</label>
                    <b-form-input type="text" id="kayit_email" v-model="kayit.Email" placeholder="Email"
                      :state="kayit_email_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_email_state">Email adresini giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_bilgilendirmemailleri">Bilgilendirme Mailleri</label>
                    <b-form-input type="text" id="kayit_bilgilendirmemailleri" v-model="kayit.BilgilendirmeMailleri"
                      placeholder="Bilgilendirme Mailleri (, koyarak birden fazla yazabilirsiniz)"></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_operasyonyetkilisi">Operasyon Yetkilisi</label>
                    <b-form-input type="text" id="kayit_operasyonyetkilisi" v-model="kayit.OperasyonYetkilisi"
                      placeholder="Operasyon Yetkilisi"></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_operasyontelefonu">Operasyon Telefonu</label>
                    <b-form-input type="text" id="kayit_operasyontelefonu" v-model="kayit.OperasyonTelefonu"
                      placeholder="Operasyon Telefonu"></b-form-input>
                  </div>
                </div>
                <div v-if="isAcente === false">
                  <div class="form-row">
                    <div class="col-md-6 mb-3">
                      <label for="kayit_durum">Durum</label>
                      <b-form-select id="kayit_durum" v-model="kayit.Durum"
                        :options="kayit_durum_options"></b-form-select>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label></label>
                      <div class="checkbox checkbox-dark">
                        <b-form-checkbox name="OzelAcente" v-model="kayit.OzelAcente" :state="kayit.Aktif">Özel
                          Acente</b-form-checkbox><br>
                        <small>Özel Acente : Rehber ve araç seçimlerinde sınırlamalara takılmaz.</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-6 mb-3">
                      <label for="kayit_durum">Komisyon Alma Tarihi</label>
                      <datepicker :language="tr" input-class="datepicker-here form-control digits"
                        id="kayit_komisyonalmatarihi" v-model="kayit.KomisyonAlmaTarihi" :clear-button="true"
                        clear-button-icon="fa fa-times" placeholder="Komisyon Alma Tarihi" :format="date_format">
                      </datepicker>
                    </div>
                    <div class="col-md-6 mb-3">
                    </div>
                  </div>
                </div>
                <router-link :to="'/acente/list'" class="btn btn-light pull-left">Geri Dön</router-link>
                <b-button type="submit" class="pull-right" variant="primary">Kaydet</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Acente",

      breadLinks: [],

      date_format: "dd.MM.yyyy",
      tr: tr,

      kayit: {
        Adi: "",
        Unvan: "",
        YetkiliKisi: "",
        Adres: "",
        SehirId: 0,
        VergiDairesi: "",
        VergiNo: "",
        LogoFl: null,
        Telefon: "",
        GSM: "",
        Email: "",
        Durum: 30,
        BilgilendirmeMailleri: "",
        OperasyonYetkilisi: "",
        OperasyonTelefonu: "",
        LaalEntegrasyonAyarlariJson: "",
        YetkiliUnvan: "",
        TursabBelgeNo: "",
        Tipi: 0
      },

      kayit_sehir_options: [{ value: "0", text: "Seçiniz" }],

      kayit_durum_options: [
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" }
      ],

      kayit_tipi_options: [
        { value: "0", text: "Acente" },
        { value: "10", text: "Otel" }
      ],

      isAcente: this.$user.isAcente(),
    };
  },
  mounted() {
    let vm = this;

    if (this.$user.isAcente() === false) {
      this.breadLinks.push({ path: "/Acente/list", title: "Acenteler", icon: "chevron-left" });
      this.breadLinks.push({ path: "/acente/detay/" + this.$route.params.id, title: "Detay", icon: "zoom-in", });
    }

    this.onSehirList();

    if (this.id !== undefined) {
      vm.pageTitle = "Acente Düzelt";

      this.$connection.get("/Acenteler/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle("Yeni Acente", response.Message, "error");
        }
      });
    }
  },
  components: {
    Datepicker
  },
  computed: {
    kayit_adi_state() {
      if (this.kayit.Adi == null || this.kayit.Adi == "") return false;
      else return true;
    },
    kayit_unvan_state() {
      if (this.kayit.Unvan == null || this.kayit.Unvan == "") return false;
      else return true;
    },
    kayit_yetkilikisi_state() {
      if (this.kayit.YetkiliKisi == null || this.kayit.YetkiliKisi == "")
        return false;
      else return true;
    },
    kayit_adres_state() {
      if (this.kayit.Adres == null || this.kayit.Adres == "") return false;
      else return true;
    },
    kayit_sehirid_state() {
      if (this.kayit.SehirId == "0") return false;
      else return true;
    },
    kayit_vergino_state() {
      if (
        this.kayit.VergiNo == null ||
        this.kayit.VergiNo == "" ||
        this.kayit.VergiNo.length < 10 ||
        this.kayit.VergiNo.length > 11
      )
        return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
  },
  methods: {
    onSehirList() {
      let vm = this;

      this.$connection.get("/sehirler/select", function (response) {
        vm.kayit_sehir_options = vm.kayit_sehir_options.concat(response);
      });
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adi_state &&
        this.kayit_unvan_state &&
        this.kayit_yetkilikisi_state &&
        this.kayit_adres_state &&
        this.kayit_sehirid_state &&
        this.kayit_vergino_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state;

      if (isValid) {
        let vm = this;

        let url = "/Acenteler";
        let method = "post";
        let messageTitle = "Yeni Acente";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Değişiklikler Güncellendi";
        }

        if (this.kayit.KomisyonAlmaTarihi != null) {
          let tarih = this.$formatHelper.formatDate(this.kayit.KomisyonAlmaTarihi);
          let arrTarih = tarih.split(".");
          this.kayit.KomisyonAlmaTarihi = arrTarih[2] + "-" + arrTarih[1] + "-" + arrTarih[0];
        }

        vm.$connection.postData(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                '',
                "success",
                function () {
                  if (vm.isAcente) {
                    vm.$router.push("/acente/profil");
                  }
                  else {
                    if (yeniKayit)
                      vm.$router.push("/acente/detay/" + response.Data);
                    else
                      vm.$router.push("/acente/detay/" + vm.id);
                  }
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
