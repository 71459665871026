<template>
  <div class="card rezervasyon-form">
    <div class="card-header">
      <div class="row">
        <div class="col-md-4">
          <b-form-group>
            <b-form-radio-group @change="ciftYonSec()" buttons button-variant="outline-primary"
              v-model="kayit.isCiftYon" :options="yon_options"></b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group>
            <b-form-radio-group buttons button-variant="outline-success" v-model="kayit.Donus"
              :options="donus_options"></b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-2 text-right">
          <b-button @click="cikis()" variant="dark"><i class="fa fa-times"></i></b-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">

            </div>
            <div class="col-md-6 text-right">
              <h6 class="text-danger">BELİRTİLEN FİYATLARA KDV DAHİL DEĞİLDİR.</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h5><span class="badge badge-primary">{{ kayit.Donus ? 'Dönüş' : 'Geliş' }}</span></h5>
            </div>
            <div class="col-md-6 text-right">
              <h5>Transfer Ücreti : <b-badge variant="info">{{ $formatHelper.formatMoney(transferFiyati)
                  }}₺</b-badge></h5>
            </div>
          </div>
          <div class="form-row transfer-form-donus-row">
            <div :class="'col-md-6 mb-3 transfer-form-donus nereden ' + (kayit.Donus ? 'donus' : 'gelis')">
              <b-form-select id="kayit_bolgeid" @change="hedefBolgeList()" v-model="kayit.BolgeId" :options="bolgeler"
                :state="kayit_bolgeid_state"></b-form-select>
              <b-form-invalid-feedback :state="kayit_bolgeid_state">Lütfen havalimanını
                seçiniz.</b-form-invalid-feedback>
            </div>
            <div :class="'col-md-6 mb-3 transfer-form-donus nereye ' + (kayit.Donus ? 'donus' : 'gelis')">
              <b-input-group>
                <b-form-input type="text" id="kayit_nereye" v-model="kayit.Nereye" :state="kayit_nereye_state"
                  placeholder="Otel Adı"></b-form-input>
                <b-input-group-append v-if="hedefBolgeler.length > 2">
                  <b-form-select id="kayit_hedefbolgeid" v-model="kayit.HedefBolgeId" :options="hedefBolgeler"
                    :state="kayit_nereye_state"></b-form-select>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback :state="kayit_nereye_state">Lütfen otel adını yazınız.</b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-4 mb-3">
              <label for="kayit_tarih">Tarih</label>
              <datepicker :language="tr" input-class="datepicker-here form-control digits" id="kayit_tarih"
                v-model="kayit.Tarih" placeholder="Transfer Tarihi" :format="date_format" :state="kayit_tarih_state">
              </datepicker>
              <b-form-invalid-feedback :state="kayit_tarih_state">Tur tarihini giriniz</b-form-invalid-feedback>
            </div>
            <div class="col-md-2 mb-3">
              <label for="kayit_saat">Saat</label>
              <b-form-input type="time" v-model="kayit.SaatFull" id="kayit_saat"
                :state="kayit_saatfull_state"></b-form-input>
              <b-form-invalid-feedback :state="kayit_saatfull_state">Tur saatini giriniz</b-form-invalid-feedback>
            </div>
            <div class="col-md-6 mb-3">
              <label for="kayit_nereyeadres">&nbsp;</label>
              <b-form-input type="text" id="kayit_nereyeadres" v-model="kayit.NereyeAdres"
                placeholder="Otel Adresi"></b-form-input>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <b-form-textarea id="kayit_aciklama" v-model="kayit.Aciklama" placeholder="Açıklama"></b-form-textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 mb-3">
              <b-form-select id="kayit_aractipiid" @change="aracTipiSec()" v-model="kayit.AracTipiId"
                :options="aracTipleri" :state="kayit_aractipi_state"></b-form-select>
              <b-form-invalid-feedback :state="kayit_aractipi_state">Araç tipini seçiniz</b-form-invalid-feedback>
            </div>
            <div class="col-md-6 mb-3">
              <b-form-input type="text" id="kayit_ucuskodu" v-model="kayit.UcusKodu" :state="kayit_ucuskodu_state"
                placeholder="Uçuş Kodu"></b-form-input>
              <b-form-invalid-feedback :state="kayit_ucuskodu_state">Uçuş kodunu yazınız</b-form-invalid-feedback>
            </div>
          </div>

          <div class="transfer-form-ciftyon" v-if="kayit.isCiftYon">
            <h5><span class="badge badge-primary">{{ kayit.Donus == false ? 'Dönüş' : 'Geliş' }}</span></h5>
            <div class="form-row transfer-form-donus-row">
              <div :class="'col-md-6 mb-3 transfer-form-donus nereden ' + (kayit.Donus == false ? 'donus' : 'gelis')">
                <b-form-select id="kayit_donus_bolgeid" @change="donusHedefBolgeList()" v-model="kayit.DonusBolgeId"
                  :options="bolgeler" :state="kayit_donus_bolgeid_state"></b-form-select>
                <b-form-invalid-feedback :state="kayit_donus_bolgeid_state">Lütfen havalimanını
                  seçiniz.</b-form-invalid-feedback>
              </div>
              <div :class="'col-md-6 mb-3 transfer-form-donus nereye ' + (kayit.Donus == false ? 'donus' : 'gelis')">
                <b-input-group>
                  <b-form-input type="text" id="kayit_donus_nereye" v-model="kayit.DonusNereye"
                    :state="kayit_donus_nereye_state" placeholder="Otel Adı"></b-form-input>
                  <b-input-group-append v-if="donusHedefBolgeler.length > 2">
                    <b-form-select id="kayit_donus_hedefbolgeid" v-model="kayit.DonusHedefBolgeId"
                      :options="donusHedefBolgeler" :state="kayit_donus_nereye_state"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="kayit_donus_nereye_state">Lütfen otel adını
                  yazınız.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label for="kayit_donus_tarih">Tarih</label>
                <datepicker :language="tr" input-class="datepicker-here form-control digits" id="kayit_donus_tarih"
                  v-model="kayit.DonusTarih" placeholder="Transfer Tarihi" :format="date_format"
                  :state="kayit_donus_tarih_state">
                </datepicker>
                <b-form-invalid-feedback :state="kayit_donus_tarih_state">Tur tarihini giriniz</b-form-invalid-feedback>
              </div>
              <div class="col-md-2 mb-3">
                <label for="kayit_donus_saat">Saat</label>
                <b-form-input type="time" v-model="kayit.DonusSaatFull" id="kayit_donus_saat"
                  :state="kayit_donus_saatfull_state"></b-form-input>
                <b-form-invalid-feedback :state="kayit_donus_saatfull_state">Tur saatini
                  giriniz</b-form-invalid-feedback>
              </div>
              <div class="col-md-6 mb-3">
                <label for="kayit_donus_nereyeadres">&nbsp;</label>
                <b-form-input type="text" id="kayit_nereyeadres" v-model="kayit.DonusNereyeAdres"
                  placeholder="Otel Adresi"></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <b-form-textarea v-model="kayit.DonusAciklama" placeholder="Açıklama"></b-form-textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3"></div>
              <div class="col-md-6 mb-3">
                <b-form-input type="text" id="kayit_donus_ucuskodu" v-model="kayit.DonusUcusKodu"
                  :state="kayit_donus_ucuskodu_state" placeholder="Uçuş Kodu"></b-form-input>
                <b-form-invalid-feedback :state="kayit_donus_ucuskodu_state">Uçuş kodunu
                  yazınız</b-form-invalid-feedback>
              </div>
            </div>
          </div>

          <hr />

          <div class="form-row">
            <div class="col-md-3 mb-5 b-primary b-r-3 p-2">
              <h4 class="f-w-600">Misafirler Ekle</h4>
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="kayit_yolcu_pasaport">Pasaport No / T.C. Kimlik No</label>
                  <b-form-input type="text" id="kayit_yolcu_pasaport" v-model="misafirKayit.PasaportNo"
                    placeholder="Pasaport No / T.C. Kimlik No" :state="misafirekle_pasaportno_state"></b-form-input>
                  <b-form-invalid-feedback :state="misafirekle_pasaportno_state">Pasaport veya T.C. kimlik
                    numarasını
                    giriniz.</b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="kayit_yolcu_adsoyad">Adı Soyadı</label>
                  <b-form-input type="text" id="kayit_yolcu_adsoyad" v-model="misafirKayit.AdSoyad"
                    placeholder="Adı Soyadı" :state="misafirekle_adsoyad_state"></b-form-input>
                  <b-form-invalid-feedback :state="misafirekle_adsoyad_state">Misafirin adını ve soyadını
                    giriniz.</b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="kayit_yolcu_uyruk">Uyruk</label>
                  <multiselect id="kayit_yolcu_uyruk" v-model="misafirKayit.UyrukSelect" tag-placeholder="Uyruk Seçiniz"
                    placeholder="Uyruk seçiniz" label="text" selectLabel="" deselectLabel="" selectedLabel=""
                    track-by="value" :options="ulkeler"></multiselect>
                  <b-form-invalid-feedback :state="misafirekle_uyruk_state">Misafirin uyruğunu
                    seçiniz.</b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="kayit_yolcu_cinsiyet">Cinsiyet</label>
                  <b-form-select id="kayit_yolcu_cinsiyet" v-model="misafirKayit.Cinsiyet" :options="cinsiyet_options"
                    :state="misafirekle_cinsiyet_state"></b-form-select>
                  <b-form-invalid-feedback :state="misafirekle_cinsiyet_state">Misafirin cinsiyetini
                    seçiniz.</b-form-invalid-feedback>
                </div>
              </div>
              <b-button type="button" @click="misafirEkle()" variant="info">Ekle</b-button>
            </div>
            <div class="col-md-9 mb-5">
              <h4 class="f-w-600">
                Misafirler
                <b-badge variant="primary">{{ kayit.Yolcular.length }} kişi</b-badge>
              </h4>

              <div class="alert alert-info" v-if="kayit.Yolcular.length === 0">
                Bu Transfer İçin Misafir Girilmemiş
              </div>

              <div class="form-row">
                <div class="col-md-4" v-for="row in kayit.Yolcular" v-bind:key="row.Id">
                  <div class="mb-2 b-dark b-r-3 p-2">
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_pasaport">Pasaport No / T.C. Kimlik No</label>
                        <b-form-input type="text" v-model="row.PasaportNo"
                          placeholder="Pasaport No / T.C. Kimlik No"></b-form-input>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_adsoyad">Adı Soyadı</label>
                        <b-form-input type="text" v-model="row.AdSoyad" placeholder="Adı Soyadı"></b-form-input>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_uyruk">Uyruk</label>
                        <multiselect v-model="row.UyrukSelect" tag-placeholder="Uyruk Seçiniz"
                          placeholder="Uyruk seçiniz" :id="row.Id" label="text" track-by="value" :options="ulkeler"
                          selectLabel="" deselectLabel="" selectedLabel="">
                        </multiselect>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_yas">Cinsiyet</label>
                        <b-form-select v-model="row.Cinsiyet" :options="cinsiyet_options"></b-form-select>
                      </div>
                    </div>
                    <b-button type="button" @click="misafirKaldir(row)" variant="danger">Kaldır</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal id="yukleniyor" ref="mdlTransferFormYukleniyor" :no-close-on-backdrop="true" :no-close-on-esc="true"
          centered :hide-header="true" :hide-footer="true">
          <div class="row">
            <div class="col-md-4">
              <div class="loader-box text-center"><span class="rotate solid colored"></span></div>
            </div>
            <div class="col-md-8">
              <h3 class="mt-5">Transfer Oluşturuluyor. Lütfen Bekleyiniz</h3>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="card-footer">
      <b-button @click="onComplete()" variant="success" :disabled="loading" class="pull-right">{{ loading ?
        'Rezervasyon&nbsp;Oluşturuluyor' : 'Rezervasyon Oluştur' }}</b-button>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import { BFormTimepicker } from "bootstrap-vue";
import Multiselect from "vue-multiselect";

export default {
  props: {
    rezervasyon: {},
    acenteId: "",
    id: undefined,
    misafirler: null,
    rezervasyonVerildi: false
  },
  data() {
    return {
      detayValidMessage: "",

      date_format: "dd.MM.yyyy",
      tr: tr,

      loading: false,

      mutationAcenteId: this.acenteId,

      kayit: {
        ...(this.rezervasyon === undefined
          ? {
            isCiftYon: false,
            BolgeId: 0,
            Nereye: null,
            NereyeAdres: null,
            HedefBolgeId: 0,
            AracTipiId: 0,
            Tarih: null,
            SaatFull: "09:00",
            Saat: 0,
            Dakika: 0,
            UcusKodu: null,
            Aciklama: null,
            Donus: false,
            DonusBolgeId: 0,
            DonusNereye: null,
            DonusHedefBolgeId: 0,
            DonusTarih: null,
            DonusSaatFull: "09:00",
            DonusSaat: 0,
            DonusDakika: 0,
            DonusUcusKodu: null,
            DonusAciklama: null,
            AcentaNo: null,
            Yolcular: []
          }
          : this.rezervasyon),
      },

      yon_options: [
        { text: 'Tek Yön', value: false },
        { text: 'Çift Yön', value: true }
      ],

      donus_options: [
        { text: 'Havalimanından Otele', value: false },
        { text: 'Otelden Havalimanına', value: true }
      ],

      bolgeler: [{ text: 'Havalimanı', value: 0 }],
      hedefBolgeler: [{ text: 'Hedef Bölge', value: 0 }],
      donusHedefBolgeler: [{ text: 'Hedef Bölge', value: 0 }],
      aracTipleri: [{ text: 'Araç Tipi', value: 0 }],
      transferFiyati: 0,

      cinsiyet_options: [
        { text: 'Kadın', value: 'K' },
        { text: 'Erkek', value: 'E' },
        { text: 'Çocuk', value: '3-12' },
        { text: 'Bebek', value: '0-2' },
      ],

      ulkeler: [{ value: "", text: "Uyruk" }],

      misafirKayit: {
        Id: 0,
        PasaportNo: "",
        Uyruk: "",
        UyrukSelect: { value: "", text: "Uyruk" },
        AdSoyad: "",
        Cinsiyet: "K"
      },
    };
  },
  mounted() {
    if (this.rezervasyon === undefined) {
      this.kayit.Tarih = this.addDays(1);
    } else {
      for (let i = 0; i < this.rezervasyon.Yolcular.length; i++) {
        const element = this.rezervasyon.Yolcular[i];
        element.UyrukSelect = { value: element.UlkeKodu, text: element.Ulke };
      }

      this.hedefBolgeList();

      if (this.rezervasyon.isCiftYon) {
        this.donusHedefBolgeList();
      }
    }

    if (this.acenteId === 0) {
      this.mutationAcenteId = this.$user.acenteId();
    }

    this.bolgeList();
    this.aracTipiList();
    this.ulkeList();
  },
  components: {
    Datepicker,
    Multiselect,
    BFormTimepicker
  },
  computed: {
    kayit_bolgeid_state() {
      if (this.kayit.BolgeId == 0)
        return false;
      else return true;
    },
    kayit_nereye_state() {
      if (this.kayit.Nereye == null || this.kayit.Nereye == "" || this.kayit.HedefBolgeId == 0)
        return false;
      else return true;
    },
    kayit_tarih_state() {
      if (this.kayit.Tarih == "" || this.kayit.Tarih == null) return false;
      else return true;
    },
    kayit_saatfull_state() {
      if (this.kayit.SaatFull == "" || this.kayit.SaatFull == null) return false;
      else return true;
    },
    kayit_ucuskodu_state() {
      if (this.kayit.UcusKodu == "" || this.kayit.UcusKodu == null) return false;
      else return true;
    },
    kayit_donus_bolgeid_state() {
      if (this.kayit.isCiftYon && this.kayit.DonusBolgeId == 0)
        return false;
      else return true;
    },
    kayit_donus_nereye_state() {
      if (this.kayit.isCiftYon && (this.kayit.DonusNereye == null || this.kayit.DonusNereye == "" || this.kayit.DonusHedefBolgeId == 0))
        return false;
      else return true;
    },
    kayit_donus_tarih_state() {
      if (this.kayit.isCiftYon && (this.kayit.DonusTarih == "" || this.kayit.DonusTarih == null || this.kayit.DonusTarih <= this.kayit.Tarih)) return false;
      else return true;
    },
    kayit_donus_saatfull_state() {
      if (this.kayit.isCiftYon && (this.kayit.DonusSaatFull == "" || this.kayit.DonusSaatFull == null)) return false;
      else return true;
    },
    kayit_donus_ucuskodu_state() {
      if (this.kayit.isCiftYon && (this.kayit.DonusUcusKodu == "" || this.kayit.DonusUcusKodu == null)) return false;
      else return true;
    },
    kayit_aractipi_state() {
      if (this.kayit.AracTipiId == 0) return false;
      else return true;
    },
    kayit_misafir_state() {
      if (this.kayit.Yolcular == null || this.kayit.Yolcular.length == 0)
        return false;
      else return true;
    },
    misafirekle_pasaportno_state() {
      if (
        this.misafirKayit.PasaportNo == "" ||
        this.misafirKayit.PasaportNo == null
      )
        return false;
      else return true;
    },
    misafirekle_adsoyad_state() {
      if (this.misafirKayit.AdSoyad == "" || this.misafirKayit.AdSoyad == null)
        return false;
      else return true;
    },
    misafirekle_uyruk_state() {
      if (
        this.misafirKayit.UyrukSelect == null ||
        this.misafirKayit.UyrukSelect.value == ""
      )
        return false;
      else return true;
    },
    misafirekle_cinsiyet_state() {
      if (this.misafirKayit.Cinsiyet == "" || this.misafirKayit.Cinsiyet == null)
        return false;
      else return true;
    }
  },
  watch: {
    misafirler: function () {
      this.resetForm();
      this.kayit.Yolcular = structuredClone(this.misafirler);
    },
    rezervasyonVerildi(newValue, oldValue) {
      if (newValue === true) {
        this.resetForm();
      }
    }
  },
  methods: {
    resetForm() {
      this.kayit = {
        isCiftYon: false,
        BolgeId: 0,
        Nereye: null,
        NereyeAdres: null,
        HedefBolgeId: 0,
        AracTipiId: 0,
        Tarih: null,
        SaatFull: "09:00",
        Saat: 0,
        Dakika: 0,
        UcusKodu: null,
        Aciklama: null,
        Donus: false,
        DonusBolgeId: 0,
        DonusNereye: null,
        DonusHedefBolgeId: 0,
        DonusTarih: null,
        DonusSaatFull: "09:00",
        DonusSaat: 0,
        DonusDakika: 0,
        DonusUcusKodu: null,
        DonusAciklama: null,
        AcentaNo: null,
        Yolcular: []
      };
      this.loading = false;
      this.$bvModal.hide("yukleniyor");
    },
    formatTarih(tarih) {
      return this.$moment(String(tarih)).format("YYYY-MM-DD");
    },
    addDays(days) {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    bolgeList() {
      let vm = this;
      this.$connection.get("/laalTransferler/bolgeler", function (response) {
        let list = response.map((item) => {
          return {
            text: item.Adi,
            value: item.Id,
          };
        });
        vm.bolgeler = vm.bolgeler.concat(list);
      });
    },
    ciftYonSec() {
      this.aracTipiSec();
    },
    donusHedefBolgeList() {
      let vm = this;
      this.$connection.get("/laalTransferler/hedefBolgeler/" + this.kayit.DonusBolgeId, function (response) {
        let list = response.map((item) => {
          return {
            text: item.Tanim,
            value: item.Id,
          };
        });

        vm.donusHedefBolgeler = [
          { text: 'Hedef Bölge', value: 0 }
        ];
        vm.donusHedefBolgeler = vm.donusHedefBolgeler.concat(list);
        if (list.length == 1) {
          vm.kayit.DonusHedefBolgeId = list[0].value;
        } else {
          vm.kayit.DonusHedefBolgeId = null;
        }

        vm.aracTipiSec();
      });
    },
    hedefBolgeList() {
      let vm = this;
      this.$connection.get("/laalTransferler/hedefBolgeler/" + this.kayit.BolgeId, function (response) {
        let list = response.map((item) => {
          return {
            text: item.Tanim,
            value: item.Id,
          };
        });

        vm.hedefBolgeler = [
          { text: 'Hedef Bölge', value: 0 }
        ];
        vm.hedefBolgeler = vm.hedefBolgeler.concat(list);
        if (list.length == 1) {
          vm.kayit.HedefBolgeId = list[0].value;
        } else {
          vm.kayit.HedefBolgeId = null;
        }

        vm.aracTipiSec();
      });
    },
    aracTipiList() {
      let vm = this;
      this.$connection.get("/laalTransferler/aracTipleri", function (response) {
        let list = response.map((item) => {
          return {
            text: item.Tanim,
            value: item.Id,
          };
        });
        vm.aracTipleri = vm.aracTipleri.concat(list);
      });
    },
    aracTipiSec() {
      let vm = this;
      let isValid = this.kayit_bolgeid_state &&
        this.kayit_aractipi_state;

      if (isValid) {
        vm.$connection.get("/laalTransferler/fiyat/" + vm.kayit.BolgeId + "/" + vm.kayit.HedefBolgeId + "/" + vm.kayit.AracTipiId, function (response) {
          vm.transferFiyati = response.SatisFiyat;

          //Dönüş varsa hesapla
          if (vm.kayit.isCiftYon && vm.kayit_donus_bolgeid_state) {
            vm.$connection.get("/laalTransferler/fiyat/" + vm.kayit.DonusBolgeId + "/" + vm.kayit.DonusHedefBolgeId + "/" + vm.kayit.AracTipiId, function (responseDonus) {
              vm.transferFiyati += responseDonus.SatisFiyat;
            });
          }
        });
      }
    },
    ulkeList() {
      let vm = this;
      this.$connection.get("/laalTransferler/ulkeler", function (response) {
        let ulkeOptions = response.map((item) => {
          return {
            text: item.Uyruk,
            value: item.Kod,
          };
        });
        vm.ulkeler = vm.ulkeler.concat(ulkeOptions);
      });
    },
    misafirEkle() {
      let isValid =
        this.misafirekle_pasaportno_state &&
        this.misafirekle_adsoyad_state &&
        this.misafirekle_uyruk_state &&
        this.misafirekle_cinsiyet_state;

      if (isValid) {
        this.misafirKayit.Uyruk = this.misafirKayit.UyrukSelect.value;
        this.misafirKayit.Id = this.kayit.Yolcular.length + 1;

        this.kayit.Yolcular.push(this.misafirKayit);

        this.misafirKayit = {
          Id: 0,
          PasaportNo: "",
          AdSoyad: "",
          Uyruk: this.misafirKayit.UyrukSelect.value,
          UyrukSelect: this.misafirKayit.UyrukSelect,
          Cinsiyet: this.misafirKayit.Cinsiyet,
        };
      }
    },
    misafirKaldir(misafir) {
      this.$listHelper.removeItem(this.kayit.Yolcular, misafir);
    },
    cikis() {
      let vm = this;
      this.$alert.confirm(
        "İptal",
        "Rezervasyondan vazgeçiyorsunuz yapmış olduğunuz değişiklikler kaybolacaktır. Emin misiniz?",
        "error",
        function () {
          if (vm.id == undefined) {
            vm.$router.push("/");
          } else {
            vm.$router.push("/turRezervasyon/detay/" + vm.id);
          }
        }
      );
    },
    onComplete() {
      let isValid =
        this.kayit_bolgeid_state &&
        this.kayit_nereye_state &&
        this.kayit_tarih_state &&
        this.kayit_saatfull_state &&
        this.kayit_ucuskodu_state &&
        this.kayit_donus_bolgeid_state &&
        this.kayit_donus_nereye_state &&
        this.kayit_donus_tarih_state &&
        this.kayit_donus_saatfull_state &&
        this.kayit_donus_ucuskodu_state &&
        this.kayit_aractipi_state &&
        this.kayit_misafir_state;

      if (!isValid) {
        this.$alert.messageWithTitle(
          "Eksik Bilgi",
          "Lütfen rezervasyon bilgilerinizi kontrol ediniz.",
          "error"
        );
      } else {
        let vm = this;

        let neredenTanimList = this.bolgeler.filter((item) => {
          return item.value == vm.kayit.BolgeId;
        });
        let ilk_neredenTanim, ilk_nereyeTanim;
        if (this.kayit.Donus == false) {
          ilk_neredenTanim = neredenTanimList[0].text;
          ilk_nereyeTanim = this.kayit.Nereye;
        } else {
          ilk_neredenTanim = this.kayit.Nereye;
          ilk_nereyeTanim = neredenTanimList[0].text;
        }
        let ilk_tarih = this.$formatHelper.formatDate(this.kayit.Tarih) + ' ' + this.kayit.SaatFull;

        let aracTipiList = this.aracTipleri.filter((item) => {
          return item.value == vm.kayit.AracTipiId;
        });
        let aracTipi = aracTipiList[0].text;

        let msj = ilk_tarih + ' tarihinde ' + ilk_neredenTanim +
          ' bölgesinden alınıp ' +
          ilk_nereyeTanim + ' bölgesine ' +
          aracTipi + ' aracı ile ' +
          this.kayit.Yolcular.length + ' kişi götürülecektir.';

        if (this.kayit.isCiftYon) {
          let iki_neredenTanimList = this.bolgeler.filter((item) => {
            return item.value == vm.kayit.DonusBolgeId;
          });
          
          let iki_neredenTanim = '', iki_nereyeTanim = '';

          if (this.kayit.Donus == false) {
            iki_neredenTanim = this.kayit.DonusNereye;
            iki_nereyeTanim = iki_neredenTanimList[0].text;
          } else {
            iki_neredenTanim = iki_neredenTanimList[0].text;
            iki_nereyeTanim = this.kayit.DonusNereye;
          }
          let iki_tarih = this.$formatHelper.formatDate(this.kayit.DonusTarih) + ' ' + this.kayit.DonusSaatFull;

          msj += '<br />Dönüş Transferinde İse;<br />'
          msj += iki_tarih + ' tarihinde ' + iki_neredenTanim + ' bölgesinden alınıp ' + iki_nereyeTanim + ' bölgesine ' + aracTipi + ' aracı ile ' + this.kayit.Yolcular.length + ' kişi götürülecektir.'
        }

        this.$alert.confirm(
          "Transfer Tamamlanacaktır! Lütfen son bir kontrol edip onaylayınız.",
          "",
          "warning",
          function () {

            let tarih = vm.$formatHelper.formatDate(vm.kayit.Tarih);
            let arrTarih = tarih.split(".");
            vm.kayit.Tarih = arrTarih[2] + "-" + arrTarih[1] + "-" + arrTarih[0];

            let arrSaat = vm.kayit.SaatFull.split(":");
            vm.kayit.Saat = arrSaat[0];
            vm.kayit.Dakika = arrSaat[1];

            if (vm.kayit.isCiftYon) {
              tarih = vm.$formatHelper.formatDate(vm.kayit.DonusTarih);
              arrTarih = tarih.split(".");
              vm.kayit.DonusTarih = arrTarih[2] + "-" + arrTarih[1] + "-" + arrTarih[0];

              arrSaat = vm.kayit.DonusSaatFull.split(":");
              vm.kayit.DonusSaat = arrSaat[0];
              vm.kayit.DonusDakika = arrSaat[1];
            }

            for (let i = 0; i < vm.kayit.Yolcular.length; i++) {
              const element = vm.kayit.Yolcular[i];
              element.UlkeKodu = element.UyrukSelect.value;
            }

            vm.loading = true;
            vm.$bvModal.show("yukleniyor");
            vm.$emit("onComplete", vm.kayit);

          },
          undefined, undefined, undefined, msj
        );
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>