<template>
    <b-modal id="aracTakipGoster" size="xl" v-model="mutateGoster" centered title="Araç Takip" :hide-footer="true"
        class="theme-modal" @hidden="kapat()">
        <div class="row" v-if="arac != null">
            <div class="col-md-6">
                <div v-if="lokasyon != null">
                    <GmapMap :center="{ lat: lokasyon.lat, lng: lokasyon.lon }" :zoom="16"
                        style="width: auto; height: 500px">
                        <GmapMarker ref="myMarker"
                            :position="google && new google.maps.LatLng(lokasyon.lat, lokasyon.lon)"
                            :clickable="true" />
                    </GmapMap>
                </div>
            </div>
            <div class="col-md-6">
                <table class="product-page-width">
                    <tbody>
                        <tr>
                            <td colspan="2" class="text-center">
                                <b-carousel :id="'carousel-' + arac.Id" :interval="4000" controls indicators
                                    background="#ababab" style="
                              width: 350px !important;
                              text-shadow: 1px 1px 2px #333;
                            ">
                                    <b-carousel-slide v-for="(item, index) in arac.Resimler" :key="index"
                                        :img-src="item.Resim"></b-carousel-slide>
                                </b-carousel>
                            </td>
                        </tr>
                        <tr>
                            <th>Plaka</th>
                            <td>{{ arac.Plaka }}</td>
                        </tr>
                        <tr>
                            <th>Şoför Adı</th>
                            <td>{{ arac.SoforAdi }}</td>
                        </tr>
                        <tr>
                            <th>Marka</th>
                            <td>{{ arac.AracMarka }}</td>
                        </tr>
                        <tr>
                            <th>Model</th>
                            <td>{{ arac.AracModel }}</td>
                        </tr>
                        <tr>
                            <th>Araç Takip Sistemi</th>
                            <td><c-aktif-cell :isAktif="arac.isAracTakip"></c-aktif-cell></td>
                        </tr>
                        <tr>
                            <th>Wifi</th>
                            <td><c-aktif-cell :isAktif="arac.isWifi"></c-aktif-cell></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

export default {
    props: {
        aracId: null,
        goster: false,
        turId: null,
        regularId: null
    },
    data() {
        return {
            arac: null,
            lokasyon: null,

            mutateGoster: false
        };
    },
    mounted() {
    },
    components: {
    },
    computed: {
        google: gmapApi
    },
    watch: {
        goster(newValue, oldValue) {
            if (this.aracId != null) {
                this.getArac();
            }
        },
    },
    methods: {
        getArac() {
            let vm = this;

            let url = "/araclar/view/" + this.aracId + "?";
            if (this.turId != null && this.turId != "")
                url += "turId=" + this.turId;
            if (this.regularId != null && this.regularId != "")
                url += "regularId=" + this.regularId

            this.$connection.get(url, function (response) {
                if (response.Success) {
                    vm.arac = response.Data;

                    if (vm.arac.isAracTakip) {
                        vm.getLokasyon();
                    }
                    vm.mutateGoster = true;
                } else {
                    vm.$alert.messageWithFunction("Araç Gösterim", response.Message, "error", function () {
                        vm.mutateGoster = false;
                        vm.$emit("kapatildi");
                    });
                }
            }, function (error) {
                vm.$alert.messageWithFunction("Araç Gösterim", error.Message, "error", function () {
                    vm.mutateGoster = false;
                    vm.$emit("kapatildi");
                });
            });
        },
        getLokasyon() {
            let vm = this;

            let url = "/araclar/aracTakipVerisi/" + this.aracId + "?";
            if (this.turId != null && this.turId != "")
                url += "turId=" + this.turId;
            if (this.regularId != null && this.regularId != "")
                url += "regularId=" + this.regularId

            this.$connection.get(url, function (response) {
                if (response.Success) {
                    vm.lokasyon = {
                        lat: parseFloat(response.Data.LokasyonX),
                        lon: parseFloat(response.Data.LokasyonY)
                    };
                } else {
                    vm.$alert.messageWithTitle("Araç Takip Lokasyon", response.Message, "error");
                }
            }, function (error) {
                vm.$alert.messageWithTitle("Araç Takip Lokasyon", error.Message, "error");
            });
        },
        kapat() {
            this.mutateGoster = false;
            this.$emit("kapatildi");
        }
    },
};
</script>
