<template>
  <iframe
    id="sozlesmeFrame"
    name="sozlesmeFrame"
    :srcdoc="sozlesmeMetni"
    width="100%"
    height="500"
    frameborder="no"
  >
  </iframe>
</template>
<script>
export default {
  props: {
    acente: null,
    sozlesme: "",
  },
  data() {
    return {
      sozlesmeTabStart:
        '<style>@page { margin: 0; }.page { width: 21cm; min-height: 29.7cm; padding: 1cm; margin: 0; }.page { font-family: "Times New Roman", Times, serif; }.page-break { display: block; page-break-before: always; }</style><div class="sozlesmePrint">',
      sozlesmeTabEnd: "</div>",

      sozlesmeMetni: this.sozlesme,

      parametreler: [
        { key: "REHBER_ADISOYADI", value: "" },
        { key: "REHBER_TCKİMLİKNO", value: "" },
        { key: "REHBER_RUHSATNAME", value: "" },
        { key: "REHBER_ÇALIŞMA_KARTI", value: "" },
        { key: "REHBER_DİL", value: "" },
        { key: "REHBER_ADRES", value: "" },
        { key: "REHBER_TELEFON", value: "" },
        { key: "REHBER_GSM", value: "" },
        { key: "REHBER_EMAIL", value: "" },
        { key: "REHBER_TUTAR", value: "" },
        { key: "REHBER_IBAN", value: "" },

        { key: "TARİH", value: this.$formatHelper.formatDate(new Date()) },
        { key: "KİŞİ_SAYISI", value: "1" },
        { key: "TUR_DİL", value: "İngilizce" },
        { key: "TUR_ADI", value: "RED TUR" },
        { key: "GÜN_SAYISI", value: "1" },
        {
          key: "SÖZLEŞME_TARİHİ",
          value: this.$formatHelper.formatDate(new Date()) + " 09:00",
        },
        {
          key: "BİTİŞ_TARİHİ",
          value: this.$formatHelper.formatDate(new Date()) + " 18:00",
        },
        { key: "SAAT", value: "09:00" },

        { key: "ACENTE_ADI", value: this.acente.Unvan },
        {
          key: "ACENTE_TÜRSAB_İŞLETME_BELGE_NO",
          value: this.acente.TursabBelgeNo,
        },
        {
          key: "ACENTE_ADRES",
          value: this.acente.Adres + " / " + this.acente.SehirAdi,
        },
        { key: "ACENTE_ŞEHİR", value: this.acente.SehirAdi },
        { key: "ACENTE_TELEFON", value: this.acente.Telefon },
        { key: "ACENTE_YETKİLİ_ADI", value: this.acente.YetkiliKisi },
        { key: "ACENTE_YETKİLİ_UNVAN", value: this.acente.YetkiliUnvan },
        {
          key: "KAŞE_İMZA",
          value:
            '<img src="' +
            this.acente.KaseImza +
            '"  style="max-width: 200px;" />',
        },

        { key: "ARAÇ_FİRMA_ADI", value: "" },
        { key: "ARAÇ_FİRMA_YETKİ_BELGESİ_TÜRÜ", value: "" },
        { key: "ARAÇ_FİRMA_YETKİ_BELGESİ_NO", value: "" },
        { key: "PLAKA", value: "" },
        { key: "ARAÇ_TUTAR", value: "" },
      ],
    };
  },
  mounted() {
    for (let i = 0; i < this.parametreler.length; i++) {
      const element = this.parametreler[i];
      if (element.value !== "") {
        this.sozlesmeMetni = this.sozlesmeMetni.replaceAll(
          "[" + element.key + "]",
          element.value
        );
      }
    }
    this.sozlesmeMetni =
      this.sozlesmeTabStart + this.sozlesmeMetni + this.sozlesmeTabEnd;
  },
  components: {},
  computed: {},
  methods: {},
};
</script>
