<template>
  <div>
    <b-button v-b-modal.ipucuModal variant="info">
      <div class="media">
        <i class="icon-light-bulb"></i>
        <div class="media-body align-self-center">Kolay Kullanım İçin İpuçları</div>
      </div>
    </b-button>

    <b-modal id="ipucuModal" size="xl" centered title="İpucu" :ok-only="true" cancel-title="Kapat" class="theme-modal">
      <div class="default-according">
        <b-card no-body class="mb-1" v-for="(ipucu, index) in ipucuList" v-bind:key="index">
          <b-card-header header-tag="div" role="tab" class="bg-primary">
            <h5 class="mb-0">
              <b-button v-b-toggle="'ipucuacordion' + index" variant="default" class="text-white"><i
                  class="icon-light-bulb"></i> {{ index + 1 }}. {{ ipucu.baslik }}</b-button>
            </h5>
          </b-card-header>
          <b-collapse :id="'ipucuacordion' + index" visible accordion="myaccordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <span style="font-size: 16px;" v-html="ipucu.icerik"></span>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    tip: '',
  },
  data() {
    return {
      ipucuList: [],
    };
  },
  mounted() {
    switch (this.tip) {
      case 'rehber':
        break;

      case 'arac':
        break;

      default:
        break;
    }
    
    this.ipucuList.push({
      baslik: "Telefon Ekranına Sabitleme", 
      icerik: 'Telefonunuzun browserından capbook.net\'i açınız.<br /><br />Android telefonlar için tarayıcıda sağ üst kısımda bulunan üç noktaya tıkladıktan sonra Ayarlar menüsünü açıp Ana Ekrana Ekle butonu ile ekleyebilirsiniz.<br /><br />iPhone için alt tarafta bulunan Paylaş (<i class="icon-export"></i>) butonuna basıp gelen menüden Ana Ekrana Ekle butonuna basarak telefonunuzun uygulamalar kısmına kısayol ekleyebilirsiniz.' 
    });
  },
};
</script>
