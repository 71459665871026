<template>
  <div>
    <b-modal id="aracOdemeForm" centered title="Ödeme Bildirimi" @ok="kaydetSubmit" ok-title="Kaydet"
      cancel-title="Kapat" class="theme-modal">
      <b-form @submit="kaydetSubmit">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tarih">Tarih</label>
            <datepicker :language="tr" input-class="datepicker-here form-control digits" id="kayit_tarih"
              v-model="kayit.Tarih" placeholder="Tarih" :format="date_format" :state="kayit_tarih_state">
            </datepicker>
            <b-form-invalid-feedback :state="kayit_tarih_state">Tarih giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tutar">Tutar</label>
            <b-form-input id="kayit_tutar" v-model="kayit.Tutar" :state="kayit_tutar_state"></b-form-input>
            <b-form-invalid-feedback :state="kayit_tutar_state">Tutarı giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_parabirimi">Parabirimi</label>
            <b-form-select id="kayit_parabirimi" v-model="kayit.ParaBirimiId" :options="parabirimleri"
              :state="kayit_parabirimi_state"></b-form-select>
            <b-form-invalid-feedback :state="kayit_parabirimi_state">Parabirimini seçiniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_aciklama">Açıklama</label>
            <b-form-textarea id="kayit_aciklama" v-model="kayit.Aciklama"
              :state="kayit_aciklama_state"></b-form-textarea>
            <b-form-invalid-feedback :state="kayit_aciklama_state">Açıklama giriniz.</b-form-invalid-feedback>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";

export default {
  props: {
    arac: null
  },
  data() {
    return {
      date_format: "dd.MM.yyyy",
      tr: tr,

      kayit: {
        Id: 0,
        AracId: "",
        Tipi: 20,
        ParaBirimiId: null,
        Tutar: null,
        Aciklama: "",
        Tarih: null,
      },

      parabirimleri: [{ value: null, text: "Seçiniz" }],
    };
  },
  components: {
    Datepicker,
  },
  mounted() {
    this.paraBirimiList();
  },
  computed: {
    kayit_parabirimi_state() {
      if (this.kayit.ParaBirimiId == null || this.kayit.ParaBirimiId == "")
        return false;
      else return true;
    },
    kayit_tarih_state() {
      if (this.kayit.Tarih == null || this.kayit.Tarih == "") return false;
      else return true;
    },
    kayit_tutar_state() {
      if (this.kayit.Tutar == null) return false;
      else return true;
    },
    kayit_aciklama_state() {
      if (this.kayit.Aciklama == null || this.kayit.Aciklama == "")
        return false;
      else return true;
    },
  },
  methods: {
    paraBirimiList() {
      let vm = this;

      this.$connection.get("/paraBirimleri/select", function (response) {
        vm.parabirimleri = vm.parabirimleri.concat(response);
      });
    },
    kaydetSubmit(event) {
      event.preventDefault();

      let isValid =
        this.kayit_parabirimi_state &&
        this.kayit_tarih_state &&
        this.kayit_tutar_state &&
        this.kayit_aciklama_state;

      if (isValid) {
        let vm = this;

        let url = "/araclar/folyo";
        let method = "post";
        let messageTitle = "Yeni Folyo";

        vm.kayit.AracId = vm.arac.Id;

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.kayit = {
                    Id: 0,
                    AracId: "",
                    Tipi: 20,
                    ParaBirimiId: null,
                    Tutar: null,
                    Aciklama: "",
                    Tarih: null,
                  };

                  vm.$emit("onKayitEklendi", response.Data);
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    }
  },
};
</script>
