<template>
  <div>
    <Breadcrumbs main="Restoranlar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Restoran Kaydı</h5>
              <span
                >Yeni restoran veya restoran kaydında değişiklikler için
                aşağıdaki formu kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onCustomStyleSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Restoran Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_adi"
                      v-model="kayit.Adi"
                      :state="kayit_adi_state"
                      placeholder="Restoran Adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adi_state"
                      >Restoran adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Unvan</label>
                    <b-form-input
                      type="text"
                      id="kayit_unvan"
                      v-model="kayit.Unvan"
                      :state="kayit_unvan_state"
                      placeholder="Restoran Unvanı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_unvan_state"
                      >Restoran unvanını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Adres</label>
                    <b-form-textarea
                      type="text"
                      id="kayit_adres"
                      v-model="kayit.Adres"
                      :state="kayit_adres_state"
                      placeholder="Restoran Adresi"
                    ></b-form-textarea>
                    <b-form-invalid-feedback :state="kayit_adres_state"
                      >Restoranın adresini giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Açıklama</label>
                    <b-form-textarea
                      type="text"
                      id="kayit_aciklama"
                      v-model="kayit.Aciklama"
                      placeholder="Açıklama"
                    ></b-form-textarea>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Konum X</label>
                    <b-form-input
                      type="text"
                      id="kayit_konumx"
                      v-model="kayit.KonumX"
                      :state="kayit_konumx_state"
                      placeholder="Restoran Enlemini"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_konumx_state"
                      >Restoran konumunun enlemini
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Konum Y</label>
                    <b-form-input
                      type="text"
                      id="kayit_konumy"
                      v-model="kayit.KonumY"
                      :state="kayit_konumy_state"
                      placeholder="Restoran Boylamını"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_konumy_state"
                      >Restoran konumunun boylamını
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Vergi Dairesi</label>
                    <b-form-input
                      type="text"
                      id="kayit_vergidairesi"
                      v-model="kayit.VergiDairesi"
                      placeholder="Vergi Dairesi"
                    ></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Vergi No</label>
                    <b-form-input
                      type="text"
                      id="kayit_vergiNo"
                      v-model="kayit.VergiNo"
                      :state="kayit_vergino_state"
                      placeholder="T.C. Kimlik No veya Vergi No"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_vergino_state"
                      >Bireyselse 11 haneli T.C. kimlik numarası kurumsal ise 10
                      haneli vergi numarasını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tipi">Menü Alış Fiyatı</label>
                    <b-form-input
                      type="text"
                      id="kayit_menufiyatialis"
                      v-model="kayit.MenuFiyatiAlis"
                      :state="kayit_menufiyatialis_state"
                      placeholder="Menü Alış Fiyatı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_menufiyatialis_state"
                      >Menü alış fiyatını giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tipi">Menü Satış Fiyatı</label>
                    <b-form-input
                      type="text"
                      id="kayit_menufiyatisatis"
                      v-model="kayit.MenuFiyatiSatis"
                      :state="kayit_menufiyatisatis_state"
                      placeholder="Menü Satış Fiyatı"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="kayit_menufiyatisatis_state"
                      >Menü satış fiyatını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_resim">Resim</label>
                    <div class="row">
                      <div class="col-md-9">
                        <b-form-file
                          class="form-control"
                          v-model="kayit.ResimFl"
                          plain
                        ></b-form-file>
                      </div>
                      <div class="col-md-3">
                        <div
                          class="avatar"
                          v-if="kayit.Resim !== '' && kayit.Resim !== undefined"
                        >
                          <img
                            class="img-40 rounded-circle"
                            :src="kayit.Resim"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label>Durum</label>
                    <b-form-select
                      id="kayit_durum"
                      v-model="kayit.Durum"
                      :options="kayit_durum_options"
                    ></b-form-select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-5">
                    <h4 class="f-w-600">
                      Yaş İndirim Oranları
                      <b-button
                        @click="defaultIndirimOranlariAta()"
                        class="pull-right"
                        variant="success"
                        >Standart İndirim Oranlarını Ata</b-button
                      >
                    </h4>
                    <table class="table">
                      <thead>
                        <tr class="bg-warning">
                          <th>
                            <b-form-select
                              v-model="indirimOrani.Yas"
                              :options="yas_options"
                            ></b-form-select>
                          </th>
                          <th>
                            <b-form-input
                              type="text"
                              v-model="indirimOrani.Oran"
                              placeholder="İndirim Oranı"
                            ></b-form-input>
                          </th>
                          <th>
                            <b-button
                              type="button"
                              @click="indirimOraniEkle()"
                              variant="info"
                              >Ekle</b-button
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="kayit.Indirimler.length === 0">
                          <td colspan="3" class="text-center">
                            Bu Müze İçin Henüz Yaş İndirimi Tanımlanmamıştır
                          </td>
                        </tr>
                        <tr
                          v-for="row in kayit.Indirimler"
                          v-bind:key="row.YasMin"
                        >
                          <td>
                            <b-form-select
                              v-model="row.Yas"
                              :options="yas_options"
                            ></b-form-select>
                          </td>
                          <td>
                            <b-form-input
                              type="text"
                              v-model="row.Oran"
                              placeholder="İndirim Oranı"
                            ></b-form-input>
                          </td>
                          <td>
                            <b-button
                              type="button"
                              @click="indirimOraniKaldir(row)"
                              variant="danger"
                              >Kaldır</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <router-link
                  :to="'/restoran/list'"
                  class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Restoran",

      breadLinks: [
        { path: "/restoran/list", title: "Restoranlar", icon: "chevron-left" },
      ],

      kayit: {
        Adi: "",
        Unvan: "",
        Adres: "",
        VergiDairesi: "",
        VergiNo: "",
        KonumX: "",
        KonumY: "",
        Durum: 30,
        Aciklama: "",
        ResimFl: null,
        MenuFiyatiAlis: 0,
        MenuFiyatiSatis: 0,
        Indirimler: [],
      },

      kayit_durum_options: [
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],

      indirimOrani: {
        Yas: 0,
        Oran: null,
      },

      yas_options: [],

      defaulIndirimOranlari: [
        {
          Yas: 0,
          Oran: 50,
        },
        {
          Yas: 1,
          Oran: 25,
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.onYasList();

    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Restoran Düzelt";

      this.$connection.get("/restoranlar/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle(
            "Yeni Restoran",
            response.Message,
            "error"
          );
        }
      });
    }
  },
  computed: {
    kayit_adi_state() {
      if (this.kayit.Adi == null || this.kayit.Adi == "") return false;
      else return true;
    },
    kayit_unvan_state() {
      if (this.kayit.Unvan == null || this.kayit.Unvan == "") return false;
      else return true;
    },
    kayit_adres_state() {
      if (this.kayit.Adres == null || this.kayit.Adres == "") return false;
      else return true;
    },
    kayit_konumx_state() {
      // if (this.kayit.KonumX == null || this.kayit.KonumX == '')
      //   return false;
      // else
      return true;
    },
    kayit_konumy_state() {
      // if (this.kayit.KonumY == null || this.kayit.KonumY == '')
      //   return false;
      // else
      return true;
    },
    kayit_vergino_state() {
      // if (this.kayit.VergiNo == null || this.kayit.VergiNo == '' || this.kayit.VergiNo.length < 10 || this.kayit.VergiNo.length > 11)
      //   return false;
      // else
      return true;
    },
    kayit_menufiyatialis_state() {
      if (this.kayit.MenuFiyatiAlis == "" || this.kayit.MenuFiyatiAlis == "0")
        return false;
      else return true;
    },
    kayit_menufiyatisatis_state() {
      if (this.kayit.MenuFiyatiSatis == "" || this.kayit.MenuFiyatiSatis == "0")
        return false;
      else return true;
    },
  },
  methods: {
    onYasList() {
      let vm = this;

      this.$connection.get("/parametreler/yaslar", function (response) {
        vm.yas_options = response;
      });
    },
    defaultIndirimOranlariAta() {
      this.kayit.Indirimler = structuredClone(this.defaulIndirimOranlari);
    },
    indirimOraniEkle() {
      if (this.indirimOrani.Yas === null || this.indirimOrani.Oran === null) {
        this.$alert.messageWithTitle(
          "Eksik Alan",
          "Yaş ve indirim oranı alanlarını doldurunuz.",
          "error"
        );
        return;
      }

      this.indirimOrani.Oran = parseInt(this.indirimOrani.Oran);
      this.kayit.Indirimler.push(this.indirimOrani);
      this.indirimOrani = {
        Yas: 0,
        Oran: null,
      };
    },
    indirimOraniKaldir(indirim) {
      this.$listHelper.removeItem(this.kayit.Indirimler, indirim);
    },
    onCustomStyleSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adi_state &&
        this.kayit_unvan_state &&
        this.kayit_adres_state &&
        this.kayit_konumx_state &&
        this.kayit_konumy_state &&
        this.kayit_vergino_state &&
        this.kayit_menufiyatialis_state &&
        this.kayit_menufiyatisatis_state;

      if (isValid) {
        let vm = this;

        let url = "/restoranlar";
        let method = "post";
        let messageTitle = "Yeni Restoran";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Restoran Düzelt";
        }

        for (let i = 0; i < vm.kayit.Indirimler.length; i++) {
          const element = vm.kayit.Indirimler[i];
          element.Oran = parseInt(element.Oran);
        }

        vm.$connection.postData(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/restoran/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
