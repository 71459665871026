<template>
  <div class="row">
    <div class="col-md-6">
      <vc-calendar :attributes="attrs" @update:from-page="updatepage" @dayclick="dayclick" title-position="left"
        is-expanded />
      <p class="alert alert-info mt-3" v-if="maxKapaliGun > 0">
        1 Aylık Çalışma Takviminde EN FAZLA {{ maxKapaliGun }} GÜN kapalı olarak
        gösterebilirsiniz.
      </p>
      <p class="alert alert-info mt-3" v-else>
        Musaitlik takviminden; Capbook harici aldığınız turlarınızın günlerini kapattığınızdan Emin Olunuz
      </p>
    </div>
    <div class="col-md-6">
      <h4>Renk Açıklamaları</h4>
      <ul class="takvim-aciklama">
        <li class="red"><span class="rounded-circle"></span> KAPALI GÜNLER</li>
        <!--<li class="gray"><span class="rounded-circle"></span> Yeni rezervasyon</li>-->
        <li class="yellow">
          <span class="rounded-circle"></span> YENİ REZERVASYON
        </li>
        <li class="green">
          <span class="rounded-circle"></span> TAMAMLANMIŞ İŞLER
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    aracId: "",
  },
  data() {
    return {
      attrs: [],
      ozelGunler: [],
      aktifYil: 0,

      maxKapaliGun: 0
    };
  },
  components: {},
  mounted() { 
    let vm = this;
    this.$connection.get("/parametreler?tanim=Araç Max Kapalı Gün Sayısı", function (response) {
      if (response != null) {
        vm.maxKapaliGun = response.Deger;
      }
    });
  },
  methods: {
    takvimList(filtre) {
      let vm = this;

      this.$connection.post(
        "/araclar/takvim/" + this.aracId,
        filtre,
        "post",
        function (response) {
          vm.attrs = vm.ozelGunler.concat(
            response.map((item) => {
              return {
                key: item.Id,
                highlight: item.Renk,
                dates: item.Tarih,
                popover: {
                  label: item.Aciklama,
                },
              };
            })
          );
          vm.rezervasyonListele(filtre);
        }
      );
    },
    rezervasyonListele(filtre) {
      let vm = this;

      this.$connection.post(
        "/araclar/takvim/rezervasyon/" + this.aracId,
        filtre,
        "post",
        function (response) {
          vm.attrs = vm.attrs.concat(
            response.map((item) => {
              return {
                key: item.Id,
                highlight: item.Renk,
                dates: item.Tarih,
                popover: {
                  label: item.Aciklama,
                },
              };
            })
          );
        }
      );
    },
    ozelGunlerListele(filtre) {
      let vm = this;

      this.$connection.get(
        "/takvimler/ozelGunler/" + this.aktifYil,
        function (response) {
          vm.ozelGunler = response.map((item) => {
            return {
              key: item.Id,
              highlight: false,
              content: "purple",
              dot: true,
              dates: item.Tarih,
              popover: {
                label: item.Aciklama,
              },
            };
          });

          vm.takvimList(filtre);
        }
      );
    },
    updatepage(page) {
      let baslangic = new Date(page.year, page.month - 1, 1);
      let bitis = new Date(page.year, page.month - 1, 1);
      this.$formatHelper.addMonth(bitis, 1);

      let filtre = {
        BaslangicTarihi: this.$formatHelper.formatDateApi(baslangic),
        BitisTarihi: this.$formatHelper.formatDateApi(bitis),
      };

      if (this.aktifYil != page.year) {
        //Yil değişti özel günleri listele
        this.aktifYil = page.year;
        this.ozelGunlerListele(filtre);
      } else {
        this.takvimList(filtre);
      }
    },
    dayclick(day) {
      let kapali = true;
      let messageTitle = "Kapat",
        message = "kapatmak",
        key = "";
      if (day.attributes.length > 0) {
        let attributes = day.attributes.filter((item) => {
          return item.highlight !== undefined;
        });
        if (attributes.length > 0) {
          if (attributes[0].highlight.base.color === "blue") {
            this.$alert.messageWithTitle(
              "Uyarı",
              "Bu tarihte turunuz olduğu için kapatamazsınız.",
              "warning"
            );
            return;
          }
          kapali = false;
          messageTitle = "Aç";
          message = "açmak";
          key = attributes[0].key;
        }
      }

      let vm = this;
      this.$alert.confirm(
        messageTitle,
        "Bu günü " + message + " istediğinize emin misiniz?",
        "warning",
        function () {
          let form = {
            Tarih: vm.$formatHelper.formatDateApi(day.date),
            Kapali: kapali,
          };
          vm.$connection.post(
            "/araclar/takvim/" + vm.aracId,
            form,
            "put",
            function (response) {
              if (kapali) {
                //attr ekle
                vm.attrs.push({
                  key: vm.$formatHelper.formatDateApi(day.date),
                  highlight: "red",
                  dates: day.date,
                  popover: {
                    label: "Bugün kapatılmıştır.",
                  },
                });
              } else {
                let removeItems = vm.attrs.filter((item) => item.key == key);
                if (removeItems.length > 0) {
                  vm.$listHelper.removeItem(vm.attrs, removeItems[0]);
                }
              }
            }
          );
        }
      );
    },
  },
};
</script>
<style>
.takvim-aciklama li {
  margin-bottom: 25px;
}

.takvim-aciklama li span {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.takvim-aciklama li.gray span {
  background-color: rgb(160, 174, 192);
}

.takvim-aciklama li.yellow span {
  background-color: rgb(255, 255, 0);
}

.takvim-aciklama li.green span {
  background-color: rgb(198, 246, 213);
}

.takvim-aciklama li.blue span {
  background-color: rgb(66, 153, 225);
}

.takvim-aciklama li.red span {
  background-color: rgb(245, 101, 101);
}
</style>
