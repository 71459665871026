import Swal from 'sweetalert2';

export default {
    message: function (title) {
        Swal.fire({
            title
        });
    },
    messageWithTitle: function (title, text, icon = 'success') {
        Swal.fire({
            title,
            text,
            icon
        });
    },
    messageWithFunction: function (title, text, icon, okFunc) {
        icon = icon === "" || icon === null || icon === undefined ? "success" : icon;
        Swal.fire({
            title,
            text,
            confirmButtonColor: '#4466f2',
            icon
        }).then((result) => {
            if (okFunc !== undefined && okFunc !== null)
                okFunc();
        });
    },
    confirm: function (title, text, icon, okFunc, cancelFunc, confirmButtonText = 'Tamam', cancelButtonText = 'İptal', html = '') {
        icon = icon === "" || icon === null || icon === undefined ? "success" : icon;
        Swal.fire({
            title,
            text,
            icon,
            html,
            showCancelButton: true,
            confirmButtonText,
            confirmButtonColor: '#4466f2',
            cancelButtonText,
            cancelButtonColor: '#4466f2',
        }).then((result) => {
            if (result.isConfirmed) {
                if (okFunc !== undefined && okFunc !== null)
                    okFunc();
            } else if(result.isDismissed) {
                if (cancelFunc !== undefined && cancelFunc !== null)
                    cancelFunc();
            }
        });
    }
}