<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="page-wrapper" id="canvas-bookmark">
      <!-- Page Header Start-->
      <div class="page-main-header" :class="{ open: toggle_sidebar_var }">
        <div class="main-header-right row">
          <div class="main-header-left d-lg-none">
            <div class="logo-wrapper"><router-link :to="{ name: 'default' }"><img src="../assets/images/mobile-logo.png"
                  alt=""></router-link></div>
          </div>
          <div class="mobile-sidebar">
            <div class="media-body text-left switch-md">
              <a href="#" class="switch" v-on:click="toggle_sidebar">
                <feather type="align-left"></feather>
                <div style="font-size: 11px;">Menü {{ toggle_sidebar_var ? 'Aç' : 'Kapat' }}</div>
              </a>
            </div>
          </div>
          <div class="nav-right col">
            <div class="header-reklam" v-if="user.Kullanici.KullaniciTipi == 10 || user.Kullanici.KullaniciTipi == 15">
              <Reklam :bannerTipi="3"></Reklam>
            </div>
            <div class="btn-group">
              <b-button v-if="user != null && user.Kullanici.isGsmDogrulandi == false" variant="danger"
                @click="dogrulamaAc(0)">
                Telefonunuzu Doğrulayınız
              </b-button>
              <b-button v-if="user != null && user.Kullanici.isEmailDogrulandi == false" variant="danger"
                @click="dogrulamaAc(1)">
                Emailinizi Doğrulayınız
              </b-button>
            </div>
            <ul class="nav-menus pull-right" :class="{ open: mobile_toggle }">
              <li><a href="javascript:void(0)" class="text-dark" v-on:click="toggle_fullscreen()">
                  <feather type="maximize"></feather>
                </a></li>
              <li class="onhover-dropdown">
                <div class="media align-items-center"><img class="align-self-center pull-right img-50 rounded-circle"
                    :src="getImgUrl()" alt="header-user">
                </div>
                <ul class="profile-dropdown onhover-show-div p-20">
                  <li><a @click="logout">
                      <feather type="log-out"></feather> Çıkış
                    </a></li>
                </ul>
              </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" v-on:click="mobile_toggle = !mobile_toggle">
              <feather type="more-horizontal"></feather>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="dogrulamaForm" centered @ok="dogrulamaSubmit" hide-footer :title="dogrulama.Baslik"
        ok-title="Doğrula" cancel-title="Kapat" class="theme-modal">
        <div class="alert alert-info" v-html="dogrulama.Mesaj"></div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="dogrulama_kod">Doğrulama Kodu</label>
            <b-form-input id="dogrulama_kod" v-model="dogrulamaForm.DogrulamaKodu"
              :state="dogrulama_kod_state"></b-form-input>
            <b-form-invalid-feedback :state="dogrulama_kod_state">6 haneli doğrulama kodunu
              giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b-button @click="$bvModal.hide('dogrulamaForm')" variant="danger">Kapat</b-button>
          </div>
          <div class="col-md-5 text-center">
            <b-button @click="dogrulamaAc(dogrulama.Tip)" block variant="dark">Yeniden Gönder</b-button>
          </div>
          <div class="col-md-4 text-right">
            <b-button @click="dogrulamaSubmit" variant="primary">Doğrula</b-button>
          </div>
        </div>
      </b-modal>

    </div>
  </div>
</template>
<script>
import Reklam from "./c_reklam";
var body = document.getElementsByTagName("body")[0];
import { mapState } from "vuex";

export default {
  name: 'Search',
  data() {
    return {
      terms: '',
      searchResult: false,
      searchResultEmpty: false,
      //toggle_sidebar_var: false,
      clicked: false,
      toggle_rightsidebar_var: false,
      rightclicked: false,
      mobile_toggle: false,
      mobile_search: false,
      search: [],
      user: null,
      dogrulama: {
        Baslik: '',
        Mesaj: '',
        Tip: 0
      },
      dogrulamaForm: {
        DogrulamaKodu: ''
      }
    }
  },
  mounted() {
    this.user = this.$user.getUser();
  },
  components: {
    Reklam
  },
  computed: {
    ...mapState({
      menuItems: state => state.menu.data,
      toggle_sidebar_var: state => state.menu.toggle_sidebar_var
    }),
    dogrulama_kod_state() {
      if (this.dogrulamaForm.DogrulamaKodu == '' || this.dogrulamaForm.DogrulamaKodu == null)
        return false;

      return true;
    }
  },
  methods: {
    getImgUrl() {
      // console.log("localStorage.getItem('Userinfo').photoURL", localStorage.getItem('Userinfo'));

      return require("../assets/images/dashboard/user.png");
    },
    searchTerm: function () {
      // this.$store.dispatch('menu/searchTerm', this.terms)
      let items = [];
      this.terms = this.terms.toLowerCase();
      this.menuItems.filter(menuItems => {
        if (menuItems.title.toLowerCase().includes(this.terms) && menuItems.type === 'link') {
          items.push(menuItems);
        }
        if (!menuItems.children) return false
        menuItems.children.filter(subItems => {
          if (subItems.title.toLowerCase().includes(this.terms) && subItems.type === 'link') {
            subItems.icon = menuItems.icon
            items.push(subItems);
          }
          if (!subItems.children) return false
          subItems.children.filter(suSubItems => {
            if (suSubItems.title.toLowerCase().includes(this.terms)) {
              suSubItems.icon = menuItems.icon
              items.push(suSubItems);
            }
          })
        })
        this.search = items
      });
    },

    logout: function () {
      this.$router.push('/giris');
      localStorage.clear('user')
    },
    addFix() {
      body.classList.add("offcanvas")
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove("offcanvas")
      this.searchResult = false;
      this.terms = ''
    },
    toggle_sidebar() {
      this.$store.dispatch("menu/setToggleSidebar", !this.toggle_sidebar_var);
      this.clicked = !this.toggle_sidebar_var;
      this.$emit('clicked', this.clicked);
    },
    toggle_rightsidebar() {
      this.toggle_rightsidebar_var = !this.toggle_rightsidebar_var,
        this.rightclicked = !this.toggle_rightsidebar_var,
        this.$emit('rightclicked', this.rightclicked)
    },
    toggle_fullscreen() {
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    dogrulamaAc(tip) {
      let vm = this;

      vm.dogrulama.Tip = tip;

      let url = '/kullanicilar/gsmDogrulamaGonder';
      let messageTitle = "Cep Telefonu Doğrulama";
      if (tip == 1) {
        url = '/kullanicilar/emailDogrulamaGonder';
        messageTitle = "Email Doğrulama";
      }

      vm.$connection.get(url, function (response) {
        vm.$bvModal.show('dogrulamaForm');

        if (tip === 0) {
          vm.dogrulama.Baslik = "Cep Telefonu Doğrulama";
          vm.dogrulama.Mesaj = "Cep telefonunuza gönderilen doğrulama kodunu giriniz.<br />" + response.Message;
        } else {
          vm.dogrulama.Baslik = "Email Doğrulama";
          vm.dogrulama.Mesaj = "Email adresinize gönderilen doğrulama kodunu giriniz.<br />" + response.Message;
        }
      }, function (error) {
        vm.$alert.messageWithTitle(messageTitle, error.Message, 'error');
      });
    },
    dogrulamaSubmit(target) {
      target.preventDefault();

      let isValid = this.dogrulama_kod_state;

      if (isValid) {
        let vm = this;

        let url = '/kullanicilar/gsmDogrula/';
        let messageTitle = "Cep Telefonu Doğrulama";
        if (vm.dogrulama.Tip === 1) {
          url = '/kullanicilar/emailDogrula/';
          messageTitle = "Email Doğrulama";
        }

        vm.$connection.get(url + vm.dogrulamaForm.DogrulamaKodu, function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(messageTitle, response.Message, 'success', function () {
              vm.$bvModal.hide('dogrulamaForm');

              if (vm.dogrulama.Tip === 0)
                vm.$user.setGsmDogrulandi();
              else
                vm.$user.setEmailDogrulandi();

              vm.user = vm.$user.getUser();
            });
          }
        }, function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, 'error');
        });
      }
    }
  },
  watch: {
    search: function () {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.search.length)
        this.searchResultEmpty = true;
      else
        this.searchResultEmpty = false;
    }
  }
}
</script>