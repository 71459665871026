<template>
  <div>
    <p
      :id="'tarih-row-' + rowKey"
      v-if="kayitTarihi !== null || kayitTarihi !== undefined"
    >
      {{ formatDate(kayitTarihi, "DD.MM.YYYY") }}
    </p>
    <p v-else>Tarih yok</p>
    <b-tooltip :target="'tarih-row-' + rowKey" triggers="hover" placement="top">
      Kayıt : {{ formatDate(kayitTarihi, "DD.MM.YYYY hh:mm") }}<br />
      <span v-if="guncelTarih !== null || guncelTarih !== undefined"
        >Güncel : {{ formatDate(guncelTarih, "DD.MM.YYYY hh: mm") }}</span
      >
    </b-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    kayitTarihi: null,
    guncelTarih: null,
    rowKey: "",
  },
  methods: {
    formatDate(value, format) {
      if (value) {
        return this.$moment(String(value)).format(format);
      }
    },
  },
};
</script>
