<template>
  <div>
    <Breadcrumbs main="Tur Rezervasyonu" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid tur-rezervasyon-duzelt">
      <!--<div class="row">
        <div class="col-md-4 mb-3">
          <b-input-group size="lg" prepend="Arama">
            <b-form-input v-model="filtre" @input="(event) => aracFiltrele(event)"></b-form-input>
          </b-input-group>
        </div>
      </div>-->

      <b-pagination v-model="aracSayfa" align="center" :total-rows="toplamAracSayisi"
        :per-page="sayfalamaSayfaBasiKayit"></b-pagination>

      <div class="row" v-if="kayit.RehberId === null || kayit.RehberId > 0">
        <div class="col-md-6 mt-3 offset-md-3">
          <b-button variant="warning" @click="aracSec(null)" block>
            <strong style="color: #000"><i class="fa fa-car"></i> ARAÇ SEÇMEDEN DEVAM ETMEK İSTİYORUM</strong>
          </b-button>
        </div>
      </div>

      <div class="row tur-rez-form-arac mt-4">
        <div class="col-md-2 col-md-2-special" :if="araclar !== null" v-for="arac in araclar" :key="arac.Id">
          <b-card header-tag="div" header-class="p-3" :bg-variant="arac.Id === kayit.AracId ? 'dark' : 'light'"
            :header-bg-variant="arac.Id === kayit.AracId ? 'primary' : 'light'" body-class="p-1">
            <h5 slot="header">
              <b-link :class="arac.Id === kayit.AracId ? 'text-white' : 'text-dark'" @click="aracSec(arac)">
                {{ arac.SoforAdi }}
              </b-link>
              <div class="pull-right">
                <b-badge v-if="arac.isWifi" variant="warning">
                  <i class="fa fa-wifi" aria-hidden="true" style="font-size: 14px"></i>
                </b-badge>
                <b-badge v-if="arac.isAracTakip" variant="warning">
                  <i class="fa fa-location-arrow" aria-hidden="true" style="font-size: 14px"></i>
                </b-badge>
              </div>
            </h5>
            <div class="product-box">
              <div class="product-img">
                <b-carousel :id="'carousel-' + arac.Id" :interval="4000" controls indicators background="#ababab"
                  img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333">
                  <b-carousel-slide v-for="(resim, index) in arac.Resimler" :key="index"
                    :img-src="resim"></b-carousel-slide>
                </b-carousel>
              </div>
              <div class="product-details">
                <b-button class="primary" :disabled="arac.Id === kayit.AracId" @click="aracSec(arac)">
                  Aracı Seç
                </b-button>
              </div>
            </div>
          </b-card>
        </div>
      </div>

      <b-pagination v-model="aracSayfa" align="center" :total-rows="toplamAracSayisi"
        :per-page="sayfalamaSayfaBasiKayit"></b-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Rezervasyon Araç Değiştir",

      breadLinks: [
        {
          path: "/turRezervasyon/detay/" + this.$route.params.id,
          title: "Rezervasyon Detayı",
          icon: "chevron-left",
        },
      ],

      kayit: {},

      sayfalamaSayfaBasiKayit: 18,

      coreAraclar: [],
      araclar: [],
      toplamAracSayisi: 0,
      aracSayfa: 1,
      activeArac: {},

      acenteId: "",

      filtre: "",
    };
  },
  mounted() {
    this.onDetay();
  },
  components: {},
  watch: {
    aracSayfa: function () {
      this.onAraclarList();
    }
  },
  methods: {
    aracFiltrele(event) {
      let kelime = event.toUpperCase().replaceAll("I", "İ");
      console.log(kelime);
      var sonuc = this.coreAraclar.filter((item) => {
        return (
          item.Marka.toUpperCase().replaceAll("I", "İ").indexOf(kelime) > -1 ||
          item.Model.toUpperCase().replaceAll("I", "İ").indexOf(kelime) > -1 ||
          item.Plaka.toUpperCase().replaceAll("I", "İ").indexOf(kelime) > -1 ||
          item.SoforAdi.toUpperCase().replaceAll("I", "İ").indexOf(kelime) > -1
        );
      });

      this.araclar = sonuc;
    },
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/" + this.id,
        function (response) {
          if (response.Success) {
            vm.kayit = response.Data;
            vm.aracSayisiBul();
          } else {
            vm.$alert.messageWithTitle("Tur Detay", response.Message, "error");
          }
        }
      );
    },
    formatTarih(tarih) {
      return this.$moment(String(tarih)).format("YYYY-MM-DD");
    },
    aracSayisiBul() {
      let turTarihi = this.formatTarih(this.kayit.Tarih);
      let vm = this;

      let filtre = {
        TurTarihi: turTarihi,
        AcenteId: this.kayit.AcenteId,
        AracId: this.kayit.AracId,
        Skip: 0,
        Take: 0
      };

      this.$connection.post("/araclar/selectCount", filtre, 'post', function (count) {
        vm.toplamAracSayisi = count;
        if (vm.toplamAracSayisi > 0) {
          vm.onAraclarList();
        }
      });
    },
    onAraclarList() {
      let turTarihi = this.formatTarih(this.kayit.Tarih);
      let vm = this;

      let filtre = {
        TurTarihi: turTarihi,
        AcenteId: this.kayit.AcenteId,
        AracId: this.kayit.AracId,
        Skip: ((this.aracSayfa - 1) * this.sayfalamaSayfaBasiKayit),
        Take: this.sayfalamaSayfaBasiKayit
      };

      vm.$connection.post("/araclar/select", filtre, 'post', function (response) {
        vm.araclar = response;

        let aracs = vm.araclar.filter((item) => {
          return item.Id === vm.kayit.AracId;
        });
        if (aracs.length > 0) vm.activeArac = aracs[0];
      });
    },
    aracSec(arac) {
      if (arac == null) {
        arac = {
          Id: 0,
          EncId: "0",
          AracFirmaId: 0,
          EncAracFirmaId: null,
          SoforAdi: "Araçsız",
          Plaka: "Araçsız",
          Marka: "",
          Model: "",
          isWifi: false,
          isAracTakip: false,
          AracTipiTanim: null,
          Resimler: [],
          Yildiz: 0,
          ToplamYorumSayisi: 0
        }
      }
      
      let vm = this;
      this.$alert.confirm(
        "Araç Değiştir",
        "Rezervasyonun aracı değiştirilecektir. Emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/turRezervasyonlari/" + vm.kayit.Id + "/arac/" + arac.EncId,
            {},
            "put",
            function () {
              vm.kayit.AracId = arac.Id;
              vm.kayit.EncAracId = arac.EncId;
              vm.activeArac = arac;

              vm.$alert.messageWithFunction(
                "Araç Değiştir",
                "Rezervasyon aracı değiştirildi.",
                "success",
                function () {
                  vm.$router.push("/turRezervasyon/detay/" + vm.kayit.Id);
                }
              );
            }
          );
        }
      );
    },
  },
};
</script>
