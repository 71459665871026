export default {
    validEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    validGsm: function (gsm) {
        var re = /^[0]?5([0-9]{2})?[-. ]([0-9]{3})[-. ]([0-9]{2})[-. ]([0-9]{2})$/;
        return re.test(gsm);
    },
    validPassword: function (password) {
        var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
        return re.test(password);
    },
    getMimeType: function (file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }
}