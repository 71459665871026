<template>
  <div>
    <Breadcrumbs main="Dashboard" title="Kontrol Paneli" />
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-12">
          <b-form @submit="onFiltreSubmit" class="pull-right">
            <b-input-group prepend="Tarih">
              <template #prepend>
                <b-button @click="gun(-1)" variant="light"><i class="fa fa-angle-left"></i></b-button>
                <b-button @click="gun(+1)" variant="light"><i class="fa fa-angle-right"></i></b-button>
              </template>
              <datepicker input-class="datepicker-here form-control digits" v-model="filtre.Tarih" :language="tr"
                placeholder="Başlangıç Tarihi" :format="date_format">
              </datepicker>
              <b-input-group-append>
                <b-button type="submit" variant="outline-success">Listele</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-rez" role="tablist">
            <li class="nav-item" v-on:click="changetabRez('turRez')">
              <a class="nav-link" :class="[
            { 'show active': showtabRez == 'turRez' }
          ]" id="top-turrez" data-toggle="tab" role="tab" aria-controls="turRez" aria-selected="true">PRIVATE TUR
                REZERVASYONLARI <b-badge :variant="turRez_sayisi > 0 ? 'primary' : 'dark'">{{ turRez_sayisi }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetabRez('regularIstek')">
              <a class="nav-link" :class="[
            { 'show active': showtabRez == 'regularIstek' }
          ]" id="top-regularistek" data-toggle="tab" role="tab" aria-controls="regularIstek"
                aria-selected="true">REGULAR
                TUR İSTEKLERİ <b-badge :variant="regularIstek_sayisi > 0 ? 'primary' : 'dark'">{{ regularIstek_sayisi }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetabRez('regularRez')">
              <a class="nav-link" :class="[
            { 'show active': showtabRez == 'regularRez' }
          ]" id="top-regularrez" data-toggle="tab" role="tab" aria-controls="regularRez" aria-selected="true">REGULAR
                TUR REZERVASYONLARI <b-badge :variant="regularRez_sayisi > 0 ? 'primary' : 'dark'">{{ regularRez_sayisi }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetabRez('balonRez')">
              <a class="nav-link" :class="[
            { 'show active': showtabRez == 'balonRez' }
          ]" id="top-balonrez" data-toggle="tab" role="tab" aria-controls="balonRez" aria-selected="true">BALON
                REZERVASYONLARI <b-badge :variant="balonRez_sayisi > 0 ? 'primary' : 'dark'">{{ balonRez_sayisi }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetabRez('transferRez')">
              <a class="nav-link" :class="[
            { 'show active': showtabRez == 'transferRez' }
          ]" id="top-transferrez" data-toggle="tab" role="tab" aria-controls="transferRez" aria-selected="true">TRANSFER
                REZERVASYONLARI <b-badge :variant="transferRez_sayisi > 0 ? 'primary' : 'dark'">{{ transferRez_sayisi }}</b-badge></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabRezContent">
        <div class="tab-pane fade" :class="{ 'show active': showtabRez == 'turRez' }" id="turRez" role="tabpanel"
          aria-labelledby="turRez">
          <TurRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null" @onKayitSayisi="onTurRezSayisi" />
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtabRez == 'regularIstek' }" id="regularIstek"
          role="tabpanel" aria-labelledby="regularIstek">
          <RegularIstekList :tarih="filtre.Tarih" v-if="filtre.Tarih != null" @onKayitSayisi="onRegularIstekSayisi" />
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtabRez == 'regularRez' }" id="regularRez"
          role="tabpanel" aria-labelledby="regularRez">
          <RegularRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null"
            @onKayitSayisi="onRegularRezSayisi" />
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtabRez == 'balonRez' }" id="balonRez" role="tabpanel"
          aria-labelledby="balonRez">
          <BalonRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null" @onKayitSayisi="onBalonRezSayisi" />
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtabRez == 'transferRez' }" id="transferRez" role="tabpanel"
          aria-labelledby="transferRez">
          <TransferRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null" @onKayitSayisi="onTransferRezSayisi" />
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
            <li class="nav-item" v-on:click="changetab('acentelist')">
              <a class="nav-link" :class="[
            { 'show active': showtab == 'acentelist' }
          ]" id="top-acentelist" data-toggle="tab" role="tab" aria-controls="acentelist" aria-selected="true">ONAY
                BEKLEYEN<br>ACENTE VE OTELLER <b-badge :variant="acente_sayisi > 0 ? 'primary' : 'dark'">{{ acente_sayisi }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetab('rehberlist')">
              <a class="nav-link" :class="[
            { 'show active': showtab == 'rehberlist' }
          ]" id="top-rehberlist" data-toggle="tab" role="tab" aria-controls="rehberlist" aria-selected="true">ONAY
                BEKLEYEN REHBERLER <b-badge :variant="rehber_sayisi > 0 ? 'primary' : 'dark'">{{ rehber_sayisi }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetab('araclist')">
              <a class="nav-link" :class="[
            { 'show active': showtab == 'araclist' }
          ]" id="top-araclist" data-toggle="tab" role="tab" aria-controls="araclist" aria-selected="false">ONAY
                BEKLEYEN ARAÇLAR
                <b-badge :variant="arac_sayisi > 0 ? 'primary' : 'dark'">{{ arac_sayisi }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetab('aracresimlist')">
              <a class="nav-link" :class="[
            { 'show active': showtab == 'aracresimlist' }
          ]" id="top-aracresimlist" data-toggle="tab" role="tab" aria-controls="aracresimlist"
                aria-selected="false">ONAY BEKLEYEN ARAÇ RESİMLERİ
                <b-badge :variant="aracresim_sayisi > 0 ? 'primary' : 'dark'">{{ aracresim_sayisi }}</b-badge></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div class="tab-pane fade" :class="{ 'show active': showtab == 'acentelist' }" id="acentelist" role="tabpanel"
          aria-labelledby="acentelist">
          <AcenteOnayBekleyenList @rowcount="(sayi) => { acente_sayisi = sayi }" />
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'rehberlist' }" id="rehberlist" role="tabpanel"
          aria-labelledby="rehberlist">
          <RehberOnayBekleyenList @rowcount="(sayi) => { rehber_sayisi = sayi }" />
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'araclist' }" id="araclist" role="tabpanel"
          aria-labelledby="araclist">
          <AracOnayBekleyenList @rowcount="(sayi) => { arac_sayisi = sayi }" />
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'aracresimlist' }" id="aracresimlist"
          role="tabpanel" aria-labelledby="aracresimlist">
          <AracResimOnayBekleyenList @rowcount="(sayi) => { aracresim_sayisi = sayi }" />
        </div>
      </div>

      <YorumDashboard />
    </div>

    <DuyuruGosterimModal />
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import TurRezervasyonList from "@/components/c_tur_rezervasyon_dashboard_yonetim.vue";
import AcenteOnayBekleyenList from "@/components/c_dashboard_acente_onay_bekleyenler.vue";
import RehberOnayBekleyenList from "@/components/c_dashboard_rehber_onay_bekleyenler.vue";
import AracOnayBekleyenList from "@/components/c_dashboard_arac_onay_bekleyenler.vue";
import AracResimOnayBekleyenList from "@/components/c_dashboard_aracResim_onay_bekleyenler.vue";
import DuyuruGosterimModal from "@/components/c_duyuru_gosterim";
import RegularIstekList from "@/components/c_regular_istek_dashboard_yonetim";
import RegularRezervasyonList from "@/components/c_regular_rezervasyon_dashboard_yonetim";
import BalonRezervasyonList from "@/components/c_balon_rezervasyon_dashboard_yonetim";
import TransferRezervasyonList from "@/components/c_transfer_rezervasyon_dashboard_yonetim";
import YorumDashboard from "@/components/c_yorum_dashboard";

export default {
  data() {
    return {
      showtab: "acentelist",
      showtabRez: "turRez",

      acente_sayisi: 0,
      rehber_sayisi: 0,
      arac_sayisi: 0,
      aracresim_sayisi: 0,

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre: {
        Tarih: null,
      },

      turRez_sayisi: 0,
      regularIstek_sayisi: 0,
      regularRez_sayisi: 0,
      balonRez_sayisi: 0,
      transferRez_sayisi: 0
    };
  },
  components: {
    Datepicker,
    TurRezervasyonList,
    AcenteOnayBekleyenList,
    RehberOnayBekleyenList,
    AracOnayBekleyenList,
    AracResimOnayBekleyenList,
    DuyuruGosterimModal,
    RegularIstekList,
    RegularRezervasyonList,
    BalonRezervasyonList,
    TransferRezervasyonList,
    YorumDashboard
  },
  mounted() {
    let user = this.$user.getUser();
    if (user.Kullanici.RedirectUrl !== "/dashboard/default")
      this.$router.push(user.Kullanici.RedirectUrl);

    let bugun = new Date();
    this.filtre.Tarih = bugun;
  },
  methods: {
    onFiltreSubmit(target) {
      target.preventDefault();
    },
    gun(sayi) {
      this.filtre.Tarih = this.$formatHelper.addDay(
        new Date(this.filtre.Tarih),
        sayi
      );
    },
    onTurRezSayisi(sayi) {
      this.turRez_sayisi = sayi;
    },
    onRegularIstekSayisi(sayi) {
      this.regularIstek_sayisi = sayi;
    },
    onRegularRezSayisi(sayi) {
      this.regularRez_sayisi = sayi;
    },
    onBalonRezSayisi(sayi) {
      this.balonRez_sayisi = sayi;
    },
    onTransferRezSayisi(sayi) {
      this.transferRez_sayisi = sayi;
    },
    changetab(tab) {
      this.showtab = tab;
    },
    changetabRez(tab) {
      this.showtabRez = tab;
    },
  }
};
</script>
