<template>
  <div>
    <Breadcrumbs main="Mekanlar" title="Liste" />

    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <b-form @submit="onFiltreSubmit" inline>
            <label class="sr-only" for="inline-form-input-name">Kategori</label>
            <b-form-select
              id="filtre_kategoriId"
              v-model="filtre.KategoriId"
              :options="filtre_kategoriler"
              class="mb-2 mr-sm-2 mb-sm-0"
            ></b-form-select>

            <label class="sr-only" for="inline-form-input-name">Kelime</label>
            <b-form-input
              type="text"
              id="filtre_kelime"
              v-model="filtre.Kelime"
              placeholder="Kelime"
              class="mb-2 mr-sm-2 mb-sm-0"
            ></b-form-input>

            <b-button type="submit" class="pull-right" variant="warning"
              >Listele</b-button
            >
          </b-form>
        </div>
      </div>

      <div class="product-wrapper-grid">
        <div class="row">
          <div
            class="col-lg-3 col-md-4"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="card">
              <div class="product-box">
                <div class="product-img">
                  <img class="img-fluid" :src="item.Resim" :alt="item.Adi" />
                  <div class="product-hover">
                    <ul>
                      <li>
                        <button
                          class="btn"
                          @click="quickView(item)"
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                        >
                          <i class="icon-eye"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="product-details">
                  <h4>{{ item.Adi }}</h4>
                  <p v-html="item.Adres"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal size="lg" v-model="modalShow" hide-footer hide-header>
        <button
          class="close"
          type="button"
          v-on:click="quickViewClose(modalShow)"
        >
          <span>×</span>
        </button>
        <div class="product-box quickview row">
          <div class="product-img col-md-6">
            <img class="img-fluid" :src="mekan.Resim" :alt="mekan.Adi" />
          </div>
          <div class="product-details col-md-6 text-left">
            <h1>{{ mekan.Adi }}</h1>
            <div class="product-view">
              <h6 class="f-w-600">Mekan Detayları</h6>
              <p class="mb-0" v-html="mekan.Adres"></p>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [],

      modalShow: false,

      mekan: {},

      filtre_kategoriler: [
        {
          value: null,
          text: "Hepsi",
        },
      ],
      filtre: {
        KategoriId: null,
        Kelime: null,
      },
    };
  },
  mounted() {
    this.onKategoriList();
    this.onList();
  },
  methods: {
    onFiltreSubmit(evt) {
      evt.preventDefault();
      this.onList();
    },
    onKategoriList() {
      let vm = this;

      this.$connection.get("/kategoriler/select/1", function (response) {
        vm.filtre_kategoriler = vm.filtre_kategoriler.concat(response);
      });
    },
    onList() {
      let vm = this;

      this.$connection.post(
        "/mekanlar/detayListele",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
    quickView: function (item) {
      this.modalShow = true;

      let vm = this;

      this.$connection.get("/mekanlar/" + item.Id, function (response) {
        if (response.Success) {
          vm.mekan = response.Data;
          return vm.mekan;
        }
      });
    },
    quickViewClose: function () {
      this.modalShow = false;
    },
  },
};
</script>
