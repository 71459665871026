<template>
  <div>
    <Breadcrumbs main="Araçlar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Araç Kaydı</h5>
              <span>Yeni araç veya araç kaydında değişiklikler için aşağıdaki formu
                kullanabilirsiniz</span>
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_soforadi">Şoför Adı</label>
                    <b-form-input type="text" id="kayit_soforadi" v-model="kayit.SoforAdi" :state="kayit_soforadi_state"
                      placeholder="Şoför Adı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_soforadi_state">Şoför adını giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_plaka">Plaka</label>
                    <b-form-input type="text" id="kayit_plaka" v-model="kayit.Plaka" :state="kayit_plaka_state"
                      placeholder="Plaka"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_plaka_state">Araç plakasını giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_marka">Marka</label>
                    <b-form-input type="text" id="kayit_marka" v-model="kayit.Marka" placeholder="Marka"
                      :state="kayit_marka_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_marka_state">Araç markasını giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_model">Model</label>
                    <b-form-input type="text" id="kayit_model" v-model="kayit.Model" placeholder="Model"
                      :state="kayit_model_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_model_state">Araç modelini giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_yetkibelgesituru">Yetki Belgesi Türü</label>
                    <b-form-input type="text" id="kayit_yetkibelgesituru" v-model="kayit.YetkiBelgesiTuru"
                      placeholder="Yetki Belgesi Türü"></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_yetkibelgesino">Yetki Belgesi No</label>
                    <b-form-input type="text" id="kayit_yetkibelgesino" v-model="kayit.YetkiBelgesiNo"
                      placeholder="Yetki Belgesi No"></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tckimlikno">T.C. Kimlik No</label>
                    <b-form-input type="text" id="kayit_tckimlikno" v-model="kayit.TcKimlikNo"
                      :state="kayit_tckimlikno_state" placeholder="11 haneli T.C. kimlik numarası"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_tckimlikno_state">Şoförün T.C. kimlik numarasını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3" v-if="isDurumGuncelle">
                    <label>Durum</label>
                    <b-form-select id="kayit_durum" v-model="kayit.Durum"
                      :options="kayit_durum_options"></b-form-select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_gsm">Cep Telefonu</label>
                    <b-form-input type="text" id="kayit_gsm" v-model="kayit.GSM" v-mask="'5## ### ## ##'"
                      placeholder="Cep Telefonu" :state="kayit_gsm_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_gsm_state">Cep telefonunu giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_email">Email</label>
                    <b-form-input type="text" id="kayit_email" v-model="kayit.Email" placeholder="Email"
                      :state="kayit_email_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_email_state">Email adresini giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label>Takip Cihazı</label>
                    <b-form-select id="kayit_takipcihazid" v-model="kayit.TakipCihazId"
                      :options="kayit_takipcihaz_options"></b-form-select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox name="isWifi" v-model="kayit.isWifi">Wifi</b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <b-form-input type="text" id="kayit_sira" v-model="kayit.Sira"
                      placeholder="Listeleme Sırası"></b-form-input>
                    <p>En büyük değer en başta gözükür</p>
                  </div>
                </div>
                <!--<div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_alisucreti">Servis Alış Ücreti</label>
                    <b-form-input type="text" id="kayit_alisucreti" v-model="kayit.AlisUcreti"
                      :state="kayit_alisucreti_state" placeholder="Servis Alış Ücreti"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_alisucreti_state">Servis alış ücretiniz
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_satisucreti">Servis Satış Ücreti</label>
                    <b-form-input type="text" id="kayit_satisucreti" v-model="kayit.SatisUcreti"
                      :state="kayit_satisucreti_state" placeholder="Servis Satış Ücreti"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_satisucreti_state">Servis satış ücretini alış ücretinden
                      büyük
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>-->
                <div class="form-row" v-if="id === undefined">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
                    <b-form-input type="text" id="kayit_kullaniciadi" v-model="kayit.KullaniciAdi"
                      placeholder="Kullanıcı Adı" :state="kayit_kullaniciadi_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_kullaniciadi_state">Ana araç firması kullanıcısının kullanıcı
                      adını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sifre">Şifre</label>
                    <b-form-input type="text" id="kayit_sifre" v-model="kayit.Sifre" placeholder="Şifre"
                      :state="kayit_sifre_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sifre_state">Ana araç firması kullanıcısı için 6 ile 20
                      karakter
                      arasında rakam, küçük ve büyük harf bulunan bir şifre
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <router-link
                  :to="this.aracFirmaId === '0' ? '/aracFirma/bagimsizAracList' : '/aracFirma/aracList/' + this.aracFirmaId"
                  class="btn btn-light pull-left">Geri Dön</router-link>
                <b-button type="submit" class="pull-right" variant="primary">Kaydet</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      aracFirmaId: this.$route.params.aracFirmaId,
      id: this.$route.params.id,

      pageTitle: "Yeni Araç Firma",

      breadLinks: [
        {
          path:
            this.$route.params.aracFirmaId !== "0"
              ? "/AracFirma/aracList/" + this.$route.params.aracFirmaId
              : "/AracFirma/bagimsizAracList",
          title: "Araçlar",
          icon: "chevron-left",
        },
      ],

      kayit: {
        AracFirmaIdPT: this.$route.params.aracFirmaId,
        SoforAdi: "",
        Email: "",
        GSM: "",
        TcKimlikNo: "",
        AracTipiId: 0,
        Plaka: "",
        Durum: 0,
        Marka: "",
        Model: "",
        isWifi: true,
        isAracTakip: true,
        TakipCihazId: 0,
        EntegrasyonAyarlariJson: "",
        YetkiBelgesiTuru: "",
        YetkiBelgesiNo: "",
        Sira: 0,
        /*AlisUcreti: 0,
        SatisUcreti: 0,*/
        KullaniciAdi: "",
        Sifre: "",
      },

      isDurumGuncelle: false,

      kayit_aractipi_options: [{ value: "0", text: "Seçiniz" }],

      kayit_durum_options: [
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],

      kayit_takipcihaz_options: [
        { value: "0", text: "Yok" },
        { value: "1", text: "Trio" },
        { value: "2", text: "Arvento" },
        { value: "3", text: "Mobiliz" },
      ],
    };
  },
  mounted() {
    this.getFirma();

    let vm = this;

    if (this.$user.isYonetim()) {
      this.isDurumGuncelle = true;
    }

    if (this.id !== undefined) {
      vm.pageTitle = "Araç Firma Düzelt";

      this.$connection.get("/Araclar/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
          vm.kayit.AracFirmaIdPT = vm.aracFirmaId;

          if (
            vm.$user.isYonetim() == false &&
            vm.kayit.Durum !== 0 &&
            vm.kayit.Durum !== 100
          ) {
            //merkez değilse giren İptal veya yeni değilse de düzenlesin durumu
            vm.isDurumGuncelle = true;
          }
        } else {
          vm.$alert.messageWithTitle("Yeni Araç", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_soforadi_state() {
      if (this.kayit.SoforAdi == null || this.kayit.SoforAdi == "")
        return false;
      else return true;
    },
    kayit_plaka_state() {
      if (this.kayit.Plaka == null || this.kayit.Plaka == "") return false;
      else return true;
    },
    kayit_marka_state() {
      if (this.kayit.Marka == null || this.kayit.Marka == "") return false;
      else return true;
    },
    kayit_model_state() {
      if (this.kayit.Model == null || this.kayit.Model == "") return false;
      else return true;
    },
    kayit_tckimlikno_state() {
      if (
        this.kayit.TcKimlikNo == null ||
        this.kayit.TcKimlikNo == "" ||
        this.kayit.TcKimlikNo.length != 11
      )
        return false;
      else return true;
    },
    kayit_aractipiid_state() {
      if (this.kayit.AracTipiId == "0") return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
    /*kayit_alisucreti_state() {
      if (this.kayit.AlisUcreti === "" || this.kayit.AlisUcreti === null)
        return false;
      else return true;
    },
    kayit_satisucreti_state() {
      if (
        this.kayit.SatisUcreti === "" ||
        this.kayit.SatisUcreti === null ||
        parseFloat(this.kayit.SatisUcreti) < parseFloat(this.kayit.AlisUcreti)
      )
        return false;
      else return true;
    },*/
    kayit_kullaniciadi_state() {
      if (
        this.id === undefined &&
        (this.kayit.KullaniciAdi === null || this.kayit.KullaniciAdi === "")
      )
        return false;
      else return true;
    },
    kayit_sifre_state() {
      if (
        this.id === undefined &&
        !this.$validationHelper.validPassword(this.kayit.Sifre)
      )
        return false;
      else return true;
    },
  },
  methods: {
    getFirma() {
      if (this.aracFirmaId !== "0") {
        let vm = this;

        this.$connection.get(
          "/AracFirmalari/" + this.aracFirmaId,
          function (response) {
            if (response.Success) {
              vm.pageTitle = response.Data.Adi + " Araçları";
            } else {
              vm.$alert.messageWithTitle(
                "Firma Araçları",
                response.Message,
                "error"
              );
            }
          }
        );
      }
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_soforadi_state &&
        this.kayit_plaka_state &&
        this.kayit_marka_state &&
        this.kayit_model_state &&
        this.kayit_tckimlikno_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        /*this.kayit_alisucreti_state &&
        this.kayit_satisucreti_state &&*/
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state;

      if (isValid) {
        let vm = this;

        let url = "/Araclar";
        let method = "post";
        let messageTitle = "Yeni Araç";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Değişiklikler Güncellendi.";
        }

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                '',
                "success",
                function () {
                  if (vm.aracFirmaId !== "0") {
                    vm.$router.push("/AracFirma/aracList/" + vm.aracFirmaId);
                  } else {
                    vm.$router.push("/AracFirma/bagimsizAracList");
                  }
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
