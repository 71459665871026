<template>
    <div>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="authentication-main">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <div class="auth-innerright">
                                <div class="reset-password-box">
                                    <div class="text-center"><img alt="" src="../assets/images/logo.png"></div>
                                    <div class="card mt-4 mb-0">
                                        <h4>Şifremi Unuttum</h4>
                                        <b-form @submit="sifreTalep" class="theme-form" v-if="showSection === 'talep'">
                                            <div class="form-group">
                                                <label class="col-form-label">Email, Cep Telefonu ya da Kullanıcı
                                                    Adınızı
                                                    Giriniz</label>
                                                <div class="form-row mb-3">
                                                    <div class="col-md-12">
                                                        <b-form-input type="text" v-model="kelime"
                                                            :state="talep_kelime_state"
                                                            placeholder="Email, cep telefonu ya da kullanıcı adı"></b-form-input>
                                                        <b-form-invalid-feedback :state="talep_kelime_state">Email, cep
                                                            telefonu veya kullanıcı adını
                                                            giriniz.</b-form-invalid-feedback>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-md-6">
                                                        <router-link class="btn btn-link" :to="'/giris'">Giriş
                                                            Yap</router-link>
                                                    </div>
                                                    <div class="col-md-6 text-right">
                                                        <b-button variant="primary" type="submit">Gönder</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-form>
                                        <b-form @submit="sifreYenileme" class="theme-form"
                                            v-if="showSection === 'yenile'">
                                            <div class="alert alert-info">Doğrulama kodu {{ userGsm }} telefonuna
                                                whatsapp
                                                üzerinden ve {{ userEmail }} mail adresine gönderilmiştir.</div>
                                            <div class="form-group rounded p-4 opt-box">
                                                <label class="col-form-label pt-0">Doğrulama Kodu</label>
                                                <div class="form-row">
                                                    <div class="col-md-12">
                                                        <b-form-input type="text" v-model="sifreYenile.DogrulamaKodu"
                                                            :state="yenile_dogrulamakodu_state"
                                                            placeholder="6 haneli doğrulama kodu"></b-form-input>
                                                        <b-form-invalid-feedback :state="yenile_dogrulamakodu_state">6
                                                            haneli doğrulama kodunu giriniz.</b-form-invalid-feedback>
                                                    </div>
                                                </div>
                                            </div>

                                            <h6 class="f-14 mt-4 mb-3">Şifrenizi Oluşturun</h6>
                                            <div class="form-group">
                                                <label class="col-form-label" for="yenile_sifre">Yeni Şifre</label>
                                                <b-form-input type="text" id="yenile_sifre" v-model="sifreYenile.Sifre"
                                                    placeholder="Şifre" :state="yenile_sifre_state"></b-form-input>
                                                <b-form-invalid-feedback :state="yenile_sifre_state">8 ile 20 karakter
                                                    arasında rakam, küçük ve büyük harf bulunan bir
                                                    şifre giriniz</b-form-invalid-feedback>
                                            </div>
                                            <div class="form-group form-row mb-2">
                                                <div class="col-md-6">
                                                    <router-link class="btn btn-link" :to="'/giris'">Giriş
                                                        Yap</router-link>
                                                </div>
                                                <div class="col-md-6 text-right">
                                                    <b-button variant="primary" type="submit">Değiştir</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            kelime: '',
            showSection: 'talep',
            id: '',
            userGsm: '',
            userEmail: '',

            sifreYenile: {
                DogrulamaKodu: '',
                Sifre: ''
            }
        };
    },
    computed: {
        talep_kelime_state() {
            if (this.kelime == null || this.kelime == '')
                return false;
            else
                return true;
        },
        yenile_dogrulamakodu_state() {
            if (this.sifreYenile.DogrulamaKodu == null || this.sifreYenile.DogrulamaKodu == '' || this.sifreYenile.DogrulamaKodu.length != 6)
                return false;
            else
                return true;
        },
        yenile_sifre_state() {
            if (!this.$validationHelper.validPassword(this.sifreYenile.Sifre))
                return false;
            else
                return true;
        }
    },
    methods: {
        sifreTalep(event) {
            event.preventDefault();

            let isValid = this.talep_kelime_state;

            if (isValid) {
                let vm = this;

                this.$connection.get('/auth/SifreYenilemeTalep?kelime=' + this.kelime, function (response) {
                    if (response.Success) {
                        vm.showSection = 'yenile';
                        vm.id = response.Data.Id;
                        vm.userEmail = response.Data.Email;
                        vm.userGsm = response.Data.Gsm;
                    } else {
                        vm.$alert.messageWithTitle("Şifre Talep", response.Message, 'error');
                    }
                });
            }
        },
        sifreYenileme(event) {
            event.preventDefault();

            let isValid = this.yenile_dogrulamakodu_state && this.yenile_sifre_state;

            if (isValid) {
                let vm = this;

                this.$connection.post('/auth/SifreYenile/' + this.id, this.sifreYenile, 'put', function (response) {
                    if (response.Success) {
                        vm.$alert.messageWithFunction("Şifre Yenile", response.Message, 'success', function () {
                            vm.$router.push('/giris');
                        });
                    } else {
                        vm.$alert.messageWithTitle("Şifre Yenile", response.Message, 'error');
                    }
                });
            }
        }
    }
}
</script>