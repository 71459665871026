<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        Puana Göre Filtrele
        <star-rating v-model="maxPuan" :star-size="20" :max-rating="5" :show-rating="true"></star-rating>
      </div>
    </div>

    <div class="alert alert-info mt-3" v-if="yorumlar.length == 0">Hiç yorum bulunmadı.</div>
    <b-list-group class="mt-3">

      <b-list-group-item tag="div" class="flex-column align-items-start" v-for="yorum in yorumlar" :key="yorum.Id">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            <star-rating v-model="yorum.Puan" :star-size="20" :max-rating="5" :show-rating="false"
              :read-only="true"></star-rating>
          </h5>
          <small>
            <c-tarih-cell :kayitTarihi="yorum.KayitTarihi" :rowKey="yorum.Id"></c-tarih-cell>
          </small>
        </div>
        <p class="mb-1" v-if="yorum.Yorum == ''">Yorum yok</p>
        <p class="mb-1" v-if="yorum.Yorum != ''">
          {{ yorum.Yorum }}
        </p>
      </b-list-group-item>

    </b-list-group>
    <b-button type="button" v-if="sonrakiSayfa" @click="sonrakiSayfaGec()" variant="primary" class="mt-2">Daha Fazla Yükle</b-button>
  </div>
</template>
<script>
import StarRating from 'vue-star-rating';

export default {
  props: {
    id: null
  },
  data() {
    return {
      yorumlar: [],

      sonrakiSayfa: true,

      maxPuan: 5,

      filtre: {
        BaslangicTarihi: null,
        BitisTarihi: null,
        MinPuan: null,
        MaxPuan: 5,
        RehberId: this.id,
        SavunmaKabulDurumu: null,
        RehbereGoster: null,
        Skip: 0,
        Take: 20
      }
    };
  },
  components: {
    StarRating
  },
  mounted() {
    this.list();
  },
  watch: {
    maxPuan(newValue, oldValue) {
      this.filtre.MaxPuan = newValue;
      this.filtre.Skip = 0;
      this.list(true);
    }
  },
  methods: {
    filtreSubmit(event) {
      event.preventDefault();
      this.list(true);
    },
    list(bosalt) {
      let vm = this;

      vm.filtre.MinPuan = vm.filtre.MinPuan == "" ? null : vm.filtre.MinPuan;
      vm.filtre.MaxPuan = vm.filtre.MaxPuan == "" ? null : vm.filtre.MaxPuan;

      this.$connection.post(
        "/rehberler/yorumlari",
        vm.filtre,
        "post",
        function (response) {
          if (bosalt == true) {
            vm.yorumlar = response;
          } else {
            if (response.length == 0) {
              vm.sonrakiSayfa = false;
            } else {
              if (response.length < 20) {
                vm.sonrakiSayfa = false;
              }

              vm.yorumlar = vm.yorumlar.concat(response);
            }
          }
        }
      );
    },
    sonrakiSayfaGec() {
      this.filtre.Skip = this.filtre.Skip + 1;
      this.list(false);
    }
  },
};
</script>
