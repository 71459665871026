<template>
  <div class="card rezervasyon-form">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <h5><span class="badge badge-primary">{{ kayit.Donus ? 'Dönüş' : 'Geliş' }}</span></h5>
          <div class="form-row transfer-form-donus-row">
            <div :class="'col-md-6 mb-3 transfer-form-donus nereden ' + (kayit.Donus ? 'donus' : 'gelis')">
              <b-form-select id="kayit_bolgeid" @change="hedefBolgeList()" v-model="kayit.BolgeId" :options="bolgeler"
                :state="kayit_bolgeid_state"></b-form-select>
              <b-form-invalid-feedback :state="kayit_bolgeid_state">Lütfen transferin başlangıç noktasını
                seçiniz.</b-form-invalid-feedback>
            </div>
            <div :class="'col-md-6 mb-3 transfer-form-donus nereye ' + (kayit.Donus ? 'donus' : 'gelis')">
              <b-input-group>
                <b-form-input type="text" id="kayit_nereye" v-model="kayit.Nereye" :state="kayit_nereye_state"
                  placeholder="Nereye"></b-form-input>
                <b-input-group-append>
                  <b-form-select id="kayit_hedefbolgeid" v-model="kayit.HedefBolgeId" :options="hedefBolgeler"
                    :state="kayit_nereye_state"></b-form-select>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback :state="kayit_nereye_state">Lütfen transferin bitiş noktasını yazın ve
                bölgesini
                seçiniz.</b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-4 mb-3">
              <label for="kayit_tarih">Tarih</label>
              <datepicker :language="tr" input-class="datepicker-here form-control digits" id="kayit_tarih"
                v-model="kayit.Tarih" placeholder="Transfer Tarihi" :format="date_format" :state="kayit_tarih_state">
              </datepicker>
              <b-form-invalid-feedback :state="kayit_tarih_state">Tur tarihini giriniz</b-form-invalid-feedback>
            </div>
            <div class="col-md-2 mb-3">
              <label for="kayit_saat">Saat</label>
              <b-form-input type="time" v-model="kayit.SaatFull" id="kayit_saat"
                :state="kayit_saatfull_state"></b-form-input>
              <b-form-invalid-feedback :state="kayit_saatfull_state">Tur saatini giriniz</b-form-invalid-feedback>
            </div>
            <div class="col-md-6 mb-3">
              <label for="kayit_nereyeadres">&nbsp;</label>
              <b-form-input type="text" id="kayit_nereyeadres" v-model="kayit.NereyeAdres"
                :state="kayit_nereyeadres_state" placeholder="Otel Adresi"></b-form-input>
              <b-form-invalid-feedback :state="kayit_nereyeadres_state">Otel adresini giriniz</b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <b-form-textarea id="kayit_aciklama" v-model="kayit.Aciklama" placeholder="Açıklama"></b-form-textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 mb-3">
              <b-form-select id="kayit_aractipiid" @change="aracTipiSec()" v-model="kayit.AracTipiId"
                :options="aracTipleri" :state="kayit_aractipi_state"></b-form-select>
              <b-form-invalid-feedback :state="kayit_aractipi_state">Araç tipini seçiniz</b-form-invalid-feedback>
            </div>
            <div class="col-md-6 mb-3">
              <b-form-input type="text" id="kayit_ucuskodu" v-model="kayit.UcusKodu" :state="kayit_ucuskodu_state"
                placeholder="Uçuş Kodu"></b-form-input>
              <b-form-invalid-feedback :state="kayit_ucuskodu_state">Uçuş kodunu yazınız</b-form-invalid-feedback>
            </div>
          </div>

          <div class="transfer-form-ciftyon" v-if="kayit.isCiftYon">
            <h5><span class="badge badge-primary">{{ kayit.Donus == false ? 'Dönüş' : 'Geliş' }}</span></h5>
            <div class="form-row transfer-form-donus-row">
              <div :class="'col-md-6 mb-3 transfer-form-donus nereden ' + (kayit.Donus == false ? 'donus' : 'gelis')">
                <b-form-select id="kayit_donus_bolgeid" @change="donusHedefBolgeList()" v-model="kayit.DonusBolgeId"
                  :options="bolgeler" :state="kayit_donus_bolgeid_state"></b-form-select>
                <b-form-invalid-feedback :state="kayit_donus_bolgeid_state">Lütfen transferin başlangıç noktasını
                  seçiniz.</b-form-invalid-feedback>
              </div>
              <div :class="'col-md-6 mb-3 transfer-form-donus nereye ' + (kayit.Donus == false ? 'donus' : 'gelis')">
                <b-input-group>
                  <b-form-input type="text" id="kayit_donus_nereye" v-model="kayit.DonusNereye"
                    :state="kayit_donus_nereye_state" placeholder="Nereye"></b-form-input>
                  <b-input-group-append>
                    <b-form-select id="kayit_donus_hedefbolgeid" v-model="kayit.DonusHedefBolgeId"
                      :options="donusHedefBolgeler" :state="kayit_donus_nereye_state"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="kayit_donus_nereye_state">Lütfen transferin bitiş noktasını yazın ve
                  bölgesini
                  seçiniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label for="kayit_donus_tarih">Tarih</label>
                <datepicker :language="tr" input-class="datepicker-here form-control digits" id="kayit_donus_tarih"
                  v-model="kayit.DonusTarih" placeholder="Transfer Tarihi" :format="date_format"
                  :state="kayit_donus_tarih_state">
                </datepicker>
                <b-form-invalid-feedback :state="kayit_donus_tarih_state">Tur tarihini giriniz</b-form-invalid-feedback>
              </div>
              <div class="col-md-2 mb-3">
                <label for="kayit_donus_saat">Saat</label>
                <b-form-input type="time" v-model="kayit.DonusSaatFull" id="kayit_donus_saat"
                  :state="kayit_donus_saatfull_state"></b-form-input>
                <b-form-invalid-feedback :state="kayit_donus_saatfull_state">Tur saatini
                  giriniz</b-form-invalid-feedback>
              </div>
              <div class="col-md-6 mb-3">
                <label for="kayit_donus_nereyeadres">&nbsp;</label>
                <b-form-input type="text" id="kayit_nereyeadres" v-model="kayit.DonusNereyeAdres"
                  :state="kayit_donus_nereyeadres_state" placeholder="Otel Adresi"></b-form-input>
                <b-form-invalid-feedback :state="kayit_donus_nereyeadres_state">Otel adresini
                  giriniz</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <b-form-textarea v-model="kayit.DonusAciklama" placeholder="Açıklama"></b-form-textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3"></div>
              <div class="col-md-6 mb-3">
                <b-form-input type="text" id="kayit_donus_ucuskodu" v-model="kayit.DonusUcusKodu"
                  :state="kayit_donus_ucuskodu_state" placeholder="Uçuş Kodu"></b-form-input>
                <b-form-invalid-feedback :state="kayit_donus_ucuskodu_state">Uçuş kodunu
                  yazınız</b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <h5 class="pull-left">Transfer Ücreti : <b-badge variant="info">{{ $formatHelper.formatMoney(transferFiyati)
          }}₺</b-badge></h5>
      <b-button @click="onComplete()" :disabled="loading" variant="success" class="pull-right">{{loading ? 'Rezervasyon Güncelleniyor' : 'Rezervasyon Düzelt'}}</b-button>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import { BFormTimepicker } from "bootstrap-vue";
import Multiselect from "vue-multiselect";

export default {
  props: {
    rezervasyon: {},
    acenteId: "",
    id: undefined
  },
  data() {
    return {
      detayValidMessage: "",

      date_format: "dd.MM.yyyy",
      tr: tr,

      loading: false,

      mutationAcenteId: this.acenteId,

      kayit: {},

      donus_options: [
        { text: 'Havalimanından Adrese Git', value: false },
        { text: 'Adresten Havalimanına Git', value: true }
      ],

      bolgeler: [{ text: 'Havalimanı', value: 0 }],
      hedefBolgeler: [{ text: 'Hedef Bölge', value: 0 }],
      aracTipleri: [{ text: 'Araç Tipi', value: 0 }],
      transferFiyati: 0,
    };
  },
  mounted() {
    this.kayit = {
      Kod: this.rezervasyon.Kod,
      BolgeId: this.rezervasyon.BolgeId,
      Nereye: this.rezervasyon.Nereye,
      NereyeAdres: this.rezervasyon.NereyeAdres,
      HedefBolgeId: this.rezervasyon.HedefBolgeId,
      AracTipiId: this.rezervasyon.AracTipiId,
      Tarih: this.rezervasyon.Tarih,
      SaatFull: this.$moment(this.rezervasyon.Tarih).format("HH:mm"),
      Saat: this.$moment(this.rezervasyon.Tarih).format("HH"),
      Dakika: this.$moment(this.rezervasyon.Tarih).format("mm"),
      UcusKodu: this.rezervasyon.UcusKodu,
      Aciklama: this.rezervasyon.Aciklama,
      Donus: this.rezervasyon.isDonus
    };

    if (this.acenteId === 0) {
      this.mutationAcenteId = this.$user.acenteId();
    }

    this.bolgeList();
    this.aracTipiList();
  },
  components: {
    Datepicker,
    Multiselect,
    BFormTimepicker
  },
  computed: {
    kayit_bolgeid_state() {
      if (this.kayit.BolgeId == 0)
        return false;
      else return true;
    },
    kayit_nereye_state() {
      if (this.kayit.Nereye == null || this.kayit.Nereye == "" || this.kayit.HedefBolgeId == 0)
        return false;
      else return true;
    },
    kayit_tarih_state() {
      if (this.kayit.Tarih == "" || this.kayit.Tarih == null) return false;
      else return true;
    },
    kayit_saatfull_state() {
      if (this.kayit.SaatFull == "" || this.kayit.SaatFull == null) return false;
      else return true;
    },
    kayit_nereyeadres_state() {
      if (this.kayit.NereyeAdres == "" || this.kayit.NereyeAdres == null) return false;
      else return true;
    },
    kayit_ucuskodu_state() {
      if (this.kayit.UcusKodu == "" || this.kayit.UcusKodu == null) return false;
      else return true;
    },
    kayit_aractipi_state() {
      if (this.kayit.AracTipiId == 0) return false;
      else return true;
    }
  },
  methods: {
    resetForm() {
      this.kayit = {
        isCiftYon: false,
        BolgeId: 0,
        Nereye: null,
        NereyeAdres: null,
        HedefBolgeId: 0,
        AracTipiId: null,
        Tarih: this.addDays(1),
        SaatFull: "09:00",
        Saat: 0,
        Dakika: 0,
        UcusKodu: null,
        Aciklama: null,
        Donus: false,
        DonusBolgeId: 0,
        DonusNereye: null,
        DonusHedefBolgeId: 0,
        DonusTarih: null,
        DonusSaatFull: "09:00",
        DonusSaat: 0,
        DonusDakika: 0,
        DonusUcusKodu: null,
        DonusAciklama: null,
        AcentaNo: null,
        Yolcular: []
      };
      this.finishButtonText = "Oluştur";
      this.mutateTab = 0;
      this.turValidMessage = "";
      this.detayValidMessage = "";
      this.rehberValidMessage = "";
      this.aracValidMessage = "";
      this.ekliMekanlar = [];
      this.turHesapla = {};
      this.finalModel = {};
      this.activeTur = {};
      this.activeOpsiyon = {};
      this.activeOpsiyonDetay = {};
      this.activeRehber = {};
      this.activeArac = {};
      this.turOpsiyonlari = [];

      this.$refs.rezervasyonWizard.reset();
    },
    formatTarih(tarih) {
      return this.$moment(String(tarih)).format("YYYY-MM-DD");
    },
    addDays(days) {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    bolgeList() {
      let vm = this;
      this.$connection.get("/laalTransferler/bolgeler", function (response) {
        let list = response.map((item) => {
          return {
            text: item.Adi,
            value: item.Id,
          };
        });
        vm.bolgeler = vm.bolgeler.concat(list);
        vm.hedefBolgeList();
      });
    },
    hedefBolgeList() {
      let vm = this;
      this.$connection.get("/laalTransferler/hedefBolgeler/" + this.kayit.BolgeId, function (response) {
        let list = response.map((item) => {
          return {
            text: item.Tanim,
            value: item.Id,
          };
        });

        vm.hedefBolgeler = [
          { text: 'Hedef Bölge', value: 0 }
        ];
        vm.hedefBolgeler = vm.hedefBolgeler.concat(list);
        if (list.length == 1) {
          vm.kayit.HedefBolgeId = list[0].value;
        } else {
          vm.kayit.HedefBolgeId = null;
        }

        vm.aracTipiSec();
      });
    },
    aracTipiList() {
      let vm = this;
      this.$connection.get("/laalTransferler/aracTipleri", function (response) {
        let list = response.map((item) => {
          return {
            text: item.Tanim,
            value: item.Id,
          };
        });
        vm.aracTipleri = vm.aracTipleri.concat(list);
      });
    },
    aracTipiSec() {
      let vm = this;
      let isValid = this.kayit_bolgeid_state &&
        this.kayit_nereye_state &&
        this.kayit_aractipi_state;

      if (isValid) {
        vm.$connection.get("/laalTransferler/fiyat/" + vm.kayit.BolgeId + "/" + vm.kayit.HedefBolgeId + "/" + vm.kayit.AracTipiId, function (response) {
          vm.transferFiyati = response.SatisFiyat;
        });
      }
    },
    onComplete() {
      let isValid =
        this.kayit_bolgeid_state &&
        this.kayit_nereye_state &&
        this.kayit_tarih_state &&
        this.kayit_saatfull_state &&
        this.kayit_nereyeadres_state &&
        this.kayit_ucuskodu_state &&
        this.kayit_aractipi_state;

      if (!isValid) {
        this.$alert.messageWithTitle(
          "Eksik Bilgi",
          "Lütfen rezervasyon bilgilerinizi kontrol ediniz.",
          "error"
        );
      } else {
        let tarih = this.$formatHelper.formatDate(this.kayit.Tarih);
        let arrTarih = tarih.split(".");
        this.kayit.Tarih = arrTarih[2] + "-" + arrTarih[1] + "-" + arrTarih[0];

        let arrSaat = this.kayit.SaatFull.split(":");
        this.kayit.Saat = arrSaat[0];
        this.kayit.Dakika = arrSaat[1];

        this.loading = true;
        this.$emit("onComplete", this.kayit);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>