<template>
  <div>
    <Breadcrumbs main="Balon Firma" title="Kontenjanlar" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="card">
            <div class="card-header">
              <b-button @click="geri()">
                <i class="fa fa-angle-left"></i>
              </b-button>
              {{ ay }} / {{ yil }}
              <b-button @click="ileri()">
                <i class="fa fa-angle-right"></i>
              </b-button>
              <b-button @click="kaydet()" class="pull-right" variant="success">KAYDET</b-button>
            </div>
            <div class="card-body">

              <table class="table">
                <tr>
                  <th>Tarih</th>
                  <th>Alış (€)</th>
                  <th>Satış (€)</th>
                  <th>Kontenjan</th>
                  <th>Doluluk</th>
                </tr>
                <tr v-for="item in items">
                  <td>
                    {{ $moment(String(item.Tarih)).format("DD.MM.YYYY") }}
                  </td>
                  <td>
                    <b-form-input type="text" v-model="item.AlisFiyati" :state="alis_fiyati(item)"></b-form-input>
                  </td>
                  <td>
                    <b-form-input type="text" v-model="item.SatisFiyati" :state="satis_fiyati(item)"></b-form-input>
                  </td>
                  <td>
                    <b-form-input type="text" v-model="item.Kontenjan"></b-form-input>
                  </td>
                  <td>
                    <b-form-input type="text" v-model="item.Dolu" :state="dolu(item)"></b-form-input>
                  </td>
                </tr>
              </table>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      breadLinks: [{ path: "/balonFirma/list", title: "Balon Firmaları", icon: "chevron-left" }],

      items: [],

      ay: 0,
      yil: 0
    };
  },
  mounted() {
    let bugun = new Date();
    this.ay = bugun.getMonth() + 1;
    this.yil = bugun.getFullYear();

    this.onList();
  },
  methods: {
    alis_fiyati(item) {
      if (item.Kontenjan > 0 && item.AlisFiyati == 0) return false;
      else return true;
    },
    satis_fiyati(item) {
      if (item.Kontenjan > 0 && item.SatisFiyati == 0) return false;
      else if (item.Kontenjan > 0 && item.AlisFiyati > item.SatisFiyati) return false;
      else return true;
    },
    dolu(item) {
      if (item.Dolu > item.Kontenjan) return false;
      else return true;
    },
    onList() {
      let vm = this;

      this.$connection.get("/balonFirmalari/kontenjan/" + this.id + "/" + this.ay + "/" + this.yil, function (response) {
        vm.items = response;
      });
    },
    geri() {
      let tarih = new Date(this.yil, (this.ay - 1), 1);
      tarih.setMonth(tarih.getMonth() - 1)
      this.ay = tarih.getMonth() + 1;
      this.yil = tarih.getFullYear();
      this.onList();
    },
    ileri() {
      let tarih = new Date(this.yil, (this.ay - 1), 1);
      tarih.setMonth(tarih.getMonth() + 1)
      this.ay = tarih.getMonth() + 1;
      this.yil = tarih.getFullYear();
      this.onList();
    },
    kaydet() {
      let vm = this;

      let validate = true;
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (!this.alis_fiyati(element) || !this.satis_fiyati(element) || !this.dolu(element)) {
          validate = false;
          break;
        }
      }

      if (validate) {
        vm.$connection.post(
          "/balonFirmalari/kontenjan/" + vm.id,
          this.items,
          "POST",
          function () {
            vm.$alert.messageWithTitle(
              "Kontenjan Kaydet",
              "Kontenjan kaydedildi.",
              "success"
            );
          },
          function (error) {
            vm.$alert.messageWithTitle("Kontenjan Kaydet", error.Message, "error");
          }
        );
      }else{
        vm.$alert.messageWithTitle("Kontenjan Kaydet", "Lütfen formdaki hataları gideriniz.", "warning");
      }
    }
  },
};
</script>