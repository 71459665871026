<template>
  <div class="row">
    <div class="col-md-12">
      <b-card header-tag="div" header-bg-variant="info" class="card-absolute"
        v-if="showOpsiyonTutarlar && detay.TurDetayi.OpsiyonTutarlar.length > 0">
        <h5 slot="header">Opsiyon Tutarları</h5>

        <b-table striped hover :fields="opsiyonAcenteTutarFields" :items="detay.TurDetayi.OpsiyonTutarlar">
          <template #cell(Detay)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              Detay {{ row.detailsShowing ? 'Gizle' : 'Göster' }}
            </b-button>
          </template>
          <template #row-details="row">
            <b-table :fields="opsiyonTutarFields" :items="row.item.Tutarlar" head-variant="dark">
              <template #cell(YasStr)="row">
                <b-badge variant="primary">{{ row.item.YasStr }}</b-badge>
              </template>
              <template #cell(Oran)="data">
                <b-badge variant="primary">{{ data.value }}</b-badge>
              </template>
              <template #cell(Fiyat)="data">
                <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="€"></c-money-cell>
              </template>
              <template #cell(ToplamFiyat)="data">
                <c-money-cell :fiyat="data.value" simge="€"></c-money-cell>
              </template>
              <template #cell(AcenteFiyati)="data">
                <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
              </template>
            </b-table>
          </template>
        </b-table>
      </b-card>

      <b-card header-tag="div" header-bg-variant="dark" class="card-absolute" v-if="detay.TurDetayi.Muzeler.length > 0">
        <h5 slot="header">Müzeler</h5>

        <b-table striped hover :fields="muzeAcenteTutarFields" :items="detay.TurDetayi.Muzeler">
          <template #cell(Detay)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              Detay {{ row.detailsShowing ? 'Gizle' : 'Göster' }}
            </b-button>
          </template>
          <template #row-details="row">
            <b-card class="mb-1" v-for="muze in row.item.Muzeler" v-bind:key="muze.MuzeId">
              <b-card-header header-tag="div" class="bg-primary">
                <h5 class="mb-0">
                  {{ muze.MuzeAdi }}
                  <small class="digits">Fiyat {{ muze.MuzeFiyatiTL }}₺</small> /
                  <small class="digits">Toplam {{ muze.ToplamTutarTL }}₺</small>
                </h5>
              </b-card-header>
              <b-card-body class="p-0">
                <b-table striped hover :fields="muzeTutarFields" :items="muze.Tutarlar"></b-table>
              </b-card-body>
            </b-card>
          </template>
        </b-table>
      </b-card>

      <b-card header-tag="div" header-bg-variant="warning" class="card-absolute"
        v-if="detay.TurDetayi.Restoranlar != null && detay.TurDetayi.Restoranlar.length > 0">
        <h5 slot="header">Restoran</h5>

        <b-table striped hover :fields="acenteRestoranTutarFields" :items="detay.TurDetayi.Restoranlar">
          <template #cell(Detay)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              Detay {{ row.detailsShowing ? 'Gizle' : 'Göster' }}
            </b-button>
          </template>
          <template #cell(MenuAlisFiyati)="data">
            <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="₺"></c-money-cell>
          </template>
          <template #cell(MenuSatisFiyati)="data">
            <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="₺"></c-money-cell>
          </template>
          <template #cell(ToplamAlis)="data">
            <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="₺"></c-money-cell>
          </template>
          <template #cell(ToplamSatis)="data">
            <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="₺"></c-money-cell>
          </template>

          <template #row-details="row">
            <b-table :fields="restoranTutarFields" :items="row.item.Tutarlar" head-variant="dark"
              v-if="showRestoranTutarlar">
              <template #cell(YasStr)="row">
                <b-badge variant="primary">{{ row.item.YasStr }}</b-badge>
              </template>
              <template #cell(Oran)="data">
                <b-badge variant="primary">{{ data.value }}</b-badge>
              </template>
              <template #cell(AlisFiyat)="data">
                <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="₺"></c-money-cell>
              </template>
              <template #cell(ToplamAlisFiyat)="data">
                <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="₺"></c-money-cell>
              </template>
              <template #cell(SatisFiyat)="data">
                <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="₺"></c-money-cell>
              </template>
              <template #cell(ToplamSatisFiyat)="data">
                <c-money-cell :fiyat="data.value" simge="₺"></c-money-cell>
              </template>
            </b-table>
          </template>
        </b-table>


      </b-card>

      <div class="row">
        <div class="col-md-6">
          <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
            <h5 slot="header">Rehber</h5>
            <b-card-body class="p-0">
              <table class="product-page-width">
                <tbody>
                  <tr>
                    <th style="width: 30%">Adı</th>
                    <td>{{ detay.TurDetayi.Rehber.RehberAdi }}</td>
                  </tr>
                  <tr>
                    <th>Dil</th>
                    <td>{{ detay.TurDetayi.Rehber.Dil }}</td>
                  </tr>
                  <tr v-if="showRehberAlis">
                    <th>Ücret</th>
                    <td>
                      <b-badge variant="warning"><c-money-cell :fiyat="detay.TurDetayi.Rehber.RehberAlisFiyati"
                          simge="₺"></c-money-cell></b-badge>
                    </td>
                  </tr>
                  <tr v-if="showRehberSatis">
                    <th>Ücret</th>
                    <td>
                      <b-badge variant="success"><c-money-cell :fiyat="detay.TurDetayi.Rehber.RehberSatisFiyati"
                          simge="₺"></c-money-cell></b-badge>
                    </td>
                  </tr>
                  <tr v-if="showRehberKomisyon">
                    <th>Komisyon Oranı</th>
                    <td>
                      <b-badge variant="info">%{{
          detay.TurDetayi.Rehber.RehberKomisyonOrani
        }}</b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-md-6">
          <b-card header-tag="div" header-bg-variant="danger" class="card-absolute">
            <h5 slot="header">Araç & Şoför</h5>
            <b-card-body class="p-0">
              <table class="product-page-width">
                <tbody>
                  <tr v-if="showAracFirma">
                    <th style="width: 30%">Araç Firması</th>
                    <td>{{ detay.AracFirmaAdi }}</td>
                  </tr>
                  <tr>
                    <th>Şoför</th>
                    <td>{{ detay.TurDetayi.Arac.SoforAdi }}</td>
                  </tr>
                  <tr>
                    <th>Plaka</th>
                    <td>{{ detay.TurDetayi.Arac.Plaka }}</td>
                  </tr>
                  <tr v-if="showAracAlis">
                    <th>Ücret</th>
                    <td>
                      <b-badge variant="warning"><c-money-cell :fiyat="detay.TurDetayi.Arac.AlisFiyati"
                          simge="₺"></c-money-cell></b-badge>
                    </td>
                  </tr>
                  <tr v-if="showAracSatis">
                    <th>Ücret</th>
                    <td>
                      <b-badge variant="success"><c-money-cell :fiyat="detay.TurDetayi.Arac.SatisFiyati"
                          simge="₺"></c-money-cell></b-badge>
                    </td>
                  </tr>
                  <tr v-if="showAracKomisyon">
                    <th>Komisyon Oranı</th>
                    <td>
                      <b-badge variant="info">%{{ detay.TurDetayi.Arac.SoforKomisyonOrani }}</b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detay: null,
  },
  data() {
    return {
      showOpsiyonTutarlar: false,
      showMekanKomisyon: false,
      showRestoranAlis: false,
      showRestoranSatis: false,
      showRestoranTutarlar: false,
      showRehberAlis: false,
      showRehberSatis: false,
      showRehberKomisyon: false,
      showAracFirma: false,
      showAracAlis: false,
      showAracSatis: false,
      showAracKomisyon: false,

      opsiyonAcenteTutarFields: [
        { key: "Detay", label: "Detay", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: false },
        { key: "TurOpsiyonAdi", label: "Opsiyon", sortable: false },
      ],

      opsiyonTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "Fiyat", label: "Fiyat", sortable: false },
        { key: "ToplamFiyat", label: "Toplam", sortable: false },
        { key: "AcenteFiyati", label: "Acente Ö.F.", sortable: false },
      ],

      muzeAcenteTutarFields: [
        { key: "Detay", label: "Detay", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: false },
        { key: "TurOpsiyonAdi", label: "Opsiyon", sortable: false },
      ],

      muzeTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "FiyatTL", label: "Fiyat", sortable: false },
        { key: "ToplamTutarTL", label: "Toplam", sortable: false },
      ],

      acenteRestoranTutarFields: [
        { key: "Detay", label: "Detay", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: false },
        { key: "TurOpsiyonAdi", label: "Opsiyon", sortable: false },
        { key: "RestoranAdi", label: "Restoran", sortable: false },
        { key: "MenuAlisFiyati", label: "Menü Alış", sortable: false },
        { key: "MenuSatisFiyati", label: "Menü Satış", sortable: false },
        { key: "ToplamAlis", label: "Toplam Alış", sortable: false },
        { key: "ToplamSatis", label: "Toplam Satış", sortable: false },
      ],

      restoranTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "SatisFiyat", label: "Fiyat", sortable: false },
        { key: "ToplamSatisFiyat", label: "Toplam", sortable: false },
      ],
    };
  },
  mounted() {
    let user = this.$user.getUser();
    switch (user.Kullanici.KullaniciTipi) {
      //Acente Yönetici
      case 10:
        this.showOpsiyonTutarlar = true;
        
        this.fiyatGosterme();
        break;

      //Acente Kullanıcı
      case 15:
        this.showOpsiyonTutarlar = true;
        
        this.fiyatGosterme();
        break;

      //Rehber
      case 20:
        this.showRehberAlis = true;

        this.fiyatGosterme();
        break;

      //Araç Firması
      case 30:
      case 35:
        this.showAracAlis = true;

        this.fiyatGosterme();
        break;

      //Araç
      case 40:
        this.showAracAlis = true;

        this.fiyatGosterme();
        break;

      //Merkez
      case 80:
      case 85:
      case 100:
        this.showOpsiyonTutarlar = true;
        this.showMekanKomisyon = true;
        this.showRestoranAlis = true;
        this.showRestoranSatis = true;
        this.showRestoranTutarlar = true;
        this.showRehberAlis = true;
        this.showRehberSatis = true;
        this.showRehberKomisyon = true;
        this.showAracFirma = true;
        this.showAracAlis = true;
        this.showAracSatis = true;
        this.showAracKomisyon = true;
        break;

      default:
        break;
    }

    if (this.showRestoranAlis) {
      this.restoranTutarFields = [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "AlisFiyat", label: "Alış Fiyatı", sortable: false },
        { key: "ToplamAlisFiyat", label: "Toplam Alış", sortable: false },
        { key: "SatisFiyat", label: "Satış Fiyatı", sortable: false },
        { key: "ToplamSatisFiyat", label: "Toplam Satış", sortable: false },
      ];
    }
  },
  methods: {
    fiyatGosterme() {
      this.opsiyonTutarFields = [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false }
      ];

      this.muzeTutarFields = [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
      ];

      this.acenteRestoranTutarFields = [
        { key: "Detay", label: "Detay", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: false },
        { key: "TurOpsiyonAdi", label: "Opsiyon", sortable: false },
        { key: "RestoranAdi", label: "Restoran", sortable: false }
      ];

      this.restoranTutarFields = [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false }
      ];
    }
  }
};
</script>
