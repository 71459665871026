<template>
    <div class="table-responsive mb-0">
        <table class="table table-bordernone table-striped">
            <thead>
                <tr>
                    <th scope="col">İşlem</th>
                    <th scope="col">Kod</th>
                    <th scope="col">Durum</th>
                    <th scope="col">Tarih</th>
                    <th scope="col">Otel </th>
                    <th scope="col">Misafirler</th>
                    <th scope="col">Tur / Opsiyon</th>
                    <th scope="col">Rehber</th>
                    <th scope="col">Şoför</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.Id">
                    <td>
                        <b-btn-group>
                            <router-link :to="`/turRezervasyon/detay/${item.Id}`"
                                class="btn btn-info btn-xs">Detay</router-link>
                        </b-btn-group>
                    </td>
                    <td>
                        <b-badge variant="info">{{ item.Kod }}</b-badge>
                    </td>
                    <td>
                        <RezervasyonDurum :durum="item.DurumStr" :durumInt="item.Durum" />
                    </td>
                    <td>
                        <c-tarih-cell :kayitTarihi="item.Tarih" :rowKey="item.Id"></c-tarih-cell>
                        <b-badge variant="primary">{{ item.Saat }}</b-badge>
                    </td>
                    <td>{{ item.OtelAdi }}</td>
                    <td>
                        <c-misafirler :misafirler="item.Misafirler"></c-misafirler>
                        <b-badge variant="warning">{{ item.KisiSayisi }}</b-badge>
                    </td>
                    <td :class="item.TurRenk + ' sabitKol'">
                        <div>{{ item.TurAdi }} / <small>{{ item.TurOpsiyonAdi }}</small></div>
                        <a :href="'/reservation/tour/' + item.IntId + '/' + item.Kod" class="btn btn-info btn-xs"
                            target="_blank">Rezervasyon Kartı</a>
                    </td>
                    <td>
                        <div v-if="item.RehberAdi !== null">
                            <div class="customers">
                                <ul class="mb-1">
                                    <li class="d-inline-block">
                                        <a href="#" :id="'rehber-tooltip-' + item.Id">
                                            <img class="img-40 rounded-circle" :src="item.RehberResim" alt="">
                                        </a>
                                    </li>
                                </ul>
                                {{ item.RehberAdi }}
                            </div>
                            <b-tooltip :target="'rehber-tooltip-' + item.Id" triggers="hover" placement="right">
                                <img class="img-fluid" :src="item.RehberResim" alt="">
                            </b-tooltip>
                        </div>
                    </td>
                    <td>
                        <div class="customers">
                            <ul class="mb-1">
                                <li class="d-inline-block">
                                    <a href="#" :id="'arac-tooltip-' + item.Id" v-b-tooltip.hover>
                                        <img class="img-40 rounded-circle" :src="item.AracResim" alt="">
                                    </a>
                                </li>
                            </ul>
                            {{ item.SoforAdi }}<br>
                            {{ item.Plaka }}
                        </div>
                        <b-tooltip :target="'arac-tooltip-' + item.Id" triggers="hover" placement="left">
                            <img class="img-fluid" :src="item.AracResim" alt="">
                        </b-tooltip>
                    </td>
                </tr>

            </tbody>
        </table>

    </div>
</template>
<script>
import RezervasyonDurum from "./c_turRezervasyon_durum";

export default {
    props: {
        tarih: null
    },
    data() {
        return {
            items: []
        }
    },
    components: {
        RezervasyonDurum
    },
    mounted() {
        this.listele();
    },
    watch: {
        tarih(newValue, oldValue) {
            this.listele();
        },
    },
    methods: {
        listele() {
            let vm = this;

            let filtre = {
                Tarih: this.$formatHelper.formatDateApi(this.tarih)
            }

            this.$connection.post("/turRezervasyonlari/filtreForAracFirma", filtre, "post", function (response) {
                vm.items = response;
            });
        }
    }
}
</script>