<template>
  <div>
    <Breadcrumbs main="Balon Firmaları" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Balon Firma Kaydı</h5>
              <span>Yeni balon firması veya balon firması kaydında değişiklikler
                için aşağıdaki formu kullanabilirsiniz</span>
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Balon Firma Adı</label>
                    <b-form-input type="text" id="kayit_adi" v-model="kayit.Adi" :state="kayit_adi_state"
                      placeholder="Balon Firma Adı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adi_state">Balon firma adını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Unvan</label>
                    <b-form-input type="text" id="kayit_unvan" v-model="kayit.Unvan" :state="kayit_unvan_state"
                      placeholder="Balon Firma Unvanı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_unvan_state">Balon firma unvanını
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_adi">Adres</label>
                    <b-form-textarea type="text" id="kayit_adres" v-model="kayit.Adres" :state="kayit_adres_state"
                      placeholder="Balon Firma Adresi"></b-form-textarea>
                    <b-form-invalid-feedback :state="kayit_adres_state">Balon firmasının adresini
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Konum X</label>
                    <b-form-input type="text" id="kayit_konumx" v-model="kayit.KonumX" :state="kayit_konumx_state"
                      placeholder="Mekan Enlemini"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_konumx_state">Mekan konumunun enlemini
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Konum Y</label>
                    <b-form-input type="text" id="kayit_konumy" v-model="kayit.KonumY" :state="kayit_konumy_state"
                      placeholder="Mekan Boylamını"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_konumy_state">Mekan konumunun boylamını
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_aciklama">Açıklama</label>
                    <vue-editor v-model="kayit.Aciklama"></vue-editor>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Vergi Dairesi</label>
                    <b-form-input type="text" id="kayit_vergidairesi" v-model="kayit.VergiDairesi"
                      placeholder="Vergi Dairesi"></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Vergi No</label>
                    <b-form-input type="text" id="kayit_vergiNo" v-model="kayit.VergiNo" :state="kayit_vergino_state"
                      placeholder="T.C. Kimlik No veya Vergi No"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_vergino_state">Bireyselse 11 haneli T.C. kimlik numarası
                      kurumsal ise 10
                      haneli vergi numarasını giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Yetkili Kişi</label>
                    <b-form-input type="text" id="kayit_yetkilikisi" v-model="kayit.YetkiliKisi"
                      :state="kayit_yetkilikisi_state" placeholder="Yetkili Kişi"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_yetkilikisi_state">Yetkili kişi adını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_email">Email</label>
                    <b-form-input type="text" id="kayit_email" v-model="kayit.Email" placeholder="Email"
                      :state="kayit_email_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_email_state">Email adresini giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_gsm">Cep Telefonu</label>
                    <b-form-input type="text" id="kayit_gsm" v-model="kayit.GSM" v-mask="'5## ### ## ##'"
                      placeholder="Cep Telefonu" :state="kayit_gsm_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_gsm_state">Cep telefonunu giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_telefon">Telefon</label>
                    <b-form-input type="text" id="kayit_telefon" v-model="kayit.Telefon"
                      placeholder="Telefon"></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_alisfiyati">Alış Fiyatı</label>
                    <b-form-input type="text" id="kayit_alisfiyati" v-model="kayit.AlisFiyati"
                      :state="kayit_alisfiyati_state" placeholder="Servis Alış Ücreti"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_alisfiyati_state">Alış fiyatını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_satisfiyati">Satış Fiyatı</label>
                    <b-form-input type="text" id="kayit_satisfiyati" v-model="kayit.SatisFiyati"
                      :state="kayit_satisfiyati_state" placeholder="Servis Satış Ücreti"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_satisfiyati_state">Satış fiyatını alış ücretinden büyük
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label>Durum</label>
                    <b-form-select id="kayit_durum" v-model="kayit.Durum"
                      :options="kayit_durum_options"></b-form-select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_kontenjan">Kontenjan</label>
                    <b-form-input type="text" id="kayit_kontenjan" v-model="kayit.Kontenjan"
                      :state="kayit_kontenjan_state" placeholder="Kontenjan"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_kontenjan_state">Kontenjanı giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_gunsayisi">Gün Sayısı</label>
                    <b-form-input type="text" id="kayit_gunsayisi" v-model="kayit.GunSayisi"
                      placeholder="Gün Sayısı"></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_satisakapalisaat">Satışa Kapalı Saat (Ör: 15)</label>
                    <b-form-input type="text" id="kayit_satisakapalisaat" v-model="kayit.SatisaKapaliSaat"
                      placeholder="Satışa Kapalı Saat"></b-form-input>
                  </div>
                </div>
                <router-link :to="'/aracFirma/list'" class="btn btn-light pull-left">Geri Dön</router-link>
                <b-button type="submit" class="pull-right" variant="primary">Kaydet</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Balon Firma",

      breadLinks: [
        {
          path: "/BalonFirma/list",
          title: "Balon Firmaları",
          icon: "chevron-left",
        },
      ],

      kayit: {
        Adi: "",
        Unvan: "",
        YetkiliKisi: "",
        Adres: "",
        VergiDairesi: "",
        VergiNo: "",
        KonumX: "",
        KonumY: "",
        Aciklama: "",
        Telefon: "",
        GSM: "",
        Email: "",
        Durum: 30,
        Kontenjan: 0,
        AlisFiyati: 0,
        SatisFiyati: 0,
        GunSayisi: 0,
        SatisaKapaliSaat: 0,
      },

      kayit_durum_options: [
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],
    };
  },
  components: {
    VueEditor
  },
  mounted() {
    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Balon Firma Düzelt";

      this.$connection.get("/BalonFirmalari/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle(
            "Yeni Balon Firma",
            response.Message,
            "error"
          );
        }
      });
    }
  },
  computed: {
    kayit_adi_state() {
      if (this.kayit.Adi == null || this.kayit.Adi == "") return false;
      else return true;
    },
    kayit_unvan_state() {
      if (this.kayit.Unvan == null || this.kayit.Unvan == "") return false;
      else return true;
    },
    kayit_yetkilikisi_state() {
      if (this.kayit.YetkiliKisi == null || this.kayit.YetkiliKisi == "")
        return false;
      else return true;
    },
    kayit_adres_state() {
      if (this.kayit.Adres == null || this.kayit.Adres == "") return false;
      else return true;
    },
    kayit_konumx_state() {
      if (this.kayit.KonumX == null || this.kayit.KonumX == "") return false;
      else return true;
    },
    kayit_konumy_state() {
      if (this.kayit.KonumY == null || this.kayit.KonumY == "") return false;
      else return true;
    },
    kayit_vergino_state() {
      if (
        this.kayit.VergiNo == null ||
        this.kayit.VergiNo == "" ||
        this.kayit.VergiNo.length < 10 ||
        this.kayit.VergiNo.length > 11
      )
        return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
    kayit_alisfiyati_state() {
      if (
        this.kayit.AlisFiyati === "" ||
        this.kayit.AlisFiyati === null ||
        parseFloat(this.kayit.AlisFiyati) === 0
      )
        return false;
      else return true;
    },
    kayit_satisfiyati_state() {
      if (
        this.kayit.SatisFiyati === "" ||
        this.kayit.SatisFiyati === null ||
        parseFloat(this.kayit.SatisFiyati) === 0 ||
        parseFloat(this.kayit.SatisFiyati) < parseFloat(this.kayit.AlisFiyati)
      )
        return false;
      else return true;
    },
    kayit_kontenjan_state() {
      if (
        this.kayit.Kontenjan === null ||
        this.kayit.Kontenjan === "" ||
        parseInt(this.kayit.Kontenjan) === 0
      )
        return false;
      else return true;
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adi_state &&
        this.kayit_unvan_state &&
        this.kayit_yetkilikisi_state &&
        this.kayit_adres_state &&
        this.kayit_konumx_state &&
        this.kayit_konumy_state &&
        this.kayit_vergino_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        this.kayit_alisfiyati_state &&
        this.kayit_satisfiyati_state &&
        this.kayit_kontenjan_state;

      if (isValid) {
        let vm = this;

        let url = "/BalonFirmalari";
        let method = "post";
        let messageTitle = "Yeni Balon Firma";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Balon Firma Düzelt";
        }

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/BalonFirma/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
