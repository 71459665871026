<template>
  <div>
    <Breadcrumbs main="Regular Rezervasyon" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid tur-rezervasyon-duzelt">
      <RegularRezervasyonForm v-if="isComponentShow" @onComplete="onComplete" :rezervasyon="kayit"
        :acenteId="acenteId" :id="id" :tab="tab" />
    </div>
  </div>
</template>
<script>
import RegularRezervasyonForm from "@/components/c_regular_rezervasyon_form.vue";

export default {
  data() {
    return {
      id: this.$route.params.id,
      tab: this.$route.query.tab,

      pageTitle: "Rezervasyon Düzelt",

      breadLinks: [
        {
          path: "/regularRezervasyon/istekDetay/" + this.$route.params.id,
          title: "Rezervasyon Detayı",
          icon: "chevron-left",
        },
      ],

      kayit: {},

      acenteId: "",

      isComponentShow: false,
    };
  },
  mounted() {
    this.onDetay();
    this.$store.dispatch("menu/setToggleSidebar", true);
  },
  components: {
    RegularRezervasyonForm,
  },
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/regularRezervasyonlar/istek/" + this.id,
        function (response) {
          if (response.Success) {
            vm.isComponentShow = true;

            vm.acenteId = response.Data.AcenteId;

            vm.kayit.TurId = response.Data.TurId;
            vm.kayit.EncTurId = response.Data.EncTurId;
            vm.kayit.TurOpsiyonId = response.Data.TurOpsiyonId;
            vm.kayit.EncTurOpsiyonId = response.Data.EncTurOpsiyonId;
            vm.kayit.Tarih = response.Data.Tarih;
            vm.kayit.Aciklama = response.Data.Aciklama;
            vm.kayit.OtelAdi = response.Data.OtelAdi;
            vm.kayit.OtelAdresi = response.Data.OtelAdresi;
            vm.kayit.DilId = response.Data.DilId;
            vm.kayit.EncDilId = response.Data.EncDilId;
            vm.kayit.KisiSayisi = response.Data.KisiSayisi;
            vm.kayit.Misafirler = response.Data.Misafirler;
          } else {
            vm.$alert.messageWithTitle("Regular Detay", response.Message, "error");
          }
        }
      );
    },
    onComplete(kayit) {
      let vm = this;

      let url = "/regularRezervasyonlar/istek/" + this.id;
      let method = "put";
      let messageTitle = "Regular Rezervasyon Düzenle";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(
              messageTitle,
              response.Message,
              "success",
              function () {
                vm.$router.push("/regularRezervasyon/istekDetay/" + vm.id);
              }
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
  },
};
</script>
