<template>
  <div>
    <Breadcrumbs main="Araçlar" title="Yorumlar" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="default-according">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="div" role="tab" text-variant="dark" header-bg-variant="primary">
                <h5 class="mb-0">
                  <b-button v-b-toggle.simpleaccordion-1>DETAYLI FİLTRELEMEK İÇİN TIKLAYINIZ
                    <small>({{
      this.$formatHelper.formatDate(filtre.BaslangicTarihi)
    }}
                      -
                      {{
        this.$formatHelper.formatDate(filtre.BitisTarihi)
      }}
                      arası yorumlar listeleniyor)</small>
                  </b-button>
                </h5>
              </b-card-header>
              <b-collapse id="simpleaccordion-1" accordion="myaccordion" role="tabpanel">
                <b-card-body>
                  <b-form @submit="filtreSubmit">
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_kelime">Kelime</label>
                        <b-form-input type="text" id="filtre_kelime" v-model="filtre.Kelime"
                          placeholder="Kelime"></b-form-input>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_turrezervasyonkodu">Tur Rezervasyon Kodu</label>
                        <b-form-input type="text" id="filtre_turrezervasyonkodu" v-model="filtre.TurRezervasyonKodu"
                          placeholder="Tur Rezervasyon Kodu"></b-form-input>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_baslangictarihi">Başlangıç Tarihi</label>
                        <datepicker input-class="datepicker-here form-control digits" id="filtre_baslangictarihi"
                          v-model="filtre.BaslangicTarihi" :language="tr" :clear-button="true"
                          clear-button-icon="fa fa-times" placeholder="Başlangıç Tarihi" :format="date_format">
                        </datepicker>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_bitistarihi">Bitiş Tarihi</label>
                        <datepicker input-class="datepicker-here form-control digits" id="filtre_bitistarihi"
                          v-model="filtre.BitisTarihi" :language="tr" :clear-button="true"
                          clear-button-icon="fa fa-times" placeholder="Bitiş Tarihi" :format="date_format"></datepicker>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3" v-if="savunmaKabulDurumlari != null">
                        <label for="filtre_savunma_durumu">Savunma Durumu</label>
                        <b-form-select id="filtre_savunma_durumu" v-model="filtre.SavunmaKabulDurumu"
                          :options="savunmaKabulDurumlari"></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3" v-if="aracaGoster != null">
                        <label for="filtre_gonderim_durumu">Gönderim Durumu</label>
                        <b-form-select id="filtre_gonderim_durumu" v-model="filtre.AracaGoster"
                          :options="aracaGoster"></b-form-select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3" v-if="filtre_acenteler !== null">
                        <label for="filtre_acente">Acente</label>
                        <b-input-group>
                          <vue-bootstrap-typeahead v-model="queryAcente" :data="filtre_acenteler"
                            :serializer="(item) => item.Adi" @hit="filtre.AcenteId = $event.Id"
                            placeholder="Acentelerde Ara" />
                          <b-input-group-append>
                            <b-button @click="acenteTemizle()" variant="danger">X</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                      <div class="col-md-6 mb-3" v-if="filtre_araclar !== null">
                        <label for="filtre_rehber">Araç</label>
                        <b-input-group>
                          <vue-bootstrap-typeahead v-model="queryArac" :data="filtre_araclar" :serializer="(item) => item.SoforAdi + ' (' + item.Plaka + ')'
                            " @hit="filtre.AracId = $event.EncId" placeholder="Araçlarda Ara" />
                          <b-input-group-append>
                            <b-button @click="aracTemizle()" variant="danger">X</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_min_puan">Min Puan</label>
                        <b-form-input type="text" id="filtre_min_puan" v-model="filtre.MinPuan"
                          placeholder="Minumum Puan 1-10 Arası"></b-form-input>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_max_puan">Mak Puan</label>
                        <b-form-input type="text" id="filtre_max_puan" v-model="filtre.MaxPuan"
                          class="mb-2 mr-sm-2 mb-sm-0" placeholder="Maksimum Puan 1-10 Arası"></b-form-input>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <b-button type="submit" variant="warning">Listele</b-button>
                      </div>
                    </div>
                  </b-form>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="card">
            <div class="card-body">
              <b-table striped hover :fields="tablefields" :items="yorumlar">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <b-button @click="detayAc(row.item.Id)" class="btn btn-xs btn-primary">Detay</b-button>
                    <a :href="'/turRezervasyon/detay/' + row.item.TurRezervasyonId" class="btn btn-xs btn-dark"
                      target="_blank">Rez. {{ row.item.TurRezervasyonKodu
                      }}</a>
                  </b-btn-group>
                </template>
                <template #cell(AcenteAdi)="row">
                  {{ row.item.AcenteAdi }}<br /><small>{{ row.item.KullaniciAdi }}</small>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </b-table>
            </div>
          </div>

          <b-modal id="yorumDetay" size="xl" centered title="Yorum Detayı" hide-footer class="theme-modal">
            <AracYorum :id="seciliYorumId" v-if="seciliYorumId != null">
            </AracYorum>
          </b-modal>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import AracYorum from "@/components/c_arac_yorum";

export default {
  data() {
    return {
      breadLinks: [],
      tablefields: [
        { key: "Islem", label: "İşlem", sortable: false },
        { key: "SoforAdi", label: "Araç", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: false },
        { key: "AracaGosterStr", label: "Gösterim D.", sortable: false },
        { key: "SavunmaKabulDurumuStr", label: "Savunma D.", sortable: false },
        { key: "KayitTarihi", label: "Yorum T.", sortable: false },
        { key: "Puan", label: "Puan", sortable: false },
        { key: "Yorum", label: "Yorum", sortable: false }
      ],

      date_format: "dd.MM.yyyy",
      tr: tr,

      yorumlar: [],

      savunmaKabulDurumlari: [
        { value: null, text: "Savunma Durumu" },
        { value: "0", text: "Boş" },
        { value: "1", text: "Kabul Edilenler" },
        { value: "2", text: "Red Edilenler" },
      ],

      aracaGoster: [
        { value: null, text: "Gönderim Durumu" },
        { value: "0", text: "Gönderilmemişler" },
        { value: "1", text: "Gönderilenler" },
        { value: "2", text: "Savunma Yaptıkları" },
      ],

      filtre: {
        BaslangicTarihi: null,
        BitisTarihi: null,
        MinPuan: null,
        MaxPuan: null,
        AracId: null,
        AcenteId: null,
        SavunmaKabulDurumu: null,
        AracaGoster: null,
        Kelime: null,
        TurRezervasyonKodu: null
      },

      queryAcente: "",
      filtre_acenteler: [{ value: null, text: "Acenteler" }],

      queryArac: "",
      filtre_araclar: [{ value: null, text: "Araçlar" }],

      seciliYorumId: null
    };
  },
  components: {
    Datepicker,
    VueBootstrapTypeahead,
    AracYorum
  },
  watch: {
    queryAcente(newQuery) {
      let vm = this;

      let searchFiltre = {
        Kelime: newQuery,
      };

      this.$connection.post(
        "/acenteler/filtre",
        searchFiltre,
        "post",
        function (response) {
          vm.filtre_acenteler = response;
        }
      );
    },
    queryArac(newQuery) {
      let vm = this;

      this.$connection.get(
        "/araclar/selectByKelime?kelime=" + newQuery,
        function (response) {
          vm.filtre_araclar = response;
        }
      );
    }
  },
  mounted() {
    let baslangicTarihi = new Date();
    let bitisTarihi = new Date();
    baslangicTarihi.setDate(bitisTarihi.getDate() - 15);
    bitisTarihi.setDate(bitisTarihi.getDate() + 15);
    this.filtre.BaslangicTarihi = baslangicTarihi;
    this.filtre.BitisTarihi = bitisTarihi;

    this.list();
  },
  methods: {
    filtreSubmit(event) {
      event.preventDefault();
      this.list();
    },
    list() {
      let vm = this;

      vm.filtre.MinPuan = vm.filtre.MinPuan == "" ? null : vm.filtre.MinPuan;
      vm.filtre.MaxPuan = vm.filtre.MaxPuan == "" ? null : vm.filtre.MaxPuan;
      vm.filtre.TurRezervasyonKodu = vm.filtre.TurRezervasyonKodu == "" ? null : vm.filtre.TurRezervasyonKodu;

      this.$connection.post(
        "/araclar/yorumlari",
        vm.filtre,
        "post",
        function (response) {
          vm.yorumlar = response;
        }
      );
    },
    detayAc(id) {
      this.seciliYorumId = id;
      this.$bvModal.show("yorumDetay");
    },
    aracTemizle() {
      this.queryArac = "";
      this.filtre.AracId = null;
    },
    acenteTemizle() {
      this.queryAcente = "";
      this.filtre.AcenteId = null;
    }
  },
};
</script>