<template>
  <div>
    <Breadcrumbs main="Tur Rezervasyonu" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid tur-rezervasyon-duzelt">
      <!--<div class="row">
        <div class="col-md-4 mb-3">
          <b-input-group size="lg" prepend="Arama">
            <b-form-input v-model="filtre" @input="(event) => rehberFiltrele(event)"></b-form-input>
          </b-input-group>
        </div>
      </div>-->

      <b-pagination v-model="rehberSayfa" align="center" :total-rows="toplamRehberSayisi"
        :per-page="sayfalamaSayfaBasiKayit"></b-pagination>

      <div class="row" v-if="kayit.AracId === null || kayit.AracId > 0">
        <div class="col-md-6 mt-3 offset-md-3">
          <b-button variant="warning" @click="rehberSec(null)" block>
            <strong style="color: #000"><i class="fa fa-flag"></i> REHBER SEÇMEDEN DEVAM ETMEK İSTİYORUM</strong>
          </b-button>
        </div>
      </div>

      <div class="row tur-rez-form-rehber mt-4">
        <div class="col-md-2 col-md-2-special" :if="rehberler !== null" v-for="rehber in rehberler" :key="rehber.Id">
          <b-card header-tag="div" header-class="p-3" :bg-variant="rehber.Id === kayit.RehberId ? 'dark' : 'light'"
            :header-bg-variant="rehber.Id === kayit.RehberId ? 'primary' : 'light'
      " body-class="p-1">
            <h5 slot="header">
              <b-link :class="rehber.Id === kayit.RehberId ? 'text-white' : 'text-dark'
      " @click="rehberSec(rehber)">
                {{ rehber.AdSoyad }}
              </b-link>
            </h5>
            <div class="product-box">
              <div class="product-img">
                <img class="img-fluid" :src="rehber.Resim" alt />
              </div>
              <div class="product-details" :class="rehber.Id === kayit.RehberId ? 'text-white' : 'text-dark'
      ">
                <p>{{ rehber.Dilleri }}</p>
                <b-button variant="warning" block @click="rehberDetayAc(rehber)">
                  Özgeçmiş
                </b-button>
                <b-button variant="primary" block :disabled="rehber.Id === kayit.RehberId" @click="rehberSec(rehber)">
                  Rehberi Seç
                </b-button>
              </div>
            </div>
          </b-card>
        </div>
      </div>

      <b-pagination v-model="rehberSayfa" align="center" :total-rows="toplamRehberSayisi"
        :per-page="sayfalamaSayfaBasiKayit"></b-pagination>

      <b-modal id="rehberDetay" size="lg" hide-footer centered :title="rehberDetay.AdSoyad" cancel-title="Kapat"
        class="theme-modal">
        <div class="row">
          <div class="col-md-12">
            <span v-html="rehberDetay.Ozgecmis"></span>

            <b-button class="mt-3" block @click="$bvModal.hide('rehberDetay')">Kapat</b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Rezervasyon Rehber Değiştir",

      breadLinks: [
        {
          path: "/turRezervasyon/detay/" + this.$route.params.id,
          title: "Rezervasyon Detayı",
          icon: "chevron-left",
        },
      ],

      kayit: {},

      sayfalamaSayfaBasiKayit: 18,

      coreRehberler: [],
      rehberler: [],
      toplamRehberSayisi: 0,
      rehberSayfa: 1,
      activeRehber: {},
      rehberDetay: {},

      acenteId: "",

      filtre: "",
    };
  },
  mounted() {
    this.onDetay();
  },
  components: {},
  watch: {
    rehberSayfa: function () {
      this.onRehberlerList();
    }
  },
  methods: {
    rehberFiltrele(event) {
      let kelime = event.toUpperCase().replaceAll("I", "İ");
      var sonuc = this.coreRehberler.filter((item) => {
        return (
          item.AdSoyad.toUpperCase().replaceAll("I", "İ").indexOf(kelime) >
          -1 ||
          item.Dilleri.toUpperCase().replaceAll("I", "İ").indexOf(kelime) > -1
        );
      });

      this.rehberler = sonuc;
    },
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/" + this.id,
        function (response) {
          if (response.Success) {
            vm.kayit = response.Data;
            vm.rehberSayisiBul();
          } else {
            vm.$alert.messageWithTitle("Tur Detay", response.Message, "error");
          }
        }
      );
    },
    formatTarih(tarih) {
      return this.$moment(String(tarih)).format("YYYY-MM-DD");
    },
    rehberSayisiBul() {
      let vm = this;

      let filtre = {
        DilId: this.kayit.DilId,
        TurTarihi: this.formatTarih(this.kayit.Tarih),
        AcenteId: this.kayit.AcenteId,
        RehberId: this.kayit.RehberId,
        Skip: 0,
        Take: 0
      };

      this.$connection.post("/rehberler/selectCount", filtre, 'post', function (count) {
        vm.toplamRehberSayisi = count;

        if (vm.toplamRehberSayisi > 0) {
          vm.onRehberlerList();
        }
      });
    },
    onRehberlerList() {
      let vm = this;

      let filtre = {
        DilId: this.kayit.DilId,
        TurTarihi: this.formatTarih(this.kayit.Tarih),
        AcenteId: this.kayit.AcenteId,
        RehberId: this.kayit.RehberId,
        Skip: ((this.rehberSayfa - 1) * this.sayfalamaSayfaBasiKayit),
        Take: this.sayfalamaSayfaBasiKayit
      };

      vm.$connection.post("/rehberler/select", filtre, 'post', function (response) {
        vm.rehberler = response;

        let rehbers = vm.rehberler.filter((item) => {
          return item.Id === vm.kayit.RehberId;
        });
        if (rehbers.length > 0) vm.activeRehber = rehbers[0];
      });
    },
    rehberDetayAc(rehber) {
      this.rehberDetay = rehber;
      this.$bvModal.show("rehberDetay");
    },
    rehberSec(rehber) {
      if (rehber == null) {
        rehber = {
          Id: 0,
          EncId: "0"
        }
      }

      let vm = this;
      this.$alert.confirm(
        "Rehber Değiştir",
        "Rezervasyonun rehberi değiştirilecektir. Emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/turRezervasyonlari/" + vm.kayit.Id + "/rehber/" + rehber.EncId,
            {},
            "put",
            function () {
              vm.kayit.RehberId = rehber.Id;
              vm.kayit.EncRehberId = rehber.EncId;
              vm.activeRehber = rehber;

              vm.$alert.messageWithFunction(
                "Rehber Değiştir",
                "Rezervasyon rehberi değiştirildi.",
                "success",
                function () {
                  vm.$router.push("/turRezervasyon/detay/" + vm.kayit.Id);
                }
              );
            }
          );
        }
      );
    },
  },
};
</script>
