<template>
  <div class="table-responsive mb-0">
    <table class="table table-bordernone table-striped">
      <thead>
        <tr>
          <th scope="col">İşlem</th>
          <th scope="col">Acente</th>
          <th scope="col">Kod</th>
          <th scope="col">Güzergah</th>
          <th scope="col">Misafir</th>
          <th scope="col">Durum</th>
          <th scope="col">Tarih</th>
          <th scope="col">Şoför</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.Id">
          <td>
            <b-btn-group>
              <router-link :to="`/transfer/detay/${item.Id}`" class="btn btn-info btn-xs">Detay</router-link>
            </b-btn-group>
          </td>
          <td>
            <b-badge variant="danger" v-if="item.AcenteTipi == 0">{{ item.AcenteAdi }}</b-badge>
            <b-badge variant="warning" v-if="item.AcenteTipi == 10">{{ item.AcenteAdi }}</b-badge>
          </td>
          <td><b-badge variant="info">{{ item.Kod }}</b-badge></td>
          <td>
            <span v-if="item.Donus == false">{{ item.Detay.Bolge.Adi }} - {{ item.Detay.Rezervasyon.Nereye }}</span>
            <span v-else>{{ item.Detay.Rezervasyon.Nereye }} - {{ item.Detay.Bolge.Adi }}</span>
          </td>
          <td>{{ item.Detay.Yolcular[0].AdSoyad }}</td>
          <td>
            <RezervasyonDurum :durum="item.DurumStr" :durumInt="item.Durum" />
          </td>
          <td>
            <c-tarih-cell :kayitTarihi="item.Tarih" :rowKey="item.Id"></c-tarih-cell>
          </td>
          <td class="text-center">
            <div v-if="item.Detay.Sofor.AdSoyad != null">
              <span v-if="item.Detay.Sofor.Resim != 'http://laalvip.com'">
                <img :src="item.Detay.Sofor.Resim" class="img-50 rounded-circle" /><br />
              </span>
              <span>{{ item.Detay.Sofor.AdSoyad }} - {{ item.Detay.Sofor.Plaka }}</span><br />
              <b-button @click="aracNerede(item)" size="xs" variant="info">Araç ve Şoför Detayı</b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal id="lokasyon" size="xl" centered title="Araç Nerede" class="theme-modal" :hide-footer="true">
      <Lokasyon :transfer="lokasyonTransfer" v-if="lokasyonTransfer != null" @onClose="aracNeredeKapat" />
    </b-modal>
  </div>
</template>
<script>
import RezervasyonDurum from "./c_transferRezervasyon_durum";
import Lokasyon from "./c_transfer_lokasyon";

export default {
  props: {
    tarih: null
  },
  data() {
    return {
      items: [],
      lokasyonTransfer: null
    };
  },
  components: {
    RezervasyonDurum,
    Lokasyon
  },
  mounted() {
    this.listele();
  },
  watch: {
    tarih(newValue, oldValue) {
      this.listele();
    },
  },
  methods: {
    listele() {
      let vm = this;

      let filtre = {
        Tarih: this.$formatHelper.formatDateApi(this.tarih)
      }

      this.$connection.post(
        "/laalTransferler/filtreForYonetim",
        filtre,
        "post",
        function (response) {
          vm.items = response;

          vm.$emit("onKayitSayisi", vm.items.length);
        }
      );
    },
    aracNerede(transfer) {
      this.lokasyonTransfer = transfer;
      this.$bvModal.show("lokasyon");
    },
    aracNeredeKapat() {
      this.$bvModal.hide("lokasyon");
    }
  },
};
</script>