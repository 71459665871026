<template>
  <div>
    <Breadcrumbs main="Duyurular" title="Liste" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <b-form @submit="onFiltreSubmit" inline>
                <label class="sr-only" for="filtre_kelime">Kelime</label>
                <b-form-input type="text" id="filtre_kelime" v-model="filtre.Kelime" placeholder="Kelime"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>

                <label class="sr-only" for="filtre_kullanicitipi">Kullanıcı Tipi</label>
                <b-form-select id="filtre_kullanicitipi" v-model="filtre.KullaniciTipi"
                  :options="filtre_kullanicitipleri" class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <label class="sr-only" for="filtre_baslangictarihi">Başlangıç Tarihi</label>
                <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                  id="filtre_baslangictarihi" v-model="filtre.BaslangicTarihi" :clear-button="true"
                  clear-button-icon="fa fa-times" placeholder="Başlangıç Tarihi" :format="date_format"></datepicker>

                <label class="sr-only" for="filtre_bitistarihi">Bitiş Tarihi</label>
                <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                  id="filtre_bitistarihi" v-model="filtre.BitisTarihi" :clear-button="true"
                  clear-button-icon="fa fa-times" placeholder="Bitiş Tarihi" :format="date_format"></datepicker>

                <label class="sr-only" for="filtre_aktif">Aktif</label>
                <b-form-select id="filtre_aktif" v-model="filtre.Aktif" :options="filtre_aktif"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <label class="sr-only" for="filtre_surekli_goster">Sürekli Gösterim</label>
                <b-form-select id="filtre_surekli_goster" v-model="filtre.SurekliGoster"
                  :options="filtre_surekli_goster" class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <b-button type="submit" class="pull-right" variant="warning">Listele</b-button>
              </b-form>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link :to="`/duyuru/kayit/${row.item.Id}`"
                      class="btn btn-success btn-xs">Düzelt</router-link>
                    <b-button size="xs" @click="sil(row.item)" variant="danger">Sil</b-button>
                  </b-btn-group>
                </template>
                <template #cell(KullaniciTipleriList)="data">
                  <b-badge v-for="kullanici in data.value" v-bind:key="kullanici" variant="primary">{{ kullanici
                    }}</b-badge>
                </template>
                <template #cell(Aktif)="data">
                  <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                </template>
                <template #cell(SurekliGoster)="data">
                  <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";

export default {
  data() {
    return {
      breadLinks: [{ path: "/duyuru/kayit", title: "Ekle", icon: "plus" }],
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "Baslik", label: "Başlık", sortable: true },
        { key: "Aktif", label: "Aktif", sortable: true },
        { key: "SurekliGoster", label: "Sürekli Göster", sortable: true },
        { key: "Oncelik", label: "Oncelik", sortable: true },
        { key: "Sira", label: "Sıra", sortable: true },
        { key: "KullaniciTipleriList", label: "Kullanıcı Tipleri", sortable: true, },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre: {
        Kelime: "",
        KullaniciTipi: null,
        BaslangicTarihi: null,
        BitisTarihi: null,
        Aktif: null,
        SurekliGoster: null,
      },

      filtre_kullanicitipleri: [{ value: null, text: "Kullanıcı Tipi" }],

      filtre_aktif: [
        { value: null, text: "Hepsi" },
        { value: true, text: "Aktifler" },
        { value: false, text: "Pasifler" },
      ],

      filtre_surekli_goster: [
        { value: null, text: "Hepsi" },
        { value: true, text: "Sürekli Gösterilenler" },
        { value: false, text: "Bir Kere Gösterilenler" },
      ],
    };
  },
  components: {
    Datepicker,
  },
  mounted() {
    this.onKullaniciTipiList();
    this.onList();
  },
  methods: {
    onFiltreSubmit(evt) {
      evt.preventDefault();
      this.onList();
    },
    onKullaniciTipiList() {
      let vm = this;

      this.$connection.get(
        "/kullanicilar/kullaniciTipleri",
        function (response) {
          vm.filtre_kullanicitipleri =
            vm.filtre_kullanicitipleri.concat(response);
        }
      );
    },
    onList() {
      let vm = this;

      this.$connection.post(
        "/duyurular/filtre",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Duyuru kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/duyurular/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Duyuru kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
  },
};
</script>
