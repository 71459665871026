<template>
  <div>
    <b-alert show variant="warning" class="mb-3">
      Profilinizdeki tüm bilgilerinizi eksik doldurduğunuzdan EMİN OLUNUZ , aksi takdirde sistem sizi listelemeyecek ve sistem üzerinden iş alamayacaksınız.
    </b-alert>
    <b-table striped hover :fields="dilFields" :items="dillerAna">
      <template #cell(OzelAlisFiyati)="row">
        <b-form-input type="text" v-model="row.item.RehberDil.AlisFiyati" placeholder="Alış Fiyatı"></b-form-input>
      </template>
      <template #cell(Islem)="row">
        <b-btn-group>
          <b-button @click="ekle(row.item)" v-if="row.item.RehberDil.Id === null" variant="success">Ekle</b-button>
          <b-button @click="guncelle(row.item)" v-if="row.item.RehberDil.Id !== null"
            variant="success">Güncelle</b-button>
          <b-button @click="kaldir(row.item)" v-if="row.item.RehberDil.Id !== null" variant="danger">Kaldır</b-button>
        </b-btn-group>
      </template>
    </b-table>
  </div>
</template>
<script>
import c_money_cell from "./c_money_cell.vue";

export default {
  props: {
    dilleri: [],
    rehberId: "",
  },
  data() {
    return {
      mutateDilleri: this.dilleri,

      dillerAna: [],
      dilFields: [
        { key: "Tanim", label: "Dil", sortable: true },
        { key: "OzelAlisFiyati", label: "Rehber Satış", sortable: true },
        { key: "Islem", label: "İşlemler", sortable: false },
      ],
    };
  },
  components: {
    c_money_cell,
  },
  mounted() {
    this.onDilAnaList();
  },
  computed: {

  },
  methods: {
    onDilAnaList() {
      let vm = this;

      this.$connection.get("/diller", function (response) {
        vm.dillerAna = response.map((item) => {
          return {
            ...item,
            RehberDil: vm.dilSeciliMi(item),
          };
        });
      });
    },
    dilSeciliMi: function (dil) {
      var rehberDilleri = this.mutateDilleri.filter((item) => {
        return item.DilId === dil.Id;
      });

      if (rehberDilleri.length > 0) return rehberDilleri[0];
      else return { Id: null, AlisFiyati: 0, SatisFiyati: 0 };
    },
    kaldir(item) {
      let vm = this;
      this.$alert.confirm(
        "Kaldır",
        "Bu dil bildiğiniz dillerden kaldırılacak ve rezervsayon alamayacaksınız devam etmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete(
            "/rehberler/" + vm.rehberId + "/dil/" + item.RehberDil.Id,
            function (response) {
              item.RehberDil = { Id: null, AlisFiyati: 0, SatisFiyati: 0 };
              vm.$alert.messageWithTitle(
                "Dil Kaldır",
                "Dil kaldırıldı.",
                "success"
              );
            }
          );
        }
      );
    },
    ekle(item) {
      let vm = this;

      if (isNaN(item.RehberDil.AlisFiyati) || item.RehberDil.AlisFiyati == "0" || item.RehberDil.AlisFiyati == "") {
        vm.$alert.messageWithTitle("Satış Fiyatı", "Lütfen bir satış fiyatı giriniz.", "error");
        return;
      }

      let form = {
        DilId: item.EncId,
        AlisFiyati: item.RehberDil.AlisFiyati
      };

      vm.$connection.post(
        "/rehberler/" + vm.rehberId + "/dil",
        form,
        "post",
        function (response) {
          if (response.Success) {
            item.RehberDil = {
              Id: response.Data,
              AlisFiyati: form.AlisFiyati,
              SatisFiyati: form.SatisFiyati,
            };
            vm.$alert.messageWithTitle("Dil Ekle", "Dil eklendi.", "success");
          }
        },
        function (error) {
          vm.$alert.messageWithTitle("Dil Ekle", error.Message, "error");
        }
      );
    },
    guncelle(item) {
      let vm = this;

      let form = {
        DilId: item.EncId,
        AlisFiyati: item.RehberDil.AlisFiyati
      };

      vm.$connection.post(
        "/rehberler/" + vm.rehberId + "/dil/" + item.RehberDil.Id,
        form,
        "put",
        function () {
          vm.$alert.messageWithTitle(
            "Dil Güncelle",
            "Fiyat güncellendi.",
            "success"
          );
        },
        function (error) {
          vm.$alert.messageWithTitle("Dil Güncelle", error.Message, "error");
        }
      );
    }
  },
};
</script>