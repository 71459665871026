<template>
  <div>
    <Breadcrumbs main="Fiyatlar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <Banner :bannerYeri="1"></Banner>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/c_banner";

export default {
  data() {
    return {
      pageTitle: "",

      breadLinks: []
    };
  },
  components: {
    Banner
  },
  mounted() {
  },
  methods: {
  },
};
</script>
