<template>
  <div>
    <Breadcrumbs main="Regular İstekleri" title="Planla" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12" v-if="activeTab == 'istekListe'">
          <div class="row">
            <div class="col-md-6">
              <b-input-group prepend="Tur Tarihi" class="mb-3">
                <datepicker input-class="datepicker-here form-control digits" v-model="filtre.Tarih" :language="tr"
                  placeholder="Planlamak İstediğiniz Tur Tarihini Seçiniz" :format="date_format">
                </datepicker>
                <b-input-group-append>
                  <!--<b-button @click="tarihDegisti(-1)" variant="outline-info"><i class="fa fa-arrow-left"></i></b-button>
              <b-button @click="tarihDegisti(1)" variant="outline-info"><i class="fa fa-arrow-right"></i></b-button>-->
                  <b-button @click="tarihDegisti(0)" variant="info">Listele</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-md-6 text-right">
              <b-button variant="success" @click="planla()" v-if="seciliIstekler.length > 0">Seçilenleri
                Planla</b-button>
            </div>
          </div>

          <hr>
          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-button @click="istekSec(row.item)" :variant="istekSecili(row.item)">SEÇ</b-button>
                </template>
                <template #cell(Misafirler)="row">
                  <router-link :to="`/regularRezervasyon/istekDetay/${row.item.Id}`" target="_blank">{{
          row.item.Misafirler }}</router-link>
                </template>
                <template #cell(TurAdi)="row">
                  <strong>{{ row.item.TurAdi }}</strong><br>
                  <small>{{ row.item.TurOpsiyonAdi }}</small>
                </template>
                <template #cell(OtelAdi)="row">
                  <strong>{{ row.item.OtelAdi }}</strong><br>
                  <small>{{ row.item.OtelAdresi }}</small>
                </template>
                <template #cell(KisiSayisi)="data">
                  <b-badge variant="primary">{{ data.value }}</b-badge>
                </template>
              </c-table>
            </div>
          </div>
        </div>

        <div class="col-md-12" v-if="activeTab == 'planListe'">
          <div class="row">
            <div class="col-md-6 mb-3">
              <h4>
                Tur Tarihi : {{ this.$formatHelper.formatDate(filtre.Tarih) }}
              </h4>
            </div>
            <div class="col-md-6 text-right">
              <div class="btn-group">
                <b-button variant="dark" @click="geriDon()">Geri Dön</b-button>
                <b-button variant="success" @click="rezervasyonOlustur()">Regular Rezervasyonu Oluştur</b-button>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <b-table :fields="tablefieldsSecili" :items="seciliIstekler">
                <template #cell(Misafirler)="row">
                  <router-link :to="`/regularRezervasyon/istekDetay/${row.item.Id}`" target="_blank">{{
          row.item.Misafirler }}</router-link>
                </template>
                <template #cell(SaatBaslangic)="row">
                  <b-form-input type="time" v-model="row.item.SaatBaslangic"
                    placeholder="Alınacak Saat Aralığı Başlangıç"></b-form-input>
                </template>
                <template #cell(SaatBitis)="row">
                  <b-form-input type="time" v-model="row.item.SaatBitis"
                    placeholder="Alınacak Saat Aralığı Bitiş"></b-form-input>
                </template>
                <template #cell(TurAdi)="row">
                  <strong>{{ row.item.TurAdi }}</strong><br>
                  <small>{{ row.item.TurOpsiyonAdi }}</small>
                </template>
                <template #cell(OtelAdi)="row">
                  <strong>{{ row.item.OtelAdi }}</strong><br>
                  <small>{{ row.item.OtelAdresi }}</small>
                </template>
                <template #cell(KisiSayisi)="data">
                  <b-badge variant="primary">{{ data.value }}</b-badge>
                </template>
              </b-table>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="success" class="card-absolute">
                <h5 slot="header">Rehber</h5>
                <vue-bootstrap-typeahead v-model="queryRehber" :data="filtre_rehberler"
                  :serializer="(item) => item.AdSoyad" @hit="seciliRehber = $event" placeholder="Rehberlerde Ara" />
              </b-card>
              <b-card header-tag="div" header-class="p-3" v-if="seciliRehber != null" body-class="p-1">
                <h5 slot="header">
                  {{ seciliRehber.AdSoyad }}
                </h5>
                <div class="product-box">
                  <div class="product-img">
                    <img class="img-fluid" :src="seciliRehber.Resim" alt />
                  </div>
                  <div class="product-details">
                    <p>{{ seciliRehber.Dilleri }}</p>
                    <b-button variant="warning" block @click="rehberDetayAc(seciliRehber)">
                      Özgeçmiş
                    </b-button>
                  </div>
                </div>
              </b-card>

              <b-modal id="rehberDetay" size="lg" hide-footer centered :title="rehberDetay.AdSoyad" cancel-title="Kapat"
                class="theme-modal">
                <div class="row">
                  <div class="col-md-12">
                    <span v-html="rehberDetay.Ozgecmis"></span>

                    <b-button class="mt-3" block @click="$bvModal.hide('rehberDetay')">Kapat</b-button>
                  </div>
                </div>
              </b-modal>
            </div>
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="success" class="card-absolute">
                <h5 slot="header">Araç</h5>
                <vue-bootstrap-typeahead v-model="queryArac" :data="filtre_araclar" :serializer="(item) => item.SoforAdi + ' (' + item.Plaka + ')'
          " @hit="seciliArac = $event" placeholder="Araçlarda Ara" />
              </b-card>

              <b-card header-tag="div" header-class="p-3" body-class="p-1" v-if="seciliArac != null">
                <h5 slot="header">
                  {{ seciliArac.SoforAdi }}
                  <div class="pull-right">
                    <b-badge v-if="seciliArac.isWifi" variant="warning">
                      <i class="fa fa-wifi" aria-hidden="true" style="font-size: 14px"></i>
                    </b-badge>
                    <b-badge v-if="seciliArac.isAracTakip" variant="warning">
                      <i class="fa fa-location-arrow" aria-hidden="true" style="font-size: 14px"></i>
                    </b-badge>
                  </div>
                </h5>
                <div class="product-box">
                  <div class="product-img">
                    <b-carousel :id="'carousel-' + seciliArac.Id" :interval="4000" controls indicators
                      background="#ababab" img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333">
                      <b-carousel-slide v-for="(resim, index) in seciliArac.Resimler" :key="index"
                        :img-src="resim"></b-carousel-slide>
                    </b-carousel>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

export default {
  data() {
    return {
      tablefields: [
        { key: "Islem", label: "Seç", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: true },
        { key: "DilAdi", label: "Dil", sortable: true },
        { key: "TurAdi", label: "Tur", sortable: true, thClass: "sabitKol" },
        { key: "OtelAdi", label: "Otel", sortable: true, thClass: "sabitKol" },
        { key: "Misafirler", label: "Misafir", sortable: true },
        { key: "KisiSayisi", label: "Kişi", sortable: true }
      ],

      tablefieldsSecili: [
        { key: "AcenteAdi", label: "Acente", sortable: true },
        { key: "SaatBaslangic", label: "Alış Saat Aralığı", sortable: true },
        { key: "SaatBitis", label: "Alış Saat Aralığı", sortable: true },
        { key: "DilAdi", label: "Dil", sortable: true },
        { key: "TurAdi", label: "Tur", sortable: true, thClass: "sabitKol" },
        { key: "OtelAdi", label: "Otel", sortable: true, thClass: "sabitKol" },
        { key: "Misafirler", label: "Misafir", sortable: true },
        { key: "KisiSayisi", label: "Kişi", sortable: true }
      ],

      filtre: {
        Tarih: null
      },

      seciliDilId: 0,
      seciliTurId: '',

      activeTab: 'istekListe',

      queryRehber: "",
      filtre_rehberler: [{ value: null, text: "Rehberler" }],
      seciliRehber: null,
      rehberDetay: {},

      queryArac: "",
      filtre_araclar: [{ value: null, text: "Araçlar" }],
      seciliArac: null,

      items: [],

      date_format: "dd.MM.yyyy",
      tr: tr,

      seciliIstekler: []
    };
  },
  components: {
    Datepicker,
    VueBootstrapTypeahead,
  },
  watch: {
    queryRehber(newQuery) {
      let vm = this;

      let form = {
        Kelime: newQuery,
        Tarih: this.filtre.Tarih,
        DilId: this.seciliDilId
      }

      this.$connection.post(
        "/rehberler/selectByMusait",
        form,
        'post',
        function (response) {
          vm.filtre_rehberler = response;
        }
      );
    },
    queryArac(newQuery) {
      let vm = this;

      this.$connection.get(
        "/araclar/selectByKelime?kelime=" + newQuery,
        function (response) {
          vm.filtre_araclar = response;
        }
      );
    }
  },
  mounted() {
    let degTarih = new Date();
    degTarih.setDate(degTarih.getDate() + 1);
    this.filtre.Tarih = degTarih;

    this.onList();
  },
  methods: {
    tarihDegisti(yon) {
      let tarih = this.filtre.Tarih.getDate();
      switch (yon) {
        case -1:
          //Geri
          this.filtre.Tarih.setDate(tarih - 1);
          break;

        case 1:
          //ileri
          this.filtre.Tarih.setDate(tarih + 1);
          break;

        default:
          break;
      }

      this.onList();
    },
    onList() {
      let vm = this;

      this.$connection.post(
        "/regularRezervasyonlar/istek/planList",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
    istekSecili(istek) {
      if (this.seciliIstekler.filter(item => { return item.Id === istek.Id }).length)
        return 'success';
      else
        return 'dark';
    },
    istekSec(istek) {
      let seciliIstek = this.seciliIstekler.filter(item => { return item.Id === istek.Id });
      if (seciliIstek.length == 0) {
        this.seciliIstekler.push(istek);
      } else {
        this.$listHelper.removeItem(this.seciliIstekler, istek);
      }
    },
    planla() {
      this.activeTab = 'planListe';
      this.seciliDilId = this.seciliIstekler[0].DilId;
      this.seciliTurId = this.seciliIstekler[0].TurId;
      this.seciliIstekler = this.seciliIstekler.map((item) => {
        return {
          ...item,
          SaatBaslangic: '09:00',
          SaatBitis: '09:15'
        };
      });
    },
    rehberDetayAc(rehber) {
      this.rehberDetay = rehber;
      this.$bvModal.show("rehberDetay");
    },
    geriDon() {
      this.activeTab = 'istekListe';
    },
    rezervasyonOlustur() {
      let vm = this;

      if (this.seciliIstekler.length == 0) {
        vm.$alert.messageWithTitle("Regular Rezervasyon Oluştur", "Lütfen istek rezervasyonu seçiniz.", "error");
        return;
      }
      if (this.seciliRehber == null) {
        vm.$alert.messageWithTitle("Regular Rezervasyon Oluştur", "Lütfen rehber seçiniz.", "error");
        return;
      }
      if (this.seciliArac == null) {
        vm.$alert.messageWithTitle("Regular Rezervasyon Oluştur", "Lütfen araç seçiniz.", "error");
        return;
      }

      let form = {
        Tarih: vm.filtre.Tarih,
        TurId: vm.seciliTurId,
        Saat: "09:00",
        Aciklama: "",
        DilId: vm.seciliDilId,
        RehberId: vm.seciliRehber.EncId,
        AracId: vm.seciliArac.EncId,
        AracFirmaId: vm.seciliRehber.AracFirmaId,
        Misafirler: []
      };

      for (let index = 0; index < this.seciliIstekler.length; index++) {
        const element = this.seciliIstekler[index];
        form.Misafirler.push({
          IstekId: element.Id,
          BaslangicSaatAraligi: element.SaatBaslangic + '-' + element.SaatBitis
        });
      }

      vm.$connection.post(
        "/regularRezervasyonlar/rezervasyon/planla",
        form,
        "post",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(
              "Regular Rezervasyon",
              response.Message,
              "success",
              function () {
                vm.$router.push("/regularRezervasyon/rezDetay/" + response.Data);
              }
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle('Regular Rezervasyon Oluştur', error.Message, "error");
        }
      );
    }
  },
};
</script>