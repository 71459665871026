<template>
  <div>
    <Breadcrumbs main="Panel Menüleri" title="Liste" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <label class="sr-only" for="filtre_tipi">Kullanıcı Tipleri</label>
              <b-form-select
                id="filtre_tipi"
                v-model="filtre_tipi"
                :options="tipler"
                @change="onFiltreTipChange"
                class="mb-2 mr-sm-2 mb-sm-0"
              ></b-form-select>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <JsonEditorVue v-model="menuler" :onChange="updateEditor" />

              <b-button
                @click="menuKaydet()"
                class="text-center"
                variant="primary"
                >Kaydet</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JsonEditorVue from "json-editor-vue";

export default {
  data() {
    return {
      breadLinks: [
        {
          path: "/kullaniciTipi/list",
          title: "Kullanıcı Tipleri",
          icon: "chevron-left",
        },
      ],

      menuler: [],

      filtre_tipi: 0,
      tipler: [{ value: 0, text: "Kullanıcı Tipleri" }],
    };
  },
  mounted() {
    this.onTipler();
    this.onList();
  },
  components: {
    JsonEditorVue,
  },
  methods: {
    onTipler() {
      let vm = this;

      this.$connection.get(
        "/kullanicilar/KullaniciTipleri",
        function (response) {
          vm.tipler = vm.tipler.concat(response);
        }
      );
    },
    onFiltreTipChange() {
      this.onList();
    },
    onList() {
      let vm = this;

      if (vm.filtre_tipi > 0) {
        this.$connection.get(
          "/kullanicilar/kullaniciTipiMenu/" + vm.filtre_tipi,
          function (response) {
            vm.menuler = response;
          }
        );
      }
    },
    updateEditor(content) {
      this.menuler = content.json;
    },
    menuKaydet() {
      let vm = this;
      let form = {
        Tipi: this.filtre_tipi,
        MenuJson: JSON.stringify(this.menuler),
      };
      vm.$connection.post(
        "/kullanicilar/kullaniciTipiMenu",
        form,
        "post",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithTitle(
              "Menü Kaydet",
              "Menüler kaydedildi.",
              "success"
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle("Menü Kaydet", error.Message, "error");
        }
      );
    },
  },
};
</script>
