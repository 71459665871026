<template>
  <div class="card height-equal">
    <div class="card-header">
      <h5>Araç Resim - Onay Bekleyenler</h5>
    </div>
    <div class="card-body">
      <div class="upcoming-event">
        <div class="row">
          <div class="col-md-6" v-for="item in items" :key="item.Id">
            <div class="upcoming-innner media mb-4">
              <div class="left m-r-50">
                <a :href="item.Resim" target="_blank"><img :src="item.Resim" class="img-80 rounded-circle" /></a>
              </div>
              <div class="media-body">
                <p class="mb-0">
                  {{ item.AracFirmaAdi }}
                  <span class="pull-right">{{ formatDate(item.KayitTarihi) }}</span>
                </p>
                <h6 class="f-w-600">{{ item.SoforAdi }}</h6>
                <p class="mb-0">
                  <strong>Resim Tipi :</strong> {{ item.TipiStr }}<br />
                  <strong>Ekleyen : </strong> {{ item.KullaniciAdi }}
                </p>
                <b-btn-group>
                  <b-button size="xs" @click="onayla(item)" class="btn btn-info btn-xs">Onayla</b-button>
                  <b-button size="xs" @click="sil(item)" variant="danger">Sil</b-button>
                </b-btn-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    formatDate(date) {
      return this.$formatHelper.formatDate(date);
    },
    onList() {
      let vm = this;

      this.$connection.get(
        "/araclar/resim/onaybekleyenler",
        function (response) {
          vm.items = response;

          vm.$emit("rowcount", vm.items.length);
        }
      );
    },
    onayla(resim) {
      let vm = this;
      this.$alert.confirm(
        "Resim Onayla",
        "Resmi onaylamak istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/araclar/resim/" + resim.Id + "/durum/30",
            {},
            "put",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithTitle("Onay", response.Message, "success");
                vm.$listHelper.removeItem(vm.items, resim);
              } else {
                vm.$alert.messageWithTitle("Onay", response.Message, "error");
              }
            }
          );
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Resim Sil",
        "Resmi silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete(
            "/araclar/resim/" + item.Id,
            function (response) {
              vm.$alert.messageWithFunction(
                "Silindi",
                "Resim kaydı silindi.",
                "error",
                function () {
                  vm.$listHelper.removeItem(vm.items, item);
                }
              );
            }
          );
        }
      );
    },
  },
};
</script>
