<template>
  <div class="table-responsive mb-0">
    <table class="table table-bordernone table-striped">
      <thead>
        <tr>
          <th scope="col">İşlem</th>
          <th scope="col">Kod</th>
          <th scope="col">Durum</th>
          <th scope="col">Tarih</th>
          <th scope="col" class="sabitKol">
            <div>Tur / Opsiyon</div>
          </th>
          <th scope="col">Rehber</th>
          <th scope="col">Şoför</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.Id">
          <td>
            <b-btn-group>
              <router-link :to="`/regularRezervasyon/rezDetay/${item.Id}`"
                class="btn btn-info btn-xs">Detay</router-link>
            </b-btn-group>
          </td>
          <td><b-badge variant="info">{{ item.Kod }}</b-badge></td>
          <td>
            <RezervasyonDurum :durum="item.DurumStr" :durumInt="item.Durum" />
          </td>
          <td>
            <c-tarih-cell :kayitTarihi="item.Tarih" :rowKey="item.Id"></c-tarih-cell>
          </td>
          <td :class="item.TurRenk + ' sabitKol'">
            <div>
              {{ item.TurAdi }} / <small>{{ item.TurOpsiyonAdi }}</small>
            </div>
            <a :href="'/reservation/regular/' + item.IntId + '/' + item.Kod + '/' + istekId" v-for="istekId in item.AcentelerIstekId" :key="istekId" class="btn btn-info btn-xs"
              target="_blank">Rezervasyon Kartı</a>
          </td>
          <td>
            <div class="customers">
              <ul class="mb-1">
                <li class="d-inline-block">
                  <a href="#" :id="'rehber-tooltip-' + item.Id">
                    <img class="img-40 rounded-circle" :src="item.RehberResim" alt="" />
                  </a>
                </li>
              </ul>
              {{ item.RehberAdi }}
            </div>
            <b-tooltip :target="'rehber-tooltip-' + item.Id" triggers="hover" placement="right">
              <img class="img-fluid" :src="item.RehberResim" alt="" />
            </b-tooltip>
          </td>
          <td>
            <div class="customers">
              <ul class="mb-1">
                <li class="d-inline-block">
                  <a href="#" :id="'arac-tooltip-' + item.Id" v-b-tooltip.hover>
                    <img class="img-40 rounded-circle" :src="item.AracResim" alt="" />
                  </a>
                </li>
              </ul>
              {{ item.SoforAdi }}<br />
              {{ item.Plaka }}<br>
              <b-button v-if="item.isAracTakip" variant="info" @click="aracTakipAc(item.AracId, item.Id)" size="xs">Lokasyon</b-button>
            </div>
            <b-tooltip :target="'arac-tooltip-' + item.Id" triggers="hover" placement="left">
              <img class="img-fluid" :src="item.AracResim" alt="" />
            </b-tooltip>
          </td>
        </tr>
      </tbody>
    </table>
    
    <AracTakipModal :aracId="aracTakipGosterId" :goster="aracTakipGoster" :turId="aracTakipGosterTurId" :regularId="aracTakipGosterRegularId" @kapatildi="aracTakipKapatildi()" />
  </div>
</template>
<script>
import RezervasyonDurum from "./c_regularRezervasyon_durum";
import AracTakipModal from './c_arac_takip_goster';

export default {
  props: {
    tarih: null
  },
  data() {
    return {
      items: [],

      aracTakipGosterId: null,
      aracTakipGosterTurId: null,
      aracTakipGosterRegularId: null,
      aracTakipGoster: false
    };
  },
  components: {
    RezervasyonDurum,
    AracTakipModal
  },
  mounted() {
    this.listele();
  },
  watch: {
    tarih(newValue, oldValue) {
      this.listele();
    },
  },
  methods: {
    listele() {
      let vm = this;

      let filtre = {
        Tarih: this.$formatHelper.formatDateApi(this.tarih)
      }

      this.$connection.post(
        "/regularRezervasyonlar/rezervasyon/filtreForAcente",
        filtre,
        "post",
        function (response) {
          vm.items = response;

          vm.$emit("onKayitSayisi", vm.items.length);
        }
      );
    },
    aracTakipAc(aracId, regularId) {
      this.aracTakipGosterId = aracId;
      this.aracTakipGosterTurId = "";
      this.aracTakipGosterRegularId = regularId;
      this.aracTakipGoster = true;
    },
    aracTakipKapatildi(){
      this.aracTakipGosterId = null;
      this.aracTakipGosterTurId = null;
      this.aracTakipGosterRegularId = null;
      this.aracTakipGoster = false;
    },
  },
};
</script>