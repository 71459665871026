<template>
  <b-card header-tag="div" header-bg-variant="secondary" class="card-absolute" v-if="isBoxShow">
    <h5 slot="header">Tutarlar</h5>
    <b-card-body>
      <table class="product-page-width">
        <tbody>
          <tr v-if="isToplamTutar">
            <th>Toplam Tutar</th>
            <td>
              <b-badge variant="success"><c-money-cell :fiyat="detay.SatisToplamTutar" simge="€"></c-money-cell></b-badge>
            </td>
          </tr>
          <tr v-if="isToplamMaliyet">
            <th>Toplam Maliyet</th>
            <td>
              <b-badge variant="danger"><c-money-cell :fiyat="detay.AlisToplamTutar" simge="€"></c-money-cell></b-badge>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card-body>
  </b-card>
</template>
<script>
export default {
  props: {
    detay: {},
  },
  data() {
    return {
      isToplamTutar: false,
      isToplamMaliyet: false,
      
      isBoxShow: false,
    };
  },
  mounted() { },
  watch: {
    detay() {
      let user = this.$user.getUser();
      switch (user.Kullanici.KullaniciTipi) {
        //Acente
        case 10:
        case 15:
          this.isToplamTutar = true;
          break;

        //Merkez
        case 80:
        case 85:
        case 100:
          this.isToplamTutar = true;
          this.isToplamMaliyet = true;
          break;

        default:
          break;
      }

      this.isBoxShow =
        this.isToplamTutar ||
        this.isToplamMaliyet;
    },
  },
};
</script>
