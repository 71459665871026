<template>
  <div class="container-fluid">
    <div class="page-header">
      <div class="row">
        <div class="col-lg-6">
          <div class="page-header-left">
            <h3>{{ title }}</h3>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ path: '/dashboard/default' }">
                  <feather type="home"></feather>
                </router-link>
              </li>
              <li class="breadcrumb-item" v-if="main">{{ main }}</li>
              <li class="breadcrumb-item active" v-if="title">{{ title }}</li>
            </ol>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="bookmark pull-right" v-if="mutableLinks !== undefined">
            <ul>
              <draggable
                v-model="mutableLinks"
                group="bookmark"
                @start="drag = true"
                @end="drag = false"
              >
                <li
                  v-for="(items, index) in mutableLinks.slice(0, 8)"
                  :key="index"
                >
                  <router-link :to="{ path: items.path }">
                    <feather
                      v-b-tooltip.hover
                      :title="items.title"
                      :type="items.icon"
                    ></feather>
                  </router-link>
                </li>
              </draggable>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bookmark from "./bookmark";
import draggable from "vuedraggable";

export default {
  props: {
    title: {
      default: "home",
    },
    main: {
      default: "",
    },
    links: [],
  },
  data: function () {
    return {
      mutableLinks: this.links === undefined ? [] : this.links,
    };
  },
  components: {
    Bookmark,
    draggable,
  },
};
</script>
