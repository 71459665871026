<template>
    <b-modal id="aracTakipAyar" v-model="mutateGoster" centered title="Araç Takip Cihazı Ayarları" @ok="kaydet"
        ok-title="Güncelle" cancel-title="Kapat" class="theme-modal">
        <div class="form-row">
            <div class="col-md-12 mb-3">
                <label for="kayit_takipcihaz">Takip Cihazı</label>
                <b-form-select :disabled="ayarPasif" id="kayit_takipcihaz" v-model="kayit.TakipCihaz"
                    :options="takip_cihazlari"></b-form-select>
            </div>
        </div>
        <div class="form-row" v-if="kayit.TakipCihaz == 1">
            <div class="col-md-12 mb-3">
                <label for="kayit_trio_username">Kullanıcı Adı (username)</label>
                <b-form-input :disabled="ayarPasif" id="kayit_trio_username" v-model="kayit.Username"
                    :state="kayit_username_state" placeholder="Trio kullanıcı adı."></b-form-input>
                <b-form-invalid-feedback :state="kayit_username_state">Trio'daki kullanıcı adını giriniz
                </b-form-invalid-feedback>
            </div>
            <div class="col-md-12 mb-3">
                <label for="kayit_trio_password">Şifre (password)</label>
                <b-form-input :disabled="ayarPasif" type="password" id="kayit_trio_password" v-model="kayit.Password"
                    :state="kayit_password_state" placeholder="Trio şifreniz."></b-form-input>
                <b-form-invalid-feedback :state="kayit_password_state">Trio'daki şifrenizi giriniz
                </b-form-invalid-feedback>
            </div>
        </div>
        <div class="form-row" v-if="kayit.TakipCihaz == 2">
            <div class="col-md-12 mb-3">
                <label for="kayit_arvento_username">Kullanıcı Adı (username)</label>
                <b-form-input :disabled="ayarPasif" id="kayit_arvento_username" v-model="kayit.Username"
                    :state="kayit_username_state" placeholder="Arvento kullanıcı adı."></b-form-input>
                <b-form-invalid-feedback :state="kayit_username_state">Arvento'daki kullanıcı adını
                    giriniz.</b-form-invalid-feedback>
            </div>
            <div class="col-md-12 mb-3">
                <label for="kayit_pin1">Pin 1 (pin1)</label>
                <b-form-input :disabled="ayarPasif" type="password" id="kayit_pin1" v-model="kayit.Pin1"
                    :state="kayit_pin1_state" placeholder="Arvento Pin1 bilginiz."></b-form-input>
                <b-form-invalid-feedback :state="kayit_pin1_state">Arvento'daki 1. pin bilginizi
                    giriniz.</b-form-invalid-feedback>
            </div>
            <div class="col-md-12 mb-3">
                <label for="kayit_pin2">Pin 2 (pin2)</label>
                <b-form-input :disabled="ayarPasif" type="password" id="kayit_pin2" v-model="kayit.Pin2"
                    :state="kayit_pin2_state" placeholder="Arvento Pin2 bilginiz."></b-form-input>
                <b-form-invalid-feedback :state="kayit_pin2_state">Arvento'daki 2. pin bilginizi
                    giriniz.</b-form-invalid-feedback>
            </div>
            <div class="col-md-12 mb-3">
                <label for="kayit_ipadres">IP Adresi</label>
                <b-form-input :disabled="ayarPasif" id="kayit_pin2" v-model="kayit.IpAddress"
                    placeholder="Arventoda kendi sunucunuzu kullanıyorsanız ip adresini giriniz."></b-form-input>
            </div>
        </div>
        <div class="form-row" v-if="kayit.TakipCihaz == 3">
            <div class="col-md-12 mb-3">
                <label for="kayit_mobiliz_token">Güvenlik Anahtarı (token)</label>
                <b-form-input :disabled="ayarPasif" type="password" id="kayit_mobiliz_token" v-model="kayit.Token"
                    :state="kayit_token_state" placeholder="Mobiliz token."></b-form-input>
                <b-form-invalid-feedback :state="kayit_token_state">Mobiliz'deki güvenlik anahtarınızı giriniz.
                </b-form-invalid-feedback>
            </div>
        </div>
        <div class="form-row" v-if="aracId != null">
            <div class="col-md-12 mb-3">
                <label for="kayit_takip_cihaz_no">Takip Cihaz Numarası</label>
                <b-form-input id="kayit_takip_cihaz_no" v-model="kayit.TakipCihazId" :state="kayit_takipcihazid_state"
                    placeholder="Takip cihaz numarası."></b-form-input>
                <b-form-invalid-feedback :state="kayit_takipcihazid_state">Aracınızın takip cihaz numarasını giriniz.
                </b-form-invalid-feedback>
            </div>
        </div>

        <div v-if="lokasyon != null">
            <GmapMap :center="{ lat: lokasyon.lat, lng: lokasyon.lon }" :zoom="16" style="width: auto; height: 500px">
                <GmapMarker ref="myMarker" :position="google && new google.maps.LatLng(lokasyon.lat, lokasyon.lon)"
                    :clickable="true" />
            </GmapMap>
        </div>

        <template #modal-footer>
            <div class="w-100">
                <b-button variant="dark" class="float-left" @click="kapat()">Kapat</b-button>

                <div class="btn-group float-right">
                    <b-button type="button" @click="testEt()" variant="info">Test Et ve Güncelle</b-button>
                    <b-button type="button" @click="kaydet()" variant="success">Güncelle</b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

export default {
    props: {
        aracFirmaId: null,
        aracId: null,
        goster: false,
        ayarlar: {},
        ayarPasif: true
    },
    data() {
        return {
            kayit: {
                TakipCihaz: 0,
                Username: null,
                Pin1: null,
                Pin2: null,
                IpAddress: null,
                Token: null,
                Password: null,
                TakipCihazId: null
            },

            mutateGoster: this.goster,

            takip_cihazlari: [
                { value: 0, text: "Seçiniz" },
                { value: 1, text: "Trio" },
                { value: 2, text: "Arvento" },
                { value: 3, text: "Mobiliz" },
            ],

            lokasyon: null
        };
    },
    mounted() {
    },
    components: {
    },
    computed: {
        google: gmapApi,
        kayit_username_state() {
            if (this.kayit.TakipCihaz == 1 || this.kayit.TakipCihaz == 2) {
                if (this.kayit.Username == null || this.kayit.Username == '') return false;
            }
            return true;
        },
        kayit_password_state() {
            if (this.kayit.TakipCihaz == 1) {
                if (this.kayit.Password == null || this.kayit.Password == '') return false;
            }
            return true;
        },
        kayit_pin1_state() {
            if (this.kayit.TakipCihaz == 2) {
                if (this.kayit.Pin1 == null || this.kayit.Pin1 == '') return false;
            }
            return true;
        },
        kayit_pin2_state() {
            if (this.kayit.TakipCihaz == 2) {
                if (this.kayit.Pin2 == null || this.kayit.Pin2 == '') return false;
            }
            return true;
        },
        kayit_token_state() {
            if (this.kayit.TakipCihaz == 3) {
                if (this.kayit.Token == null || this.kayit.Token == '') return false;
            }
            return true;
        },
        kayit_takipcihazid_state() {
            if (this.aracId != null && (this.kayit.TakipCihaz == 2 || this.kayit.TakipCihaz == 3)) {
                if (this.kayit.TakipCihazId == null || this.kayit.TakipCihazId == '') return false;
            }
            return true;
        },
    },
    watch: {
        goster(newValue, oldValue) {
            if (this.aracFirmaId != null) {
                this.aracFirmaAyarlari();
            } else if (this.aracId != null) {
                this.aracAyarlari();
            }
        },
    },
    methods: {
        aracFirmaAyarlari() {
            let vm = this;

            this.$connection.get("/aracFirmalari/aracTakipAyarlari/" + this.aracFirmaId, function (response) {
                vm.kayit = response;
                vm.mutateGoster = true;
            });
        },
        aracAyarlari() {
            let vm = this;

            this.$connection.get("/araclar/aracTakipAyarlari/" + this.aracId, function (response) {
                vm.kayit = response;
                vm.mutateGoster = true;
            });
        },
        kapat() {
            this.mutateGoster = false;
        },
        kaydet() {
            let isValid =
                this.kayit_username_state && this.kayit_password_state
                && this.kayit_pin1_state && this.kayit_pin2_state
                && this.kayit_token_state;

            if (isValid) {
                let vm = this;

                let form = {
                    TakipCihaz: this.kayit.TakipCihaz,
                    AyarJson: JSON.stringify(this.kayit)
                };

                let url = "/aracFirmalari/aracTakipAyarGuncelle/" + this.aracFirmaId;
                if (this.aracId != null) {
                    url = "/araclar/aracTakipAyarGuncelle/" + this.aracId;
                }

                vm.$connection.post(
                    url,
                    form,
                    "put",
                    function (response) {
                        if (response.Success) {
                            vm.$alert.messageWithFunction(
                                "Araç Takip Ayarları",
                                response.Message,
                                "success",
                                function () {
                                    vm.mutateGoster = false;
                                }
                            );
                        }
                    },
                    function (error) {
                        vm.$alert.messageWithTitle(
                            "Araç Takip Ayarları",
                            error.Message,
                            "error"
                        );
                    }
                );
            }
        },
        testEt() {
            let isValid =
                this.kayit_username_state && this.kayit_password_state
                && this.kayit_pin1_state && this.kayit_pin2_state
                && this.kayit_token_state;

            if (isValid) {
                let vm = this;

                vm.lokasyon = null;

                let form = {
                    TakipCihaz: this.kayit.TakipCihaz,
                    AyarJson: JSON.stringify(this.kayit)
                };

                let url = "/aracFirmalari/aracTakipAyarlariTest/" + this.aracFirmaId;
                if (this.aracId != null) {
                    url = "/araclar/aracTakipAyarlariTest/" + this.aracId;
                }

                vm.$connection.post(
                    url,
                    form,
                    "post",
                    function (response) {
                        if (response.Success) {
                            vm.lokasyon = {
                                lat: parseFloat(response.Data.LokasyonX),
                                lon: parseFloat(response.Data.LokasyonY)
                            };
                            vm.$alert.messageWithTitle(
                                "Araç Takip Test",
                                "Bilgiler başarılı bir şekilde güncellendi.",
                                "success"
                            );
                        }
                    },
                    function (error) {
                        vm.$alert.messageWithTitle(
                            "Araç Takip Test",
                            error.Message,
                            "error"
                        );
                    }
                );
            }
        },
    },
};
</script>
