<template>
  <div>
    <Breadcrumbs main="Kullanıcılar" title="Liste" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <b-form @submit="onFiltreSubmit" inline>
                <label class="sr-only" for="filtre_kelime">Kelime</label>
                <b-form-input type="text" id="filtre_kelime" v-model="filtre.Kelime" placeholder="Kelime"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>

                <label class="sr-only" for="filtre_kullanicitipi">Kullanıcı Tipi</label>
                <b-form-select id="filtre_kullanicitipi" v-model="filtre.Tipi" :options="filtre_kullanicitipleri"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <label class="sr-only" for="filtre_durum">Durum</label>
                <b-form-select id="filtre_durum" v-model="filtre.Durum" :options="filtre_durumlar"
                  class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <label class="sr-only" for="filtre_baslangictarihi">Başlangıç Tarihi</label>
                <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                  id="filtre_baslangictarihi" v-model="filtre.BaslangicTarihi" :clear-button="true"
                  clear-button-icon="fa fa-times" placeholder="Başlangıç Tarihi" :format="date_format"></datepicker>

                <label class="sr-only" for="filtre_bitistarihi">Bitiş Tarihi</label>
                <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
                  id="filtre_bitistarihi" v-model="filtre.BitisTarihi" :clear-button="true"
                  clear-button-icon="fa fa-times" placeholder="Bitiş Tarihi" :format="date_format"></datepicker>

                <label class="sr-only" for="filtre_isGsmDogrulandi">Gsm Doğrulandı</label>
                <b-form-select id="filtre_isGsmDogrulandi" v-model="filtre.isGsmDogrulandi"
                  :options="filtre_isgsmdogrulandi" class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <label class="sr-only" for="filtre_isEmailDogrulandi">Email Doğrulandı</label>
                <b-form-select id="filtre_isEmailDogrulandi" v-model="filtre.isEmailDogrulandi"
                  :options="filtre_isemaildogrulandi" class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

                <b-button type="submit" class="pull-right" variant="warning">Listele</b-button>
              </b-form>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link :to="`/kullanici/kayit/${row.item.Id}`"
                      class="btn btn-success btn-xs">Düzelt</router-link>
                    <b-button size="xs" @click="sil(row.item)" variant="danger">Sil</b-button>
                  </b-btn-group>
                </template>
                <template #cell(GSM)="row">
                  <a :href="'tel:0090' + row.item.GSM">{{ row.item.GSM }}</a>
                  <c-aktif-cell :isAktif="row.item.isGsmDogrulandi"></c-aktif-cell>
                </template>
                <template #cell(Email)="row">
                  <a :href="'mailto:' + row.item.Email">{{ row.item.Email }}</a>
                  <c-aktif-cell :isAktif="row.item.isEmailDogrulandi"></c-aktif-cell>
                </template>
                <template #cell(isEmailDogrulandi)="data">
                  <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";

export default {
  data() {
    return {
      breadLinks: [{ path: "/kullanici/kayit", title: "Ekle", icon: "plus" }],
      tablefields: [
        {
          key: "Islem",
          label: "İşlemler",
          sortable: false,
          stickyColumn: true,
        },
        { key: "TipiStr", label: "Tipi", sortable: true, stickyColumn: true },
        { key: "AdSoyad", label: "Adı Soyadı", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol" },
        { key: "GSM", label: "Cep", sortable: true, thClass: "sabitKol" },
        { key: "KullaniciAdi", label: "Kullanıcı Adı", sortable: true },
        { key: "EkleyenKullaniciAdi", label: "Ek. Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre: {
        Kelime: "",
        Tipi: null,
        BaslangicTarihi: null,
        BitisTarihi: null,
        isGsmDogrulandi: null,
        isEmailDogrulandi: null,
        Durum: null,
      },

      filtre_kullanicitipleri: [{ value: null, text: "Kullanıcı Tipi" }],

      filtre_isgsmdogrulandi: [
        { value: null, text: "Gsm Doğrulandı" },
        { value: "true", text: "Doğrulananlar" },
        { value: "false", text: "Doğrulanmayanlar" },
      ],

      filtre_isemaildogrulandi: [
        { value: null, text: "Email Doğrulandı" },
        { value: "true", text: "Doğrulananlar" },
        { value: "false", text: "Doğrulanmayanlar" },
      ],

      filtre_durumlar: [
        { value: null, text: "Durum" },
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],
    };
  },
  components: {
    Datepicker,
  },
  mounted() {
    this.onKullaniciTipiList();
    this.onList();
  },
  methods: {
    onKullaniciTipiList() {
      let vm = this;

      this.$connection.get(
        "/kullanicilar/merkezKullaniciTipleri",
        function (response) {
          vm.filtre_kullanicitipleri =
            vm.filtre_kullanicitipleri.concat(response);
        }
      );
    },
    onFiltreSubmit(event) {
      event.preventDefault();

      this.onList();
    },
    onList() {
      let vm = this;

      this.$connection.post(
        "/kullanicilar/filtre",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Kullanıcı kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete(
            "/kullanicilar/" + item.Id,
            function (response) {
              vm.$alert.messageWithFunction(
                "Silindi",
                "Kullanıcı kaydı silindi.",
                "error",
                function () {
                  vm.$listHelper.removeItem(vm.items, item);
                }
              );
            }
          );
        }
      );
    },
  },
};
</script>
