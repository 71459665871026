<template>
  <div>
    <Breadcrumbs main="Turlar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Tur Opsiyon Kaydı</h5>
              <span>İlgili tura yeni opsiyon ekleyebilir veya opsiyonda değişiklik
                yapmak için aşağıdaki formu kullanabilirsiniz</span>
            </div>
            <div class="card-body">
              <b-form @submit="onCustomStyleSubmit">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_adi">Opsiyon Adı</label>
                    <b-form-input type="text" id="kayit_adi" v-model="kayit.Adi" :state="kayit_adi_state"
                      placeholder="Opsiyon adı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adi_state">Opsiyon adını giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_aciklama">Açıklama</label>
                    <ckeditor :editor="editor" v-model="kayit.Aciklama" :state="kayit_aciklama_state"></ckeditor>
                    <b-form-invalid-feedback :state="kayit_aciklama_state">Opsiyon açıklamasını
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_fiyat">Fiyat</label>
                    <b-input-group :prepend="paraBirimi">
                      <b-form-input type="text" id="kayit_fiyat" v-model="kayit.Fiyat" :state="kayit_fiyat_state"
                        placeholder="Opsiyon fiyatı"></b-form-input>
                      <b-form-invalid-feedback :state="kayit_fiyat_state">Opsiyon fiyatını
                        giriniz</b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <b-form-input type="text" id="kayit_sira" v-model="kayit.Sira"
                      placeholder="Liste Sırası"></b-form-input>
                    <p>
                      Opsiyon listelemesinde en büyük değer en başta gözükür.
                    </p>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3 animate-chk">
                    <label>Müzeler</label>
                    <label class="d-block" for="muzeTumunuSec"><input class="checkbox_animated"
                        v-model="isMuzeTumunuSec" value="true" id="muzeTumunuSec" type="checkbox"
                        @change="muzeTumunuSecChange" />Tümünü Seç</label>
                    <hr />
                    <div class="checkbox-animated">
                      <label v-for="muze in muzeler" :key="muze.Id" class="d-block" :for="'muzeId' + muze.Id">
                        <input class="checkbox_animated" v-model="selectedMuzeler" :value="muze.Id"
                          :id="'muzeId' + muze.Id" type="checkbox" />
                        {{ muze.Tanim }}
                        <b-badge variant="primary">{{ muze.GirisUcreti }} {{ muze.Simge }}</b-badge>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3 animate-chk">
                    <label>Restoranlar</label>
                    <label v-if="restoranInputType == 'checkbox'" class="d-block" for="restoranTumunuSec"><input
                        class="checkbox_animated" v-model="isRestoranTumunuSec" value="true" id="restoranTumunuSec"
                        type="checkbox" @change="restoranTumunuSecChange" />Tümünü Seç</label>
                    <hr />
                    <div class="checkbox-animated">
                      <label v-for="restoran in restoranlar" :key="restoran.Id" class="d-block"
                        :for="'restoranId' + restoran.Id">
                        <input :class="restoranInputType + '_animated'" v-model="selectedRestoranlar"
                          :value="restoran.Id" :id="'restoranId' + restoran.Id" :type="restoranInputType" />
                        {{ restoran.Adi }}
                        <b-badge variant="primary">A: {{ restoran.MenuFiyatiAlis }} ₺</b-badge>
                        <b-badge variant="success">S: {{ restoran.MenuFiyatiSatis }} ₺</b-badge>
                      </label>
                    </div>
                  </div>
                </div>
                <router-link :to="`/tur/opsiyon/${turId}`" class="btn btn-light pull-left">Geri Dön</router-link>
                <b-button type="submit" class="pull-right" variant="primary">Kaydet</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      turId: this.$route.params.turId,
      id: this.$route.params.id,

      pageTitle: "",

      breadLinks: [
        {
          path: "/tur/opsiyon/" + this.$route.params.turId,
          title: "Turlar",
          icon: "chevron-left",
        },
      ],

      tur: {},
      paraBirimi: '₺',
      restoranInputType: 'checkbox',

      kayit: {
        Adi: "",
        Aciklama: "",
        Fiyat: 0,
        Sira: 0,
        Muzeler: [],
        Mekanlar: [],
        Restoranlar: [],
      },

      isMuzeTumunuSec: false,
      selectedMuzeler: [],

      isRestoranTumunuSec: false,
      selectedRestoranlar: [],

      restoranlar: [],
      muzeler: [],

      editor: ClassicEditor,
    };
  },
  components: {},
  mounted() {
    let vm = this;

    if (this.turId !== undefined) {
      vm.pageTitle = "";

      this.$connection.get("/turlar/" + this.turId, function (response) {
        if (response.Success) {
          vm.tur = response.Data;
          if (response.Data.Tipi == 10) {
            vm.paraBirimi = '€';
            vm.restoranInputType = 'radio';
          }

          vm.pageTitle = response.Data.Adi;

          if (vm.id !== undefined) {
            vm.kayitGetir();
          }
        } else {
          vm.$alert.messageWithTitle(
            "Opsiyon Kaydet",
            response.Message,
            "error"
          );
        }
      });
    }

    this.$connection.get("/restoranlar/select", function (response) {
      vm.restoranlar = response;
    });

    this.$connection.get("/muzeler/select", function (response) {
      vm.muzeler = response;
    });
  },
  computed: {
    kayit_adi_state() {
      if (this.kayit.Adi == null || this.kayit.Adi == "") return false;
      else return true;
    },
    kayit_fiyat_state() {
      return true;
      // if (this.kayit.Fiyat == 0)
      //   return false;
      // else
      //   return true;
    },
    kayit_aciklama_state() {
      if (this.kayit.Aciklama == null || this.kayit.Aciklama == "")
        return false;
      else return true;
    },
  },
  methods: {
    kayitGetir() {
      let vm = this;
      this.$connection.get(
        "/turlar/opsiyonlarGetById/" + this.id,
        function (response) {
          if (response.Success) {
            vm.kayit = response.Data;

            vm.kayit.Muzeler = response.Data.MuzelerInt;
            vm.selectedMuzeler = response.Data.MuzelerInt;
            vm.kayit.Mekanlar = response.Data.MekanlarInt;
            vm.selectedMekanlar = response.Data.MekanlarInt;

            if (vm.tur.Tipi == 10) {
              vm.kayit.Restoranlar = response.Data.RestoranlarInt[0];
              vm.selectedRestoranlar = response.Data.RestoranlarInt[0];
            } else {
              vm.kayit.Restoranlar = response.Data.RestoranlarInt;
              vm.selectedRestoranlar = response.Data.RestoranlarInt;
            }
          } else {
            vm.$alert.messageWithTitle(
              "Opsiyon Kaydet",
              response.Message,
              "error"
            );
          }
        }
      );
    },
    muzeTumunuSecChange() {
      if (this.isMuzeTumunuSec) {
        for (let i = 0; i < this.muzeler.length; i++) {
          this.selectedMuzeler.push(this.muzeler[i].Id);
        }
      } else {
        this.selectedMuzeler = [];
      }
    },
    restoranTumunuSecChange() {
      if (this.isRestoranTumunuSec) {
        for (let i = 0; i < this.restoranlar.length; i++) {
          this.selectedRestoranlar.push(this.restoranlar[i].Id);
        }
      } else {
        this.selectedRestoranlar = [];
      }
    },
    onCustomStyleSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adi_state &&
        this.kayit_fiyat_state &&
        this.kayit_aciklama_state;

      if (isValid) {
        let vm = this;

        let url = "/turlar/opsiyonlar/" + vm.turId;
        let method = "post";
        let messageTitle = "Yeni Opsiyonu";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url = "/turlar/opsiyonlar/" + vm.id;
          method = "put";
          messageTitle = "Opsiyon Düzelt";
        }

        vm.kayit.Muzeler = vm.selectedMuzeler;
        if (vm.tur.Tipi == 10) {
          vm.kayit.Restoranlar = [];
          vm.kayit.Restoranlar.push(vm.selectedRestoranlar);
        } else {
          vm.kayit.Restoranlar = vm.selectedRestoranlar;
        }

        vm.kayit.Mekanlar = [];

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/tur/opsiyon/" + vm.turId);
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>