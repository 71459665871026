<template>
  <div>
    <div class="media">
      <div class="media-body">
        <h5 class="mb-2 mt-2 f-w-700">TÜM REZERVASYONLARINIZ</h5>
      </div>
      <div class="pull-right mt-0">
        <b-form @submit="onFiltreSubmit">
          <b-input-group prepend="Tarih">
            <template #prepend>
              <b-button @click="gun(-1)" variant="light"><i class="fa fa-angle-left"></i></b-button>
              <b-button @click="gun(+1)" variant="light"><i class="fa fa-angle-right"></i></b-button>
            </template>
            <datepicker input-class="datepicker-here form-control digits" v-model="filtre.Tarih" :language="tr"
              placeholder="Başlangıç Tarihi" :format="date_format">
            </datepicker>
            <b-input-group-append>
              <b-button type="submit" variant="outline-success">Listele</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
          <li class="nav-item" v-on:click="changetab('rezervasyon')">
            <a class="nav-link" :class="[
          { 'show active': showtab == 'rezervasyon' },
          'shadow-lg p-25 shadow-showcase',
        ]" id="top-rezervasyon" data-toggle="tab" role="tab" aria-controls="rezervasyon" aria-selected="true">PRIVATE
              TUR
              REZERVASYONLARI <b-badge :variant="turRezervasyonSayisi == 0 ? 'dark' : 'info'">{{ turRezervasyonSayisi
                }}</b-badge><br>
              <i v-if="showtab == 'rezervasyon'" class="icon-angle-down"></i>
            </a>
          </li>
          <li class="nav-item" v-on:click="changetab('regularistek')">
            <a class="nav-link" :class="[
          { 'show active': showtab == 'regularistek' },
          'shadow-lg p-25 shadow-showcase',
        ]" id="top-regular-istek" data-toggle="tab" role="tab" aria-controls="regularistek"
              aria-selected="true">REGULAR TUR İSTEKLERİ <b-badge
                :variant="regularIstekSayisi == 0 ? 'dark' : 'info'">{{
          regularIstekSayisi }}</b-badge><br>
              <i v-if="showtab == 'regularistek'" class="icon-angle-down"></i>
            </a>
          </li>
          <li class="nav-item" v-on:click="changetab('regularrezervasyon')">
            <a class="nav-link" :class="[
          { 'show active': showtab == 'regularrezervasyon' },
          'shadow-lg p-25 shadow-showcase',
        ]" id="top-regular-rezervasyon" data-toggle="tab" role="tab" aria-controls="regularrezervasyon"
              aria-selected="true">REGULAR TUR REZERVASYONLARI <b-badge
                :variant="regularRezervasyonSayisi == 0 ? 'dark' : 'info'">{{ regularRezervasyonSayisi }}</b-badge><br>
              <i v-if="showtab == 'regularrezervasyon'" class="icon-angle-down"></i>
            </a>
          </li>
          <li class="nav-item" v-on:click="changetab('balonrezervasyon')">
            <a class="nav-link" :class="[
          { 'show active': showtab == 'balonrezervasyon' },
          'shadow-lg p-25 shadow-showcase',
        ]" id="top-balon-rezervasyon" data-toggle="tab" role="tab" aria-controls="balonrezervasyon"
              aria-selected="true">BALON REZERVASYONLARI <b-badge
                :variant="balonRezervasyonSayisi == 0 ? 'dark' : 'info'">{{ balonRezervasyonSayisi }}</b-badge><br><br>
              <i v-if="showtab == 'balonrezervasyon'" class="icon-angle-down"></i>
            </a>
          </li>
          <li class="nav-item" v-on:click="changetab('transferrezervasyon')">
            <a class="nav-link" :class="[
          { 'show active': showtab == 'transferrezervasyon' },
          'shadow-lg p-25 shadow-showcase',
        ]" id="top-transfer-rezervasyon" data-toggle="tab" role="tab" aria-controls="transferrezervasyon"
              aria-selected="true">TRANSFER REZERVASYONLARI <b-badge
                :variant="transferRezervasyonSayisi == 0 ? 'dark' : 'info'">{{ transferRezervasyonSayisi
                }}</b-badge><br><br>
              <i v-if="showtab == 'transferrezervasyon'" class="icon-angle-down"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="tab-content" id="top-tabContent">
      <div class="tab-pane fade" :class="{ 'show active': showtab == 'rezervasyon' }" id="rezervasyon" role="tabpanel"
        aria-labelledby="rezervasyon">

        <div class="table-responsive mb-0">
          <table class="table table-bordernone table-striped">
            <thead>
              <tr>
                <th scope="col">İşlem</th>
                <th scope="col">Kod</th>
                <th scope="col">Durum</th>
                <th scope="col">Tarih</th>
                <th scope="col">Otel</th>
                <th scope="col">Misafirler</th>
                <th scope="col" class="sabitKol">
                  <div>Tur / Opsiyon</div>
                </th>
                <th scope="col">Rehber</th>
                <th scope="col">Şoför</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.Id">
                <td>
                  <b-btn-group>
                    <router-link :to="`/turRezervasyon/detay/${item.Id}`"
                      class="btn btn-info btn-xs">Detay</router-link>
                  </b-btn-group>
                </td>
                <td>
                  <b-badge variant="info">{{ item.Kod }}</b-badge>
                </td>
                <td>
                  <RezervasyonDurum :durum="item.DurumStr" :durumInt="item.Durum" />
                </td>
                <td>
                  <c-tarih-cell :kayitTarihi="item.Tarih" :rowKey="item.Id"></c-tarih-cell>
                  <b-badge variant="primary">{{ item.Saat }}</b-badge>
                </td>
                <td>{{ item.OtelAdi }}</td>
                <td>
                  <c-misafirler :misafirler="item.Misafirler"></c-misafirler>
                  <b-badge variant="warning">{{ item.KisiSayisi }}</b-badge>
                </td>
                <td :class="item.TurRenk + ' sabitKol'">
                  <div>
                    {{ item.TurAdi }} / <small>{{ item.TurOpsiyonAdi }}</small>
                  </div>
                  <a :href="'/reservation/tour/' + item.IntId + '/' + item.Kod" class="btn btn-info btn-xs"
                    target="_blank">Rezervasyon
                    Kartı</a>
                </td>
                <td>
                  <div v-if="item.RehberAdi !== null">
                    <div class="customers">
                      <ul class="mb-1">
                        <li class="d-inline-block">
                          <a href="#" :id="'rehber-tooltip-' + item.Id">
                            <img class="img-40 rounded-circle" :src="item.RehberResim" alt="" />
                          </a>
                        </li>
                      </ul>
                      {{ item.RehberAdi }}
                    </div>
                    <b-tooltip :target="'rehber-tooltip-' + item.Id" triggers="hover" placement="right">
                      <img class="img-fluid" :src="item.RehberResim" alt="" />
                    </b-tooltip>
                  </div>
                </td>
                <td>
                  <div v-if="item.SoforAdi !== null">
                    <div class="customers">
                      <ul class="mb-1">
                        <li class="d-inline-block">
                          <a href="#" :id="'arac-tooltip-' + item.Id" v-b-tooltip.hover>
                            <img class="img-40 rounded-circle" :src="item.AracResim" alt="" />
                          </a>
                        </li>
                      </ul>
                      {{ item.SoforAdi }}<br />
                      {{ item.Plaka }}<br>
                      <b-button v-if="item.isAracTakip" variant="info" @click="aracTakipAc(item.AracId, item.Id)"
                        size="xs">Lokasyon</b-button>
                    </div>
                    <b-tooltip :target="'arac-tooltip-' + item.Id" triggers="hover" placement="left">
                      <img class="img-fluid" :src="item.AracResim" alt="" />
                    </b-tooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="tab-pane fade" :class="{ 'show active': showtab == 'regularistek' }" id="regularistek" role="tabpanel"
        aria-labelledby="regularistek">
        <RegularRezervasyonIstekList :tarih="filtre.Tarih" v-if="filtre.Tarih != null"
          @onKayitSayisi="onRegularIstKayitSayisi" />
      </div>
      <div class="tab-pane fade" :class="{ 'show active': showtab == 'regularrezervasyon' }" id="regularrezervasyon"
        role="tabpanel" aria-labelledby="regularistek">
        <RegularRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null"
          @onKayitSayisi="onRegularRezKayitSayisi" />
      </div>
      <div class="tab-pane fade" :class="{ 'show active': showtab == 'balonrezervasyon' }" id="balonrezervasyon"
        role="tabpanel" aria-labelledby="regularistek">
        <BalonRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null" @onKayitSayisi="onBalonKayitSayisi" />
      </div>
      <div class="tab-pane fade" :class="{ 'show active': showtab == 'transferrezervasyon' }" id="transferrezervasyon"
        role="tabpanel" aria-labelledby="regularistek">
        <TransferRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null"
          @onKayitSayisi="onTransferKayitSayisi" />
      </div>
    </div>

    <AracTakipModal :aracId="aracTakipGosterId" :goster="aracTakipGoster" :turId="aracTakipGosterTurId"
      :regularId="aracTakipGosterRegularId" @kapatildi="aracTakipKapatildi()" />
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import RezervasyonDurum from "./c_turRezervasyon_durum";
import RegularRezervasyonIstekList from './c_regular_istek_dashboard_acente';
import RegularRezervasyonList from './c_regular_rezervasyon_dashboard_acente';
import BalonRezervasyonList from './c_balon_rezervasyon_dashboard_acente';
import TransferRezervasyonList from './c_transfer_rezervasyon_dashboard_acente';
import AracTakipModal from './c_arac_takip_goster';

export default {
  props: {},
  data() {
    return {
      items: [],

      showtab: "rezervasyon",

      date_format: "dd.MM.yyyy",
      tr: tr,

      turRezervasyonSayisi: 0,
      regularIstekSayisi: 0,
      regularRezervasyonSayisi: 0,
      balonRezervasyonSayisi: 0,
      transferRezervasyonSayisi: 0,

      acik_popover: "",

      filtre: {
        Tarih: null,
      },

      aracTakipGosterId: null,
      aracTakipGosterTurId: null,
      aracTakipGosterRegularId: null,
      aracTakipGoster: false
    };
  },
  components: {
    Datepicker,
    RezervasyonDurum,
    RegularRezervasyonIstekList,
    RegularRezervasyonList,
    BalonRezervasyonList,
    TransferRezervasyonList,
    AracTakipModal
  },
  mounted() {
    let bugun = new Date();
    this.filtre.Tarih = bugun;

    this.listele();
  },
  watch: {
    tarih(newValue, oldValue) {
      this.listele();
    },
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    onFiltreSubmit(target) {
      target.preventDefault();
      this.listele();
    },
    gun(sayi) {
      this.filtre.Tarih = this.$formatHelper.addDay(
        new Date(this.filtre.Tarih),
        sayi
      );
      this.listele();
    },
    listele() {
      let vm = this;

      this.filtre.Tarih = this.$formatHelper.formatDateApi(this.filtre.Tarih);

      this.$connection.post(
        "/turRezervasyonlari/filtreForAcente",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;
          vm.turRezervasyonSayisi = response.length;
        }
      );
    },
    aracTakipAc(aracId, turId) {
      this.aracTakipGosterId = aracId;
      this.aracTakipGosterTurId = turId;
      this.aracTakipGosterRegularId = "";
      this.aracTakipGoster = true;
    },
    aracTakipKapatildi() {
      this.aracTakipGosterId = null;
      this.aracTakipGosterTurId = null;
      this.aracTakipGosterRegularId = null;
      this.aracTakipGoster = false;
    },
    onRegularIstKayitSayisi(sayi) {
      this.regularIstekSayisi = sayi;
    },
    onRegularRezKayitSayisi(sayi) {
      this.regularRezervasyonSayisi = sayi;
    },
    onBalonKayitSayisi(sayi) {
      this.balonRezervasyonSayisi = sayi;
    },
    onTransferKayitSayisi(sayi) {
      this.transferRezervasyonSayisi = sayi;
    }
  },
};
</script>
