<template>
  <b-card header-tag="div" header-bg-variant="secondary" class="card-absolute" v-if="isBoxShow">
    <h5 slot="header">Tutarlar</h5>
    <table class="product-page-width">
      <tbody>
        <tr v-if="isToplamTutar">
          <th>Toplam Tutar</th>
          <td>
            <b-badge variant="success"><c-money-cell :fiyat="detay.ToplamTutar" simge="TL"></c-money-cell></b-badge>
          </td>
        </tr>
        <tr v-if="isToplamMaliyet">
          <th>Toplam Maliyet</th>
          <td>
            <b-badge variant="danger"><c-money-cell :fiyat="detay.ToplamMaliyet" simge="TL"></c-money-cell></b-badge>
          </td>
        </tr>
        <tr v-if="isTurTutar">
          <th>Tur Toplamı</th>
          <td>
            <b-badge variant="warning"><c-money-cell :fiyat="detay.TurTutar" simge="TL"></c-money-cell></b-badge>
          </td>
        </tr>
        <tr v-if="isAracTutar">
          <th>Araç Tutar</th>
          <td>
            <b-badge variant="info"><c-money-cell :fiyat="detay.AracTutar" simge="TL"></c-money-cell></b-badge>
          </td>
        </tr>
        <tr v-if="isRehberTutar">
          <th>Rehber Tutar</th>
          <td>
            <b-badge variant="dark"><c-money-cell :fiyat="detay.RehberTutar" simge="TL"></c-money-cell></b-badge>
          </td>
        </tr>
        <tr v-if="isRestoranTutar">
          <th>Restoran Tutar</th>
          <td>
            <b-badge variant="secondary"><c-money-cell :fiyat="detay.RestoranTutar" simge="TL"></c-money-cell></b-badge>
          </td>
        </tr>
        <tr v-if="isMuzeTutar">
          <th>Müze Tutar</th>
          <td>
            <b-badge variant="info"><c-money-cell :fiyat="detay.MuzeTutar" simge="TL"></c-money-cell></b-badge>
          </td>
        </tr>
      </tbody>
    </table>
  </b-card>
</template>
<script>
export default {
  props: {
    detay: {},
  },
  data() {
    return {
      isToplamTutar: false,
      isToplamMaliyet: false,
      isTurTutar: false,
      isAracTutar: false,
      isRehberTutar: false,
      isRestoranTutar: false,
      isMuzeTutar: false,

      isBoxShow: false,
    };
  },
  mounted() { },
  watch: {
    detay() {
      let user = this.$user.getUser();
      switch (user.Kullanici.KullaniciTipi) {
        //Acente
        case 10:
        case 15:
          this.isToplamTutar = true;
          if (this.detay.TurTutar > 0) this.isTurTutar = true;
          this.isAracTutar = true;
          this.isRehberTutar = true;
          this.isRestoranTutar = true;
          this.isMuzeTutar = true;
          break;

        //Rehber
        case 20:
          break;

        //Araç Firması
        case 30:
        case 35:
          break;

        //Araç
        case 40:
          break;

        //Merkez
        case 80:
        case 85:
        case 100:
          this.isToplamTutar = true;
          this.isToplamMaliyet = true;
          if (this.detay.TurTutar > 0) this.isTurTutar = true;
          this.isAracTutar = true;
          this.isRehberTutar = true;
          this.isRestoranTutar = true;
          this.isMuzeTutar = true;
          break;

        default:
          break;
      }

      this.isBoxShow =
        this.isToplamTutar ||
        this.isToplamMaliyet ||
        this.isTurTutar ||
        this.isAracTutar ||
        this.isRehberTutar ||
        this.isRestoranTutar ||
        this.isMuzeTutar;
    },
  },
};
</script>
