<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs border-tab tabs-scoial" id="yorum-tab" role="tablist">
          <li class="nav-item" v-on:click="changetab('arac')">
            <a class="nav-link" :class="[
            { 'show active': showtab == 'arac' }
          ]" id="yorum-arac" data-toggle="tab" role="tab" aria-controls="arac" aria-selected="true">ARAÇLARA GELEN
              YORUMLAR <b-badge variant="primary">{{ arac_yorumlar.length }}</b-badge></a>
          </li>
          <li class="nav-item" v-on:click="changetab('rehber')">
            <a class="nav-link" :class="[
            { 'show active': showtab == 'rehber' }
          ]" id="yorum-rehber" data-toggle="tab" role="tab" aria-controls="rehber" aria-selected="true">REHBERLERE
              GELEN YORUMLAR <b-badge variant="primary">{{ rehber_yorumlar.length }}</b-badge></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="tab-content" id="yorum-tabContent">
      <div class="tab-pane fade" :class="{ 'show active': showtab == 'arac' }" id="arac" role="tabpanel"
        aria-labelledby="arac">

        <c-table :tablefields="arac_tablefields" :items="arac_yorumlar">
          <template #cell(Islem)="row">
            <b-btn-group>
              <b-button @click="aracDetayAc(row.item.Id)" class="btn btn-xs btn-primary">Detay</b-button>
              <a :href="'/turRezervasyon/detay/' + row.item.TurRezervasyonId" class="btn btn-xs btn-dark"
                target="_blank">Rez. {{ row.item.TurRezervasyonKodu
                }}</a>
            </b-btn-group>
          </template>
          <template #cell(AcenteAdi)="row">
            {{ row.item.AcenteAdi }}<br /><small>{{ row.item.KullaniciAdi }}</small>
          </template>
          <template #cell(KayitTarihi)="row">
            <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
              :rowKey="row.item.Id"></c-tarih-cell>
          </template>
        </c-table>

        <b-modal id="aracYorumDetay" size="xl" centered title="Yorum Detayı" hide-footer class="theme-modal">
          <AracYorum :id="seciliAracYorumId" v-if="seciliAracYorumId != null">
          </AracYorum>
        </b-modal>

      </div>

      <div class="tab-pane fade" :class="{ 'show active': showtab == 'rehber' }" id="rehber" role="tabpanel"
        aria-labelledby="rehber">

        <c-table :tablefields="rehber_tablefields" :items="rehber_yorumlar">
          <template #cell(Islem)="row">
            <b-btn-group>
              <b-button @click="rehberDetayAc(row.item.Id)" class="btn btn-xs btn-primary">Detay</b-button>
              <a :href="'/turRezervasyon/detay/' + row.item.TurRezervasyonId" class="btn btn-xs btn-dark"
                target="_blank">Rez. {{ row.item.TurRezervasyonKodu
                }}</a>
            </b-btn-group>
          </template>
          <template #cell(AcenteAdi)="row">
            {{ row.item.AcenteAdi }}<br /><small>{{ row.item.KullaniciAdi }}</small>
          </template>
          <template #cell(KayitTarihi)="row">
            <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
              :rowKey="row.item.Id"></c-tarih-cell>
          </template>
        </c-table>

        <b-modal id="rehberYorumDetay" size="xl" centered title="Yorum Detayı" hide-footer class="theme-modal">
          <RehberYorum :id="seciliRehberYorumId" v-if="seciliRehberYorumId != null">
          </RehberYorum>
        </b-modal>

      </div>
    </div>
  </div>
</template>
<script>
import RehberYorum from "./c_rehber_yorum";
import AracYorum from "./c_arac_yorum";

export default {
  props: {
  },
  data() {
    return {
      showtab: "arac",

      arac_tablefields: [
        { key: "Islem", label: "İşlem", sortable: false },
        { key: "SoforAdi", label: "Araç", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: false },
        { key: "AracaGosterStr", label: "Gösterim D.", sortable: false },
        { key: "SavunmaKabulDurumuStr", label: "Savunma D.", sortable: false },
        { key: "KayitTarihi", label: "Yorum T.", sortable: false },
        { key: "Puan", label: "Puan", sortable: false },
        { key: "Yorum", label: "Yorum", sortable: false }
      ],
      arac_yorumlar: [],
      seciliAracYorumId: null,

      rehber_tablefields: [
        { key: "Islem", label: "İşlem", sortable: false },
        { key: "RehberAdi", label: "Rehber", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: false },
        { key: "RehbereGosterStr", label: "Gösterim D.", sortable: false },
        { key: "SavunmaKabulDurumuStr", label: "Savunma D.", sortable: false },
        { key: "KayitTarihi", label: "Yorum T.", sortable: false },
        { key: "Puan", label: "Puan", sortable: false },
        { key: "Yorum", label: "Yorum", sortable: false }
      ],
      rehber_yorumlar: [],
      seciliRehberYorumId: null
    };
  },
  components: {
    RehberYorum,
    AracYorum
  },
  mounted() {
    this.aracYorumlar();
    this.rehberYorumlar();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    aracYorumlar() {
      let filtre = {
        BaslangicTarihi: null,
        BitisTarihi: null,
        MaxPuan: 2,
        SavunmaKabulDurumu: 0
      }

      let vm = this;
      this.$connection.post(
        "/araclar/yorumlari",
        filtre,
        "post",
        function (response) {
          vm.arac_yorumlar = response;
        }
      );
    },
    aracDetayAc(id) {
      this.seciliAracYorumId = id;
      this.$bvModal.show("aracYorumDetay");
    },
    rehberYorumlar() {
      let filtre = {
        BaslangicTarihi: null,
        BitisTarihi: null,
        MaxPuan: 2,
        SavunmaKabulDurumu: 0
      }

      let vm = this;
      this.$connection.post(
        "/rehberler/yorumlari",
        filtre,
        "post",
        function (response) {
          vm.rehber_yorumlar = response;
        }
      );
    },
    rehberDetayAc(id) {
      this.seciliRehberYorumId = id;
      this.$bvModal.show("rehberYorumDetay");
    },
  },
};
</script>
