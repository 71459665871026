<template>
  <b-row align-h="center">
    <div class="col-md-6" v-if="lokasyon != null">
      <GmapMap :center="{ lat: lokasyon.Data.lat, lng: lokasyon.Data.lon }" :zoom="16"
        style="width: auto; height: 500px">
        <GmapMarker ref="myMarker" :position="google && new google.maps.LatLng(lokasyon.Data.lat, lokasyon.Data.lon)"
          :clickable="true" />
      </GmapMap>
    </div>
    <div class="col-md-6">
      <table class="product-page-width">
        <tbody>
          <tr v-if="mutationTransfer.Detay.Sofor.Resimler != null && mutationTransfer.Detay.Sofor.Resimler.length > 0">
            <td colspan="2" class="text-center">
              <b-carousel :interval="4000" controls indicators background="#ababab" style="
                              width: 350px !important;
                              text-shadow: 1px 1px 2px #333;
                            ">
                <b-carousel-slide v-for="(item, index) in mutationTransfer.Detay.Sofor.Resimler" :key="index"
                  :img-src="item"></b-carousel-slide>
              </b-carousel>
            </td>
          </tr>
          <tr v-else>
            <td colspan="2" class="text-center">
              <img :src="mutationTransfer.Detay.Sofor.Resim" class="img-100 rounded-circle" />
            </td>
          </tr>
          <tr>
            <th style="width:40%">Plaka</th>
            <td>{{ mutationTransfer.Detay.Sofor.Plaka }}</td>
          </tr>
          <tr>
            <th>Şoför Adı</th>
            <td>{{ mutationTransfer.Detay.Sofor.AdSoyad }}</td>
          </tr>
          <tr>
            <th>Şoför Telefonu</th>
            <td><a :href="'tel:' + mutationTransfer.Detay.Sofor.GSM">{{ mutationTransfer.Detay.Sofor.GSM }}</a></td>
          </tr>
          <tr>
            <th>Marka</th>
            <td>{{ mutationTransfer.Detay.Sofor.Marka }}</td>
          </tr>
          <tr>
            <th>Model</th>
            <td>{{ mutationTransfer.Detay.Sofor.Model }}</td>
          </tr>
          <tr>
            <th>Araç Takip Sistemi</th>
            <td><c-aktif-cell :isAktif="mutationTransfer.Detay.Sofor.isAracTakip"></c-aktif-cell></td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-row>
</template>
<script>
import { gmapApi } from 'vue2-google-maps'

export default {
  props: {
    transfer: null
  },
  data() {
    return {
      mutationTransfer: this.transfer,
      lokasyon: null
    };
  },
  components: {
  },
  computed: {
    google: gmapApi
  },
  mounted() {
    this.onLokasyon();
  },
  methods: {
    onLokasyon() {
      let vm = this;

      this.$connection.get(
        "/laalTransferler/lokasyon/" + this.mutationTransfer.Id,
        function (response) {
          if (response.Success) {
            vm.lokasyon = response;
          }
        },
        function (error) {

        }
      );
    },
  },
};
</script>