<template>
  <div>
    <Breadcrumbs main="Kullanıcılar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Kullanıcı Kaydı</h5>
              <span>Yeni kullanıcı veya kullanıcı kaydında değişiklikler için
                aşağıdaki formu kullanabilirsiniz</span>
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_adsoyad">Ad Soyad</label>
                    <b-form-input type="text" id="kayit_adsoyad" v-model="kayit.AdSoyad" :state="kayit_adsoyad_state"
                      placeholder="Adı Soyadı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adsoyad_state">Ad soyadı giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tipi">Kullanıcı Tipi</label>
                    <b-form-select id="kayit_tipi" v-model="kayit.Tipi" :options="kayit_tipi_options"
                      :state="kayit_tipi_state"></b-form-select>
                    <b-form-invalid-feedback :state="kayit_tipi_state">Kullanıcı tipini
                      seçiniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_durum">Durum</label>
                    <b-form-select id="kayit_durum" v-model="kayit.Durum"
                      :options="kayit_durum_options"></b-form-select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_gsm">Cep Telefonu</label>
                    <b-form-input type="text" id="kayit_gsm" v-model="kayit.GSM" v-mask="'5## ### ## ##'"
                      placeholder="Cep Telefonu" :state="kayit_gsm_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_gsm_state">Cep telefonunu giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_email">Email</label>
                    <b-form-input type="text" id="kayit_email" v-model="kayit.Email" placeholder="Email"
                      :state="kayit_email_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_email_state">Email adresini giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
                    <b-form-input type="text" id="kayit_kullaniciadi" v-model="kayit.KullaniciAdi"
                      placeholder="Kullanıcı Adı" :state="kayit_kullaniciadi_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_kullaniciadi_state">Ana mekan kullanıcısının kullanıcı adını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sifre">Şifre</label>
                    <b-form-input type="text" id="kayit_sifre" v-model="kayit.Sifre" placeholder="Şifre"
                      :state="kayit_sifre_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sifre_state">Ana mekan kullanıcısı için 8 ile 20 karakter
                      arasında
                      rakam, küçük ve büyük harf bulunan bir şifre
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <router-link :to="'/kullanici/list'" class="btn btn-light pull-left">Geri Dön</router-link>
                <b-button type="submit" class="pull-right" variant="primary">Kaydet</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Kullanıcı",

      breadLinks: [
        {
          path: "/kullanici/list",
          title: "Kullanıcılar",
          icon: "chevron-left",
        },
      ],

      kayit: {
        Tipi: "0",
        AdSoyad: "",
        Durum: 0,
        Email: "",
        GSM: "",
        KullaniciAdi: "",
        Sifre: "",
      },

      kayit_tipi_options: [
        {
          value: "0",
          text: "Seçiniz",
        },
      ],

      kayit_durum_options: [
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],
    };
  },
  mounted() {
    this.onTipler();

    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Kullanıcı Düzelt";

      this.$connection.get("/kullanicilar/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle(
            "Yeni Kullanıcı",
            response.Message,
            "error"
          );
        }
      });
    }
  },
  computed: {
    kayit_tipi_state() {
      if (this.kayit.Tipi == null || this.kayit.Tipi == "0") return false;
      else return true;
    },
    kayit_adsoyad_state() {
      if (this.kayit.AdSoyad == null || this.kayit.AdSoyad == "") return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
    kayit_kullaniciadi_state() {
      if (
        this.id === undefined &&
        (this.kayit.KullaniciAdi === null || this.kayit.KullaniciAdi === "")
      )
        return false;
      else return true;
    },
    kayit_sifre_state() {
      if (
        this.id === undefined &&
        !this.$validationHelper.validPassword(this.kayit.Sifre)
      )
        return false;
      else return true;
    },
  },
  methods: {
    onTipler() {
      let vm = this;

      this.$connection.get(
        "/kullanicilar/KullaniciTipleri",
        function (response) {
          vm.kayit_tipi_options = vm.kayit_tipi_options.concat(response);
        }
      );
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_tipi_state &&
        this.kayit_adsoyad_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state;

      if (isValid) {
        let vm = this;

        let url = "/kullanicilar";
        let method = "post";
        let messageTitle = "Yeni Kullanıcı";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Kullanıcı Düzelt";

          vm.kayit.SifreYeni = vm.kayit.Sifre;
        }

        vm.kayit.AcenteId = 0;
        vm.kayit.RehberId = 0;
        vm.kayit.AracFirmaId = 0;
        vm.kayit.AracId = 0;
        vm.kayit.MekanId = 0;
        vm.kayit.RestoranId = 0;

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/kullanici/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
