<template>
  <div>
    <img class="img-fluid" v-for="banner in bannerlar" :key="banner.Id" :src="banner.Gorsel" :alt="banner.Baslik" />
  </div>
</template>
<script>
export default {
  props: {
    bannerYeri: 0,
  },
  data() {
    return {
      bannerlar: []
    };
  },
  mounted() {
    this.bannerYukle();
  },
  components: {},
  methods: {
    bannerYukle() {
      let vm = this;

      this.$connection.get("/bannerlar/all/" + this.bannerYeri, function (response) {
        vm.bannerlar = response.filter(x => { return x.Aktif === true });
      });
    },
  },
};
</script>
