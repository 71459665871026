<template>
  <div>
    <div class="row">
      <div class="col-md-9">
        <Breadcrumbs main="Rehber" title="Kontrol Paneli" />
      </div>
      <div class="col-md-3 text-right mt-4 pr-5">
        <Ipucu tip="rehber" />
      </div>
    </div>

    <div class="container-fluid">
      <RehberYorumSavunma />

      <div class="row">
        <div class="col-xl-6" v-if="profilDurumu !== ''">
          <b-alert show variant="danger">
            <h4 class="alert-heading">Profil Durumunuz</h4>
            <p>
              Profilinizde bazı eksiklikler bulduk. Bunları doldurduğunuzda
              rezervasyon almanız ve işlemleriniz daha kolaylaşacaktır.
            </p>
            <hr />
            <p v-html="profilDurumu" class="mb-3"></p>
            <p>
              <router-link to="/rehber/profil" class="btn btn-dark">Güncelle</router-link>
            </p>
          </b-alert>
        </div>
        <div class="col-xl-6">
          <b-alert show variant="warning">
            <h4 class="alert-heading">Rehber Ücreti</h4>
            <p>
              Aşağıdaki butona tıklayarak rehberlik ücretinizi istediğiniz zaman güncelleyebilirsiniz.
            </p>
            <p>
              <strong>Fiyatı UYGUN olan rehberlerin İLK SIRALARDA listeleneceğini Unutmayınız</strong>
            </p>
            <hr />
            <p>
              <router-link to="/rehber/profil?tab=dil" class="btn btn-dark">Dil ve Fiyatları Güncelle</router-link>
            </p>
          </b-alert>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <b-form @submit="onFiltreSubmit" class="pull-right">
            <b-input-group prepend="Tarih">
              <template #prepend>
                <b-button @click="gun(-1)" variant="light"><i class="fa fa-angle-left"></i></b-button>
                <b-button @click="gun(+1)" variant="light"><i class="fa fa-angle-right"></i></b-button>
              </template>
              <datepicker input-class="datepicker-here form-control digits" v-model="filtre.Tarih" :language="tr"
                placeholder="Başlangıç Tarihi" :format="date_format">
              </datepicker>
            </b-input-group>
          </b-form>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-rez" role="tablist">
            <li class="nav-item" v-on:click="changetabRez('turRez')">
              <a class="nav-link" :class="[
        { 'show active': showtabRez == 'turRez' }
      ]" id="top-turrez" data-toggle="tab" role="tab" aria-controls="turRez" aria-selected="true">TUR
                REZERVASYONLARI <b-badge variant="primary">{{ turRez_sayisi }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetabRez('regularRez')">
              <a class="nav-link" :class="[
        { 'show active': showtabRez == 'regularRez' }
      ]" id="top-regularrez" data-toggle="tab" role="tab" aria-controls="regularRez" aria-selected="true">REGULAR
                REZERVASYONLAR <b-badge variant="primary">{{ regularRez_sayisi }}</b-badge></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content" id="top-tabRezContent">
        <div class="tab-pane fade" :class="{ 'show active': showtabRez == 'turRez' }" id="turRez" role="tabpanel"
          aria-labelledby="turRez">
          <TurRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null" @onKayitSayisi="onTurRezSayisi" />
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtabRez == 'regularRez' }" id="regularRez"
          role="tabpanel" aria-labelledby="regularRez">
          <RegularRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null"
            @onKayitSayisi="onRegularRezSayisi" />
        </div>
      </div>
    </div>

    <DuyuruGosterimModal />
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import TurRezervasyonList from "@/components/c_tur_rezervasyon_dashboard_rehber";
import RegularRezervasyonList from "@/components/c_regular_rezervasyon_dashboard_rehber";
import DuyuruGosterimModal from "@/components/c_duyuru_gosterim";
import DuyuruGosterimSurekliModal from "@/components/c_duyuru_gosterim_surekli";
import RehberYorumSavunma from "@/components/c_rehber_yorum_savunma";
import Ipucu from "@/components/c_ipucu";

export default {
  data() {
    return {
      profilDurumu: "",
      showtabRez: "turRez",

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre: {
        Tarih: null,
      },

      turRez_sayisi: 0,
      regularRez_sayisi: 0
    };
  },
  components: {
    Datepicker,
    TurRezervasyonList,
    RegularRezervasyonList,
    DuyuruGosterimModal,
    DuyuruGosterimSurekliModal,
    RehberYorumSavunma,
    Ipucu
  },
  mounted() {
    let user = this.$user.getUser();
    if (user.Kullanici.RedirectUrl !== "/rehber/takvim")
      this.$router.push(user.Kullanici.RedirectUrl);

    this.profilDurumLoad();

    let bugun = new Date();
    this.filtre.Tarih = bugun;
  },
  methods: {
    profilDurumLoad() {
      let vm = this;
      this.$connection.get("/rehberler/profilDurum", function (response) {
        vm.profilDurumu = response;
      });
    },
    onFiltreSubmit(target) {
      target.preventDefault();
    },
    gun(sayi) {
      this.filtre.Tarih = this.$formatHelper.addDay(
        new Date(this.filtre.Tarih),
        sayi
      );
    },
    onTurRezSayisi(sayi) {
      this.turRez_sayisi = sayi;
    },
    onRegularRezSayisi(sayi) {
      this.regularRez_sayisi = sayi;
    },
    changetabRez(tab) {
      this.showtabRez = tab;
    },
  },
};
</script>
