<template>
  <div>
    <b-form @submit="onFiltreSubmit" inline class="mb-3">
      <label class="sr-only" for="filtremekan_kategori">Kategori</label>
      <b-form-select
        id="filtremekan_kategori"
        v-model="filtre.KategoriId"
        :options="kategoriler"
        class="mb-2 mr-sm-2 mb-sm-0"
      ></b-form-select>

      <label class="sr-only" for="filtremekan_kelime">Kelime</label>
      <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
        <b-form-input
          id="filtremekan_kelime"
          v-model="filtre.Kelime"
          placeholder="Aranacak Kelime"
        ></b-form-input>
      </b-input-group>

      <b-button type="submit" variant="primary">Listele</b-button>
    </b-form>

    <b-table striped hover :fields="mekanFields" :items="mekanlar">
      <template #cell(Islem)="row">
        <b-btn-group>
          <b-button
            size="xs"
            @click="ekle(row.item)"
            v-if="row.item.AcenteMakan === null"
            variant="success"
            >Ekle</b-button
          >
          <b-button
            size="xs"
            @click="kaldir(row.item)"
            v-if="row.item.AcenteMakan !== null"
            variant="danger"
            >Kaldır</b-button
          >
        </b-btn-group>
      </template>
      <template #cell(Anlasmali)="row">
        <b-badge variant="success" v-if="row.item.AcenteMakan !== null"
          >Anlaşmalı Kurum</b-badge
        >
        <b-badge variant="light" v-if="row.item.AcenteMakan === null"
          >Anlaşma Yok</b-badge
        >
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  props: {
    acenteId: "",
    mekanlari: [],
  },
  data() {
    return {
      mekanFields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "KategoriAdi", label: "Kategori", sortable: true },
        { key: "Adi", label: "Mekan Adı", sortable: true },
        { key: "Anlasmali", label: "Anlaşmalı Kurum", sortable: true },
      ],

      mekanlar: [],

      filtre: {
        KategoriId: null,
        Kelime: null,
      },

      kategoriler: [{ value: null, text: "Hepsi" }],
    };
  },
  mounted() {
    this.onKategoriList();
  },
  watch: {
    mekanlari(newValue, oldValue) {
      this.onList();
    },
  },
  methods: {
    onFiltreSubmit(evt) {
      evt.preventDefault();
      this.onList();
    },
    onKategoriList() {
      let vm = this;

      this.$connection.get("/kategoriler/select/1", function (response) {
        vm.kategoriler = vm.kategoriler.concat(response);
      });
    },
    onList() {
      let vm = this;

      this.$connection.post(
        "/mekanlar/selectFiltre",
        vm.filtre,
        "post",
        function (response) {
          vm.mekanlar = response.map((item) => {
            return {
              ...item,
              AcenteMakan: vm.anlasmaliMi(item),
              KomOran: 0,
            };
          });

          for (let i = 0; i < vm.mekanlar.length; i++) {
            const element = vm.mekanlar[i];
            if (element.AcenteMakan != null) {
              element.KomOran = element.AcenteMakan.KomisyonOrani;
            } else {
              element.KomOran = element.KomisyonOrani;
            }
          }
        }
      );
    },
    kaldir(item) {
      if (item.AcenteMakan === null) {
        this.$alert.messageWithTitle(
          "Acente Makanı",
          "Bu mekan ile anlaşma bulunmuyor.",
          "warning"
        );
        return;
      }

      let vm = this;
      vm.$connection.delete(
        "/acenteler/" + vm.acenteId + "/mekanlar/" + item.Id,
        function (response) {
          item.AcenteMakan = null;
          item.KomOran = item.KomisyonOrani;
          vm.$emit("onGuncellendi");
        }
      );
    },
    ekle(item) {
      let vm = this;

      let form = {
        MekanId: item.EncId,
        KomisyonOrani: item.KomOran,
      };

      vm.$connection.post(
        "/acenteler/" + vm.acenteId + "/mekanlar",
        form,
        "post",
        function (response) {
          if (response.Success) {
            item.AcenteMakan = {
              KomisyonOrani: item.KomOran,
            };
            vm.$emit("onGuncellendi");
          }
        },
        function (error) {
          vm.$alert.messageWithTitle("Mekan Kaydet", error.Message, "error");
        }
      );
    },
    mekanKaydet(item) {
      if (item.KomOran <= 0) {
        this.$alert.messageWithTitle(
          "Acente Makanı",
          "Komisyon oranını sıfırdan büyük giriniz.",
          "warning"
        );
        return;
      }

      let vm = this;

      let form = {
        MekanId: item.EncId,
        KomisyonOrani: item.KomOran,
      };

      vm.$connection.post(
        "/acenteler/" + vm.acenteId + "/mekanlar",
        form,
        "post",
        function (response) {
          if (response.Success) {
            item.AcenteMakan = {
              KomisyonOrani: item.KomOran,
            };
            vm.$emit("onGuncellendi");
          }
        },
        function (error) {
          vm.$alert.messageWithTitle("Mekan Kaydet", error.Message, "error");
        }
      );
    },
    anlasmaliMi: function (mekan) {
      var acenteMekanlari = this.mekanlari.filter((item) => {
        return item.MekanId === mekan.Id;
      });

      if (acenteMekanlari.length > 0) return acenteMekanlari[0];
      else return null;
    },
  },
};
</script>
