<template>
  <div>
    <Breadcrumbs main="Mekanlar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Mekan Kaydı</h5>
              <span
                >Yeni mekan veya mekan kaydında değişiklikler için aşağıdaki
                formu kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Mekan Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_adi"
                      v-model="kayit.Adi"
                      :state="kayit_adi_state"
                      placeholder="Mekan Adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adi_state"
                      >Mekan adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Unvan</label>
                    <b-form-input
                      type="text"
                      id="kayit_unvan"
                      v-model="kayit.Unvan"
                      :state="kayit_unvan_state"
                      placeholder="Mekan Unvanı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_unvan_state"
                      >Mekan unvanını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Adres</label>
                    <b-form-textarea
                      type="text"
                      id="kayit_adres"
                      v-model="kayit.Adres"
                      :state="kayit_adres_state"
                      placeholder="Mekan Adresi"
                    ></b-form-textarea>
                    <b-form-invalid-feedback :state="kayit_adres_state"
                      >Mekanın adresini giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_resim">Resim</label>
                    <div class="row">
                      <div class="col-md-9">
                        <b-form-file
                          class="form-control"
                          v-model="kayit.ResimFl"
                          plain
                        ></b-form-file>
                      </div>
                      <div class="col-md-3">
                        <div
                          class="avatar"
                          v-if="kayit.Resim !== '' && kayit.Resim !== undefined"
                        >
                          <img
                            class="img-40 rounded-circle"
                            :src="kayit.Resim"
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Konum X</label>
                    <b-form-input
                      type="text"
                      id="kayit_konumx"
                      v-model="kayit.KonumX"
                      :state="kayit_konumx_state"
                      placeholder="Mekan Enlemini"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_konumx_state"
                      >Mekan konumunun enlemini giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Konum Y</label>
                    <b-form-input
                      type="text"
                      id="kayit_konumy"
                      v-model="kayit.KonumY"
                      :state="kayit_konumy_state"
                      placeholder="Mekan Boylamını"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_konumy_state"
                      >Mekan konumunun boylamını
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Vergi Dairesi</label>
                    <b-form-input
                      type="text"
                      id="kayit_vergidairesi"
                      v-model="kayit.VergiDairesi"
                      placeholder="Vergi Dairesi"
                    ></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Vergi No</label>
                    <b-form-input
                      type="text"
                      id="kayit_vergiNo"
                      v-model="kayit.VergiNo"
                      :state="kayit_vergino_state"
                      placeholder="T.C. Kimlik No veya Vergi No"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_vergino_state"
                      >Bireyselse 11 haneli T.C. kimlik numarası kurumsal ise 10
                      haneli vergi numarasını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Yetkili Kişi</label>
                    <b-form-input
                      type="text"
                      id="kayit_yetkilikisi"
                      v-model="kayit.YetkiliKisi"
                      :state="kayit_yetkilikisi_state"
                      placeholder="Yetkili Kişi"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_yetkilikisi_state"
                      >Yetkili kişi adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_kategoriidpt">Kategori</label>
                    <b-form-select
                      id="kayit_kategoriidpt"
                      v-model="kayit.KategoriId"
                      :options="kayit_kategoriId_options"
                      :state="kayit_kategoriId_state"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="kayit_kategoriId_state"
                      >Kategori seçiniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_gsm">Cep Telefonu</label>
                    <b-form-input
                      type="text"
                      id="kayit_gsm"
                      v-model="kayit.GSM"
                      v-mask="'5## ### ## ##'"
                      placeholder="Cep Telefonu"
                      :state="kayit_gsm_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_gsm_state"
                      >Cep telefonunu giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_telefon">Telefon</label>
                    <b-form-input
                      type="text"
                      id="kayit_telefon"
                      v-model="kayit.Telefon"
                      placeholder="Telefon"
                    ></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_email">Email</label>
                    <b-form-input
                      type="text"
                      id="kayit_email"
                      v-model="kayit.Email"
                      placeholder="Email"
                      :state="kayit_email_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_email_state"
                      >Email adresini giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-3 mb-3">
                    <label for="kayit_tipi">Komisyon Oranı</label>
                    <b-form-input
                      type="text"
                      id="kayit_komisyonorani"
                      v-model="kayit.KomisyonOrani"
                      :state="kayit_komisyonorani_state"
                      placeholder="Komisyon Oranı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_komisyonorani_state"
                      >0 ile 100 arasında bir komisyon oranı
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-3 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox
                        name="Aktif"
                        v-model="kayit.Aktif"
                        :state="kayit.Aktif"
                        >Aktif</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <div class="form-row" v-if="id === undefined">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_kullaniciadi"
                      v-model="kayit.KullaniciAdi"
                      placeholder="Kullanıcı Adı"
                      :state="kayit_kullaniciadi_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_kullaniciadi_state"
                      >Ana mekan kullanıcısının kullanıcı adını
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sifre">Şifre</label>
                    <b-form-input
                      type="text"
                      id="kayit_sifre"
                      v-model="kayit.Sifre"
                      placeholder="Şifre"
                      :state="kayit_sifre_state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sifre_state"
                      >Ana mekan kullanıcısı için 8 ile 20 karakter arasında
                      rakam, küçük ve büyük harf bulunan bir şifre
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <router-link :to="'/mekan/list'" class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Mekan",

      breadLinks: [
        { path: "/Mekan/list", title: "Mekanlar", icon: "chevron-left" },
      ],

      kayit: {
        Adi: "",
        Unvan: "",
        YetkiliKisi: "",
        Adres: "",
        VergiDairesi: "",
        VergiNo: "",
        KonumX: "",
        KonumY: "",
        KategoriId: 0,
        Telefon: "",
        GSM: "",
        Email: "",
        KomisyonOrani: 0,
        Aktif: true,
        ResimFl: null,
        KullaniciAdi: "",
        Sifre: "",
      },

      kayit_kategoriId_options: [{ value: "0", text: "Kategori" }],
    };
  },
  components: {
    VueEditor,
  },
  mounted() {
    let vm = this;

    this.onKategoriList();

    if (this.id !== undefined) {
      vm.pageTitle = "Mekan Düzelt";

      this.$connection.get("/Mekanlar/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle("Yeni Mekan", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_adi_state() {
      if (this.kayit.Adi == null || this.kayit.Adi == "") return false;
      else return true;
    },
    kayit_unvan_state() {
      if (this.kayit.Unvan == null || this.kayit.Unvan == "") return false;
      else return true;
    },
    kayit_yetkilikisi_state() {
      if (this.kayit.YetkiliKisi == null || this.kayit.YetkiliKisi == "")
        return false;
      else return true;
    },
    kayit_adres_state() {
      if (this.kayit.Adres == null || this.kayit.Adres == "") return false;
      else return true;
    },
    kayit_konumx_state() {
      // if (this.kayit.KonumX == null || this.kayit.KonumX == '')
      //   return false;
      // else
      return true;
    },
    kayit_konumy_state() {
      // if (this.kayit.KonumY == null || this.kayit.KonumY == '')
      //   return false;
      // else
      return true;
    },
    kayit_vergino_state() {
      // if (this.kayit.VergiNo == null || this.kayit.VergiNo == '' || this.kayit.VergiNo.length < 10 || this.kayit.VergiNo.length > 11)
      //   return false;
      // else
      return true;
    },
    kayit_kategoriId_state() {
      if (this.kayit.KategoriId == "0") return false;
      else return true;
    },
    kayit_komisyonorani_state() {
      if (
        this.kayit.KomisyonOrani === "" ||
        parseInt(this.kayit.KomisyonOrani) < 0 ||
        parseInt(this.kayit.Orani) > 0
      )
        return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
    kayit_kullaniciadi_state() {
      if (
        this.id === undefined &&
        (this.kayit.KullaniciAdi === null || this.kayit.KullaniciAdi === "")
      )
        return false;
      else return true;
    },
    kayit_sifre_state() {
      if (
        this.id === undefined &&
        !this.$validationHelper.validPassword(this.kayit.Sifre)
      )
        return false;
      else return true;
    },
  },
  methods: {
    onKategoriList() {
      let vm = this;

      this.$connection.get("/kategoriler/select/1", function (response) {
        vm.kayit_kategoriId_options =
          vm.kayit_kategoriId_options.concat(response);
      });
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adi_state &&
        this.kayit_unvan_state &&
        this.kayit_yetkilikisi_state &&
        this.kayit_adres_state &&
        this.kayit_kategoriId_state &&
        this.kayit_komisyonorani_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state &&
        this.kayit_konumx_state &&
        this.kayit_konumy_state &&
        this.kayit_vergino_state;

      if (isValid) {
        let vm = this;

        let url = "/Mekanlar";
        let method = "post";
        let messageTitle = "Yeni Mekan";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Mekan Düzelt";
        }

        vm.$connection.postData(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/Mekan/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
