<template>
  <div>
    <Breadcrumbs main="Bannerlar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Banner Kaydı</h5>
              <span>Yeni banner veya banner kaydında değişiklikler için aşağıdaki
                formu kullanabilirsiniz</span>
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tipi">Banner Tipi</label>
                    <b-form-select id="kayit_tipi" v-model="kayit.Tipi" :options="kayit_tipi_options"
                      :state="kayit_tipi_state"></b-form-select>
                    <b-form-invalid-feedback :state="kayit_tipi_state">Banner tipini seçiniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_baslik">Banner Adı</label>
                    <b-form-input type="text" id="kayit_baslik" v-model="kayit.Baslik" :state="kayit_baslik_state"
                      placeholder="Başlık"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_baslik_state">Banner adını giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_ozet">Detay</label>
                    <ckeditor :editor="editor" v-model="kayit.Ozet"></ckeditor>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_gorsel">Banner</label>
                    <div class="row">
                      <div class="col-md-9">
                        <b-form-file class="form-control" :state="kayit_gorsel_state" v-model="kayit.GorselFl"
                          plain></b-form-file>
                        <b-form-invalid-feedback :state="kayit_gorsel_state">Banner görselini
                          seçiniz</b-form-invalid-feedback>
                      </div>
                      <div class="col-md-3">
                        <div class="avatar" v-if="kayit.Gorsel !== '' && kayit.Gorsel !== undefined
      ">
                          <img class="img-40 rounded-circle" :src="kayit.Gorsel" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_link">Link</label>
                    <b-form-input type="text" id="kayit_link" v-model="kayit.Link" placeholder="Link"></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_yayin_giris_tarihi">Yayın Giriş Tarihi (Boş bırakılırsa sürekli yayında
                      olur)</label>
                    <datepicker input-class="datepicker-here form-control digits" id="kayit_yayin_giris_tarihi"
                      v-model="kayit.YayinGirisTarihi" :language="tr" :clear-button="true"
                      clear-button-icon="fa fa-times" placeholder="Yayın Giriş Tarihi" :format="date_format">
                    </datepicker>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_yayin_cikis_tarihi">Yayın Bitiş Tarihi</label>
                    <datepicker input-class="datepicker-here form-control digits" id="kayit_yayin_cikis_tarihi"
                      v-model="kayit.YayinCikisTarihi" :language="tr" :clear-button="true"
                      clear-button-icon="fa fa-times" placeholder="Yayın Bitiş Tarihi" :format="date_format">
                    </datepicker>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <b-form-input type="text" id="kayit_sira" v-model="kayit.Sira" :state="kayit_sira_state"
                      placeholder="Banner sırası"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sira_state">Banner sırasına 0'dan büyük bir rakam
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox name="Aktif" v-model="kayit.Aktif" :state="kayit.Aktif">Aktif</b-form-checkbox>
                    </div>
                  </div>
                </div>
                <router-link :to="'/banner/list'" class="btn btn-light pull-left">Geri Dön</router-link>
                <b-button type="submit" class="pull-right" variant="primary">Kaydet</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Banner",

      breadLinks: [
        { path: "/banner/list", title: "Bannerlar", icon: "chevron-left" },
      ],

      date_format: "dd.MM.yyyy",
      tr: tr,

      kayit: {
        Tipi: 0,
        Baslik: "",
        Link: "",
        Ozet: "",
        GorselFl: null,
        Aktif: true,
        YayinGirisTarihi: null,
        YayinCikisTarihi: null,
        Sira: 0,
      },

      kayit_tipi_options: [{ value: "0", text: "Seçiniz" }],

      editor: ClassicEditor,
    };
  },
  components: {
    Datepicker
  },
  mounted() {
    this.onTipler();

    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Banner Düzelt";

      this.$connection.get("/bannerlar/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle("Yeni Banner", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_tipi_state() {
      if (this.kayit.Tipi == 0) return false;
      else return true;
    },
    kayit_baslik_state() {
      if (this.kayit.Baslik == null || this.kayit.Baslik == "") return false;
      else return true;
    },
    kayit_gorsel_state() {
      if (this.id === undefined && this.kayit.GorselFl == null) return false;
      else return true;
    },
    kayit_sira_state() {
      if (this.kayit.Sira === "" || this.kayit.Sira === null) return false;
      else return true;
    },
  },
  methods: {
    onTipler() {
      let vm = this;

      this.$connection.get("/bannerlar/BannerTipleri", function (response) {
        vm.kayit_tipi_options = vm.kayit_tipi_options.concat(response);
      });
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_tipi_state &&
        this.kayit_baslik_state &&
        this.kayit_gorsel_state &&
        this.kayit_sira_state;

      if (isValid) {
        let vm = this;

        let url = "/bannerlar";
        let method = "post";
        let messageTitle = "Yeni Banner";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Banner Düzelt";
        }

        vm.kayit.YayinGirisTarihi = vm.kayit.YayinGirisTarihi != null ? this.$moment(String(vm.kayit.YayinGirisTarihi)).format('DD/MM/YYYY') : null;
        vm.kayit.YayinCikisTarihi = vm.kayit.YayinCikisTarihi != null ? this.$moment(String(vm.kayit.YayinCikisTarihi)).format('DD/MM/YYYY') : null;

        vm.$connection.postData(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/banner/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
