<template>
  <div>
    <Breadcrumbs main="Müzeler" title="Liste" />

    <div class="container-fluid">
      <div class="product-wrapper-grid">
        <div class="row">
          <div
            class="col-lg-3 col-md-4"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="card">
              <div class="product-box">
                <div class="product-img">
                  <img class="img-fluid" :src="item.Resim" :alt="item.Tanim" />
                  <div class="product-hover">
                    <ul>
                      <li>
                        <button
                          class="btn"
                          @click="quickView(item)"
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                        >
                          <i class="icon-eye"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="product-details">
                  <h4>{{ item.Tanim }}</h4>
                  <p
                    v-html="
                      item.Detay.length > 10
                        ? item.Detay.substring(0, 10) + '...'
                        : item.Detay
                    "
                  ></p>
                  <div class="product-price">
                    <c-money-cell
                      :fiyat="item.GirisUcreti"
                      :simge="item.Simge"
                    ></c-money-cell>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal size="lg" v-model="modalShow" hide-footer hide-header>
        <button
          class="close"
          type="button"
          v-on:click="quickViewClose(modalShow)"
        >
          <span>×</span>
        </button>
        <div class="product-box quickview row">
          <div class="product-img col-md-6">
            <img class="img-fluid" :src="muze.Resim" :alt="muze.Tanim" />
          </div>
          <div class="product-details col-md-6 text-left">
            <h1>{{ muze.Tanim }}</h1>
            <div class="product-price">
              Kişi Başı :
              <c-money-cell
                :fiyat="muze.GirisUcreti"
                :simge="muze.Simge"
              ></c-money-cell>
            </div>
            <div class="product-view">
              <h6 class="f-w-600">Müze Detayları</h6>
              <p class="mb-0" v-html="muze.Detay"></p>
            </div>
          </div>
          <div class="col-md-12">
            <ul
              class="nav nav-tabs border-tab mb-0"
              id="top-tab"
              role="tablist"
            >
              <li class="nav-item" @click="tabContent('calismaSaat')">
                <a
                  class="nav-link"
                  id="profile-top-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="top-calismasaat"
                  aria-selected="false"
                  :class="[{ active: activeTab == 'calismaSaat' }]"
                  >Çalışma Saatleri</a
                >
                <div class="material-border"></div>
              </li>
              <li class="nav-item" @click="tabContent('indirim')">
                <a
                  class="nav-link"
                  id="top-home-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="top-home"
                  aria-selected="false"
                  :class="[{ active: activeTab == 'indirim' }]"
                  >Fiyatlandırma</a
                >
                <div class="material-border"></div>
              </li>
            </ul>
            <div class="tab-content" id="top-tabContent">
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab == 'calismaSaat' }"
                id="top-calismasaat"
                role="tabpanel"
                aria-labelledby="calismasaat-top-tab"
              >
                <b-table striped hover :items="muze.CalismaSaatleri">
                  <template #cell(Acik)="data">
                    <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                  </template>
                </b-table>
              </div>
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeTab == 'indirim' }"
                id="top-indirim"
                role="tabpanel"
                aria-labelledby="top-indirim-tab"
              >
                <b-table
                  striped
                  hover
                  :fields="indirimFields"
                  :items="muze.Indirimler"
                >
                  <template #cell(Oran)="data">
                    <c-money-cell :fiyat="data.value" simge="₺"></c-money-cell>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [],

      modalShow: false,
      activeTab: "calismaSaat",
      indirimFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "Oran", label: "Fiyat", sortable: false },
      ],
      muze: {},
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      this.$connection.get("/muzeler/detayListele", function (response) {
        vm.items = response;
      });
    },
    quickView: function (item) {
      this.modalShow = true;

      let vm = this;

      this.$connection.get("/muzeler/" + item.Id, function (response) {
        if (response.Success) {
          vm.muze = response.Data;
          return vm.muze;
        }
      });
    },
    quickViewClose: function () {
      this.modalShow = false;
    },
    tabContent(val) {
      this.activeTab = val;
    },
  },
};
</script>
