<template>
  <div>
    <Breadcrumbs main="Rehberler" :title="pageTitle" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row" v-if="profilDurumu !== ''">
        <div class="col-xl-12">
          <b-alert show variant="danger">
            <h4 class="alert-heading">Profil Durumunuz</h4>
            <p>
              Profilinizde bazı eksiklikler bulduk. Bunları doldurduğunuzda
              rezervasyon almanız ve işlemleriniz daha kolaylaşacaktır.
            </p>
            <hr />
            <p v-html="profilDurumu" class="mb-3"></p>
          </b-alert>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
            <li class="nav-item" v-on:click="changetab('genel')">
              <a class="nav-link" :class="{ 'show active': showtab == 'genel' }" id="top-genel" data-toggle="tab"
                role="tab" aria-controls="genel" aria-selected="true">Genel <b-badge variant="info">{{ rehber.AdSoyad
                  }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetab('ozgecmis')">
              <a class="nav-link" :class="{ 'show active': showtab == 'ozgecmis' }" id="top-ozgecmis" data-toggle="tab"
                role="tab" aria-controls="ozgecmis" aria-selected="false">Özgeçmiş</a>
            </li>
            <li class="nav-item" v-on:click="changetab('dil')">
              <a class="nav-link" :class="{ 'show active': showtab == 'dil' }" id="top-dil" data-toggle="tab" role="tab"
                aria-controls="dil" aria-selected="false">Dil & Fiyat</a>
            </li>
            <li class="nav-item" v-on:click="changetab('takvim')">
              <a class="nav-link" :class="{ 'show active': showtab == 'takvim' }" id="top-takvim" data-toggle="tab"
                role="tab" aria-controls="takvim" aria-selected="false">Müsaitlik Takvimi</a>
            </li>
            <li class="nav-item" v-on:click="changetab('folyo')">
              <a class="nav-link" :class="{ 'show active': showtab == 'folyo' }" id="top-folyo" data-toggle="tab"
                role="tab" aria-controls="folyo" aria-selected="false">Folyo</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div class="tab-pane fade" :class="{ 'show active': showtab == 'genel' }" id="genel" role="tabpanel"
          aria-labelledby="genel">
          <div class="row">
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Bilgiler</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <td colspan="2" class="text-center">
                          <img class="img-80 mb-3" :src="rehber.Resim" alt="#" />
                        </td>
                      </tr>
                      <tr>
                        <th>Ad Soyad</th>
                        <td>{{ rehber.AdSoyad }}</td>
                      </tr>
                      <tr>
                        <th>Adres</th>
                        <td>{{ rehber.Adres }}</td>
                      </tr>
                      <tr>
                        <th>T.C. Kimlik No</th>
                        <td>{{ rehber.TCKimlikNo }}</td>
                      </tr>
                      <tr>
                        <th>Çalışma Kart No</th>
                        <td>{{ rehber.CalismaKartiNo }}</td>
                      </tr>
                      <tr>
                        <th>Ruhsatname No</th>
                        <td>{{ rehber.RuhsatName }}</td>
                      </tr>
                      <tr>
                        <th>Toplam Alacak</th>
                        <td>
                          <c-money-cell :fiyat="rehber.ToplamAlacak" simge="₺"></c-money-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Diller</th>
                        <td>
                          <b-list-group tag="ul">
                            <b-list-group-item v-for="dil in rehber.Dilleri" :key="dil.Id" tag="li"
                              class="d-flex justify-content-between align-items-center">
                              {{ dil.Tanim }}
                              <b-badge variant="primary" class="counter digits"><c-money-cell :fiyat="dil.AlisFiyati"
                                  simge="₺"></c-money-cell></b-badge>
                            </b-list-group-item>
                          </b-list-group>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-card header-tag="div" header-bg-variant="info" class="card-absolute">
                <h5 slot="header">Kayıt Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Kayıt Tarihi</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="rehber.KayitTarihi" :rowKey="rehber.Id"></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Güncelleme Tarihi</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="rehber.GuncelTarih" :rowKey="rehber.Id"></c-tarih-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-list-group class="mb-3">
                    <b-list-group-item v-b-modal.bilgiForm router-component-name="router-link">
                      <feather type="edit-3"></feather> Güncelle
                    </b-list-group-item>
                    <b-list-group-item v-b-modal.sifreForm router-component-name="router-link">
                      <feather type="lock"></feather> Şifre Güncelle
                    </b-list-group-item>
                    <b-list-group-item v-b-modal.resimForm router-component-name="router-link">
                      <feather type="image"></feather> Profil Resmi Güncelle
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>

              <b-card header-tag="div" header-bg-variant="warning" class="card-absolute">
                <h5 slot="header">İletişim Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Cep Telefonu</th>
                        <td>{{ rehber.GSM }}</td>
                      </tr>
                      <tr>
                        <th>2. Cep Telefonu</th>
                        <td>{{ rehber.Telefon }}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{{ rehber.Email }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
          </div>

          <b-modal id="bilgiForm" centered title="Bilgirimi Güncelle" @ok="bilgiKaydetSubmit" ok-title="Güncelle"
            cancel-title="Kapat" class="theme-modal">
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_adsoyad">Ad Soyad</label>
                <b-form-input id="kayit_adsoyad" v-model="kayitBilgi.AdSoyad"
                  :state="kayitbilgi_adsoyad_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_adsoyad_state">Adınızı ve soyadınızı
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_email">Email</label>
                <b-form-input id="kayit_email" v-model="kayitBilgi.Email"
                  :state="kayitbilgi_email_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_email_state">Geçerli bir email adresinizi
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_telefon">Cep Telefonu</label>
                <b-form-input type="text" id="kayit_gsm" v-model="kayitBilgi.GSM" v-mask="'5## ### ## ##'"
                  placeholder="Cep Telefonu" :state="kayitbilgi_gsm_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_gsm_state">Cep telefonunu giriniz</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_telefon">2. Cep Telefonu</label>
                <b-form-input id="kayit_telefon" v-model="kayitBilgi.Telefon"></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_adres">Adres</label>
                <b-form-textarea rows="3" id="kayit_adres" v-model="kayitBilgi.Adres" placeholder="Açık adresiniz"
                  :state="kayitbilgi_adres_state"></b-form-textarea>
                <b-form-invalid-feedback :state="kayitbilgi_adres_state">Açık adresinizi
                  giriniz</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_tckimlikno">T.C. Kimlik No</label>
                <b-form-input type="text" id="kayit_tckimlikno" v-model="kayitBilgi.TCKimlikNo"
                  placeholder="T.C. Kimlik Numaranız" :state="kayitbilgi_tckimlikno_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_tckimlikno_state">11 haneli T.C. kimlik numaranızı
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_ruhsatnameno">Ruhsatname No</label>
                <b-form-input type="text" id="kayit_ruhsatnameno" v-model="kayitBilgi.RuhsatName"
                  placeholder="Ruhsatname No" :state="kayitbilgi_ruhsatname_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_ruhsatname_state">Ruhsatname numaranızı
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_calismakartno">Çalışma Kartı No</label>
                <b-form-input type="text" id="kayit_calismakartno" v-model="kayitBilgi.CalismaKartiNo"
                  placeholder="Çalışma Kart No" :state="kayitbilgi_calismakartino_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_calismakartino_state">Çalışma kart numaranızı
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
          </b-modal>

          <b-modal id="sifreForm" centered title="Şifre Güncelle" @ok="sifreKaydetSubmit" ok-title="Güncelle"
            cancel-title="Kapat" class="theme-modal">
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
                <b-form-input id="kayit_kullaniciadi" v-model="kayitSifre.KullaniciAdi"
                  :state="kayitsifre_kullaniciadi_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitsifre_kullaniciadi_state">Kullanıcı adınızı
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_sifre">Şifre</label>
                <b-form-input id="kayit_sifre" v-model="kayitSifre.SifreYeni" :state="kayitsifre_sifre_state"
                  placeholder="Şifreniz boş ise güncellenmez."></b-form-input>
                <b-form-invalid-feedback :state="kayitsifre_sifre_state">En az 8 ile 20 karakter arasında rakam, küçük
                  ve büyük harf
                  bulunan bir şifre giriniz</b-form-invalid-feedback>
              </div>
            </div>
          </b-modal>

          <b-modal id="resimForm" centered title="Profil Resmi Güncelle" @ok="resimKaydetSubmit" ok-title="Güncelle"
            cancel-title="Kapat" class="theme-modal">
            <div class="form-group">
              <label class="col-form-label">Profil Resminiz</label>
              <KokartResim @onChange="onKokartChange" />
            </div>
          </b-modal>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'ozgecmis' }" id="ozgecmis" role="tabpanel"
          aria-labelledby="ozgecmis">
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Özgeçmiş</h5>
                <b-card-body class="p-1">
                  <div class="text-right mb-3">
                    <b-button variant="info" v-if="!isOzgecmisForm" @click="isOzgecmisForm = true">Özgeçmişi
                      Güncelle</b-button>
                  </div>
                  <div v-html="rehber.Ozgecmis" v-if="!isOzgecmisForm"></div>
                  <b-form @submit="ozgecmisKaydetSubmit" v-if="isOzgecmisForm">
                    <div class="form-group">
                      <label class="col-form-label">Özgeçmiş</label>
                      <ckeditor :editor="editor" v-model="kayitOzgecmis.Ozgecmis"></ckeditor>
                      <b-form-invalid-feedback :state="kayitozgecmis_ozgecmis_state">En az 100 karakterden oluşan
                        özgeçmişinizi
                        yazınız.</b-form-invalid-feedback>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 text-center">
                        <b-button variant="light" @click="isOzgecmisForm = false" class="mr-3">Kapat</b-button>
                        <b-button variant="success" type="submit">Güncelle</b-button>
                      </div>
                    </div>
                  </b-form>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'dil' }" id="dil" role="tabpanel"
          aria-labelledby="dil">
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Dil ve Fiyatlar</h5>

                <RehberDilProfil :dilleri="rehber.Dilleri" v-if="isDilLoad" :rehberId="rehber.Id"></RehberDilProfil>
              </b-card>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'takvim' }" id="takvim" role="tabpanel"
          aria-labelledby="takvim">
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Müsaitlik Takvimi</h5>
                <RehberTakvim :rehberId="rehber.Id" v-if="rehber.Id !== undefined" />
              </b-card>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'folyo' }" id="folyo" role="tabpanel"
          aria-labelledby="folyo">
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Folyo</h5>
                <b-card-body class="p-1">
                  <RehberFolyo :rehber="rehber" v-if="isFolyoLoad" :noEdit="true"></RehberFolyo>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RehberFolyo from "@/components/c_rehber_folyo";
import KokartResim from "@/components/c_kokart_resim.vue";
import RehberTakvim from "@/components/c_rehber_takvim.vue";
import RehberDilProfil from "@/components/c_rehber_dil_profil";

export default {
  data() {
    return {
      tab: this.$route.query.tab,

      pageTitle: "Rehber Profili",

      showtab: "genel",

      rehber: {},

      profilDurumu: "",

      isFolyoLoad: false,

      kayitBilgi: {
        AdSoyad: "",
        Email: "",
        Telefon: "",
        GSM: "",
        Adres: "",
        TCKimlikNo: "",
        CalismaKartiNo: "",
        RuhsatName: "",
      },

      kayitSifre: {
        AdSoyad: "",
        Email: "",
        GSM: "",
        KullaniciAdi: "",
        SifreYeni: "",
      },

      canvas: null,

      isOzgecmisForm: false,
      kayitOzgecmis: {
        Ozgecmis: "",
      },

      isDilLoad: false,

      diller: [],

      editor: ClassicEditor,
    };
  },
  components: {
    RehberFolyo,
    KokartResim,
    RehberTakvim,
    RehberDilProfil
  },
  computed: {
    kayitbilgi_adsoyad_state() {
      if (this.kayitBilgi.AdSoyad == null || this.kayitBilgi.AdSoyad == "")
        return false;
      else return true;
    },
    kayitbilgi_email_state() {
      if (!this.$validationHelper.validEmail(this.kayitBilgi.Email))
        return false;
      else return true;
    },
    kayitbilgi_adres_state() {
      if (this.kayitBilgi.Adres == null || this.kayitBilgi.Adres == "")
        return false;
      else return true;
    },
    kayitbilgi_tckimlikno_state() {
      if (
        this.kayitBilgi.TCKimlikNo == null ||
        this.kayitBilgi.TCKimlikNo == "" ||
        this.kayitBilgi.TCKimlikNo.length !== 11
      )
        return false;
      else return true;
    },
    kayitbilgi_ruhsatname_state() {
      if (
        this.kayitBilgi.RuhsatName == null ||
        this.kayitBilgi.RuhsatName == ""
      )
        return false;
      else return true;
    },
    kayitbilgi_calismakartino_state() {
      if (
        this.kayitBilgi.CalismaKartiNo == null ||
        this.kayitBilgi.CalismaKartiNo == ""
      )
        return false;
      else return true;
    },
    kayitbilgi_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayitBilgi.GSM)) return false;
      else return true;
    },
    kayitsifre_kullaniciadi_state() {
      if (
        this.kayitSifre.KullaniciAdi == null ||
        this.kayitSifre.KullaniciAdi == ""
      )
        return false;
      else return true;
    },
    kayitsifre_sifre_state() {
      if (
        this.kayitSifre.SifreYeni != null &&
        this.kayitSifre.SifreYeni != "" &&
        !this.$validationHelper.validPassword(this.kayitSifre.SifreYeni)
      )
        return false;
      else return true;
    },
    kayitozgecmis_ozgecmis_state() {
      if (
        this.kayitOzgecmis.Ozgecmis == null ||
        this.kayitOzgecmis.Ozgecmis == "" ||
        this.kayitOzgecmis.Ozgecmis.length < 100
      )
        return false;
      else return true;
    },
    kayit_resim_state() {
      if (this.canvas == null) return false;
      else return true;
    },
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;

      switch (tab) {
        case "folyo":
          if (this.isFolyoLoad === false) this.folyoList();
          break;

        case "dil":
          if (this.isDilLoad === false) this.isDilLoad = true;
          break;

        default:
          break;
      }
    },
    onDetay() {
      let vm = this;

      this.$connection.get("/rehberler/profil", function (response) {
        if (response.Success) {
          vm.rehber = response.Data;

          vm.kayitBilgi = {
            AdSoyad: response.Data.AdSoyad,
            Email: response.Data.Email,
            Telefon: response.Data.Telefon,
            GSM: response.Data.GSM,
            Adres: response.Data.Adres,
            TCKimlikNo: response.Data.TCKimlikNo,
            CalismaKartiNo: response.Data.CalismaKartiNo,
            RuhsatName: response.Data.RuhsatName,
          };

          vm.kayitSifre = {
            AdSoyad: response.Data.Kullanici.AdSoyad,
            Email: response.Data.Kullanici.Email,
            GSM: response.Data.Kullanici.GSM,
            KullaniciAdi: response.Data.Kullanici.KullaniciAdi,
            SifreYeni: "",
          };

          vm.kayitOzgecmis = {
            Ozgecmis: response.Data.Ozgecmis,
          };

          vm.profilDurumLoad();

          vm.isFolyoLoad = true;

          if (vm.tab != null) {
            vm.showtab = vm.tab
            vm.changetab(vm.showtab);
          }
        } else {
          vm.$alert.messageWithTitle("Rehber", response.Message, "error");
        }
      });
    },
    profilDurumLoad() {
      let vm = this;
      this.$connection.get("/rehberler/profilDurum", function (response) {
        vm.profilDurumu = response;
      });
    },
    bilgiKaydetSubmit(target) {
      target.preventDefault();

      let isValid =
        this.kayitbilgi_adsoyad_state &&
        this.kayitbilgi_email_state &&
        this.kayitbilgi_gsm_state &&
        this.kayitbilgi_adres_state &&
        this.kayitbilgi_tckimlikno_state &&
        this.kayitbilgi_ruhsatname_state &&
        this.kayitbilgi_calismakartino_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/rehberler/bilgi/" + this.rehber.Id,
          vm.kayitBilgi,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Bilgi Güncelle",
                response.Message,
                "success",
                function () {
                  vm.$bvModal.hide("bilgiForm");
                  vm.onDetay();
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Bilgi Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    },
    sifreKaydetSubmit(target) {
      target.preventDefault();

      let isValid =
        this.kayitsifre_kullaniciadi_state && this.kayitsifre_sifre_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/kullanicilar/forRehber/" + this.rehber.Id,
          vm.kayitSifre,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Bilgi Güncelle",
                response.Message,
                "success",
                function () {
                  vm.$bvModal.hide("sifreForm");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Bilgi Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    },
    onKokartChange(canvas) {
      this.canvas = canvas;
    },
    resimKaydetSubmit(evt) {
      evt.preventDefault();

      let isValid = this.kayit_resim_state;

      if (isValid) {
        let vm = this;

        if (this.canvas) {
          this.canvas.toBlob((blob) => {
            let kayit = { ResimFl: blob };

            vm.$connection.postData(
              "/rehberler/resim/" + vm.rehber.Id,
              kayit,
              "put",
              function (response) {
                if (response.Success) {
                  vm.$alert.messageWithFunction(
                    "Profil Düzelt",
                    response.Message,
                    "success",
                    function () {
                      vm.$bvModal.hide("resimForm");
                      vm.onDetay();
                    }
                  );
                }
              },
              function (error) {
                vm.$alert.messageWithTitle(
                  "Profil Düzelt",
                  error.Message,
                  "error"
                );
              }
            );
          }, "image/jpeg");
        }
      }
    },
    ozgecmisKaydetSubmit(target) {
      target.preventDefault();

      let isValid = this.kayitozgecmis_ozgecmis_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/rehberler/ozgecmis/" + this.rehber.Id,
          vm.kayitOzgecmis,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Özgeçmiş Güncelle",
                response.Message,
                "success",
                function () {
                  vm.rehber.Ozgecmis = vm.kayitOzgecmis.Ozgecmis;
                  vm.isOzgecmisForm = false;

                  vm.onDetay();
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Özgeçmiş Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    }
  },
};
</script>
