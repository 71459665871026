<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <b-row>
        <b-col md="6" offset-md="3">
          <div class="card" v-if="detay.Id !== undefined">
            <div class="card-body">
              <div class="invoice">
                <div>
                  <div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="media">
                          <div class="media-left" v-if="detay.AcenteLogo != ''">
                            <img class="media-object img-60" :src="detay.AcenteLogo" :alt="detay.AcenteAdi" />
                          </div>
                          <div class="media-body m-l-20">
                            <h4 class="media-heading">{{ detay.AcenteAdi }}</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="text-md-right">
                          <h3>
                            Reservation Code
                            <span class="digits counter">#{{ detay.Kod }}</span>
                          </h3>
                          <p>
                            Tour Date :
                            <span class="digits">{{ $formatHelper.formatDate(detay.Tarih) }}
                              {{ detay.Saat }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <b-row>
                    <b-col md="12">
                      <b-card header-tag="div" header-bg-variant="secondary" class="card-absolute mb-5"
                        body-class="text-center">
                        <h5 slot="header">Your Guide Details</h5>
                        <h4>{{ detay.RehberAdi }}</h4>
                        <h6>Phone : +90 {{ detay.RehberGSM }}</h6>
                        <img :src="detay.RehberResim" :alt="detay.RehberAdi" class="img-thumbnail img-100" />
                      </b-card>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-card header-tag="div" header-bg-variant="primary" class="card-absolute mb-2"
                        body-class="text-center">
                        <h5 slot="header">Your Driver Details</h5>
                        <h4>{{ detay.SoforAdi }}</h4>
                        <h6>Plate : {{ detay.Plaka }}</h6>
                        <h6>Phone : +90 {{ detay.SoforGSM }}</h6>
                        <p>
                          {{ detay.AracMarka }} / {{ detay.AracModel }}
                          <b-badge v-if="detay.isWifi" variant="warning">
                            <i class="fa fa-wifi" aria-hidden="true" style="font-size: 14px"></i>
                          </b-badge>
                        </p>
                        <img :src="detay.AracResim" :alt="detay.SoforAdi" class="img-thumbnail img-100" />
                        <div class="row mt-3" itemscope itemprop="thumbnail">
                          <figure class="col-xl-3 col-sm-6" v-for="(n, index) in detay.AracResimleri" :key="index"
                            @click="gindex = index">
                            <a>
                              <img :src="n" class="img-thumbnail" alt="" />
                            </a>
                          </figure>
                          <VueGallery :images="detay.AracResimleri" :index="gindex" @close="gindex = null"
                            :options="galleryoptions"></VueGallery>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import VueGallery from "vue-gallery";

export default {
  data() {
    return {
      id: this.$route.params.id,
      kod: this.$route.params.kod,

      detay: {},
      
      gindex: null,
      galleryoptions: {
        prevClass: "prev",
        nextClass: "next"
      }
    };
  },
  mounted() {
    this.onDetay();
  },
  components: {
    VueGallery
  },
  computed: {},
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/view/" + this.id + "/" + this.kod,
        function (response) {
          if (response.Success) {
            vm.detay = response.Data;
          } else {
            vm.$alert.messageWithTitle(
              "Tour Reservation View",
              response.Message,
              "error"
            );
          }
        }
      );
    },
  },
};
</script>
