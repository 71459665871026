<template>
  <div>
    <Breadcrumbs main="Şehirler" title="Liste" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link
                      :to="`/sehir/kayit/${row.item.Id}`"
                      class="btn btn-success btn-xs"
                      >Düzelt</router-link
                    >
                    <b-button size="xs" @click="sil(row.item)" variant="danger"
                      >Sil</b-button
                    >
                  </b-btn-group>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.KayitTarihi"
                    :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      breadLinks: [{ path: "/sehir/kayit", title: "Ekle", icon: "plus" }],
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "Plaka", label: "Plaka", sortable: true },
        { key: "Adi", label: "Adi", sortable: true },
        { key: "Sira", label: "Sıra", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      this.$connection.get("/sehirler/all", function (response) {
        vm.items = response;
      });
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Şehir kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/sehirler/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Şehir kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
  },
};
</script>
