var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"button-wrapper mb-3"},[_c('b-button',{staticClass:"button",on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadImage($event)}}}),_vm._v(" Resim Yükle ")])],1),(_vm.mutateImage.src !== null)?_c('p',{staticClass:"lead"},[_vm._v(" Resmi küçültmek için; resmin üzerindeyken mouseun scroll tuşunu kullanabilirsiniz. ")]):_vm._e(),(_vm.mutateImage.src !== null)?_c('cropper',{ref:"cropper",staticClass:"cropper upload-example-cropper",attrs:{"src":_vm.mutateImage.src,"stencil-props":{
      handlers: {},
      movable: false,
      resizable: false,
    },"resize-image":{
      adjustStencil: false,
    },"stencil-size":{
      width: 440,
      height: 700,
    },"image-restriction":"stencil"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }