<template>
  <div>
    <b-form @submit="turFiyatEkle" inline class="mb-3" v-if="noEdit == false">
      <label class="sr-only" for="yenifiyat_tur">Tur</label>
      <b-form-select
        id="yenifiyat_tur"
        @change="turOpsiyonList()"
        v-model="yeniFiyatForm.TurId"
        :options="turlar"
        class="mb-2 mr-sm-2 mb-sm-0"
        :state="yenifiyat_tur_state"
      ></b-form-select>

      <label class="sr-only" for="yenifiyat_opsiyon">Opsiyon</label>
      <b-form-select
        id="yenifiyat_opsiyon"
        v-model="yeniFiyatForm.TurOpsiyonId"
        :options="opsiyonlar"
        class="mb-2 mr-sm-2 mb-sm-0"
        :state="yenifiyat_opsiyon_state"
      ></b-form-select>

      <label class="sr-only" for="yenifiyat_fiyat">Fiyat</label>
      <b-input-group prepend="TL" class="mb-2 mr-sm-2 mb-sm-0">
        <b-form-input
          id="yenifiyat_fiyat"
          v-model="yeniFiyatForm.Fiyat"
          :state="yenifiyat_fiyat_state"
          placeholder="Fiyat"
        ></b-form-input>
      </b-input-group>

      <b-button type="submit" variant="primary">Ekle</b-button>
    </b-form>

    <b-table striped hover :fields="turFiyatFields" :items="fiyatlar">
      <template #cell(Islem)="row" v-if="noEdit == false">
        <b-btn-group>
          <b-button size="xs" @click="turFiyatSil(row.item)" variant="danger"
            >Sil</b-button
          >
        </b-btn-group>
      </template>
      <template #cell(Fiyat)="row">
        <b-input-group prepend="TL" style="width: 300px" v-if="noEdit == false">
          <b-form-input v-model="row.item.Fiyat"></b-form-input>
          <b-input-group-append>
            <b-button @click="turFiyatDuzelt(row.item)" variant="success"
              >Değiştir</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <c-money-cell
          v-if="noEdit"
          :fiyat="row.item.Fiyat"
          simge="TL"
        ></c-money-cell>
      </template>
      <template #cell(KayitTarihi)="row">
        <c-tarih-cell
          :kayitTarihi="row.item.KayitTarihi"
          :rowKey="row.item.TurOpsiyonId"
        ></c-tarih-cell>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  props: {
    fiyatlar: [],
    acenteId: "",
    noEdit: false,
  },
  data() {
    return {
      turFiyatFields: this.noEdit
        ? [
            { key: "TurAdi", label: "Tur", sortable: false },
            { key: "TurOpsiyonAdi", label: "Opsiyon", sortable: false },
            { key: "Fiyat", label: "Fiyat", sortable: false },
          ]
        : [
            { key: "Islem", label: "İşlemler", sortable: false },
            { key: "TurAdi", label: "Tur", sortable: false },
            { key: "TurOpsiyonAdi", label: "Opsiyon", sortable: false },
            { key: "Fiyat", label: "Fiyat", sortable: false },
            { key: "KullaniciAdi", label: "Kullanıcı", sortable: false },
            { key: "KayitTarihi", label: "Tarih", sortable: false },
          ],

      yeniFiyatForm: {
        TurId: null,
        TurOpsiyonId: null,
        Fiyat: 0,
      },

      turlar: [{ value: null, text: "Turlar", encId: "" }],
      opsiyonlar: [{ value: null, text: "Opsiyonlar" }],
    };
  },
  mounted() {
    this.turList();
  },
  computed: {
    yenifiyat_tur_state() {
      if (this.yeniFiyatForm.TurId == null || this.yeniFiyatForm.TurId == "")
        return false;
      else return true;
    },
    yenifiyat_opsiyon_state() {
      if (
        this.yeniFiyatForm.TurOpsiyonId == null ||
        this.yeniFiyatForm.TurOpsiyonId == ""
      )
        return false;
      else return true;
    },
    yenifiyat_fiyat_state() {
      if (
        this.yeniFiyatForm.Fiyat == null ||
        this.yeniFiyatForm.Fiyat == "" ||
        parseFloat(this.yeniFiyatForm.Fiyat) <= 0
      )
        return false;
      else return true;
    },
  },
  methods: {
    turList() {
      let vm = this;
      this.$connection.get("/turlar/forSale/0", function (response) {
        let mapResponse = response.map((tur) => {
          return {
            value: tur.Id,
            text: tur.Adi,
            encId: tur.EncId,
          };
        });
        vm.turlar = vm.turlar.concat(mapResponse);
      });
    },
    turOpsiyonList() {
      if (this.yeniFiyatForm.TurId === null) {
        this.opsiyonlar = [{ value: null, text: "Opsiyonlar" }];
        return;
      }
      let turItems = this.turlar.filter((item) => {
        return item.value == this.yeniFiyatForm.TurId;
      });
      if (turItems.length > 0) {
        let vm = this;
        this.$connection.get(
          "/turlar/opsiyonlarForSale/" + turItems[0].encId,
          function (response) {
            vm.opsiyonlar = response.map((tur) => {
              return {
                value: tur.Id,
                text: tur.Adi,
              };
            });
            vm.opsiyonlar = [{ value: null, text: "Opsiyonlar" }].concat(
              vm.opsiyonlar
            );
          }
        );
      }
    },
    turFiyatSil(fiyat) {
      let vm = this;
      this.$alert.confirm(
        "Fiyat Sil",
        "Acenteye atanmış fiyat silinecektir. Emin misiniz?",
        "error",
        function () {
          vm.$connection.delete(
            "/acenteler/" +
              vm.acenteId +
              "/turFiyat/" +
              fiyat.TurId +
              "/" +
              fiyat.TurOpsiyonId,
            function () {
              vm.$alert.messageWithFunction(
                "Fiyat Sil",
                "Fiyat silindi.",
                "success",
                function () {
                  vm.$listHelper.removeItem(vm.fiyatlar, fiyat);
                }
              );
            }
          );
        }
      );
    },
    turFiyatDuzelt(fiyat) {
      let vm = this;

      let form = {
        TurId: fiyat.TurId,
        TurOpsiyonId: fiyat.TurOpsiyonId,
        Fiyat: fiyat.Fiyat,
      };

      vm.$connection.post(
        "/acenteler/" + vm.acenteId + "/turFiyat",
        form,
        "put",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithTitle(
              "Fiyat Değiştir",
              "Fiyat değiştirildi.",
              "success"
            );
          }
        }
      );
    },
    turFiyatEkle(event) {
      event.preventDefault();

      let isValid =
        this.yenifiyat_tur_state &&
        this.yenifiyat_opsiyon_state &&
        this.yenifiyat_fiyat_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/acenteler/" + vm.acenteId + "/turFiyat",
          vm.yeniFiyatForm,
          "post",
          function (response) {
            if (response.Success) {
              vm.yeniFiyatForm.TurOpsiyonId = null;
              vm.yeniFiyatForm.Fiyat = 0;

              vm.$emit("onDetay");
            }
          },
          function (error) {
            vm.$alert.messageWithTitle("Fiyat Ekle", error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
