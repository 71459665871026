<template>
  <div class="default-according style-1 mb-3" v-if="rezervasyonlar.length > 0">
    <b-card no-body>
      <b-card-header header-tag="div" role="tab" header-bg-variant="success">
        <h5 class="mb-0">
          <b-button v-b-toggle.accordion-yorum><b-badge variant="warning">{{ rezervasyonlar.length }}</b-badge>
            Yorumunuzu Bekleyen Rezervasyon Var</b-button>
        </h5>
      </b-card-header>
      <b-collapse id="accordion-yorum" accordion="my-accordion-yorum" role="tabpanel">
        <b-card-body class="row pricing-content">
          <div class="col-xl-3 col-sm-6 xl-50 mb-3" v-for="tur in rezervasyonlar" :key="tur.Id">
            <div class="card text-center pricing-simple">
              <div class="card-body">
                <h5>{{ tur.TurAdi }}</h5>
                <h5>{{ tur.TurOpsiyonAdi }}</h5>
                <h6 class="mb-0"><strong><i>{{ tur.Misafirler }}</i></strong></h6>
                <h6 class="mb-1">Rehber: <strong>{{ tur.RehberAdi }}</strong></h6>
                <h6 class="mb-1">Şoför: <strong>{{ tur.SoforAdi }}</strong></h6>
                <h6 class="mb-1">{{ $moment(String(tur.Tarih)).format("DD.MM.YYYY") }}</h6>
              </div>
              <router-link :to="`/turRezervasyon/yorum/${tur.Id}`" class="btn btn-lg btn-primary btn-block">
                <h5 class="mb-0">Puanla</h5>
              </router-link>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
  },
  data() {
    return {
      rezervasyonlar: []
    };
  },
  mounted() {
    this.yorumBekleyenList();
  },
  computed: {
  },
  methods: {
    yorumBekleyenList() {
      let vm = this;
      this.$connection.get("/turRezervasyonlari/YorumBekleyenler", function (response) {
        vm.rezervasyonlar = response;
      });
    }
  },
};
</script>
