<template>
  <div>
    <b-modal id="acenteOdemeForm" centered title="Ödeme Bildirimi" @ok="kaydetSubmit" ok-title="Kaydet"
      cancel-title="Kapat" class="theme-modal">
      <b-form @submit="kaydetSubmit">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tarih">Tarih</label>
            <datepicker :language="tr" input-class="datepicker-here form-control digits" id="kayit_tarih"
              v-model="kayit.Tarih" placeholder="Tarih" :format="date_format" :state="kayit_tarih_state">
            </datepicker>
            <b-form-invalid-feedback :state="kayit_tarih_state">Tarih giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_parabirimi">Parabirimi</label>
            <b-form-select id="kayit_parabirimi" v-model="kayit.ParaBirimiId" @change="paraBirimiSec()"
              :options="parabirimleri" :state="kayit_parabirimi_state"></b-form-select>
            <b-form-invalid-feedback :state="kayit_parabirimi_state">Parabirimini seçiniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_kur">Kur</label>
            <b-form-input id="kayit_kur" v-model="kayit.Kur" :state="kayit_kur_state"></b-form-input>
            <b-form-invalid-feedback :state="kayit_kur_state">Kuru giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tutar">Tutar</label>
            <b-form-input id="kayit_tutar" v-model="kayit.Tutar" :state="kayit_tutar_state"></b-form-input>
            <b-form-invalid-feedback :state="kayit_tutar_state">Tutarı giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tutar">Aktarılacak Tutar</label>
            <div class="bg-info p-2">
              <c-money-cell :fiyat="kayit_hesaplanan_deger_state" simge="₺"></c-money-cell>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_aciklama">Açıklama</label>
            <b-form-textarea id="kayit_aciklama" v-model="kayit.Aciklama"
              :state="kayit_aciklama_state"></b-form-textarea>
            <b-form-invalid-feedback :state="kayit_aciklama_state">Açıklama giriniz.</b-form-invalid-feedback>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";

export default {
  props: {
    acente: null
  },
  data() {
    return {
      date_format: "dd.MM.yyyy",
      tr: tr,

      kayit: {
        Id: 0,
        AcenteId: "",
        Tipi: 20,
        ParaBirimiId: null,
        Tutar: null,
        Aciklama: "",
        Tarih: null,
        Kur: 1
      },
      kayit_hesaplanan: 0,

      parabirimleri: [{
        value: null,
        text: "Seçiniz",
        kur: 1
      }],
    };
  },
  components: {
    Datepicker,
  },
  mounted() {
    this.paraBirimiList();
  },
  computed: {
    kayit_parabirimi_state() {
      if (this.kayit.ParaBirimiId == null || this.kayit.ParaBirimiId == "")
        return false;
      else return true;
    },
    kayit_tarih_state() {
      if (this.kayit.Tarih == null || this.kayit.Tarih == "") return false;
      else return true;
    },
    kayit_tutar_state() {
      if (this.kayit.Tutar == null) return false;
      else return true;
    },
    kayit_kur_state() {
      if (this.kayit.Kur == null || this.kayit.Kur == "" || this.kayit.Kur == 0) return false;
      else return true;
    },
    kayit_aciklama_state() {
      if (this.kayit.Aciklama == null || this.kayit.Aciklama == "")
        return false;
      else return true;
    },
    kayit_hesaplanan_deger_state() {
      return this.kayit.Tutar * this.kayit.Kur;
    }
  },
  methods: {
    paraBirimiList() {
      let vm = this;

      this.$connection.get("/paraBirimleri/select", function (response) {
        vm.parabirimleri = vm.parabirimleri.concat(response);
      });
    },
    paraBirimiSec() {
      let vm = this;
      let pbirim = this.parabirimleri.filter((item) => {
        return item.value == vm.kayit.ParaBirimiId;
      });
      if (pbirim.length > 0) {
        vm.kayit.Kur = pbirim[0].Kur;
      }
    },
    kaydetSubmit(event) {
      event.preventDefault();

      let isValid =
        this.kayit_parabirimi_state &&
        this.kayit_tarih_state &&
        this.kayit_tutar_state &&
        this.kayit_kur_state &&
        this.kayit_aciklama_state;

      if (isValid) {
        let vm = this;

        let url = "/acenteler/folyo";
        let method = "post";
        let messageTitle = "Yeni Folyo";
        
        vm.kayit.AcenteId = vm.acente.Id;

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.kayit = {
                    Id: 0,
                    AcenteId: vm.acente.Id,
                    Tipi: 20,
                    ParaBirimiId: null,
                    Tutar: null,
                    Aciklama: "",
                    Tarih: null,
                    Kur: 1
                  };

                  vm.$emit("onKayitEklendi", response.Data);
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
