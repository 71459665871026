<template>
  <div>
    <RehberPerformans :performans="rehberPerformans"></RehberPerformans>

    <div class="row">
      <div class="col-md-12">
        <b-form @submit="filtreSubmit" inline class="mb-3">
          <label class="sr-only" for="filtre_baslangictarihi">Başlangıç Tarihi</label>
          <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
            id="filtre_baslangictarihi" v-model="filtre.BaslangicTarihi" placeholder="Başlangıç Tarihi"
            :format="date_format"></datepicker>

          <label class="sr-only" for="filtre_bitistarihi">Bitiş Tarihi</label>
          <datepicker :language="tr" input-class="datepicker-here form-control digits mb-2 mr-sm-2 mb-sm-0"
            id="filtre_bitistarihi" v-model="filtre.BitisTarihi" placeholder="Bitiş Tarihi" :format="date_format">
          </datepicker>

          <label class="sr-only" for="filtre_min_puan">Min Puan</label>
          <b-form-input type="text" id="filtre_min_puan" v-model="filtre.MinPuan" class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Minumum Puan 1-10 Arası"></b-form-input>

          <label class="sr-only" for="filtre_max_puan">Mak Puan</label>
          <b-form-input type="text" id="filtre_max_puan" v-model="filtre.MaxPuan" class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Maksimum Puan 1-10 Arası"></b-form-input>

          <label class="sr-only" for="filtre_savunma_durumu">Savunma Durumu</label>
          <b-form-select id="filtre_savunma_durumu" v-model="filtre.SavunmaKabulDurumu" :options="savunmaKabulDurumlari"
            class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

          <label class="sr-only" for="filtre_gonderim_durumu">Gönderim Durumu</label>
          <b-form-select id="filtre_gonderim_durumu" v-model="filtre.RehbereGoster" :options="rehbereGoster"
            class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>

          <b-button type="submit" variant="primary">Listele</b-button>
        </b-form>
      </div>
    </div>

    <c-table :tablefields="yorumFields" :items="yorumlar">
      <template #cell(Islem)="row">
        <b-btn-group>
          <b-button @click="rehberDetayAc(row.item.Id)" class="btn btn-xs btn-primary">Detay</b-button>
          <a :href="'/turRezervasyon/detay/' + row.item.TurRezervasyonId" class="btn btn-xs btn-dark"
            target="_blank">Rez. {{ row.item.TurRezervasyonKodu
            }}</a>
        </b-btn-group>
      </template>
      <template #cell(AcenteAdi)="row">
        {{ row.item.AcenteAdi }}<br /><small>{{ row.item.KullaniciAdi }}</small>
      </template>
      <template #cell(KayitTarihi)="row">
        <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
          :rowKey="row.item.Id"></c-tarih-cell>
      </template>
    </c-table>

    <b-modal id="rehberYorumDetay" size="xl" centered title="Yorum Detayı" hide-footer class="theme-modal">
      <RehberYorum :id="seciliRehberYorumId" v-if="seciliRehberYorumId != null">
      </RehberYorum>
    </b-modal>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import RehberPerformans from "@/components/c_rehber_performans";
import RehberYorum from "./c_rehber_yorum";

export default {
  props: {
    rehber: {},
    rehberPerformans: {}
  },
  data() {
    return {
      yorumFields: [
        { key: "Islem", label: "İşlem", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: false },
        { key: "RehbereGosterStr", label: "Gösterim D.", sortable: false },
        { key: "SavunmaKabulDurumuStr", label: "Savunma D.", sortable: false },
        { key: "KayitTarihi", label: "Yorum T.", sortable: false },
        { key: "Puan", label: "Puan", sortable: false },
        { key: "Yorum", label: "Yorum", sortable: false }
      ],

      date_format: "dd.MM.yyyy",
      tr: tr,

      yorumlar: [],
      
      seciliRehberYorumId: null,

      savunmaKabulDurumlari: [
        { value: null, text: "Savunma Durumu" },
        { value: "0", text: "Boş" },
        { value: "1", text: "Kabul Edilenler" },
        { value: "2", text: "Red Edilenler" },
      ],

      rehbereGoster: [
        { value: null, text: "Gönderim Durumu" },
        { value: "0", text: "Gönderilmemişler" },
        { value: "1", text: "Gönderilenler" },
        { value: "2", text: "Savunma Yaptıkları" },
      ],

      filtre: {
        BaslangicTarihi: null,
        BitisTarihi: null,
        MinPuan: null,
        MaxPuan: null,
        RehberId: this.rehber.Id,
        SavunmaKabulDurumu: null,
        RehbereGoster: null
      }
    };
  },
  components: {
    Datepicker,
    RehberPerformans,
    RehberYorum
  },
  mounted() {
    let baslangicTarihi = new Date();
    let bitisTarihi = new Date();
    baslangicTarihi.setDate(bitisTarihi.getDate() - 15);
    bitisTarihi.setDate(bitisTarihi.getDate() + 15);
    this.filtre.BaslangicTarihi = baslangicTarihi;
    this.filtre.BitisTarihi = bitisTarihi;

    this.list();
  },
  methods: {
    filtreSubmit(event) {
      event.preventDefault();
      this.list();
    },
    list() {
      let vm = this;

      vm.filtre.MinPuan = vm.filtre.MinPuan == "" ? null : vm.filtre.MinPuan;
      vm.filtre.MaxPuan = vm.filtre.MaxPuan == "" ? null : vm.filtre.MaxPuan;

      this.$connection.post(
        "/rehberler/yorumlari",
        vm.filtre,
        "post",
        function (response) {
          vm.yorumlar = response;
        }
      );
    },
    rehberDetayAc(id) {
      this.seciliRehberYorumId = id;
      this.$bvModal.show("rehberYorumDetay");
    },
  },
};
</script>
