<template>
  <div>
    <Breadcrumbs main="Menuler" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Menü Kaydı</h5>
              <span
                >Yeni menü veya menü kaydında değişiklikler için aşağıdaki formu
                kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tipi">Menü Tipi</label>
                    <b-form-select
                      id="kayit_tipi"
                      v-model="kayit.Tipi"
                      :options="kayit_tipi_options"
                      :state="kayit_tipi_state"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="kayit_tipi_state"
                      >Menü tipini seçiniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tanim">Menü Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_tanim"
                      v-model="kayit.Tanim"
                      :state="kayit_tanim_state"
                      placeholder="Menü Adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_tanim_state"
                      >Menü adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_link">Link</label>
                    <b-form-input
                      type="text"
                      id="kayit_link"
                      v-model="kayit.Link"
                      :state="kayit_link_state"
                      placeholder="Menü Linki"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_link_state"
                      >Menü linkini giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <b-form-input
                      type="text"
                      id="kayit_sira"
                      v-model="kayit.Sira"
                      :state="kayit_sira_state"
                      placeholder="Menü sırası"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sira_state"
                      >Menü sırasına 0'dan büyük bir rakam
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox
                        name="Aktif"
                        v-model="kayit.Aktif"
                        :state="kayit.Aktif"
                        >Aktif</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <router-link :to="'/menu/list'" class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Menü",

      breadLinks: [
        { path: "/menu/list", title: "Menuler", icon: "chevron-left" },
      ],

      kayit: {
        Tipi: 0,
        Tanim: "",
        Link: "",
        Aktif: true,
        Sira: 0,
      },

      kayit_tipi_options: [{ value: "0", text: "Seçiniz" }],
    };
  },
  mounted() {
    this.onTipler();

    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Menü Düzelt";

      this.$connection.get("/menuler/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle("Yeni Menü", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_tipi_state() {
      if (this.kayit.Tipi == 0) return false;
      else return true;
    },
    kayit_tanim_state() {
      if (this.kayit.Tanim == null || this.kayit.Tanim == "") return false;
      else return true;
    },
    kayit_link_state() {
      if (this.kayit.Link == null || this.kayit.Link == "") return false;
      else return true;
    },
    kayit_sira_state() {
      if (this.kayit.Sira === "" || this.kayit.Sira === null) return false;
      else return true;
    },
  },
  methods: {
    onTipler() {
      let vm = this;

      this.$connection.get("/menuler/MenuTipleri", function (response) {
        vm.kayit_tipi_options = vm.kayit_tipi_options.concat(response);
      });
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_tipi_state &&
        this.kayit_tanim_state &&
        this.kayit_link_state &&
        this.kayit_sira_state;

      if (isValid) {
        let vm = this;

        let url = "/menuler";
        let method = "post";
        let messageTitle = "Yeni Menü";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Menü Düzelt";
        }

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/menu/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
