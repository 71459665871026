<template>
  <div class="card" v-if="duyurular.length > 0">
    <div class="card-header p-3">
      <h5>Duyurular</h5>
    </div>
    <div class="card-body p-0">
      <div class="scroll-bar-wrap">
        <VuePerfectScrollbar class="scroll-area" v-once :settings="settings6">
          <div class="vertical-scroll scroll-demo">
            <p v-for="duyuru in duyurular" :key="duyuru.Id">
              <strong>{{ duyuru.Baslik }}</strong><br>
              <span v-html="duyuru.Detay"></span>
            </p>
          </div>
        </VuePerfectScrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
export default {
  data() {
    return {
      duyurular: [],

      settings6: null,
    };
  },
  mounted() {
    this.load();
  },
  components: {
    VuePerfectScrollbar
  },
  methods: {
    load() {
      let vm = this;

      this.$connection.get(
        "/duyurular/gosterimSurekli",
        function (response) {
          vm.duyurular = response;

          vm.settings6 = {
            maxScrollbarLength: 60,
          }
        }
      );
    }
  },
};
</script>
<style>
.scroll-bar-wrap {
  overflow: hidden;
}

.scroll-area {
  margin: auto;
  width: 100%;
  height: 200px;
}
</style>