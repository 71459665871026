<template>
  <div class="printArea" v-if="rezervasyon != null">
    <section class="page">
      <h1 style="text-align: center">YOLCU LİSTESİ</h1>
      <p>
        <img :src="rezervasyon.AcenteLogo" style="max-width: 200px" /><br />
        {{ rezervasyon.AcenteAdi }}
      </p>
      <table style="width: 100%">
        <tr>
          <th>Ad Soyad</th>
          <th>Uyruk</th>
          <th>Pasaport / T.C. Kimlik No</th>
        </tr>
        <tr v-for="misafir in mutateMisafirler" :key="misafir.Id">
          <td>{{ misafir.AdSoyad }}</td>
          <td>{{ misafir.Ulke }}</td>
          <td>{{ misafir.PasaportNo }}</td>
        </tr>
      </table>
    </section>
  </div>
</template>
<script>
export default {
  props: {
    rezervasyon: null,
  },
  data() {
    return {
      mutateMisafirler: this.rezervasyon.Detay.Yolcular,
    };
  },
  mounted() { },
};
</script>
<style>
@media print {
  body {
    visibility: hidden;
  }

  .printArea {
    visibility: visible;
    position: fixed;
    left: 0;
    top: 0;
  }

  .page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 1cm;
    margin: 0;
  }

  .page {
    font-family: "Times New Roman", Times, serif;
  }

  .page table {
    width: 100%;
  }

  .page-break {
    display: block;
    page-break-before: always;
  }

  .page table {
    width: 100%;
  }
}
</style>
