<template>
  <div>
    <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
      <h5 slot="header">Arac Firmasına Özel Tur Fiyatları</h5>

      <b-form @submit="turFiyatEkle" inline class="mb-3">
        <label class="sr-only" for="yenifiyat_tur">Tur</label>
        <b-form-select
          id="yenifiyat_tur"
          v-model="yeniFiyatForm.TurId"
          :options="turlar"
          class="mb-2 mr-sm-2 mb-sm-0"
          :state="yenifiyat_tur_state"
        ></b-form-select>

        <label class="sr-only" for="yenifiyat_alisucreti"
          >Servis Alış Ücreti</label
        >
        <b-input-group prepend="Alış (₺)" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            id="yenifiyat_alisucreti"
            v-model="yeniFiyatForm.AlisUcreti"
            :state="yenifiyat_alisucreti_state"
            placeholder="Servis Alış Ücreti"
          ></b-form-input>
        </b-input-group>

        <label class="sr-only" for="yenifiyat_satisucreti"
          >Servis Satış Ücreti</label
        >
        <b-input-group prepend="Satış (₺)" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            id="yenifiyat_satisucreti"
            v-model="yeniFiyatForm.SatisUcreti"
            :state="yenifiyat_satisucreti_state"
            placeholder="Servis Satış Ücreti"
          ></b-form-input>
        </b-input-group>

        <b-button type="submit" variant="primary">Ekle</b-button>
      </b-form>

      <b-table striped hover :fields="turFiyatFields" :items="fiyatlar">
        <template #cell(Islem)="row">
          <b-btn-group>
            <b-button @click="turFiyatSil(row.item)" variant="danger"
              >Sil</b-button
            >
            <b-button @click="turFiyatDuzelt(row.item)" variant="info"
              >Düzelt</b-button
            >
          </b-btn-group>
        </template>
        <template #cell(AlisUcreti)="row">
          <b-input-group prepend="₺">
            <b-form-input v-model="row.item.AlisUcreti"></b-form-input>
          </b-input-group>
        </template>
        <template #cell(SatisUcreti)="row">
          <b-input-group prepend="₺">
            <b-form-input v-model="row.item.SatisUcreti"></b-form-input>
          </b-input-group>
        </template>
        <template #cell(KayitTarihi)="row">
          <c-tarih-cell
            :kayitTarihi="row.item.KayitTarihi"
            :rowKey="row.item.TurOpsiyonId"
          ></c-tarih-cell>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    aracFirma: {},
  },
  data() {
    return {
      turFiyatFields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "TurAdi", label: "Tur", sortable: false },
        { key: "AlisUcreti", label: "Alış Ücreti", sortable: false },
        { key: "SatisUcreti", label: "Satış Ücreti", sortable: false },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: false },
        { key: "KayitTarihi", label: "Tarih", sortable: false },
      ],

      yeniFiyatForm: {
        AracFirmaId: this.aracFirma.Id,
        TurId: null,
        AlisUcreti: 0,
        SatisUcreti: 0,
      },

      genelFiyatForm: {
        AlisUcreti: this.aracFirma.AlisUcreti,
        SatisUcreti: this.aracFirma.SatisUcreti,
      },

      fiyatlar: [],

      turlar: [{ value: null, text: "Turlar", encId: "" }],
    };
  },
  mounted() {
    this.turList();
    this.fiyatList();
  },
  computed: {
    yenifiyat_tur_state() {
      if (this.yeniFiyatForm.TurId == null || this.yeniFiyatForm.TurId == "")
        return false;
      else return true;
    },
    yenifiyat_alisucreti_state() {
      if (
        this.yeniFiyatForm.AlisUcreti == null ||
        this.yeniFiyatForm.AlisUcreti == "" ||
        parseFloat(this.yeniFiyatForm.AlisUcreti) <= 0
      )
        return false;
      else return true;
    },
    yenifiyat_satisucreti_state() {
      if (
        this.yeniFiyatForm.SatisUcreti == null ||
        this.yeniFiyatForm.SatisUcreti == "" ||
        parseFloat(this.yeniFiyatForm.SatisUcreti) <
          parseFloat(this.yeniFiyatForm.AlisUcreti)
      )
        return false;
      else return true;
    },
    genelfiyat_alisucreti_state() {
      if (this.genelFiyatForm.AlisUcreti == null) return false;
      else return true;
    },
    genelfiyat_satisucreti_state() {
      if (
        this.genelFiyatForm.SatisUcreti == null ||
        parseFloat(this.genelFiyatForm.SatisUcreti) <
          parseFloat(this.genelFiyatForm.AlisUcreti)
      )
        return false;
      else return true;
    },
  },
  methods: {
    turList() {
      let vm = this;
      this.$connection.get("/turlar/forSale/0", function (response) {
        let mapResponse = response.map((tur) => {
          return {
            value: tur.EncId,
            text: tur.Adi,
          };
        });
        vm.turlar = vm.turlar.concat(mapResponse);
      });
    },
    fiyatList() {
      let vm = this;
      this.$connection.get(
        "/araclar/turFiyat/firmaList/" + this.aracFirma.Id,
        function (response) {
          vm.fiyatlar = response;
        }
      );
    },
    turFiyatSil(fiyat) {
      let vm = this;
      this.$alert.confirm(
        "Fiyat Sil",
        "Araç firmasına atanmış fiyat silinecektir. Emin misiniz?",
        "error",
        function () {
          vm.$connection.delete("/araclar/turFiyat/" + fiyat.Id, function () {
            vm.$alert.messageWithFunction(
              "Fiyat Sil",
              "Fiyat silindi.",
              "success",
              function () {
                vm.$listHelper.removeItem(vm.fiyatlar, fiyat);
              }
            );
          });
        }
      );
    },
    turFiyatDuzelt(fiyat) {
      let vm = this;

      let form = {
        AracFirmaId: this.aracFirma.Id,
        TurId: fiyat.TurId,
        AlisUcreti: fiyat.AlisUcreti,
        SatisUcreti: fiyat.SatisUcreti,
      };

      vm.$connection.post(
        "/araclar/turFiyat/" + fiyat.Id,
        form,
        "put",
        function (response) {
          if (response.Success) {
            vm.fiyatList();

            vm.$alert.messageWithTitle(
              "Fiyat Değiştir",
              "Fiyat değiştirildi.",
              "success"
            );
          }
        }
      );
    },
    turFiyatEkle(event) {
      event.preventDefault();

      let isValid =
        this.yenifiyat_tur_state &&
        this.yenifiyat_alisucreti_state &&
        this.yenifiyat_satisucreti_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/araclar/turFiyat",
          vm.yeniFiyatForm,
          "post",
          function (response) {
            if (response.Success) {
              vm.yeniFiyatForm.AlisUcreti = 0;
              vm.yeniFiyatForm.SatisUcreti = 0;

              vm.fiyatList();

              vm.$emit("onDetay");
            }
          },
          function (error) {
            vm.$alert.messageWithTitle("Fiyat Ekle", error.Message, "error");
          }
        );
      }
    },
    genelFiyatGuncelle(event) {
      event.preventDefault();

      let isValid =
        this.genelfiyat_alisucreti_state && this.genelfiyat_satisucreti_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/aracFirmalari/ucretGuncelle/" + this.aracFirma.Id,
          vm.genelFiyatForm,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithTitle(
                "Ücret Güncelle",
                "Araç Firmasının Standart Ücreti Güncellendi",
                "success"
              );
              vm.$emit("onDetay");
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Genel Fiyat Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    },
  },
};
</script>
