<template>
  <div>
    <Breadcrumbs main="Bağımsız Araçlar" :title="pageTitle" :links="breadLinks" v-if="breadLinks.length > 0" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h5>
                Toplam Borç :
                <b-badge variant="info"><c-money-cell :fiyat="toplamBorc" simge="₺"></c-money-cell></b-badge>
              </h5>
              <hr />
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <a :href="`/aracFirma/aracDetay/${row.item.Id}`" target="_blank"
                      class="btn btn-info btn-xs">Detay</a>
                    <router-link :to="`/aracFirma/aracKayit/0/${row.item.Id}`"
                      class="btn btn-success btn-xs">Düzelt</router-link>
                    <b-button size="xs" @click="sil(row.item)" variant="danger">Sil</b-button>
                  </b-btn-group>
                </template>
                <template #cell(Resim)="data">
                  <img class="img-50 rounded-circle" :src="data.value" alt="" v-if="data.value != ''" />
                </template>
                <template #cell(ToplamAlacak)="row">
                  <c-money-cell :fiyat="row.item.ToplamAlacak" simge="TL"></c-money-cell>
                  <b-button @click="odemeFormAc(row.item)" variant="info" size="xs">Ödeme Ekle</b-button>
                </template>
                <template #cell(DurumStr)="row">
                  <b-badge :variant="durumVariant(row.item.Durum)">{{
                    row.item.DurumStr
                  }}</b-badge>
                </template>
                <template #cell(Yildiz)="row">
                  <c-performans-cell :Yildiz="row.item.Yildiz" :MemnunOlmayanlar="row.item.PerformansMemnunOlmayanlar"
                    :Notr="row.item.PerformansNotr" :SavunmaRedSayisi="row.item.PerformansSavunmaRedSayisi"
                    :rowKey="row.item.Id" :key="row.item.Id"></c-performans-cell>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AracOdemeFormu :arac="aktifArac" @onKayitEklendi="folyoKayitEklendi" />
  </div>
</template>
<script>
import AracOdemeFormu from "@/components/c_arac_odeme_form";

export default {
  data() {
    return {
      pageTitle: "",

      breadLinks: [],

      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "SoforAdi", label: "Şoför", sortable: true },
        { key: "Resim", label: "Resim", sortable: true },
        { key: "Plaka", label: "Plaka", sortable: true },
        { key: "GSM", label: "GSM", sortable: true, thClass: "sabitKol" },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol" },
        { key: "Marka", label: "Marka", sortable: true },
        { key: "Model", label: "Model", sortable: true },
        { key: "ToplamAlacak", label: "Bakiye", sortable: true },
        { key: 'Yildiz', label: 'Performans', sortable: true, },
        { key: "Sira", label: "Sıra", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      aktifArac: null,

      toplamBorc: 0,

      items: [],
    };
  },
  mounted() {
    this.breadLinks = [
      {
        path: "/aracFirma/aracKayit/0",
        title: "Ekle",
        icon: "plus",
      },
    ];

    this.onList();
  },
  components: {
    AracOdemeFormu
  },
  methods: {
    onList() {
      let vm = this;

      let filtre = {
        Kelime: null,
        AracFirmaId: "0",
      };

      this.$connection.post(
        "/araclar/filtre",
        filtre,
        "post",
        function (response) {
          vm.items = response;

          vm.toplamBorc = 0;
          vm.items.forEach((item) => {
            vm.toplamBorc += item.ToplamAlacak;
          });
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Aracı silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/araclar/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Araç kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
    durumVariant(durum) {
      switch (durum) {
        case 0:
          return "info";
        case 20:
          return "warning";
        case 30:
          return "success";
        case 40:
          return "dark";
        case 100:
          return "danger";
        default:
          return "light";
      }
    },
    odemeFormAc(arac) {
      this.aktifArac = arac;
      this.$bvModal.show("aracOdemeForm");
    },
    folyoKayitEklendi(toplamAlacak) {
      this.aktifArac.ToplamAlacak = toplamAlacak;
    }
  },
};
</script>
