<template>
  <div>
    <Breadcrumbs main="Araç Firmaları" title="Folyo" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-card
            header-tag="div"
            header-bg-variant="primary"
            class="card-absolute"
          >
            <h5 slot="header">Folyo</h5>
            <b-card-body class="p-1">
              <AracFirmaFolyo
                :aracFirma="aracFirma"
                v-if="isFolyoLoad"
                :noEdit="true"
              ></AracFirmaFolyo>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AracFirmaFolyo from "@/components/c_aracFirma_folyo";

export default {
  data() {
    return {
      pageTitle: "Folyo",

      breadLinks: [],

      aracFirma: {},

      isFolyoLoad: false,
    };
  },
  mounted() {
    this.onDetay();
  },
  components: {
    AracFirmaFolyo,
  },
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get("/aracFirmalari/profil", function (response) {
        if (response.Success) {
          vm.aracFirma = response.Data;

          vm.isFolyoLoad = true;
        } else {
          vm.$alert.messageWithTitle("Araç Firma", response.Message, "error");
        }
      });
    }
  },
};
</script>
