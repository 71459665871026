<template>
  <div>
    <Breadcrumbs main="Diller" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Dil Kaydı</h5>
              <span>Yeni dil veya dil kaydında değişiklikler için aşağıdaki formu
                kullanabilirsiniz</span>
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tanim">Dil Adı</label>
                    <b-form-input type="text" id="kayit_tanim" v-model="kayit.Tanim" :state="kayit_tanim_state"
                      placeholder="Dil Adı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_tanim_state">Dil adını giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_gorsel">Bayrak</label>
                    <div class="row">
                      <div class="col-md-9">
                        <b-form-file class="form-control" v-model="kayit.BayrakFl" plain></b-form-file>
                      </div>
                      <div class="col-md-3">
                        <div class="avatar" v-if="kayit.Bayrak !== '' && kayit.Bayrak !== undefined
      ">
                          <img class="img-40 rounded-circle" :src="kayit.Bayrak" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <b-form-input type="text" id="kayit_sira" v-model="kayit.Sira" :state="kayit_sira_state"
                      placeholder="Dil sırası"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sira_state">Dilin sırasına 0'dan büyük bir rakam
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox name="Aktif" v-model="kayit.Aktif" :state="kayit.Aktif">Aktif</b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox name="isRegularRezervasyon" v-model="kayit.isRegularRezervasyon">Regular
                        Rezervasyonlarda Kullanılsın</b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_rehberalisfiyati">Standart Rehber Satış Fiyatı</label>
                    <b-form-input type="text" id="kayit_rehberalisfiyati" v-model="kayit.RehberAlisFiyati"
                      :state="kayit_rehberalisfiyati_state" placeholder="Rehber Satış Fiyatı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_rehberalisfiyati_state">Standart rehber satış fiyatını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_rehbersatisfiyati">Standart Rehber Komisyonu</label>
                    <b-form-input type="text" id="kayit_rehbersatisfiyati" v-model="kayit.RehberSatisFiyati"
                      :state="kayit_rehbersatisfiyati_state" placeholder="Rehber Komisyonu"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_rehbersatisfiyati_state">Standart rehberden alacağınız komisyonu giriniz.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <div class="alert alert-info">
                      Satış işleminde rehber fiyatı rehberin ilgili dil için belirlediği <i>{rehber fiyatı} + {{ kayit.RehberSatisFiyati }}₺</i> olacaktır.<br>
                      Rehber fiyat belirlemedi ise <i>{{ kayit.RehberAlisFiyati }}₺ + {{ kayit.RehberSatisFiyati }}₺ = {{ parseFloat(kayit.RehberAlisFiyati) + parseFloat(kayit.RehberSatisFiyati) }}₺</i> olacaktır.
                    </div>
                  </div>
                </div>
                <router-link :to="'/dil/list'" class="btn btn-light pull-left">Geri Dön</router-link>
                <b-button type="submit" class="pull-right" variant="primary">Kaydet</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Dil",

      breadLinks: [
        { path: "/dil/list", title: "Diller", icon: "chevron-left" },
      ],

      kayit: {
        Tanim: "",
        BayrakFl: null,
        RehberAlisFiyati: 0,
        RehberSatisFiyati: 0,
        Aktif: true,
        Sira: 0,
        isRegularRezervasyon: false
      },
    };
  },
  mounted() {
    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Dil Düzelt";

      this.$connection.get("/diller/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle("Yeni Dil", response.Message, "error");
        }
      });
    }
  },
  computed: {
    kayit_tanim_state() {
      if (this.kayit.Tanim == null || this.kayit.Tanim == "") return false;
      else return true;
    },
    kayit_sira_state() {
      if (this.kayit.Sira === "" || this.kayit.Sira === null) return false;
      else return true;
    },
    kayit_rehberalisfiyati_state() {
      if (
        this.kayit.RehberAlisFiyati === "" ||
        this.kayit.RehberAlisFiyati === null ||
        parseFloat(this.kayit.RehberAlisFiyati) === 0
      )
        return false;
      else return true;
    },
    kayit_rehbersatisfiyati_state() {
      if (
        this.kayit.RehberSatisFiyati === "" ||
        this.kayit.RehberSatisFiyati === null
      )
        return false;
      else return true;
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_tanim_state &&
        this.kayit_sira_state &&
        this.kayit_rehberalisfiyati_state &&
        this.kayit_rehbersatisfiyati_state;

      if (isValid) {
        let vm = this;

        let url = "/diller";
        let method = "post";
        let messageTitle = "Yeni Dil";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Dil Düzelt";
        }

        vm.$connection.postData(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/dil/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
