<template>
  <b-card header-tag="div" :header-bg-variant="renk" :bg-variant="iptal ? 'light':'white'" text-variant="dark" class="card-absolute">
    <h5 slot="header">
      Tutarlar 
      <b-badge variant="info">{{ transfer.Donus ? 'Dönüş' : 'Geliş' }}</b-badge>
      <b-badge variant="dark" v-if="iptal">İptal Edildi</b-badge>
    </h5>
    <table class="product-page-width">
      <tbody>
        <tr v-if="isAlisFiyat">
          <th>Alış Tutar</th>
          <td>
            <b-badge variant="warning"><c-money-cell :fiyat="transfer.AlisFiyati" simge="₺"></c-money-cell></b-badge>
          </td>
        </tr>
        <tr v-if="isSatisFiyat">
          <th>Transfer Tutarı</th>
          <td>
            <b-badge variant="success"><c-money-cell :fiyat="transfer.SatisFiyati" simge="₺"></c-money-cell></b-badge>
          </td>
        </tr>
      </tbody>
    </table>
  </b-card>
</template>
<script>
export default {
  props: {
    transfer: {},
  },
  data() {
    return {
      isAlisFiyat: false,
      isSatisFiyat: false,

      renk: this.transfer.Durum == 5 ? 'danger' : (this.transfer.Donus ? 'warning' : 'primary'),
      iptal: this.transfer.Durum == 5
    };
  },
  mounted() {
    let user = this.$user.getUser();
    switch (user.Kullanici.KullaniciTipi) {
      //Acente
      case 10:
      case 15:
        this.isSatisFiyat = true;
        break;

      //Rehber
      case 20:
        break;

      //Araç Firması
      case 30:
      case 35:
        break;

      //Araç
      case 40:
        break;

      //Merkez
      case 80:
      case 85:
      case 100:
        this.isSatisFiyat = true;
        this.isAlisFiyat = true;
        break;

      default:
        break;
    }
  }
};
</script>
