<template>
  <div>
    <Breadcrumbs main="Parametreler" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Parametreler</h5>
            </div>
            <div class="card-body">
              <div class="form-row" v-for="(parametre, index) in parametreler" :key="index">
                <div class="col-md-4">
                  {{ parametre.Tanim }}
                  <a href="#" v-b-tooltip.hover :title="parametre.Aciklama" variant="primary">
                    <feather type="alert-circle"></feather>
                  </a>
                </div>
                <div class="col-md-5 mb-3">
                  <b-form-input type="text" v-model="parametre.Deger" :placeholder="parametre.Tanim"
                    v-if="parametre.Tip == 'string'"></b-form-input>
                  <div class="checkbox checkbox-dark" v-if="parametre.Tip == 'bool'">
                    <b-form-checkbox v-model="parametre.Deger" :name="'Deger' + index">{{ parametre.Tanim
                      }}</b-form-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <b-button type="button" @click="parametreKaydet(parametre)" class="pull-right"
                    variant="primary">Güncelle</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Güncelle",

      breadLinks: [],

      parametreler: [
        { Tanim: "Rehber Max Kapalı Gün Sayısı", Aciklama: "Rehberin bir ayda maksimum kapatacağı gün sayısı. 0 girerseniz kapalı gün sınırı uygulanmaz.", Deger: "", Tip: "string" },
        { Tanim: "Araç Max Kapalı Gün Sayısı", Aciklama: "Aracın bir ayda maksimum kapatacağı gün sayısı. 0 girerseniz kapalı gün sınırı uygulanmaz.", Deger: "", Tip: "string" },
        { Tanim: "Rehber Başvuru Kapalı", Aciklama: "Rehber başvuru sistemini kapatmak için bu parametreyi kullanın. Eğer bu parametre aktif ise başvuru sahibine başvurudan sonra kapalı olduğuna dair bildirim gidecektir.", Deger: "", Tip: "bool" },
        { Tanim: "Araç Başvuru Kapalı", Aciklama: "Araç başvuru sistemini kapatmak için bu parametreyi kullanın. Eğer bu parametre aktif ise başvuru sahibine başvurudan sonra kapalı olduğuna dair bildirim gidecektir.", Deger: "", Tip: "bool" },
        { Tanim: "Genel Rehber Komisyonu", Aciklama: "Rehber için genel komisyon tutarını buradan belirleyebilirsiniz. Eğer rehberin komisyon tutarı 0 ise buradaki tutar eklenecektir. Sadece rakam giriniz.", Deger: "", Tip: "string" }
      ],
    };
  },
  components: {},
  mounted() {
    this.parametreDoldur();
  },
  methods: {
    parametreDoldur() {
      let vm = this;

      for (let index = 0; index < this.parametreler.length; index++) {
        const element = this.parametreler[index];
        this.$connection.get("/parametreler?tanim=" + element.Tanim, function (response) {
          if (response != null) {
            element.Deger = response.Deger;
          }
        });
      }
    },
    parametreKaydet(parametre) {
      let vm = this;

      let kayit = {
        Tanim: parametre.Tanim,
        Deger: parametre.Deger.toString()
      };

      vm.$connection.post(
        "/parametreler",
        kayit,
        "put",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithTitle(
              "Parametre Güncelle",
              parametre.Tanim + " parametresi güncellendi",
              "success"
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(parametre.Tanim + " Parametre Güncelleme", error.Message, "error");
        }
      );
    },
  },
};
</script>
