<template>
  <div>
    <div class="row" v-if="visibleButtons.yonetim">
      <div class="col-md-12 mb-3 text-center">
        <b-btn-group>
          <b-button @click="savunmaIste()" variant="success" v-if="visibleButtons.savunmaIste">Savunma İste</b-button>
          <b-button @click="savunmaGeriCek()" variant="warning" v-if="visibleButtons.savunmaGeriCek">Savunma Geri
            Çek</b-button>
          <b-button @click="savunmaKabul()" variant="success" v-if="visibleButtons.savunmaKabul">Savunma Kabul
            Edildi</b-button>
          <b-button @click="savunmaRed()" variant="danger" v-if="visibleButtons.savunmaRed">Savunma Red
            Edildi</b-button>
          <b-button @click="savunmaBos()" variant="default" v-if="visibleButtons.savunmaBos">Savunma
            Cevaplanmadı</b-button>
          <b-button @click="yorumSil()" variant="danger">Yorumu Sil</b-button>
        </b-btn-group>
      </div>
    </div>

    <table class="product-page-width">
      <tbody>
        <tr>
          <th>Rehber</th>
          <td>
            <a :href="'/rehber/detay/' + yorum.RehberId" target="_blank">{{
      yorum.RehberAdi }}</a>
          </td>
        </tr>
        <tr>
          <th>Acente</th>
          <td>
            <a :href="'/acente/detay/' + yorum.AcenteId" target="_blank">{{
      yorum.AcenteAdi, }}</a> / <small>{{ yorum.KullaniciAdi }}</small>
          </td>
        </tr>
        <tr>
          <th>Tur Rez. Kodu</th>
          <td><a :href="'/turRezervasyon/detay/' + yorum.TurRezervasyonId" target="_blank">{{
      yorum.TurRezervasyonKodu }}</a></td>
        </tr>
        <tr>
          <th>Puan</th>
          <td>
            <star-rating v-model="yorum.Puan" :star-size="40" :max-rating="5" :show-rating="false"
              :read-only="true"></star-rating>
          </td>
        </tr>
        <tr>
          <th>Yorum</th>
          <td>{{ yorum.Yorum }}</td>
        </tr>
        <tr>
          <th>Rehber Gönderim</th>
          <td>{{ yorum.RehbereGosterStr }}</td>
        </tr>
        <tr>
          <th>Savunma</th>
          <td>{{ yorum.Savunma }}</td>
        </tr>
        <tr>
          <th>Savunma Kabul</th>
          <td>{{ yorum.SavunmaKabulDurumuStr }}</td>
        </tr>
        <tr>
          <th>Kayıt Tarihi</th>
          <td>
            <c-tarih-cell :kayitTarihi="yorum.KayitTarihi" :rowKey="yorum.Id"></c-tarih-cell>
          </td>
        </tr>
        <tr>
          <th>Güncelleme Tarihi</th>
          <td>
            <c-tarih-cell :kayitTarihi="yorum.GuncelTarih" :rowKey="'Guncel' + yorum.Id"></c-tarih-cell>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import StarRating from 'vue-star-rating';

export default {
  props: {
    id: null
  },
  data() {
    return {
      yorum: {},

      visibleButtons: {
        yonetim: false,
        savunmaIste: false,
        savunmaGeriCek: false,
        savunmaKabul: false,
        savunmaRed: false,
        savunmaBos: false
      }
    };
  },
  components: {
    StarRating
  },
  mounted() {
    this.onYorum();
  },
  methods: {
    onYorum() {
      let vm = this;

      this.$connection.get("/rehberler/yorum/" + this.id, function (response) {
        if (response.Success) {
          vm.yorum = response.Data;

          vm.buttonGoster(response.Data);
        } else {
          vm.$alert.messageWithTitle("Rehber Yorum", response.Message, "error");
        }
      });
    },
    buttonGoster(yorum) {
      this.visibleButtons = {
        yonetim: false,
        savunmaIste: false,
        savunmaGeriCek: false,
        savunmaKabul: false,
        savunmaRed: false,
        savunmaBos: false
      };

      let user = this.$user.getUser();
      switch (user.Kullanici.KullaniciTipi) {
        //Merkez
        case 80:
        case 85:
        case 100:
          this.visibleButtons.yonetim = true;

          switch (yorum.RehbereGoster) {
            case 0: //Gösterme
              if (yorum.Puan < 3) {
                this.visibleButtons.savunmaIste = true;
              }
              break;

            case 1: //Gönderilmiş
              this.visibleButtons.savunmaGeriCek = true;
              break;

            case 2: //Savunma Yapılmış
              switch (yorum.SavunmaKabulDurumu) {
                case 0: //Boş
                  this.visibleButtons.savunmaKabul = true;
                  this.visibleButtons.savunmaRed = true;
                  break;

                case 1: //Kabul
                  this.visibleButtons.savunmaRed = true;
                  this.visibleButtons.savunmaBos = true;
                  break;

                case 2: //Red
                  this.visibleButtons.savunmaKabul = true;
                  this.visibleButtons.savunmaBos = true;
                  break;

                default:
                  break;
              }
              break;

            default:
              break;
          }
          break;
      }
    },
    savunmaIste() {
      let vm = this;
      this.$alert.confirm(
        "Savunma İste",
        "Rehberden yorumla ilgili savunması istenecektir. Devam etmek istiyor musunuz?",
        "warning",
        function () {
          vm.$connection.get(
            "/rehberler/yorumlari/" + vm.id + "/savunmaIste",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Savunma İste",
                  "Savunma istendi.",
                  "success",
                  function () {
                    vm.onYorum();
                  }
                );
              }
            }
          );
        }
      );
    },
    savunmaGeriCek() {
      let vm = this;
      this.$alert.confirm(
        "Savunma Geri Çek",
        "Rehberden istenen savunma geri çekilecektir. Devam etmek istiyor musunuz?",
        "info",
        function () {
          vm.$connection.get(
            "/rehberler/yorumlari/" + vm.id + "/savunmaGeriCek",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Savunma Geri Çek",
                  "Savunma isteği geri çekildi.",
                  "success",
                  function () {
                    vm.onYorum();
                  }
                );
              }
            }
          );
        }
      );
    },
    savunmaKabul() {
      let vm = this;
      this.$alert.confirm(
        "Savunma Kabul",
        "Rehberin savunması kabul edilecektir. Devam etmek istiyor musunuz?",
        "info",
        function () {
          vm.$connection.get(
            "/rehberler/yorumlari/" + vm.id + "/savunmaKabul",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Savunma Kabul",
                  "Savunma kabul edildi.",
                  "success",
                  function () {
                    vm.onYorum();
                  }
                );
              }
            }
          );
        }
      );
    },
    savunmaRed() {
      let vm = this;
      this.$alert.confirm(
        "Savunma Red",
        "Rehberin savunması red edilecektir. Devam etmek istiyor musunuz?",
        "info",
        function () {
          vm.$connection.get(
            "/rehberler/yorumlari/" + vm.id + "/savunmaRed",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Savunma Red",
                  "Savunma red edildi.",
                  "success",
                  function () {
                    vm.onYorum();
                  }
                );
              }
            }
          );
        }
      );
    },
    savunmaBos() {
      let vm = this;
      this.$alert.confirm(
        "Savunma Kararı",
        "Rehberin savunması kararından vazgeçilecektir. Devam etmek istiyor musunuz?",
        "info",
        function () {
          vm.$connection.get(
            "/rehberler/yorumlari/" + vm.id + "/savunmaDurumDegistir/0",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Savunma Kararı",
                  "Savunma kararından vazgeçilmiştir.",
                  "success",
                  function () {
                    vm.onYorum();
                  }
                );
              }
            }
          );
        }
      );
    },
    yorumSil() {
      let vm = this;
      this.$alert.confirm(
        "Yorum Sil",
        "Rehbere yapılan yorum silinecektir. Devam etmek istiyor musunuz?",
        "info",
        function () {
          vm.$connection.delete(
            "/turRezervasyonlari/RehberYorum/" + vm.yorum.TurRezervasyonId + "/" + vm.id,
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithTitle(
                  "Yorum Sil",
                  "Yorum silinmiştir.",
                  "success"
                );
              }
            }
          );
        }
      );
    },
  },
};
</script>
