<template>
  <div>
    <div class="row">
      <div class="col-md-8"></div>
      <div class="col-md-4 mb-3">
        <b-button-group class="pull-right">
          <b-button @click="yeni" variant="success">
            <div class="media">
              <feather type="plus"></feather>
              <div class="media-body align-self-center">Ekle</div>
            </div>
          </b-button>
        </b-button-group>
      </div>
    </div>

    <b-table
      striped
      hover
      :fields="kullaniciFields"
      :items="mutateKullanicilar"
    >
      <template #cell(Islem)="row">
        <b-btn-group>
          <b-button size="xs" @click="duzelt(row.item)" variant="info"
            >Düzelt</b-button
          >
          <b-button size="xs" @click="sil(row.item)" variant="danger"
            >Sil</b-button
          >
        </b-btn-group>
      </template>
      <template #cell(Tutar)="row">
        <c-money-cell
          :fiyat="row.item.Tutar"
          :simge="row.item.ParaBirimi"
        ></c-money-cell>
      </template>
      <template #cell(GSM)="row">
        {{ row.item.GSM }}
        <c-aktif-cell :isAktif="row.item.isGsmDogrulandi"></c-aktif-cell>
      </template>
      <template #cell(Email)="row">
        {{ row.item.Email }}
        <c-aktif-cell :isAktif="row.item.isEmailDogrulandi"></c-aktif-cell>
      </template>
      <template #cell(KayitTarihi)="row">
        <c-tarih-cell
          :kayitTarihi="row.item.KayitTarihi"
          :guncelTarih="row.item.GuncelTarih"
          :rowKey="row.item.Id"
        ></c-tarih-cell>
      </template>
    </b-table>

    <b-modal
      id="kullaniciEkle"
      centered
      title="Kullanıcı Kaydı"
      @ok="kaydetSubmit"
      ok-title="Kaydet"
      cancel-title="Kapat"
      class="theme-modal"
    >
      <b-form @submit="kaydetSubmit">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tip">Tipi</label>
            <b-form-select
              id="kayit_tip"
              v-model="kayit.Tipi"
              :options="tipler"
              :state="kayit_tipi_state"
            ></b-form-select>
            <b-form-invalid-feedback :state="kayit_tipi_state"
              >Tipi seçiniz.</b-form-invalid-feedback
            >
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_adsoyad">Ad Soyad</label>
            <b-form-input
              id="kayit_adsoyad"
              v-model="kayit.AdSoyad"
              :state="kayit_adsoyad_state"
            ></b-form-input>
            <b-form-invalid-feedback :state="kayit_adsoyad_state"
              >Ad soyadı giriniz.</b-form-invalid-feedback
            >
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_durum">Durum</label>
            <b-form-select
              id="kayit_durum"
              v-model="kayit.Durum"
              :options="durumlar"
              :state="kayit_durum_state"
            ></b-form-select>
            <b-form-invalid-feedback :state="kayit_durum_state"
              >Durumunu seçiniz.</b-form-invalid-feedback
            >
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_email">Email</label>
            <b-form-input
              id="kayit_email"
              v-model="kayit.Email"
              :state="kayit_email_state"
            ></b-form-input>
            <b-form-invalid-feedback :state="kayit_email_state"
              >Email adresini giriniz.</b-form-invalid-feedback
            >
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_gsm">GSM</label>
            <b-form-input
              type="text"
              id="kayit_gsm"
              v-model="kayit.GSM"
              v-mask="'5## ### ## ##'"
              placeholder="Cep Telefonu"
              :state="kayit_gsm_state"
            ></b-form-input>
            <b-form-invalid-feedback :state="kayit_gsm_state"
              >Cep telefonunu giriniz</b-form-invalid-feedback
            >
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
            <b-form-input
              id="kayit_kullaniciadi"
              v-model="kayit.KullaniciAdi"
              :state="kayit_kullaniciadi_state"
            ></b-form-input>
            <b-form-invalid-feedback :state="kayit_kullaniciadi_state"
              >Kullanıcı adını giriniz.</b-form-invalid-feedback
            >
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_sifre"
              >Şifre
              <small class="text-warning" v-if="kayit_sifre_edit_state"
                >Şifreyi yazarsanız güncellenecektir.</small
              ></label
            >
            <b-form-input
              id="kayit_sifre"
              v-model="kayit.Sifre"
              :state="kayit_sifre_state"
            ></b-form-input>
            <b-form-invalid-feedback :state="kayit_sifre_state"
              >En az 8 ile 20 karakter arasında rakam, küçük ve büyük harf
              bulunan bir şifre giriniz</b-form-invalid-feedback
            >
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import c_money_cell from "./c_money_cell.vue";

export default {
  components: { c_money_cell },
  props: {
    kullanicilar: [],
    aracFirmaId: "",
  },
  data() {
    return {
      kullaniciFields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "TipiStr", label: "Tipi", sortable: false },
        { key: "AdSoyad", label: "Ad Soyad", sortable: false },
        { key: "DurumStr", label: "Durum", sortable: false },
        { key: "Email", label: "Email", sortable: false },
        { key: "GSM", label: "Cep Telefonu", sortable: false },
        { key: "KullaniciAdi", label: "Kullanici Adı", sortable: false },
        { key: "EkleyenKullaniciAdi", label: "Ekleyen", sortable: false },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: false },
      ],

      kayit: {
        Id: null,
        Tipi: null,
        AcenteId: null,
        AdSoyad: "",
        Durum: null,
        Email: "",
        GSM: "",
        KullaniciAdi: "",
        Sifre: "",
      },

      mutateKullanicilar: this.kullanicilar,

      durumlar: [
        { value: null, text: "Seçiniz" },
        { value: 0, text: "Yeni" },
        { value: 20, text: "Pasif" },
        { value: 30, text: "Onaylı" },
        { value: 100, text: "İptal" },
      ],
      tipler: [
        { value: null, text: "Seçiniz" },
        { value: 30, text: "Yönetici" },
        { value: 35, text: "Kullanıcı" },
      ],
    };
  },
  mounted() {},
  computed: {
    kayit_tipi_state() {
      if (this.kayit.Tipi == null) return false;
      else return true;
    },
    kayit_adsoyad_state() {
      if (this.kayit.AdSoyad == null || this.kayit.AdSoyad == "") return false;
      else return true;
    },
    kayit_durum_state() {
      if (this.kayit.Durum == null) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_kullaniciadi_state() {
      if (this.kayit.KullaniciAdi === null || this.kayit.KullaniciAdi === "")
        return false;
      else return true;
    },
    kayit_sifre_state() {
      if (
        this.kayit.Id === null &&
        !this.$validationHelper.validPassword(this.kayit.Sifre)
      )
        return false;
      else return true;
    },
    kayit_sifre_edit_state() {
      if (this.kayit.Id === null) return false;
      else return true;
    },
  },
  methods: {
    list() {
      let vm = this;

      this.$connection.get(
        "/aracFirmalari/" + this.aracFirmaId + "/kullanicilar",
        function (response) {
          vm.mutateKullanicilar = response;

          vm.guncellendi();
        }
      );
    },
    sil(kullanici) {
      let vm = this;
      this.$alert.confirm(
        "Kullanıcı Sil",
        "Kullanıcıyı silmek istediğinize emin misiniz?",
        "error",
        function () {
          vm.$connection.delete(
            "/aracFirmalari/" +
              vm.aracFirmaId +
              "/kullanicilar/" +
              kullanici.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Kullanıcı Sil",
                "Kullanıcı silindi.",
                "success",
                function () {
                  vm.$listHelper.removeItem(vm.mutateKullanicilar, kullanici);
                  vm.guncellendi();
                }
              );
            }
          );
        }
      );
    },
    yeni() {
      this.$bvModal.show("kullaniciEkle");
      this.kayit = {
        Id: null,
        Tipi: null,
        AcenteId: null,
        AdSoyad: "",
        Durum: null,
        Email: "",
        GSM: "",
        KullaniciAdi: "",
        Sifre: "",
      };
    },
    duzelt(kullanici) {
      this.$bvModal.show("kullaniciEkle");
      this.kayit = { ...kullanici };
    },
    kaydetSubmit(event) {
      event.preventDefault();

      let isValid =
        this.kayit_tipi_state &&
        this.kayit_adsoyad_state &&
        this.kayit_durum_state &&
        this.kayit_email_state &&
        this.kayit_gsm_state &&
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state;

      if (isValid) {
        let vm = this;

        let url = "/aracFirmalari/" + vm.aracFirmaId + "/kullanicilar";
        let method = "post";
        let messageTitle = "Yeni Kullanıcı";
        let yeniKayit = vm.kayit.Id === null;
        if (!yeniKayit) {
          url += "/" + vm.kayit.Id;
          method = "put";
          messageTitle = "Kullanıcı Düzelt";

          vm.kayit.SifreYeni = vm.kayit.Sifre;
        }

        vm.kayit.AcenteId = 0;

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.list();

                  vm.$bvModal.hide("kullaniciEkle");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
    guncellendi() {
      this.$emit("guncellendi", this.mutateKullanicilar);
    },
  },
};
</script>
