<template>
  <div>
    <Breadcrumbs main="Turlar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link
                      :to="`/tur/opsiyonKayit/${id}/${row.item.Id}`"
                      class="btn btn-success btn-xs"
                      >Düzelt</router-link
                    >
                    <b-button size="xs" @click="sil(row.item)" variant="danger"
                      >Sil</b-button
                    >
                  </b-btn-group>
                </template>
                <template #cell(Fiyat)="data">
                  <c-money-cell :fiyat="data.value" :simge="paraBirimi"></c-money-cell>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.KayitTarihi"
                    :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "",

      tur: {},
      paraBirimi: '₺',

      breadLinks: [
        { path: "/tur/list", title: "Turlar", icon: "chevron-left" },
        {
          path: "/tur/opsiyonKayit/" + this.$route.params.id,
          title: "Opsiyon Ekle",
          icon: "plus",
        },
      ],

      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "Adi", label: "Opsiyon Adı", sortable: true },
        { key: "Fiyat", label: "Fiyat", sortable: true },
        { key: "Sira", label: "Sıra", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],
    };
  },
  mounted() {
    let vm = this;

    this.$connection.get("/turlar/" + this.id, function (response) {
      if (response.Success) {
        vm.tur = response.Data;
        vm.pageTitle = response.Data.Adi + " Opsiyonları";

        if(vm.tur.Tipi == 10){
          vm.paraBirimi = '€';
        }
      } else {
        vm.$alert.messageWithTitle("Tur Kaydı", response.Message, "error");
      }
    });

    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      this.$connection.get(
        "/turlar/opsiyonlar/" + this.id,
        function (response) {
          vm.items = response;
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Opsiyon kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete(
            "/turlar/opsiyonlar/" + item.Id,
            function (response) {
              vm.$alert.messageWithFunction(
                "Silindi",
                "Opsiyon kaydı silindi.",
                "error",
                function () {
                  vm.$listHelper.removeItem(vm.items, item);
                }
              );
            }
          );
        }
      );
    },
  },
};
</script>
