<template>
  <div>
    <div class="button-wrapper mb-3">
      <b-button class="button" @click="$refs.file.click()">
        <input type="file" ref="file" @change="loadImage($event)" accept="image/*" style="display: none" />
        Resim Yükle
      </b-button>
    </div>
    <div v-if="image.src != null">
      <p class="lead" v-if="image.src !== null">
        Resmi küçültmek için; resmin üzerindeyken mouseun scroll tuşunu
        kullanabilirsiniz.
      </p>
      <CropNavigation :zoom="zoom" @change="onZoom" />
      <cropper ref="cropper" class="twitter-cropper" background-class="twitter-cropper__background"
        foreground-class="twitter-cropper__foreground" image-restriction="stencil" :stencil-size="stencilSize"
        :stencil-props="{
        lines: {},
        handlers: {},
        movable: false,
        resizable: false,
        scalable: false,
        previewClass: 'twitter-cropper__stencil',
      }" :transitions="false" :debounce="false" :src="image.src" @change="onChange" />
    </div>
    <b-form-invalid-feedback :state="kayit_resim_state">
      <strong>Profil resminizi düzgün, geniş ve dik bir açıdan çekip yükleyiniz.</strong><br />
      Minumum 440px 770px boyutunda olmasına özen gösteriniz.
    </b-form-invalid-feedback>
  </div>
</template>
<script>
import CropNavigation from "./c_crop_navigation.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  props: {
    resim: null,
  },
  data() {
    return {
      zoom: 0,
      image: {
        src: this.resim,
        type: null,
      },
    };
  },
  components: {
    Cropper,
    CropNavigation,
  },
  computed: {
    kayit_resim_state() {
      if (this.image.src == null) return false;
      else return true;
    },
  },
  methods: {
    loadImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: this.$validationHelper.getMimeType(
              e.target.result,
              files[0].type
            ),
          };
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: 440,
        height: 700,
      };
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }

        const { canvas } = this.$refs.cropper.getResult();
        this.$emit("onChange", canvas);
      }
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
            (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
            (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        // Do something with blob: upload to a server, download and etc.
      }, this.image.type);
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      };
    },
  },
  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
};
</script>
