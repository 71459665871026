<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive mb-0">
        <table class="table table-bordernone table-striped">
          <thead>
            <tr>
              <th scope="col">Kod</th>
              <th scope="col">Tarih</th>
              <th scope="col">Acente</th>
              <th scope="col">Milliyet / Kişi</th>
              <th scope="col">Rehber</th>
              <th scope="col">Şoför</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.Id">
              <td>
                {{ item.Kod }}
              </td>
              <td>
                <c-tarih-cell :kayitTarihi="item.Tarih" :rowKey="item.Id"></c-tarih-cell>
                <b-badge variant="primary">{{ item.Saat }}</b-badge>
              </td>
              <td>
                {{ item.AcenteAdi }}
              </td>
              <td>
                {{ item.Uyruk }}
                <b-badge variant="warning">{{ item.KisiSayisi }}</b-badge>
              </td>
              <td>
                <div v-if="item.RehberAdi !== null">
                  <div class="customers">
                    <ul class="mb-1">
                      <li class="d-inline-block">
                        <a href="#" :id="'rehber-tooltip-' + item.Id">
                          <img class="img-40 rounded-circle" :src="item.RehberResim" alt="" />
                        </a>
                      </li>
                    </ul>
                    {{ item.RehberAdi }}
                  </div>
                  <b-tooltip :target="'rehber-tooltip-' + item.Id" triggers="hover" placement="right">
                    <img class="img-fluid" :src="item.RehberResim" alt="" />
                  </b-tooltip>
                </div>
              </td>
              <td>
                <div v-if="item.SoforAdi !== null">
                  <div class="customers">
                    <ul class="mb-1">
                      <li class="d-inline-block">
                        <a href="#" :id="'arac-tooltip-' + item.Id" v-b-tooltip.hover>
                          <img class="img-40 rounded-circle" :src="item.AracResim" alt="" />
                        </a>
                      </li>
                    </ul>
                    {{ item.SoforAdi }}<br />
                    {{ item.Plaka }}
                  </div>
                  <b-tooltip :target="'arac-tooltip-' + item.Id" triggers="hover" placement="left">
                    <img class="img-fluid" :src="item.AracResim" alt="" />
                  </b-tooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tarih: null
  },
  data() {
    return {
      items: []
    };
  },
  components: {
  },
  mounted() {
    this.listele();
  },
  watch: {
    tarih(newValue, oldValue) {
      this.listele();
    },
  },
  methods: {
    listele() {
      let vm = this;

      let tarih = this.$formatHelper.formatDateApiQuery(this.tarih);

      this.$connection.get(
        "/turRezervasyonlari/filtreForMekan/" + tarih,
        function (response) {
          vm.items = response;
        }
      );
    },
  },
};
</script>
