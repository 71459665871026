<template>
  <div>
    <Breadcrumbs main="Araç Firmaları" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Araç Firma Kaydı</h5>
              <span>Yeni araç firması veya araç firması kaydında değişiklikler için
                aşağıdaki formu kullanabilirsiniz</span>
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Araç Firma Adı</label>
                    <b-form-input type="text" id="kayit_adi" v-model="kayit.Adi" :state="kayit_adi_state"
                      placeholder="Araç Firma Adı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adi_state">Araç firma adını giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Unvan</label>
                    <b-form-input type="text" id="kayit_unvan" v-model="kayit.Unvan" :state="kayit_unvan_state"
                      placeholder="Araç Firma Unvanı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_unvan_state">Araç firma unvanını
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_adi">Adres</label>
                    <b-form-textarea type="text" id="kayit_adres" v-model="kayit.Adres" :state="kayit_adres_state"
                      placeholder="Araç Firma Adresi"></b-form-textarea>
                    <b-form-invalid-feedback :state="kayit_adres_state">Araç firmasının adresini
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Vergi Dairesi</label>
                    <b-form-input type="text" id="kayit_vergidairesi" v-model="kayit.VergiDairesi"
                      placeholder="Vergi Dairesi"></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Vergi No</label>
                    <b-form-input type="text" id="kayit_vergiNo" v-model="kayit.VergiNo" :state="kayit_vergino_state"
                      placeholder="T.C. Kimlik No veya Vergi No"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_vergino_state">Bireyselse 11 haneli T.C. kimlik numarası
                      kurumsal ise 10
                      haneli vergi numarasını giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Yetkili Kişi</label>
                    <b-form-input type="text" id="kayit_yetkilikisi" v-model="kayit.YetkiliKisi"
                      :state="kayit_yetkilikisi_state" placeholder="Yetkili Kişi"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_yetkilikisi_state">Yetkili kişi adını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_email">Email</label>
                    <b-form-input type="text" id="kayit_email" v-model="kayit.Email" placeholder="Email"
                      :state="kayit_email_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_email_state">Email adresini giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_gsm">Cep Telefonu</label>
                    <b-form-input type="text" id="kayit_gsm" v-model="kayit.GSM" v-mask="'5## ### ## ##'"
                      placeholder="Cep Telefonu" :state="kayit_gsm_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_gsm_state">Cep telefonunu giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_telefon">Telefon</label>
                    <b-form-input type="text" id="kayit_telefon" v-model="kayit.Telefon"
                      placeholder="Telefon"></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_operasyonyetkilisi">Operasyon Yetkilisi</label>
                    <b-form-input type="text" id="kayit_operasyonyetkilisi" v-model="kayit.OperasyonYetkilisi"
                      placeholder="Operasyon Yetkilisi"></b-form-input>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_operasyontel">Operasyon Telefonu</label>
                    <b-form-input type="text" id="kayit_operasyontel" v-model="kayit.OperasyonTel"
                      placeholder="Operasyon Telefonu"></b-form-input>
                  </div>
                </div>
                <!--<div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_alisucreti">Servis Alış Ücreti</label>
                    <b-form-input type="text" id="kayit_alisucreti" v-model="kayit.AlisUcreti"
                      :state="kayit_alisucreti_state" placeholder="Servis Alış Ücreti"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_alisucreti_state">Servis alış ücretiniz
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_satisucreti">Servis Satış Ücreti</label>
                    <b-form-input type="text" id="kayit_satisucreti" v-model="kayit.SatisUcreti"
                      :state="kayit_satisucreti_state" placeholder="Servis Satış Ücreti"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_satisucreti_state">Servis satış ücretini alış ücretinden
                      büyük
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>-->
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label>Durum</label>
                    <b-form-select id="kayit_durum" v-model="kayit.Durum"
                      :options="kayit_durum_options"></b-form-select>
                  </div>
                </div>
                <div class="form-row" v-if="id === undefined">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
                    <b-form-input type="text" id="kayit_kullaniciadi" v-model="kayit.KullaniciAdi"
                      placeholder="Kullanıcı Adı" :state="kayit_kullaniciadi_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_kullaniciadi_state">Ana araç firması kullanıcısının kullanıcı
                      adını
                      giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_sifre">Şifre</label>
                    <b-form-input type="text" id="kayit_sifre" v-model="kayit.Sifre" placeholder="Şifre"
                      :state="kayit_sifre_state"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_sifre_state">Ana araç firması kullanıcısı için 6 ile 20
                      karakter
                      arasında rakam, küçük ve büyük harf bulunan bir şifre
                      giriniz</b-form-invalid-feedback>
                  </div>
                </div>
                <router-link :to="'/aracFirma/list'" class="btn btn-light pull-left">Geri Dön</router-link>
                <b-button type="submit" class="pull-right" variant="primary">Kaydet</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Araç Firma",

      breadLinks: [
        {
          path: "/AracFirma/list",
          title: "Araç Firmaları",
          icon: "chevron-left",
        },
      ],

      kayit: {
        Adi: "",
        Unvan: "",
        YetkiliKisi: "",
        Adres: "",
        VergiDairesi: "",
        VergiNo: "",
        Telefon: "",
        GSM: "",
        Email: "",
        Durum: 30,
        OperasyonYetkilisi: "",
        OperasyonTel: "",
        EntegrasyonAyarlariJson: "",
        /*AlisUcreti: 0,
        SatisUcreti: 0,*/
        KullaniciAdi: "",
        Sifre: "",
      },

      kayit_durum_options: [
        { value: "0", text: "Yeni" },
        { value: "20", text: "Pasif" },
        { value: "30", text: "Onaylı" },
        { value: "100", text: "İptal" },
      ],
    };
  },
  mounted() {
    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Araç Firma Düzelt";

      this.$connection.get("/AracFirmalari/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle(
            "Yeni Araç Firma",
            response.Message,
            "error"
          );
        }
      });
    }
  },
  computed: {
    kayit_adi_state() {
      if (this.kayit.Adi == null || this.kayit.Adi == "") return false;
      else return true;
    },
    kayit_unvan_state() {
      if (this.kayit.Unvan == null || this.kayit.Unvan == "") return false;
      else return true;
    },
    kayit_yetkilikisi_state() {
      if (this.kayit.YetkiliKisi == null || this.kayit.YetkiliKisi == "")
        return false;
      else return true;
    },
    kayit_adres_state() {
      if (this.kayit.Adres == null || this.kayit.Adres == "") return false;
      else return true;
    },
    kayit_vergino_state() {
      if (
        this.kayit.VergiNo == null ||
        this.kayit.VergiNo == "" ||
        this.kayit.VergiNo.length < 10 ||
        this.kayit.VergiNo.length > 11
      )
        return false;
      else return true;
    },
    kayit_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayit.GSM)) return false;
      else return true;
    },
    kayit_email_state() {
      if (!this.$validationHelper.validEmail(this.kayit.Email)) return false;
      else return true;
    },
    /*kayit_alisucreti_state() {
      if (
        this.kayit.AlisUcreti === "" ||
        this.kayit.AlisUcreti === null ||
        parseFloat(this.kayit.AlisUcreti) === 0
      )
        return false;
      else return true;
    },
    kayit_satisucreti_state() {
      if (
        this.kayit.SatisUcreti === "" ||
        this.kayit.SatisUcreti === null ||
        parseFloat(this.kayit.SatisUcreti) === 0 ||
        parseFloat(this.kayit.SatisUcreti) < parseFloat(this.kayit.AlisUcreti)
      )
        return false;
      else return true;
    },*/
    kayit_kullaniciadi_state() {
      if (
        this.id === undefined &&
        (this.kayit.KullaniciAdi === null || this.kayit.KullaniciAdi === "")
      )
        return false;
      else return true;
    },
    kayit_sifre_state() {
      if (
        this.id === undefined &&
        !this.$validationHelper.validPassword(this.kayit.Sifre)
      )
        return false;
      else return true;
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_adi_state &&
        this.kayit_unvan_state &&
        this.kayit_yetkilikisi_state &&
        this.kayit_adres_state &&
        this.kayit_vergino_state &&
        this.kayit_gsm_state &&
        this.kayit_email_state &&
        /*this.kayit_alisucreti_state &&
        this.kayit_satisucreti_state &&*/
        this.kayit_kullaniciadi_state &&
        this.kayit_sifre_state;

      if (isValid) {
        let vm = this;

        let url = "/AracFirmalari";
        let method = "post";
        let messageTitle = "Yeni Araç Firma";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Değişiklikler Güncellendi.";
        }

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                '',
                "success",
                function () {
                  vm.$router.push("/AracFirma/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
