<template>
  <div class="card rezervasyon-form">
    <div class="card-body">
      <form-wizard color="#4466f2" :start-index="mutateTab" back-button-text="Geri Dön" next-button-text="İleri"
        :finish-button-text="finishButtonText" @on-complete="onComplete()" @on-validate="mergePartialModels"
        @on-change="wizardTabChange" ref="balonRezervasyonWizard" scope="props">
        <div slot="title">
          <div class="pull-right">
            <b-button @click="cikis()" variant="dark"><i class="fa fa-times"></i></b-button>
          </div>
        </div>
        <tab-content title="Tur" :before-change="() => validateStep('tur')" @on-validate="mergePartialModels">
          <div class="setup-content" id="step-1">
            <b-alert show variant="danger" class="alert dark" v-if="turValidMessage != ''">
              <p>{{ turValidMessage }}</p>
            </b-alert>
            <div class="row mega-inline">
              <div class="col-md-12">
                <b-form-group class="mb-3">
                  <div class="row">
                    <div class="col-sm-12" v-for="balon in balonFirmalari" :key="balon.Id">
                      <div class="card">
                        <div class="media p-20">
                          <div class="radio">
                            <b-form-radio name="BalonFirmaId" @change="balonDegistir(balon)"
                              v-model="kayit.BalonFirmaId" :value="balon.Id">
                              <div class="media-body">
                                <h6 class="mt-0 mega-title-badge">
                                  {{ balon.Adi }}
                                </h6>
                                <p v-html="balon.Aciklama"></p>
                              </div>
                            </b-form-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Detay" :before-change="() => validateStep('detay')">
          <div class="setup-content" id="step-2">
            <b-alert show variant="danger" class="alert dark" v-if="detayValidMessage != ''">
              <p>{{ detayValidMessage }}</p>
            </b-alert>

            <div class="row">
              <div class="col-md-12">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tarih">Tarih</label>
                    <b-form-datepicker v-model="kayit.Tarih" @context="tarihDegisti()" start-weekday="1" :min="min"
                      :max="max" locale="tr-TR" :state="kayit_tarih_state" label-no-date-selected="TUR TARİHİNİ GİRİNİZ"
                      label-help=""></b-form-datepicker>
                    <b-form-invalid-feedback :state="kayit_tarih_state">Tarih giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <h3 class="mt-4" v-if="fiyat_state_kontenjan">Kişi Başı Fiyat : <b-badge variant="info">{{ fiyat }}
                        €</b-badge></h3>
                    <h5 class="mt-4 text-danger" v-if="!fiyat_state_kontenjan">BU TARİHTE MÜSAİTLİK BULUNMAMAKTADIR</h5>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_oteladi">Otel Adı</label>
                    <b-form-input type="text" id="kayit_oteladi" v-model="kayit.OtelAdi" :state="kayit_oteladi_state"
                      placeholder="Otel Adı"></b-form-input>
                    <b-form-invalid-feedback :state="kayit_oteladi_state">Otel adını giriniz</b-form-invalid-feedback>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_oteladres">Otel Adresi</label>
                    <b-form-input type="text" id="kayit_oteladres" v-model="kayit.OtelAdresi"
                      placeholder="Otel Adresi"></b-form-input>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_aciklama">Açıklama</label>
                    <b-form-textarea id="kayit_aciklama" v-model="kayit.Aciklama"
                      placeholder="Açıklama"></b-form-textarea>
                  </div>
                </div>
                <hr />
                <div class="form-row">
                  <div class="col-md-3 mb-5 b-primary b-r-3 p-2">
                    <h4 class="f-w-600">Misafirler Ekle</h4>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_pasaport">Pasaport No / T.C. Kimlik No</label>
                        <b-form-input type="text" id="kayit_yolcu_pasaport" v-model="misafirKayit.PasaportNo"
                          placeholder="Pasaport No / T.C. Kimlik No"
                          :state="misafirekle_pasaportno_state"></b-form-input>
                        <b-form-invalid-feedback :state="misafirekle_pasaportno_state">Pasaport veya T.C. kimlik
                          numarasını zorunludur lütfen giriniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_adsoyad">Adı Soyadı</label>
                        <b-form-input type="text" id="kayit_yolcu_adsoyad" v-model="misafirKayit.AdSoyad"
                          placeholder="Adı Soyadı" :state="misafirekle_adsoyad_state"></b-form-input>
                        <b-form-invalid-feedback :state="misafirekle_adsoyad_state">Misafirin adını ve soyadını
                          giriniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_uyruk">Uyruk</label>
                        <multiselect id="kayit_yolcu_uyruk" v-model="misafirKayit.UyrukSelect"
                          tag-placeholder="Uyruk Seçiniz" placeholder="Uyruk seçiniz" label="text" selectLabel=""
                          deselectLabel="" selectedLabel="" track-by="value" :options="ulkeler"></multiselect>
                        <b-form-invalid-feedback :state="misafirekle_uyruk_state">Misafirin uyruğunu
                          seçiniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_yas">Yaş</label>
                        <b-form-select id="kayit_yolcu_yas" v-model="misafirKayit.Yas" :options="yas_options"
                          :state="misafirekle_yas_state"></b-form-select>
                        <b-form-invalid-feedback :state="misafirekle_yas_state">Misafirin yaş durumunu
                          seçiniz.</b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label for="kayit_yolcu_telefon">Telefon</label>
                        <b-form-input type="text" id="kayit_yolcu_telefon" v-model="misafirKayit.Telefon"
                          placeholder="Telefon"></b-form-input>
                      </div>
                    </div>
                    <b-button type="button" @click="misafirEkle()" variant="info">Ekle</b-button>
                  </div>
                  <div class="col-md-9 mb-5">
                    <h4 class="f-w-600">
                      Misafirler
                      <b-badge variant="primary">{{ kayit.Misafirler.length }} kişi</b-badge>
                    </h4>

                    <div class="alert alert-info" v-if="kayit.Misafirler.length === 0">
                      Bu Tur İçin Misafir Girilmemiş
                    </div>

                    <div class="form-row">
                      <div class="col-md-4" v-for="row in kayit.Misafirler" v-bind:key="row.Id">
                        <div class="mb-2 b-dark b-r-3 p-2">
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_pasaport">Pasaport No / T.C. Kimlik No</label>
                              <b-form-input type="text" v-model="row.PasaportNo"
                                placeholder="Pasaport No / T.C. Kimlik No"></b-form-input>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_adsoyad">Adı Soyadı</label>
                              <b-form-input type="text" v-model="row.AdSoyad" placeholder="Adı Soyadı"></b-form-input>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_uyruk">Uyruk</label>
                              <multiselect v-model="row.UyrukSelect" tag-placeholder="Uyruk Seçiniz"
                                placeholder="Uyruk seçiniz" :id="row.Id" label="text" track-by="value"
                                :options="ulkeler" selectLabel="" deselectLabel="" selectedLabel="">
                              </multiselect>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_yas">Yaş</label>
                              <b-form-select v-model="row.Yas" :options="yas_options"></b-form-select>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <label for="kayit_yolcu_telefon">Telefon</label>
                              <b-form-input type="text" v-model="row.Telefon" placeholder="Telefon"></b-form-input>
                            </div>
                          </div>
                          <b-button type="button" @click="misafirKaldir(row)" variant="danger">Kaldır</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Önizleme" :before-change="() => validateStep('onizleme')">
          <div class="setup-content" id="step-5" v-if="turHesapla !== undefined">
            <div class="row">
              <div class="col-md-12">
                <b-card header-tag="div" header-bg-variant="info">
                  <h5 slot="header">{{ activeBalonFirma.Adi }}</h5>
                  <p v-html="activeBalonFirma.Aciklama"></p>
                  <hr>
                  <h5>Otel : <strong>{{ kayit.OtelAdi }} <small>{{ kayit.OtelAdresi }}</small></strong></h5>
                  <h5 class="text-info">Tarih : {{ $moment(String(kayit.Tarih)).format('DD.MM.YYYY') }}</h5>
                  <hr>
                  <h5 class="text-danger">BELİRTİLEN FİYATLARA KDV DAHİL DEĞİLDİR.</h5>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Toplam Tutar</th>
                        <td>
                          <b-badge variant="success"><c-money-cell :fiyat="turHesapla.SatisToplamTutar"
                              simge="€"></c-money-cell></b-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card>
              </div>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import { BFormTimepicker } from "bootstrap-vue";
import Multiselect from "vue-multiselect";

export default {
  props: {
    rezervasyon: {},
    acenteId: "",
    tab: 0,
    id: undefined,
    misafirler: null
  },
  data() {
    return {
      mutateTab: this.tab === undefined ? 0 : parseInt(this.tab),

      finishButtonText: "Oluştur",

      turValidMessage: "",
      detayValidMessage: "",

      date_format: "dd.MM.yyyy",
      tr: tr,

      min: null,
      max: null,

      mutationAcenteId: this.acenteId,

      fiyat: 0,

      kayit: {
        ...(this.rezervasyon === undefined
          ? {
            BalonFirmaId: null,
            Tarih: null,
            Aciklama: "",
            OtelAdi: "",
            OtelAdresi: "",
            KisiSayisi: 0,
            Misafirler: []
          }
          : this.rezervasyon),
      },

      turHesapla: undefined,

      finalModel: {},

      activeBalonFirma: {},

      balonFirmalari: [],

      yas_options: [],

      ulkeler: [{ value: "", text: "Uyruk" }],

      misafirKayit: {
        Id: 0,
        PasaportNo: "",
        Uyruk: "",
        UyrukSelect: { value: "", text: "Uyruk" },
        AdSoyad: "",
        Yas: 2,
        Telefon: "",
      }
    };
  },
  mounted() {
    let now = new Date();
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let minDate = new Date(today);

    let maxDate = new Date(today);
    if (now.getDate() >= 20) {
      //Bugün 20'si ise bir sonraki ayı açalım
      maxDate.setMonth(maxDate.getMonth() + 2);
      maxDate.setDate(1);
      maxDate.setDate(maxDate.getDate() - 1);
    } else {
      maxDate.setMonth(maxDate.getMonth() + 1);
      maxDate.setDate(1);
      maxDate.setDate(maxDate.getDate() - 1);
    }

    this.min = minDate;
    this.max = maxDate;

    if (this.rezervasyon === undefined) {
      this.finishButtonText = "Oluştur";
      this.kayit.Tarih = today;
    } else {
      this.finishButtonText = "Düzelt";

      for (let i = 0; i < this.rezervasyon.Misafirler.length; i++) {
        const element = this.rezervasyon.Misafirler[i];
        element.UyrukSelect = { value: element.Uyruk, text: element.Uyruk };
      }
    }

    if (this.acenteId === 0) {
      this.mutationAcenteId = this.$user.acenteId();
    }

    if (this.misafirler != null) {
      this.kayit.Misafirler = misafirler;
    }

    this.balonFirmaList();

    if (this.mutateTab > 0) {
      this.wizardTabChange(this.mutateTab - 1, this.mutateTab);
    }

    this.onYasList();
    this.ulkeList();
  },
  components: {
    Datepicker,
    BFormTimepicker,
    Multiselect,
  },
  watch: {
    misafirler: function () {
      this.resetForm();
      this.kayit.Misafirler = structuredClone(this.misafirler);
    }
  },
  computed: {
    kayit_balonfirmaid_state() {
      if (this.kayit.BalonFirmaId == "" || this.kayit.BalonFirmaId == null) return false;
      else return true;
    },
    kayit_tarih_state() {
      if (this.kayit.Tarih == "" || this.kayit.Tarih == null) return false;
      else return true;
    },
    kayit_oteladi_state() {
      if (this.kayit.OtelAdi == "" || this.kayit.OtelAdi == null) return false;
      else return true;
    },
    fiyat_state_kontenjan() {
      if (this.fiyat == 0) return false;
      else return true;
    },
    kayit_misafir_state() {
      if (this.kayit.Misafirler == null || this.kayit.Misafirler.length == 0)
        return false;
      else return true;
    },
    misafirekle_pasaportno_state() {
      if (
        this.misafirKayit.PasaportNo == "" ||
        this.misafirKayit.PasaportNo == null
      )
        return false;
      else return true;
    },
    misafirekle_adsoyad_state() {
      if (this.misafirKayit.AdSoyad == "" || this.misafirKayit.AdSoyad == null)
        return false;
      else return true;
    },
    misafirekle_uyruk_state() {
      if (
        this.misafirKayit.UyrukSelect == null ||
        this.misafirKayit.UyrukSelect.value == ""
      )
        return false;
      else return true;
    },
    misafirekle_yas_state() {
      if (this.misafirKayit.Yas == "" || this.misafirKayit.Yas == null)
        return false;
      else return true;
    },
  },
  methods: {
    resetForm() {
      this.kayit = {
        BalonFirmaId: null,
        Tarih: this.addDays(1),
        Aciklama: "",
        OtelAdi: "",
        OtelAdresi: "",
        KisiSayisi: 0,
        Misafirler: []
      };
      this.finishButtonText = "Oluştur";
      this.mutateTab = 0;
      this.turValidMessage = "";
      this.detayValidMessage = "";
      this.turHesapla = undefined;
      this.finalModel = {};
      this.activeBalonFirma = {};

      this.$refs.balonRezervasyonWizard.reset();
    },
    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
    formatTarih(tarih) {
      return this.$moment(String(tarih)).format("YYYY-MM-DD");
    },
    validateStep(name) {
      switch (name) {
        case "tur":
          this.turValidMessage = "";
          if (!this.kayit_balonfirmaid_state) {
            this.turValidMessage = "Tur Seçiniz.";
            return false;
          }
          break;

        case "detay":
          this.detayValidMessage = "";

          if (!this.kayit_tarih_state) {
            this.detayValidMessage = "Tarih giriniz.";
            return false;
          }

          if (!this.fiyat_state_kontenjan) {
            this.detayValidMessage = "Bu tarihte kontenjan doludur.";
            return false;
          }

          if (!this.kayit_misafir_state) {
            this.detayValidMessage = "En az 1 misafir giriniz.";
            return false;
          }

          if (!this.kayit_oteladi_state) {
            this.detayValidMessage = "Otel adını yazınız.";
            return false;
          }
          break;

        default:
          break;
      }
      return true;
    },
    mergePartialModels(model, isValid) {
      return isValid;
    },
    wizardTabChange: function (prevIndex, nextIndex) {
      switch (nextIndex) {
        case 0:
          return true;
        case 1:
          this.tarihDegisti();
          break;
        case 2:
          this.detayHesapla();
          break;

        default:
          break;
      }
      window.scrollTo({ top: 0, left: 0 });
    },
    addDays(days) {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    tarihDegisti() {
      if (this.kayit.BalonFirmaId != null && Number.isInteger(this.kayit.BalonFirmaId)) {
        let vm = this;

        let tarih = this.$formatHelper.formatDate(this.kayit.Tarih);
        let arrTarih = tarih.split(".");
        let sTarih = arrTarih[2] + "-" + arrTarih[1] + "-" + arrTarih[0];

        this.$connection.get("/balonFirmalari/kontenjan/fiyat/" + this.kayit.BalonFirmaId + "/" + sTarih, function (response) {
          vm.fiyat = response;
        });
      }
    },
    balonFirmaList() {
      let vm = this;
      this.$connection.get("/balonFirmalari/forSale", function (response) {
        vm.balonFirmalari = response;

        if (vm.kayit.BalonFirmaId > 0) {
          vm.activeBalonFirma = vm.balonFirmalari.filter((item) => {
            return item.Id === vm.kayit.BalonFirmaId;
          })[0];
        }
      });
    },
    balonDegistir(balonFirma) {
      this.activeBalonFirma = balonFirma;
      this.$refs.balonRezervasyonWizard.nextTab();
    },
    onYasList() {
      let vm = this;

      this.$connection.get("/parametreler/yaslar", function (response) {
        vm.yas_options = response;
      });
    },
    ulkeList() {
      let vm = this;
      this.$connection.get("/ulkeler/select", function (response) {
        vm.ulkeler = vm.ulkeler.concat(response);
      });
    },
    misafirEkle() {
      let isValid =
        this.misafirekle_pasaportno_state &&
        this.misafirekle_adsoyad_state &&
        this.misafirekle_uyruk_state &&
        this.misafirekle_yas_state;

      if (isValid) {
        this.misafirKayit.Uyruk = this.misafirKayit.UyrukSelect.value;
        this.misafirKayit.Id = this.kayit.Misafirler.length + 1;

        this.kayit.Misafirler.push(this.misafirKayit);

        this.misafirKayit = {
          Id: 0,
          PasaportNo: "",
          AdSoyad: "",
          Uyruk: this.misafirKayit.UyrukSelect.value,
          UyrukSelect: this.misafirKayit.UyrukSelect,
          Yas: this.misafirKayit.Yas,
          Telefon: this.misafirKayit.Telefon,
        };
      }
    },
    misafirKaldir(misafir) {
      this.$listHelper.removeItem(this.kayit.Misafirler, misafir);
    },
    detayHesapla() {
      let vm = this;

      let url = "/balonRezervasyonlari/hesapla/" + this.mutationAcenteId;
      let method = "post";
      let messageTitle = "Rezervasyon Detayları Hesapla";

      let kayitClone = { ...this.kayit };

      kayitClone.BalonFirmaId = this.activeBalonFirma.EncId;
      kayitClone.KisiSayisi = kayitClone.Misafirler.length;

      for (let i = 0; i < kayitClone.Misafirler.length; i++) {
        const element = kayitClone.Misafirler[i];
        element.Uyruk = element.UyrukSelect.value;
      }

      vm.$connection.post(
        url,
        kayitClone,
        method,
        function (response) {
          vm.turHesapla = response.Data;
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
    basaDon() {
      this.mutateTab = 0;
    },
    cikis() {
      let vm = this;
      this.$alert.confirm(
        "İptal",
        "Rezervasyondan vazgeçiyorsunuz yapmış olduğunuz değişiklikler kaybolacaktır. Emin misiniz?",
        "error",
        function () {
          if (vm.id == undefined) {
            vm.$router.push("/");
          } else {
            vm.$router.push("/balonRezervasyon/detay/" + vm.id);
          }
        }
      );
    },
    onComplete() {
      let isValid =
        this.kayit_balonfirmaid_state &&
        this.kayit_tarih_state &&
        this.kayit_oteladi_state &&
        this.fiyat_state_kontenjan;

      if (!isValid) {
        this.$alert.messageWithTitle(
          "Eksik Bilgi",
          "Lütfen rezervasyon bilgilerinizi kontrol ediniz.",
          "error"
        );
      } else {
        this.kayit.BalonFirmaId = this.activeBalonFirma.EncId;
        this.kayit.KisiSayisi = this.kayit.Misafirler.length;

        for (let i = 0; i < this.kayit.Misafirler.length; i++) {
          const element = this.kayit.Misafirler[i];
          element.Uyruk = element.UyrukSelect.value;
        }

        let tarih = this.$formatHelper.formatDate(this.kayit.Tarih);
        let arrTarih = tarih.split(".");
        this.kayit.Tarih = arrTarih[2] + "-" + arrTarih[1] + "-" + arrTarih[0];

        this.$emit("onComplete", this.kayit);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.tur-opsiyon-zemin,
.radio label {
  display: block !important;
}

.vue-form-wizard .wizard-nav-pills>li>a .stepTitle {
  color: #333;
  font-weight: bold;
}

.vue-form-wizard .wizard-btn.wizard-footer-first {
  background-color: #e1e1e1 !important;
  border-color: #e1e1e1 !important;
  color: #333 !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-back {
  background-color: #333 !important;
  border-color: #333 !important;
  color: #fff !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-cancel {
  background-color: #ff5370 !important;
  border-color: #ff5370 !important;
  color: #fff !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-next {
  color: #fff !important;
}

.vue-form-wizard .wizard-btn.wizard-footer-finish {
  background-color: #22af47 !important;
  border-color: #22af47 !important;
  color: #fff !important;
}
</style>