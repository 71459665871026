<template>
  <div>
    <Breadcrumbs main="Folyo" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-card
            header-tag="div"
            header-bg-variant="primary"
            class="card-absolute"
          >
            <h5 slot="header">Folyo</h5>
            <b-card-body class="p-1">
              <AcenteFolyo
                :acente="acente"
                v-if="isFolyoLoad"
                :noEdit="true"
              ></AcenteFolyo>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AcenteFolyo from "@/components/c_acente_folyo";

export default {
  data() {
    return {
      pageTitle: "Folyo",

      id: this.$route.query.id,

      breadLinks: [],

      acente: {},

      folyolar: [],
      isFolyoLoad: false,
    };
  },
  components: {
    AcenteFolyo,
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    onDetay() {
      let vm = this;

      let url = "/acenteler/profil";
      if (this.id !== undefined) {
        url = "/acenteler/" + this.id;
      }

      this.$connection.get(url, function (response) {
        if (response.Success) {
          vm.acente = response.Data;

          vm.folyoList();
        } else {
          vm.$alert.messageWithTitle("Acente", response.Message, "error");
        }
      });
    },
    folyoList() {
      this.isFolyoLoad = true;
    },
  },
};
</script>
