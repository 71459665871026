<template>
  <div>
    <Breadcrumbs main="Restoranlar" title="Liste" />

    <div class="container-fluid">
      <div class="product-wrapper-grid">
        <div class="row">
          <div
            class="col-lg-3 col-md-4"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="card">
              <div class="product-box">
                <div class="product-img">
                  <img class="img-fluid" :src="item.Resim" :alt="item.Adi" />
                  <div class="product-hover">
                    <ul>
                      <li>
                        <button
                          class="btn"
                          @click="quickView(item)"
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                        >
                          <i class="icon-eye"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="product-details">
                  <h4>{{ item.Adi }}</h4>
                  <p v-html="item.Adres"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal size="lg" v-model="modalShow" hide-footer hide-header>
        <button
          class="close"
          type="button"
          v-on:click="quickViewClose(modalShow)"
        >
          <span>×</span>
        </button>
        <div class="product-box quickview row">
          <div class="product-img col-md-6">
            <img class="img-fluid" :src="restoran.Resim" :alt="restoran.Adi" />
          </div>
          <div class="product-details col-md-6 text-left">
            <h1>{{ restoran.Adi }}</h1>
            <div class="product-view">
              <h6 class="f-w-600">Restoran Detayları</h6>
              <p class="mb-0" v-html="restoran.Adres"></p>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [],

      modalShow: false,

      restoran: {},
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      this.$connection.get("/restoranlar/detayListele", function (response) {
        vm.items = response;
      });
    },
    quickView: function (item) {
      this.modalShow = true;

      let vm = this;

      this.$connection.get("/restoranlar/" + item.Id, function (response) {
        if (response.Success) {
          vm.restoran = response.Data;
          return vm.restoran;
        }
      });
    },
    quickViewClose: function () {
      this.modalShow = false;
    },
  },
};
</script>
