<template>
  <div>
    <Breadcrumbs
      main="Rehberler"
      title="Müsaitlik Takvimi"
      :links="breadLinks"
    />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-card
            header-tag="div"
            header-bg-variant="primary"
            class="card-absolute"
          >
            <h5 slot="header">Müsaitlik Takvimi</h5>
            <b-card-body class="p-1">
              <AracTakvim :aracId="arac.Id" v-if="arac.Id !== undefined" />
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AracTakvim from "@/components/c_arac_takvim.vue";

export default {
  data() {
    return {
      breadLinks: [],

      arac: {},
    };
  },
  mounted() {
    this.onDetay();
  },
  components: {
    AracTakvim,
  },
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get("/araclar/profil", function (response) {
        if (response.Success) {
          vm.arac = response.Data;
        } else {
          vm.$alert.messageWithTitle("Rehber", response.Message, "error");
        }
      });
    },
  },
};
</script>
