<template>
  <div>
    <b-badge variant="info">{{ yasStr }}</b-badge>
  </div>
</template>
<script>
export default {
  props: {
    yas: 0
  },
  data() {
    return {
      yasStr: "",
    };
  },
  mounted() {
    switch (this.yas) {
      case 0:
        this.yasStr = "Bebek";
        break;
      case 1:
        this.yasStr = "Çocuk";
        break;
      case 2:
        this.yasStr = "Yetişkin";
        break;
      default:
        break;
    }
  },
};
</script>
