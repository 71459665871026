<template>
  <div class="reklamlar">
    <div v-for="banner in bannerlar">
      <a :href="banner.Link" target="_blank">
        <img :src="banner.Gorsel" :alt="banner.Baslik">
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bannerTipi: 0
  },
  data() {
    return {
      bannerlar: []
    }
  },
  mounted() {
    this.bannerList();
  },
  methods: {
    bannerList() {
      let vm = this;

      this.$connection.get(
        "/bannerlar/allForView/" + this.bannerTipi,
        function (response) {
          vm.bannerlar = response;
        }
      );
    }
  },
};
</script>
