<template>
  <div>
    <Breadcrumbs main="Transfer Rezervasyonu" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
            <li class="nav-item" v-on:click="changetab('transfer')" v-if="isTransferShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'transfer' }" id="top-transfer" data-toggle="tab"
                role="tab" aria-controls="transfer" aria-selected="true">Rezervasyon
                <b-badge variant="primary">{{ detay.Kod }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetab('seyehat')" v-if="isTransferShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'seyehat' }" id="top-seyehat" data-toggle="tab"
                role="tab" aria-controls="seyehat" aria-selected="true">Seyehatler <b-badge variant="primary">{{
      detay.Detay.Rezervasyon.isCiftYon ? 'Çift Yön' : 'Tek Yön' }}</b-badge>
              </a>
            </li>
            <li class="nav-item" v-on:click="changetab('misafir')" v-if="isMisafirShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'misafir' }" id="top-misafir" data-toggle="tab"
                role="tab" aria-controls="misafir" aria-selected="false">Misafirler <b-badge variant="primary">{{ detay.Kod }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetab('islem')" v-if="isIslemShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'islem' }" id="top-islem" data-toggle="tab"
                role="tab" aria-controls="islem" aria-selected="false">İşlemler <b-badge variant="primary">{{ detay.Kod }}</b-badge></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div class="tab-pane fade" :class="{ 'show active': showtab == 'transfer' }" id="transfer" role="tabpanel"
          aria-labelledby="transfer" v-if="detay.Detay !== undefined">
          <div class="row">
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Rezervasyon Bilgileri</h5>
                <table class="product-page-width">
                  <tbody>
                    <tr>
                      <th>Kod</th>
                      <td>
                        <b-badge variant="primary">{{ detay.Kod }}</b-badge>
                      </td>
                    </tr>
                    <tr>
                      <th>Durum</th>
                      <td>
                        <b-badge variant="primary">{{ detay.DurumStr }}</b-badge>
                      </td>
                    </tr>
                    <tr>
                      <th>Son İşlem Yapan Kullanıcı</th>
                      <td>{{ detay.KullaniciAdi }}</td>
                    </tr>
                    <tr>
                      <th>Kayıt Tarihi</th>
                      <td>
                        <c-tarih-cell :kayitTarihi="detay.KayitTarihi" :rowKey="detay.Id"></c-tarih-cell>
                      </td>
                    </tr>
                    <tr>
                      <th>Güncelleme Tarihi</th>
                      <td>
                        <c-tarih-cell :kayitTarihi="detay.GuncelTarih" :rowKey="detay.Id"></c-tarih-cell>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="success" class="card-absolute">
                <h5 slot="header">Acente</h5>
                <table class="product-page-width">
                  <tbody>
                    <tr>
                      <th>Acente Adı</th>
                      <td>{{ detay.AcenteAdi }}</td>
                    </tr>
                    <tr>
                      <th>Operasyon Telefonu</th>
                      <td>0530 010 81 11</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Misafirler veya tur ile ilgili herhangi bir durumda
                  iletişime geçiniz.
                </p>
                <router-link :to="`/acente/detay/${detay.AcenteId}`" v-if="isBtnAcenteDetay"
                  class="btn btn-info pull-right">Acente Detayı</router-link>
              </b-card>

              <b-list-group>
                <b-list-group-item router-component-name="router-link" tag="a" v-if="isBtnYonetim" href="#" @click="laalGuncelle()">
                  <feather type="edit-3"></feather> Detay Güncelle
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'seyehat' }" id="seyehat" role="tabpanel"
          aria-labelledby="seyehat" v-if="detay.Detay !== undefined">
          <div class="row">
            <CTransferRezervasyonSeyahat :pTransfer="detay" v-if="detay !== undefined" />
            <CTransferRezervasyonSeyahat :pTransferId="detay.TersTransferId" v-if="detay !== undefined && detay.isCiftYon" />
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'misafir' }" id="misafir" role="tabpanel"
          aria-labelledby="misafir" v-if="detay.Detay !== undefined">
          <b-alert :show="detay.isCiftYon" variant="warning" class="mb-3">Bu transfer rezervasyonunda yapılacak yolcu değişiklikleri sadece <b-badge variant="primary">{{ detay.Kod }}</b-badge> kodlu rezervasyonu etkileyecektir.</b-alert>
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Misafirler</h5>
                <b-button variant="success" class="pull-left mb-3" v-if="visible_yolcu_state" @click="yolcuDuzeltAc(null)">Yeni Yolcu
                  Ekle</b-button>
                <b-button variant="primary" class="pull-right mb-3" @click="misafirListAc()">Misafir Listesi
                  Yazdır</b-button>
                <b-table striped hover :fields="misafirFields" :items="detay.Detay.Yolcular">
                  <template #cell(Islem)="row">
                    <b-button size="xs" @click="yolcuDuzeltAc(row.item)" v-if="visible_yolcu_state" variant="success">Düzelt</b-button>
                  </template>
                </b-table>
              </b-card>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'islem' }" id="islem" role="tabpanel"
          aria-labelledby="islem">
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="dark" class="card-absolute">
                <h5 slot="header">İşlemler</h5>
                <b-card-body>
                  <b-table striped hover :fields="islemFields" :items="detay.Islemler">
                    <template #cell(isYonetimGorsun)="data">
                      <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
                    </template>
                    <template #cell(Tarih)="row">
                      <c-tarih-cell :kayitTarihi="row.item.Tarih" :rowKey="row.item.Id"></c-tarih-cell>
                    </template>
                  </b-table>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>


      <b-modal id="misafirListeDetay" size="xl" centered title="Yolcu Listesi" @ok="misafirListYazdir" ok-title="Yazdır"
        cancel-title="Kapat" class="theme-modal">
        <CTransferRezervasyonMisafirPrint :rezervasyon="detay" />
      </b-modal>

      <b-modal id="yolcuDuzeltForm" size="lg" centered title="Yolcu Ekle/Düzelt" class="theme-modal"
        :hide-footer="true">
        <CTransferRezervasyonYolcuDuzelt :rezervasyon="detay.Detay.Rezervasyon" :yolcu="duzeltYolcu"
          @onComplete="yolcuEkleDuzelt" v-if="duzeltYolcu != null" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import CTransferRezervasyonMisafirPrint from "@/components/c_transfer_rezervasyon_misafir_print";
import CTransferRezervasyonYolcuDuzelt from '@/components/c_transfer_rezervasyon_yolcu_form';
import CTransferRezervasyonSeyahat from '@/components/c_transfer_rezervasyon_seyahat';

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Transfer",

      breadLinks: [
        {
          path: "/transfer/list",
          title: "Transfer Rezervasyonları",
          icon: "chevron-left",
        },
      ],

      loading: false,

      isTransferShow: false,
      isMisafirShow: false,
      isDetayShow: true, //Herkes görecek
      isIslemShow: false,
      isBtnDuzelt: false,
      isBtnAcenteDetay: false,
      isBtnYonetim: false,
      isBtnIptal: false,

      duzeltYolcu: null,

      showtab: "transfer",

      misafirFields: [
        { key: "Islem", label: "İşlem", sortable: false },
        { key: "AdSoyad", label: "Ad Soyad", sortable: false },
        { key: "PasaportNo", label: "Pasaport / T.C. Kimlik No", sortable: false, },
        { key: "Ulke", label: "Uyruk", sortable: false },
        { key: "Cinsiyet", label: "Cinsiyet", sortable: false }
      ],

      islemFields: [
        { key: "Islem", label: "İşlem", sortable: false },
        { key: "Aciklama", label: "Açıklama", sortable: false },
        { key: "Tarih", label: "Tarih", sortable: false },
        { key: "AcenteId", label: "Acente", sortable: false },
        { key: "RehberId", label: "Rehber", sortable: false },
        { key: "AracId", label: "Araç", sortable: false },
        { key: "MekanId", label: "Mekan", sortable: false },
        { key: "isYonetimGorsun", label: "Yön. Görsün", sortable: false },
      ],

      detay: {},

      aracResimler: [],

      iptalForm: {
        Kod: 0,
        Sebep: "",
      }
    };
  },
  components: {
    CTransferRezervasyonMisafirPrint,
    CTransferRezervasyonYolcuDuzelt,
    CTransferRezervasyonSeyahat
  },
  computed: {
    visible_yolcu_state() {
      if (this.detay.Durum == 0 || this.detay.Durum == 1) {
        if (new Date(this.detay.Tarih) > new Date()) return true;
      }

      return false;
    }
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    onDetay() {
      let vm = this;
      let user = this.$user.getUser();

      this.$connection.get(
        "/laalTransferler/" + this.id,
        function (response) {
          if (response.Success) {
            switch (user.Kullanici.KullaniciTipi) {
              //Acente Yönetici
              case 10:
                vm.isTransferShow = true;
                vm.isMisafirShow = true;
                vm.isDetayShow = true;
                vm.isDetayAcenteYoneticiFinansShow = true;
                vm.isBtnDuzelt = true;
                vm.isBtnIptal = true;
                vm.isAracSozlesme = true;
                vm.isRehberSozlesme = true;
                break;

              //Acente Kullanıcı
              case 15:
                vm.isTransferShow = true;
                vm.isMisafirShow = true;
                vm.isDetayShow = true;
                vm.isBtnDuzelt = true;
                vm.isBtnIptal = true;
                vm.isAracSozlesme = true;
                vm.isRehberSozlesme = true;
                break;

              //Rehber
              case 20:
                vm.isTransferShow = true;
                vm.isMisafirShow = true;
                vm.isRehberSozlesme = true;
                vm.showRehberYorumGoster = false;
                vm.showAracYorumGoster = false;
                let kaldirilacak = vm.misafirFields.filter((item) => {
                  return item.key == "Telefon";
                });
                for (let index = 0; index < kaldirilacak.length; index++) {
                  const element = kaldirilacak[index];
                  vm.$listHelper.removeItem(vm.misafirFields, element);
                }
                break;

              //Araç Firması
              case 30:
              case 35:
                vm.isTransferShow = true;
                vm.isMisafirShow = true;
                vm.isAracSozlesme = true;
                vm.showRehberYorumGoster = false;
                vm.showAracYorumGoster = false;
                break;

              //Araç
              case 40:
                vm.isTransferShow = true;
                vm.isMisafirShow = true;
                vm.isAracSozlesme = true;
                vm.showRehberYorumGoster = false;
                vm.showAracYorumGoster = false;
                break;

              //Merkez
              case 80:
              case 85:
              case 100:
                vm.isTransferShow = true;
                vm.isMisafirShow = true;
                vm.isDetayShow = true;
                vm.isIslemShow = true;
                vm.isBtnAcenteDetay = true;
                vm.isBtnYonetim = true;
                vm.isBtnDuzelt = true;
                vm.isBtnKismiDuzelt = true;
                vm.isBtnIptal = true;
                vm.isAracSozlesme = true;
                vm.isRehberSozlesme = true;
                break;

              default:
                break;
            }

            vm.detay = response.Data;

            switch (vm.detay.Durum) {
              case 2:
                //Tamamlandı
                break;

              case 1:
              case 0:
                //Yeni veya onaylandı
                break;

              case 100:
                //İptal
                vm.isBtnIptal = false;
                break;

              default:
                break;
            }

            //vm.aracResimListe();
          } else {
            vm.$alert.messageWithTitle("Transfer Detay", response.Message, "error");
          }
        }
      );
    },
    laalGuncelle() {
      let vm = this;
      this.$connection.get(
        "/laalTransferler/detayFromLaal/" + this.id,
        function (response) {
          if (response.Success) {
            vm.onDetay();
          } else {
            vm.$alert.messageWithTitle("Transfer Detay Güncelle", response.Message, "error");
          }
        }
      );
    },
    misafirListAc() {
      this.$bvModal.show("misafirListeDetay");
    },
    misafirListYazdir() {
      window.print();
    },
    yolcuDuzeltAc(yolcu) {
      if (yolcu == null) {
        this.duzeltYolcu = {
          Id: 0,
          AdSoyad: "",
          PasaportNo: "",
          UlkeKodu: "",
          Cinsiyet: ""
        };
      } else {
        this.duzeltYolcu = yolcu;
      }
      this.$bvModal.show("yolcuDuzeltForm");
    },
    yolcuEkleDuzelt(kayit) {
      let vm = this;

      let url = "/laalTransferler/YolcuEkleGuncelle/" + this.id;
      let method = "put";
      let messageTitle = "Transfer Yolcu Güncelle";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(
              messageTitle,
              response.Message,
              "success",
              function () {
                vm.onDetay();
                vm.$bvModal.hide("yolcuDuzeltForm");
              }
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    }
  },
};
</script>
