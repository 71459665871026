<template>
  <div>
    <Breadcrumbs main="Mekanlar" :title="pageTitle" />

    <div class="container-fluid tur-rezervasyon-detay">

      <div class="row">
        <div class="col-md-6">
          <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
            <h5 slot="header">Bilgiler</h5>
            <b-card-body>
              <table class="product-page-width">
                <tbody>
                  <tr>
                    <th>Adı</th>
                    <td>{{ mekan.Adi }}</td>
                  </tr>
                  <tr>
                    <th>Unvan</th>
                    <td>{{ mekan.Unvan }}</td>
                  </tr>
                  <tr>
                    <th>Yetkili Kişi</th>
                    <td>{{ mekan.YetkiliKisi }}</td>
                  </tr>
                  <tr>
                    <th>Adres</th>
                    <td>{{ mekan.Adres }}</td>
                  </tr>
                  <tr>
                    <th>Vergi Dairesi</th>
                    <td>{{ mekan.VergiDairesi }}</td>
                  </tr>
                  <tr>
                    <th>Vergi No</th>
                    <td>{{ mekan.VergiNo }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>

          <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
            <h5 slot="header">Kullanıcısı</h5>
            <b-card-body>
              <table class="product-page-width">
                <tbody>
                  <tr>
                    <th>Ad Soyad</th>
                    <td>{{ kullanici.AdSoyad }}</td>
                  </tr>
                  <tr>
                    <th>GSM</th>
                    <td>{{ kullanici.GSM }}</td>
                  </tr>
                  <tr>
                    <th>Gsm Doğrulandı</th>
                    <td><c-aktif-cell :isAktif="kullanici.isGsmDogrulandi"></c-aktif-cell></td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{{ kullanici.Email }}</td>
                  </tr>
                  <tr>
                    <th>Email Doğrulandı</th>
                    <td><c-aktif-cell :isAktif="kullanici.isEmailDogrulandi"></c-aktif-cell></td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>

          <b-card header-tag="div" header-bg-variant="info" class="card-absolute">
            <h5 slot="header">Kayıt Bilgileri</h5>
            <b-card-body>
              <table class="product-page-width">
                <tbody>
                  <tr>
                    <th>Kayıt Tarihi</th>
                    <td>
                      <c-tarih-cell :kayitTarihi="mekan.KayitTarihi" :rowKey="mekan.Id"></c-tarih-cell>
                    </td>
                  </tr>
                  <tr>
                    <th>Güncelleme Tarihi</th>
                    <td>
                      <c-tarih-cell :kayitTarihi="mekan.GuncelTarih" :rowKey="'G' + mekan.Id"></c-tarih-cell>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <b-list-group class="mb-3">
                <b-list-group-item v-b-modal.bilgiForm router-component-name="router-link">
                  <feather type="edit-3"></feather> Güncelle
                </b-list-group-item>
                <b-list-group-item v-b-modal.sifreForm router-component-name="router-link">
                  <feather type="lock"></feather> Şifre Güncelle
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3 text-center">
              <b-btn-group>
                <b-button @click="sil()" variant="danger">Sil</b-button>
              </b-btn-group>
            </div>
          </div>

          <b-card header-tag="div" header-bg-variant="warning" class="card-absolute">
            <h5 slot="header">İletişim Bilgileri</h5>
            <b-card-body>
              <table class="product-page-width">
                <tbody>
                  <tr>
                    <th>Cep Telefonu</th>
                    <td>{{ mekan.GSM }}</td>
                  </tr>
                  <tr>
                    <th>Cep Telefonu 2</th>
                    <td>{{ mekan.Telefon }}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{{ mekan.Email }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </div>
      </div>

      <b-modal id="bilgiForm" centered title="Bilgirimi Güncelle" @ok="bilgiKaydetSubmit" ok-title="Güncelle"
        cancel-title="Kapat" class="theme-modal">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_adi">Adı</label>
            <b-form-input id="kayit_adi" v-model="kayitBilgi.Adi" :state="kayitbilgi_adi_state"></b-form-input>
            <b-form-invalid-feedback :state="kayitbilgi_adi_state">Firma adını giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_unvan">Unvan</label>
            <b-form-input id="kayit_unvan" v-model="kayitBilgi.Unvan" :state="kayitbilgi_unvan_state"></b-form-input>
            <b-form-invalid-feedback :state="kayitbilgi_unvan_state">Firma unvanınızı giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_yetkilikisi">Yetkili Kişi</label>
            <b-form-input id="kayit_yetkilikisi" v-model="kayitBilgi.YetkiliKisi"
              :state="kayitbilgi_yetkilikisi_state"></b-form-input>
            <b-form-invalid-feedback :state="kayitbilgi_yetkilikisi_state">Yetkili kişi adı ve soyadını
              giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_adres">Adres</label>
            <b-form-textarea rows="3" id="kayit_adres" v-model="kayitBilgi.Adres" placeholder="Açık adresiniz"
              :state="kayitbilgi_adres_state"></b-form-textarea>
            <b-form-invalid-feedback :state="kayitbilgi_adres_state">Açık adresinizi
              giriniz</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_vergidairesi">Vergi Dairesi</label>
            <b-form-input id="kayit_vergidairesi" v-model="kayitBilgi.VergiDairesi"
              :state="kayitbilgi_vergidairesi_state"></b-form-input>
            <b-form-invalid-feedback :state="kayitbilgi_vergidairesi_state">Vergi dairesini
              giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_vergino">Vergi Numarası</label>
            <b-form-input type="number" id="kayit_vergino" v-model="kayitBilgi.VergiNo"
              :state="kayitbilgi_vergino_state"></b-form-input>
            <b-form-invalid-feedback :state="kayitbilgi_vergino_state">Vergi numarasını
              giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_email">Email</label>
            <b-form-input id="kayit_email" v-model="kayitBilgi.Email"
              :state="kayitbilgi_email_state"></b-form-input>
            <b-form-invalid-feedback :state="kayitbilgi_email_state">Geçerli bir email adresinizi
              giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_telefon">Cep Telefonu</label>
            <b-form-input type="text" id="kayit_gsm" v-model="kayitBilgi.GSM" v-mask="'5## ### ## ##'"
              placeholder="Cep Telefonu" :state="kayitbilgi_gsm_state"></b-form-input>
            <b-form-invalid-feedback :state="kayitbilgi_gsm_state">Cep telefonunu giriniz</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_telefon">Telefon</label>
            <b-form-input id="kayit_telefon" v-model="kayitBilgi.Telefon"></b-form-input>
          </div>
        </div>
      </b-modal>

      <b-modal id="sifreForm" centered title="Şifre Güncelle" @ok="sifreKaydetSubmit" ok-title="Güncelle"
        cancel-title="Kapat" class="theme-modal">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
            <b-form-input id="kayit_kullaniciadi" v-model="kayitSifre.KullaniciAdi"
              :state="kayitsifre_kullaniciadi_state"></b-form-input>
            <b-form-invalid-feedback :state="kayitsifre_kullaniciadi_state">Kullanıcı adınızı
              giriniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_sifre">Şifre</label>
            <b-form-input id="kayit_sifre" v-model="kayitSifre.SifreYeni" :state="kayitsifre_sifre_state"
              placeholder="Şifreniz boş ise güncellenmez."></b-form-input>
            <b-form-invalid-feedback :state="kayitsifre_sifre_state">En az 8 ile 20 karakter arasında rakam, küçük
              ve büyük harf
              bulunan bir şifre giriniz</b-form-invalid-feedback>
          </div>
        </div>
      </b-modal>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Mekan Detayı",

      mekan: {},

      kayitBilgi: {
        Adi: "",
        Unvan: "",
        YetkiliKisi: "",
        Adres: "",
        VergiDairesi: "",
        VergiNo: "",
        Telefon: "",
        GSM: "",
        Email: ""
      },

      kayitSifre: {
        AdSoyad: "",
        Email: "",
        GSM: "",
        KullaniciAdi: "",
        SifreYeni: "",
      },

      kullanici: {}
    };
  },
  components: {
  },
  computed: {
    kayitbilgi_adi_state() {
      if (this.kayitBilgi.Adi == null || this.kayitBilgi.Adi == "")
        return false;
      else return true;
    },
    kayitbilgi_unvan_state() {
      if (this.kayitBilgi.Unvan == null || this.kayitBilgi.Unvan == "")
        return false;
      else return true;
    },
    kayitbilgi_yetkilikisi_state() {
      if (this.kayitBilgi.YetkiliKisi == null || this.kayitBilgi.YetkiliKisi == "")
        return false;
      else return true;
    },
    kayitbilgi_adres_state() {
      if (this.kayitBilgi.Adres == null || this.kayitBilgi.Adres == "")
        return false;
      else return true;
    },
    kayitbilgi_email_state() {
      if (!this.$validationHelper.validEmail(this.kayitBilgi.Email))
        return false;
      else return true;
    },
    kayitbilgi_vergidairesi_state() {
      if (this.kayitBilgi.VergiDairesi == null || this.kayitBilgi.VergiDairesi == "")
        return false;
      else return true;
    },
    kayitbilgi_vergino_state() {
      if (this.kayitBilgi.VergiNo == null || (this.kayitBilgi.VergiNo.length != 10 && this.kayitBilgi.VergiNo.length != 11))
        return false;
      else return true;
    },
    kayitbilgi_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayitBilgi.GSM)) return false;
      else return true;
    },

    kayitsifre_kullaniciadi_state() {
      if (
        this.kayitSifre.KullaniciAdi == null ||
        this.kayitSifre.KullaniciAdi == ""
      )
        return false;
      else return true;
    },
    kayitsifre_sifre_state() {
      if (
        this.kayitSifre.SifreYeni != null &&
        this.kayitSifre.SifreYeni != "" &&
        !this.$validationHelper.validPassword(this.kayitSifre.SifreYeni)
      )
        return false;
      else return true;
    },

    kayitozgecmis_ozgecmis_state() {
      if (
        this.kayitOzgecmis.Ozgecmis == null ||
        this.kayitOzgecmis.Ozgecmis == "" ||
        this.kayitOzgecmis.Ozgecmis.length < 100
      )
        return false;
      else return true;
    },

    kayitdil_dil_state() {
      if (this.selectedDiller.length === 0) return false;
      else return true;
    },
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get("/mekanlar/" + this.id, function (response) {
        if (response.Success) {
          vm.mekan = response.Data;

          vm.kayitBilgi = {
            Adi: response.Data.Adi,
            Unvan: response.Data.Unvan,
            YetkiliKisi: response.Data.YetkiliKisi,
            Adres: response.Data.Adres,
            VergiDairesi: response.Data.VergiDairesi,
            VergiNo: response.Data.VergiNo,
            Telefon: response.Data.Telefon,
            GSM: response.Data.GSM,
            Email: response.Data.Email
          };

          vm.kayitSifre = {
            AdSoyad: response.Data.Kullanici.AdSoyad,
            Email: response.Data.Kullanici.Email,
            GSM: response.Data.Kullanici.GSM,
            KullaniciAdi: response.Data.Kullanici.KullaniciAdi,
            SifreYeni: "",
          };

          vm.kullanici = response.Data.Kullanici;
        } else {
          vm.$alert.messageWithTitle("Mekan", response.Message, "error");
        }
      });
    },
    bilgiKaydetSubmit(target) {
      target.preventDefault();

      let isValid =
      this.kayitbilgi_adi_state &&
        this.kayitbilgi_email_state &&
        this.kayitbilgi_gsm_state &&
        this.kayitbilgi_adres_state &&
        this.kayitbilgi_unvan_state &&
        this.kayitbilgi_yetkilikisi_state &&
        this.kayitbilgi_vergidairesi_state &&
        this.kayitbilgi_vergino_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/mekanlar/bilgi/" + this.mekan.Id,
          vm.kayitBilgi,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Bilgi Güncelle",
                response.Message,
                "success",
                function () {
                  vm.$bvModal.hide("bilgiForm");
                  vm.onDetay();
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Bilgi Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    },
    sifreKaydetSubmit(target) {
      target.preventDefault();

      let isValid =
        this.kayitsifre_kullaniciadi_state && this.kayitsifre_sifre_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/kullanicilar/forMekan/" + this.mekan.Id,
          vm.kayitSifre,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Bilgi Güncelle",
                response.Message,
                "success",
                function () {
                  vm.$bvModal.hide("sifreForm");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Bilgi Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    },
    sil() {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Mekan kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete(
            "/mekanlar/" + vm.mekan.Id,
            function (response) {
              vm.$alert.messageWithFunction(
                "Silindi",
                "Mekan kaydı silindi.",
                "error",
                function () {
                  vm.$router.push("/mekan/list");
                }
              );
            }
          );
        }
      );
    }
  },
};
</script>
