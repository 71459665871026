<template>
  <div>
    <Breadcrumbs main="Regular Rezervasyon" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
            <li class="nav-item" v-on:click="changetab('tur')" v-if="isTurShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'tur' }" id="top-tur" data-toggle="tab" role="tab"
                aria-controls="tur" aria-selected="true">Rezervasyon Detayı <b-badge variant="info">{{ detay.Kod
                  }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetab('misafir')" v-if="isMisafirShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'misafir' }" id="top-misafir" data-toggle="tab"
                role="tab" aria-controls="misafir" aria-selected="false">Misafirler</a>
            </li>
            <li class="nav-item" v-on:click="changetab('detay')" v-if="isDetayShow">
              <a class="nav-link" :class="{ 'show active': showtab == 'detay' }" id="top-detay" data-toggle="tab"
                role="tab" aria-controls="detay" aria-selected="false">Tur Detayları</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div class="tab-pane fade" :class="{ 'show active': showtab == 'tur' }" id="tur" role="tabpanel"
          aria-labelledby="tur">
          <div class="row">
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Rezervasyon Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Kod</th>
                        <td>
                          <b-badge variant="info">{{ detay.Kod }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Tarih</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="detay.Tarih" :rowKey="detay.Id"></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Saat</th>
                        <td>
                          <b-badge variant="dark">{{ detay.Saat }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th colspan="2">Açıklama</th>
                      </tr>
                      <tr>
                        <td colspan="2">{{ detay.Aciklama }}</td>
                      </tr>
                      <tr>
                        <th>Tur</th>
                        <td>
                          {{ detay.TurAdi }}
                        </td>
                      </tr>
                      <tr>
                        <th>Dil</th>
                        <td>{{ detay.DilAdi }}</td>
                      </tr>
                      <tr>
                        <th>Kişi Sayısı</th>
                        <td>
                          <b-badge variant="info">{{ detay.KisiSayisi }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Durum</th>
                        <td>
                          <b-badge variant="primary">{{ detay.DurumStr }}</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th>İptal Sebebi</th>
                        <td>{{ detay.IptalSebebi }}</td>
                      </tr>
                      <tr>
                        <th>Son İşlem Yapan Kullanıcı</th>
                        <td>{{ detay.KullaniciAdi }}</td>
                      </tr>
                      <tr>
                        <th>Kayıt Tarihi</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="detay.KayitTarihi" :rowKey="detay.Id"></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Güncelleme Tarihi</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="detay.GuncelTarih" :rowKey="detay.Id"></c-tarih-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <CRezervasyonTutarlar :detay="detay" v-if="detay !== undefined" />
            </div>
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="warning" class="card-absolute">
                <h5 slot="header">Rehber</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <td colspan="2" class="text-center">
                          <img :src="detay.RehberResim" class="img-thumbnail img-70" />
                        </td>
                      </tr>
                      <tr>
                        <th>Rehber</th>
                        <td>{{ detay.RehberAdi }}</td>
                      </tr>
                      <tr>
                        <th>Telefon</th>
                        <td><a :href="'tel:0090' + detay.RehberGSM">{{ detay.RehberGSM }}</a></td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-card header-tag="div" header-bg-variant="danger" class="card-absolute">
                <h5 slot="header">Araç</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <td colspan="2" class="text-center">
                          <b-carousel :id="'carousel-' + detay.Id" :interval="4000" controls indicators
                            background="#ababab" style="
                              width: 350px !important;
                              text-shadow: 1px 1px 2px #333;
                            ">
                            <b-carousel-slide v-for="(item, index) in aracResimler" :key="index"
                              :img-src="item.Resim"></b-carousel-slide>
                          </b-carousel>
                        </td>
                      </tr>
                      <tr v-if="isBtnYonetim">
                        <th>Araç Firması</th>
                        <td>{{ detay.AracFirmaAdi }}</td>
                      </tr>
                      <tr>
                        <th>Plaka</th>
                        <td>{{ detay.Plaka }}</td>
                      </tr>
                      <tr>
                        <th>Şoför Adı</th>
                        <td>{{ detay.SoforAdi }}</td>
                      </tr>
                      <tr>
                        <th>Şoför Telefon</th>
                        <td><a :href="'tel:0090' + detay.SoforGSM">{{ detay.SoforGSM }}</a></td>
                      </tr>
                      <tr>
                        <th>Marka</th>
                        <td>{{ detay.AracMarka }}</td>
                      </tr>
                      <tr>
                        <th>Model</th>
                        <td>{{ detay.AracModel }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-list-group>
                <b-list-group-item router-component-name="router-link" v-if="isBtnDuzelt"
                  :disabled="disable_duzelt_state" :to="'/turRezervasyon/duzelt/' + detay.Id">
                  <feather type="edit-3"></feather> Tur Değiştir
                  <span>{{
      disable_duzelt_state
        ? "(Düzeltmek için uygun değildir.)"
        : ""
    }}</span>
                </b-list-group-item>
                <b-list-group-item router-component-name="router-link" v-if="isBtnDuzelt"
                  :disabled="disable_duzelt_state" :to="'/turRezervasyon/duzelt/' + detay.Id + '?tab=1'">
                  <feather type="edit-3"></feather> Bilgi Değiştir
                  <span>{{
      disable_duzelt_state
        ? "(Düzeltmek için uygun değildir.)"
        : ""
    }}</span>
                </b-list-group-item>
                <b-list-group-item router-component-name="router-link" v-if="isBtnIptal" :disabled="disable_iptal_state"
                  tag="a" href="#" @click="iptalAc()">
                  <feather type="slash"></feather> İptal
                  {{
      disable_iptal_state ? "(İptal için uygun değildir.)" : ""
    }}
                </b-list-group-item>
                <b-list-group-item v-if="isBtnYonetim" router-component-name="router-link" tag="a" href="#"
                  @click="sil()">
                  <feather type="delete"></feather> Sil
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'misafir' }" id="misafir" role="tabpanel"
          aria-labelledby="misafir">
          <div class="row" v-if="detay.Misafirler != undefined">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Misafirler</h5>
                <c-table :tablefields="misafirFields" :items="detay.Misafirler">
                  <template #cell(Detay)="row">
                    <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                      Detay {{ row.detailsShowing ? 'Gizle' : 'Göster' }}
                    </b-button>
                  </template>
                  <template #cell(OtelAdi)="row">
                    {{ row.item.OtelAdi }}<br>
                    <small>{{ row.item.OtelAdresi }}</small>
                  </template>
                  <template #cell(SaatIlk)="row">
                    <b-badge variant="info">{{ row.item.SaatIlk }}-{{ row.item.SaatSon }}</b-badge>
                  </template>
                  <template #cell(Fiyat)="data">
                    <c-money-cell :fiyat="data.value" simge="€"></c-money-cell>
                  </template>

                  <template #row-details="row">
                    <b-table :fields="misafirSubFields" :items="row.item.IstekMisafirler" head-variant="dark"
                      :detailsShowing="true">
                      <template #cell(Fiyat)="data">
                        <c-money-cell :fiyat="data.value" simge="€"></c-money-cell>
                      </template>
                      <template #cell(Yas)="data">
                        <c-yas :yas="data.value"></c-yas>
                      </template>
                    </b-table>
                  </template>
                </c-table>
              </b-card>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'detay' }" id="detay" role="tabpanel"
          aria-labelledby="detay">
          <CRezervasyonTurDetay :detay="detay" v-if="detay.TurDetayi !== undefined" />
        </div>
      </div>

      <b-modal id="iptalForm" size="lg" centered title="Rezervasyonu İptal Et" @ok="iptalSubmit" ok-title="İptal Et"
        cancel-title="Kapat" class="theme-modal">
        <b-form @submit="iptalSubmit">
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label for="kayit_iptalsebep">İptal Sebebi</label>
              <b-form-textarea type="text" id="kayit_iptalsebep" v-model="iptalForm.IptalSebebi"
                :state="iptal_sebep_state" placeholder="İptal Sebebi"></b-form-textarea>
              <b-form-invalid-feedback :state="iptal_sebep_state">İptal sebebini yazınız.</b-form-invalid-feedback>
            </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import CRezervasyonTutarlar from "@/components/c_regularRezervasyon_tutarlar";
import CRezervasyonTurDetay from "@/components/c_regularRezervasyon_turDetay";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "İstek Detayı",

      breadLinks: [
        {
          path: "/regularRezervasyon/istekList",
          title: "Regular Rezervasyon İstekleri",
          icon: "chevron-left",
        },
      ],

      isTurShow: false,
      isMisafirShow: false,
      isDetayShow: true, //Herkes görecek
      isBtnDuzelt: false,
      isBtnKismiDuzelt: false,
      isBtnAcenteDetay: false,
      isBtnYonetim: false,
      isBtnIptal: false,

      showtab: "tur",

      misafirFields: [
        { key: "Detay", label: "Detay", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: false },
        { key: "TurOpsiyonAdi", label: "Opsiyon", sortable: false },
        { key: "OtelAdi", label: "Otel", sortable: false },
        { key: "SaatIlk", label: "Saat", sortable: false }
      ],
      misafirSubFields: [
        { key: "AdSoyad", label: "Ad Soyad", sortable: false },
        { key: "PasaportNo", label: "Pasaport No", sortable: false },
        { key: "Telefon", label: "Telefon", sortable: false },
        { key: "Uyruk", label: "Uyruk", sortable: false },
        { key: "Yas", label: "Yaş", sortable: false }
      ],

      detay: {},

      aracResimler: [],

      iptalForm: {
        IptalSebebi: "",
      }
    };
  },
  components: {
    CRezervasyonTutarlar,
    CRezervasyonTurDetay,
  },
  computed: {
    iptal_sebep_state() {
      if (this.iptalForm.IptalSebebi == null || this.iptalForm.IptalSebebi == "")
        return false;
      else return true;
    },
    disable_duzelt_state() {
      if (this.detay.Durum == 0 || this.detay.Durum == 1) {
        if (new Date(this.detay.Tarih) > new Date()) return false;
      }

      return true;
    },
    disable_iptal_state() {
      if (new Date(this.detay.Tarih) > new Date() || this.detay.Durum == 4) {
        //tamamlandı ya da süre geçmişse
        //var rezTarih = this.$moment(String(this.detay.Tarih)).format("DD.MM.YYYY");
        // if (new Date(this.detay.Tarih) > new Date())
        //    return false;
        return false;
      }

      return true;
    },
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    onDetay() {
      let vm = this;
      let user = this.$user.getUser();

      this.$connection.get(
        "/regularRezervasyonlar/rezervasyon/" + this.id,
        function (response) {
          if (response.Success) {
            switch (user.Kullanici.KullaniciTipi) {
              //Acente Yönetici
              case 10:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isDetayAcenteYoneticiFinansShow = true;
                break;

              //Acente Kullanıcı
              case 15:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                break;

              //Rehber Kullanıcı
              case 20:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                break;

              //Araç Firma ve şoför Kullanıcı
              case 30:
              case 35:
              case 40:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                break;

              //Merkez
              case 80:
              case 85:
              case 100:
                vm.isTurShow = true;
                vm.isMisafirShow = true;
                vm.isDetayShow = true;
                vm.isBtnAcenteDetay = true;
                vm.isBtnYonetim = true;
                vm.isBtnDuzelt = true;
                vm.isBtnKismiDuzelt = true;
                vm.isBtnIptal = true;
                break;

              default:
                break;
            }

            vm.detay = response.Data;

            switch (vm.detay.Durum) {
              case 0:
              case 1:
                //Yeni veya onaylandı
                vm.showBtnOnayla = false;
                break;

              case 4:
                //Tamamlandı
                break;

              case 100:
                //İptal
                vm.isBtnIptal = false;
                break;

              default:
                break;
            }

            if (vm.isBtnYonetim) {
              vm.misafirFields = [
                { key: "Detay", label: "Detay", sortable: false },
                { key: "AcenteAdi", label: "Acente", sortable: false },
                { key: "TurOpsiyonAdi", label: "Opsiyon", sortable: false },
                { key: "OtelAdi", label: "Otel", sortable: false },
                { key: "SaatIlk", label: "Saat", sortable: false },
                { key: "Fiyat", label: "Tur Fiyat", sortable: false }
              ];
              vm.misafirSubFields = [
                { key: "AdSoyad", label: "Ad Soyad", sortable: false },
                { key: "PasaportNo", label: "Pasaport No", sortable: false },
                { key: "Telefon", label: "Telefon", sortable: false },
                { key: "Uyruk", label: "Uyruk", sortable: false },
                { key: "Yas", label: "Yaş", sortable: false },
                { key: "Fiyat", label: "Fiyat", sortable: false },
                { key: "IndirimOrani", label: "İndirim O.", sortable: false },
              ];
            }

            vm.aracResimListe();
          } else {
            vm.$alert.messageWithTitle("İstek Detay", response.Message, "error");
          }
        }
      );
    },
    iptalAc() {
      this.$bvModal.show("iptalForm");
    },
    iptalSubmit(event) {
      event.preventDefault();

      let isValid = this.iptal_sebep_state;

      if (isValid) {
        let vm = this;
        this.$alert.confirm(
          "İptal",
          "Regular rezervasyon iptal edilecektir. Emin misiniz?",
          "warning",
          function () {
            vm.$connection.post(
              "/regularRezervasyonlar/rezervasyon/iptal/" + vm.detay.Id,
              vm.iptalForm,
              "put",
              function () {
                vm.$alert.messageWithFunction(
                  "İptal",
                  "Regular rezervasyon iptal edildi.",
                  "success",
                  function () {
                    vm.onDetay();
                    vm.$bvModal.hide("iptalForm");
                  }
                );
              }
            );
          }
        );
      }
    },
    sil() {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Regular rezervasyon silinecektir. Emin misiniz?",
        "error",
        function () {
          vm.$connection.delete(
            "/regularRezervasyonlar/rezervasyon/" + vm.detay.Id,
            function () {
              vm.$alert.messageWithFunction(
                "Sil",
                "Regular rezervasyon silindi.",
                "success",
                function () {
                  vm.$router.push("/regularRezervasyon/rezList");
                }
              );
            }
          );
        }
      );
    },
    misafirListAc() {
      this.$bvModal.show("misafirListeDetay");
    },
    aracResimListe() {
      let vm = this;
      this.$connection.get(
        "/araclar/resimOnayli/" + this.detay.AracId,
        function (response) {
          vm.aracResimler = response;
        }
      );
    },
  },
};
</script>
