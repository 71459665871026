<template>
  <div>
    <b-badge :variant="variant">{{ durum }}</b-badge>
  </div>
</template>
<script>
export default {
  props: {
    durum: "",
    durumInt: 0,
  },
  data() {
    return {
      variant: "light",
    };
  },
  mounted() {
    switch (this.durumInt) {
      case 0:
        this.variant = "default";
        break;
      case 1:
        this.variant = "info";
        break;
      case 2:
        this.variant = "primary";
        break;
      case 3:
        this.variant = "success";
        break;
      case 100:
        this.variant = "danger";
        break;
      default:
        break;
    }
  },
};
</script>
