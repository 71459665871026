import Vue from 'vue'
import Router from "vue-router";


import Body from '../components/body'
import auth from "../auth/auth"
import loginPage from "../auth/loginPage"
import SifremiUnuttum from "../auth/sifremiUnuttum"
import AcenteBasvur from '../auth/acenteBasvur'
import RehberBasvur from '../auth/rehberBasvur'
import AracBasvur from '../auth/aracBasvur'

import TurRezervasyonView from '../pages/reservation/tour'
import RegularRezervasyonView from '../pages/reservation/regular'


/* Dashboards */
import Default from '../pages/dashboard/default'
import Acente from '../pages/dashboard/acente'
import Rehber from '../pages/dashboard/rehber'
import AracFirma from '../pages/dashboard/aracFirma'
import Arac from '../pages/dashboard/arac'
import Mekan from '../pages/dashboard/mekan'

//Error
import Error401 from '../pages/error/error401'

//Capbook
import TurList from '../pages/tur/list';
import TurKayit from '../pages/tur/kayit';
import Opsiyon from '../pages/tur/opsiyon';
import OpsiyonKayit from '../pages/tur/opsiyonKayit';

import MuzeList from '../pages/muze/list';
import MuzeShowroom from '../pages/muze/showroom';
import MuzeKayit from '../pages/muze/kayit';

import RestoranList from '../pages/restoran/list';
import RestoranKayit from '../pages/restoran/kayit';
import RestoranShowroom from '../pages/restoran/showroom';

import MekanList from '../pages/mekan/list';
import MekanKayit from '../pages/mekan/kayit';
import MekanShowroom from '../pages/mekan/showroom';
import MekanProfil from '../pages/mekan/profil';
import MekanDetay from '../pages/mekan/detay';

import KategoriList from '../pages/kategori/list';
import KategoriKayit from '../pages/kategori/kayit';

import AcenteList from '../pages/acente/list';
import AcenteListAktifler from '../pages/acente/listAktifler';
import AcenteKayit from '../pages/acente/kayit';
import AcenteDetay from '../pages/acente/detay';
import AcenteProfil from '../pages/acente/profil';
import AcenteFolyo from '../pages/acente/folyo';
import AcenteOnayBekleyenList from '../pages/acente/onayBekleyen';
import AcenteFiyatListe from '../pages/acente/fiyatListe';

import AracFirmaList from '../pages/aracFirma/list';
import AracFirmaKayit from '../pages/aracFirma/kayit';
import AracFirmaProfil from '../pages/aracFirma/profil';
import AracFirmaFolyo from '../pages/aracFirma/folyo';
import AracFirmaDetay from '../pages/aracFirma/aracFirmaDetay';
import AracList from '../pages/aracFirma/aracList';
import BagimsizAracList from '../pages/aracFirma/bagimsizAracList';
import AracOnayBekleyenList from '../pages/aracFirma/aracOnayBekleyen';
import AracKayit from '../pages/aracFirma/aracKayit';
import AracDetay from '../pages/aracFirma/aracDetay';
import AracResimOnay from '../pages/aracFirma/aracResimOnay';
import AracProfil from '../pages/aracFirma/aracProfil';
import AracFolyo from '../pages/aracFirma/aracFolyo';
import AracTakvim from '../pages/aracFirma/aracTakvim';
import AracYorumlar from '../pages/aracFirma/yorumlar';

import AracTipiList from '../pages/aracTipi/list';
import AracTipiKayit from '../pages/aracTipi/kayit';

import BalonFirmaList from '../pages/balonFirma/list';
import BalonFirmaKayit from '../pages/balonFirma/kayit';
import BalonFirmaKontenjan from '../pages/balonFirma/kontenjan';

import BannerList from '../pages/banner/list';
import BannerKayit from '../pages/banner/kayit';

import DilList from '../pages/dil/list';
import DilKayit from '../pages/dil/kayit';

import DuyuruList from '../pages/duyuru/list';
import DuyuruKayit from '../pages/duyuru/kayit';

import IcerikList from '../pages/icerik/list';
import IcerikKayit from '../pages/icerik/kayit';

import MenuList from '../pages/menu/list';
import MenuKayit from '../pages/menu/kayit';

import RehberList from '../pages/rehber/list';
import RehberKayit from '../pages/rehber/kayit';
import RehberDetay from '../pages/rehber/detay';
import RehberProfil from '../pages/rehber/profil';
import RehberFolyo from '../pages/rehber/folyo';
import RehberOnayBekleyenList from '../pages/rehber/onayBekleyen';
import RehberTakvim from '../pages/rehber/takvim';
import RehberYogunluk from '../pages/rehber/yogunluk';
import RehberYorumlar from '../pages/rehber/yorumlar';

import TurRezervasyonList from '../pages/turRezervasyon/list';
import TurRezervasyonDetay from '../pages/turRezervasyon/detay';
import TurRezervasyonDuzelt from '../pages/turRezervasyon/duzelt';
import TurRezervasyonDuzeltArac from '../pages/turRezervasyon/duzeltArac';
import TurRezervasyonDuzeltRehber from '../pages/turRezervasyon/duzeltRehber';
import TurRezervasyonYeni from '../pages/turRezervasyon/yeni';
import TurRezervasyonYeniGrup from '../pages/turRezervasyon/yeniGrup';
import TurRezervasyonYorum from '../pages/turRezervasyon/yorum';

import RegularRezervasyonIstekList from '../pages/regularRezervasyon/istekList';
import RegularRezervasyonIstekDetay from '../pages/regularRezervasyon/istekDetay';
import RegularRezervasyonIstekDuzelt from '../pages/regularRezervasyon/istekDuzelt';
import RegularRezervasyonIstekPlanla from '../pages/regularRezervasyon/istekPlanla';
import RegularRezervasyonIstekYeni from '../pages/regularRezervasyon/istekYeni';
import RegularRezervasyonDetay from '../pages/regularRezervasyon/rezDetay';
import RegularRezervasyonList from '../pages/regularRezervasyon/rezList';

import BalonRezervasyonList from '../pages/balonRezervasyon/list';
import BalonRezervasyonDetay from '../pages/balonRezervasyon/detay';
import BalonRezervasyonDuzelt from '../pages/balonRezervasyon/duzelt';
import BalonRezervasyonYeni from '../pages/balonRezervasyon/yeni';

import TransferList from '../pages/transfer/list';
import TransferDetay from '../pages/transfer/detay';
import TransferYeni from '../pages/transfer/yeni';
import TransferTanim from '../pages/transfer/tanim';

import ParabirimiList from '../pages/paraBirimi/list';
import ParabirimiKayit from '../pages/paraBirimi/kayit';

import SehirList from '../pages/sehir/list';
import SehirKayit from '../pages/sehir/kayit';

import KullaniciTipiMenu from '../pages/kullaniciTipi/menu';

import KullaniciList from '../pages/kullanici/list';
import KullaniciKayit from '../pages/kullanici/kayit';

import ParametreGuncelle from '../pages/parametre/guncelle';
import TurAracFiyat from '../pages/parametre/turAracFiyat';

// component

Vue.use(Router)

const routes = [
  { path: '', redirect: { name: 'default' } },
  {
    path: '/giris',
    component: auth,
    children: [
      {
        path: '',
        component: loginPage,
        meta: {
          title: ' Giriş | Capbook',
        }
      },
      {
        path: 'sifremiUnuttum',
        component: SifremiUnuttum,
        meta: {
          title: ' Şifremi Unuttum | Capbook',
        }
      },
      {
        path: 'acenteBasvur',
        name: 'acenteBasvur',
        component: AcenteBasvur,
        meta: {
          title: ' Acente Başvuru | Capbook',
        }
      },
      {
        path: 'rehberBasvur',
        name: 'rehberBasvur',
        component: RehberBasvur,
        meta: {
          title: ' Rehber Başvuru | Capbook',
        }
      },
      {
        path: 'aracBasvur',
        name: 'aracBasvur',
        component: AracBasvur,
        meta: {
          title: ' Araç Başvuru | Capbook',
        }
      }
    ]
  },
  {
    path: '/reservation',
    component: auth,
    children: [
      {
        path: 'tour/:id/:kod',
        component: TurRezervasyonView,
        meta: {
          title: ' Tour Reservation'
        }
      },
      {
        path: 'regular/:id/:kod/:istekId',
        component: RegularRezervasyonView,
        meta: {
          title: ' Regular Reservation'
        }
      }
    ]
  },

  {
    path: '/dashboard',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'default',
        name: 'default',
        component: Default,
        meta: {
          title: 'Kontrol Paneli'
        }
      },
      {
        path: 'acente',
        name: 'acenteDashboard',
        component: Acente,
        meta: {
          title: 'Kontrol Paneli'
        }
      },
      {
        path: 'rehber',
        name: 'rehberDashboard',
        component: Rehber,
        meta: {
          title: 'Kontrol Paneli'
        }
      },
      {
        path: 'aracFirma',
        name: 'aracFirmaDashboard',
        component: AracFirma,
        meta: {
          title: 'Kontrol Paneli'
        }
      },
      {
        path: 'arac',
        name: 'aracDashboard',
        component: Arac,
        meta: {
          title: 'Kontrol Paneli'
        }
      },
      {
        path: 'mekan',
        name: 'mekanDashboard',
        component: Mekan,
        meta: {
          title: 'Kontrol Paneli'
        }
      }
    ]
  },
  {
    path: '/error',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'error401',
        name: 'errorYetkisiz',
        component: Error401,
        meta: {
          title: 'Yetkisiz İşlem'
        }
      }
    ]
  },
  {
    path: '/tur',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'turList',
        component: TurList,
        meta: {
          title: 'Turlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'turEkle',
        component: TurKayit,
        meta: {
          title: 'Yeni Tur - Turlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'turDuzelt',
        component: TurKayit,
        meta: {
          title: 'Düzelt - Turlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'opsiyon/:id',
        name: 'opsiyonList',
        component: Opsiyon,
        meta: {
          title: 'Turlar Opsiyonları',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'opsiyonKayit/:turId',
        name: 'opsiyonKayit',
        component: OpsiyonKayit,
        meta: {
          title: 'Tur Opsiyon Kaydet',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'opsiyonKayit/:turId/:id',
        name: 'opsiyonDuzelt',
        component: OpsiyonKayit,
        meta: {
          title: 'Tur Opsiyon Düzelt',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/muze',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'muzeList',
        component: MuzeList,
        meta: {
          title: 'Müzeler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'showroom',
        name: 'muzeShowroom',
        component: MuzeShowroom,
        meta: {
          title: 'Müzeler'
        }
      },
      {
        path: 'kayit',
        name: 'muzeEkle',
        component: MuzeKayit,
        meta: {
          title: 'Yeni Müze - Müzeler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'muzeDuzelt',
        component: MuzeKayit,
        meta: {
          title: 'Düzelt - Müzeler',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/restoran',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'restoranList',
        component: RestoranList,
        meta: {
          title: 'Restoranlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'restoranEkle',
        component: RestoranKayit,
        meta: {
          title: 'Yeni Restoran - Restoranlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'restoranDuzelt',
        component: RestoranKayit,
        meta: {
          title: 'Düzelt - Restoranlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'showroom',
        name: 'restoranShowroom',
        component: RestoranShowroom,
        meta: {
          title: 'Restoranlar'
        }
      }
    ]
  },
  {
    path: '/mekan',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'mekanList',
        component: MekanList,
        meta: {
          title: 'Mekanlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'mekanEkle',
        component: MekanKayit,
        meta: {
          title: 'Yeni Mekan - Mekanlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'profil',
        name: 'mekanProfil',
        component: MekanProfil,
        meta: {
          title: 'Mekan Profili',
          cookies: [80, 85, 100, 50]
        }
      },
      {
        path: 'detay/:id',
        name: 'mekanDetay',
        component: MekanDetay,
        meta: {
          title: 'Mekan Detayı',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'mekanDuzelt',
        component: MekanKayit,
        meta: {
          title: 'Düzelt - Mekanlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'showroom',
        name: 'mekanShowroom',
        component: MekanShowroom,
        meta: {
          title: 'Mekanlar'
        }
      }
    ]
  },
  {
    path: '/kategori',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'kategoriList',
        component: KategoriList,
        meta: {
          title: 'Kategoriler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'kategoriEkle',
        component: KategoriKayit,
        meta: {
          title: 'Yeni Kategori - Kategoriler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'kategoriDuzelt',
        component: KategoriKayit,
        meta: {
          title: 'Düzelt - Kategoriler',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/acente',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'acenteList',
        component: AcenteList,
        meta: {
          title: 'Acenteler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'listAktifler',
        name: 'acenteListAktifler',
        component: AcenteListAktifler,
        meta: {
          title: 'Acenteler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'onayBekleyen',
        name: 'acenteOnayBekleyenList',
        component: AcenteOnayBekleyenList,
        meta: {
          title: 'Onay Bekleyen Acenteler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'fiyatlar',
        name: 'acenteFiyatListe',
        component: AcenteFiyatListe,
        meta: {
          title: 'Fiyatlar'
        }
      },
      {
        path: 'kayit',
        name: 'acenteEkle',
        component: AcenteKayit,
        meta: {
          title: 'Yeni Acente - Acenteler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'acenteDuzelt',
        component: AcenteKayit,
        meta: {
          title: 'Düzelt - Acenteler',
          cookies: [10, 80, 85, 100]
        }
      },
      {
        path: 'detay/:id',
        name: 'acenteDetay',
        component: AcenteDetay,
        meta: {
          title: 'Detay - Acenteler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'profil',
        name: 'acenteProfil',
        component: AcenteProfil,
        meta: {
          title: 'Profil',
          cookies: [10]
        }
      },
      {
        path: 'folyo',
        name: 'acenteFolyo',
        component: AcenteFolyo,
        meta: {
          title: 'Folyo',
          cookies: [10, 80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/aracFirma',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'aracFirmaList',
        component: AracFirmaList,
        meta: {
          title: 'Araç Firmaları',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'aracFirmaEkle',
        component: AracFirmaKayit,
        meta: {
          title: 'Yeni Araç Firması - Araç Firmaları',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'aracFirmaDuzelt',
        component: AracFirmaKayit,
        meta: {
          title: 'Düzelt - Araç Firmaları',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'aracList/:aracFirmaId',
        name: 'aracListByFirmaId',
        component: AracList,
        meta: {
          title: 'Araçları',
          cookies: [30, 35, 80, 85, 100]
        }
      },
      {
        path: 'bagimsizAracList',
        name: 'bagimsizAracList',
        component: BagimsizAracList,
        meta: {
          title: 'Bağımsız Araçlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'aracList',
        name: 'aracList',
        component: AracList,
        meta: {
          title: 'Araçlar',
          cookies: [30, 35, 80, 85, 100]
        }
      },
      {
        path: 'aracOnayBekleyen',
        name: 'aracOnayBekleyen',
        component: AracOnayBekleyenList,
        meta: {
          title: 'Onay Bekleyen Araçlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'aracFirmaDetay/:id',
        name: 'aracFirmaDetay',
        component: AracFirmaDetay,
        meta: {
          title: 'Araç Firma Detayı - Araç Firmaları',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'aracKayit/:aracFirmaId',
        name: 'aracEkle',
        component: AracKayit,
        meta: {
          title: 'Yeni Araç - Araçlar',
          cookies: [30, 35, 80, 85, 100]
        }
      },
      {
        path: 'aracKayit',
        name: 'bagimsizAracEkle',
        component: AracKayit,
        meta: {
          title: 'Yeni Araç - Araçlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'aracKayit/:aracFirmaId/:id',
        name: 'aracDuzelt',
        component: AracKayit,
        meta: {
          title: 'Düzelt - Araçlar',
          cookies: [30, 35, 80, 85, 100]
        }
      },
      {
        path: 'aracDetay/:id',
        name: 'aracDetay',
        component: AracDetay,
        meta: {
          title: 'Detay - Araç',
          cookies: [30, 35, 80, 85, 100]
        }
      },
      {
        path: 'profil',
        name: 'aracFirmaProfil',
        component: AracFirmaProfil,
        meta: {
          title: 'Profil - Araç Firmaları',
          cookies: [30, 80, 85, 100]
        }
      },
      {
        path: 'folyo',
        name: 'aracFirmaFolyo',
        component: AracFirmaFolyo,
        meta: {
          title: 'Folyo - Araç Firmaları',
          cookies: [30, 80, 85, 100]
        }
      },
      {
        path: 'aracResimOnay',
        name: 'aracResimOnayList',
        component: AracResimOnay,
        meta: {
          title: 'Onay Bekleyen Araç Resimleri',
          cookies: [30, 35, 80, 85, 100]
        }
      },
      {
        path: 'aracProfil',
        name: 'aracProfil',
        component: AracProfil,
        meta: {
          title: 'Profil - Araçlar',
          cookies: [40, 30, 35, 80, 85, 100]
        }
      },
      {
        path: 'aracFolyo',
        name: 'aracFolyo',
        component: AracFolyo,
        meta: {
          title: 'Folyo - Araçlar',
          cookies: [40, 30, 35, 80, 85, 100]
        }
      },
      {
        path: 'aracTakvim',
        name: 'aracTakvim',
        component: AracTakvim,
        meta: {
          title: 'Müsaitlik Takvimi',
          cookies: [30, 35, 40, 80, 85, 100]
        }
      },
      {
        path: 'yorumlar',
        name: 'aracYorumlar',
        component: AracYorumlar,
        meta: {
          title: 'Araç Yorumları',
          cookies: [30, 35, 40, 80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/aracTipi',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'aracTipiList',
        component: AracTipiList,
        meta: {
          title: 'Araç Tipleri',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'aracTipiEkle',
        component: AracTipiKayit,
        meta: {
          title: 'Yeni Araç Tipi - Araç Tipleri',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'aracTipiDuzelt',
        component: AracTipiKayit,
        meta: {
          title: 'Düzelt - Araç Tipleri',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/parabirimi',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'parabirimiList',
        component: ParabirimiList,
        meta: {
          title: 'Parabirimleri',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'parabirimiEkle',
        component: ParabirimiKayit,
        meta: {
          title: 'Yeni Parabirimi - Parabirimleri',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'parabirimiDuzelt',
        component: ParabirimiKayit,
        meta: {
          title: 'Düzelt - Parabirimleri',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/balonFirma',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'balonFirmaList',
        component: BalonFirmaList,
        meta: {
          title: 'Balon Firmaları',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'balonFirmaEkle',
        component: BalonFirmaKayit,
        meta: {
          title: 'Yeni Balon Firması - Balon Firmaları',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'balonFirmaDuzelt',
        component: BalonFirmaKayit,
        meta: {
          title: 'Düzelt - Balon Firmaları',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kontenjan/:id',
        name: 'balonFirmaKontenjan',
        component: BalonFirmaKontenjan,
        meta: {
          title: 'Balon Firma Kontenjanları',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/banner',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'bannerList',
        component: BannerList,
        meta: {
          title: 'Bannerlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'bannerEkle',
        component: BannerKayit,
        meta: {
          title: 'Yeni Banner - Bannerlar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'bannerDuzelt',
        component: BannerKayit,
        meta: {
          title: 'Düzelt - Bannerlar',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/dil',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'dilList',
        component: DilList,
        meta: {
          title: 'Diller',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'dilEkle',
        component: DilKayit,
        meta: {
          title: 'Yeni Dil - Diller',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'dilDuzelt',
        component: DilKayit,
        meta: {
          title: 'Düzelt - Diller',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/duyuru',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'duyuruList',
        component: DuyuruList,
        meta: {
          title: 'Duyurular',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'duyuruEkle',
        component: DuyuruKayit,
        meta: {
          title: 'Yeni Duyuru - Duyurular',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'duyuruDuzelt',
        component: DuyuruKayit,
        meta: {
          title: 'Düzelt - Duyurular',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/icerik',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'icerikList',
        component: IcerikList,
        meta: {
          title: 'İçerikler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'icerikEkle',
        component: IcerikKayit,
        meta: {
          title: 'Yeni İçerik - İçerikler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'icerikDuzelt',
        component: IcerikKayit,
        meta: {
          title: 'İçerik - İçerikler',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/menu',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'menuList',
        component: MenuList,
        meta: {
          title: 'Menüler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'menuEkle',
        component: MenuKayit,
        meta: {
          title: 'Yeni Menü - Menüler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'menuDuzelt',
        component: MenuKayit,
        meta: {
          title: 'Menü - Menüler',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/rehber',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'rehberList',
        component: RehberList,
        meta: {
          title: 'Rehberler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'onayBekleyen',
        name: 'rehberOnayBekleyenList',
        component: RehberOnayBekleyenList,
        meta: {
          title: 'Onay Bekleyen Rehberler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'rehberEkle',
        component: RehberKayit,
        meta: {
          title: 'Yeni Rehber - Rehberler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'rehberDuzelt',
        component: RehberKayit,
        meta: {
          title: 'Rehber - Rehberler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'detay/:id',
        name: 'rehberDetay',
        component: RehberDetay,
        meta: {
          title: 'Rehber Detayı',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'profil',
        name: 'rehberProfil',
        component: RehberProfil,
        meta: {
          title: 'Profil',
          cookies: [20, 80, 85, 100]
        }
      },
      {
        path: 'folyo',
        name: 'rehberFolyo',
        component: RehberFolyo,
        meta: {
          title: 'Folyo',
          cookies: [20, 80, 85, 100]
        }
      },
      {
        path: 'takvim',
        name: 'rehberTakvim',
        component: RehberTakvim,
        meta: {
          title: 'Müsaitlik Takvimi',
          cookies: [20, 80, 85, 100]
        }
      },
      {
        path: 'yogunluk',
        name: 'rehberYogunluk',
        component: RehberYogunluk,
        meta: {
          title: 'Yoğunluk Takvimi',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'yorumlar',
        name: 'rehberYorumlar',
        component: RehberYorumlar,
        meta: {
          title: 'Rehber Yorumları',
          cookies: [20, 80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/turRezervasyon',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'turRezervasyonList',
        component: TurRezervasyonList,
        meta: {
          title: 'Tur Rezervasyonları',
          cookies: [10, 15, 20, 30, 35, 40, 80, 85, 100]
        }
      },
      {
        path: 'detay/:id',
        name: 'turRezervasyonDetay',
        component: TurRezervasyonDetay,
        meta: {
          title: 'Tur Rezervasyon Detayı',
          cookies: [10, 15, 20, 30, 35, 40, 80, 85, 100]
        }
      },
      {
        path: 'duzelt/:id',
        name: 'turRezervasyonDuzelt',
        component: TurRezervasyonDuzelt,
        meta: {
          title: 'Tur Rezervasyon Düzelt',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'duzeltArac/:id',
        name: 'turRezervasyonDuzeltArac',
        component: TurRezervasyonDuzeltArac,
        meta: {
          title: 'Tur Rezervasyon Araç Düzelt',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'duzeltRehber/:id',
        name: 'turRezervasyonDuzeltRehber',
        component: TurRezervasyonDuzeltRehber,
        meta: {
          title: 'Tur Rezervasyon Rehber Düzelt',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'yeni/:id',
        name: 'turRezervasyonYeni',
        component: TurRezervasyonYeni,
        meta: {
          title: 'Yeni Tur Rezervasyonu',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'yeniGrup/:id',
        name: 'turRezervasyonYeniGrup',
        component: TurRezervasyonYeniGrup,
        meta: {
          title: 'Yeni Grup Tur Rezervasyonu',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'yorum/:id',
        name: 'turRezervasyonYorum',
        component: TurRezervasyonYorum,
        meta: {
          title: 'Tur Rezervasyon Yorumu',
          cookies: [10, 15, 80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/regularRezervasyon',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'istekList',
        name: 'regularRezervasyonIstekList',
        component: RegularRezervasyonIstekList,
        meta: {
          title: 'Regular Rezervasyon İstekleri',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'istekDetay/:id',
        name: 'regularRezervasyonIstekDetay',
        component: RegularRezervasyonIstekDetay,
        meta: {
          title: 'Regular Rezervasyon İstek Detayı',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'istekYeni/:id',
        name: 'regularRezervasyonIstekYeni',
        component: RegularRezervasyonIstekYeni,
        meta: {
          title: 'Yeni Regular Rezervasyon',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'istekDuzelt/:id',
        name: 'regularRezervasyonIstekDuzelt',
        component: RegularRezervasyonIstekDuzelt,
        meta: {
          title: 'Regular Rezervasyon İstek Düzelt',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'istekPlanla',
        name: 'regularRezervasyonIstekPlanla',
        component: RegularRezervasyonIstekPlanla,
        meta: {
          title: 'Regular Rezervasyon İstek Planla',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'rezDetay/:id',
        name: 'regularRezervasyonDetay',
        component: RegularRezervasyonDetay,
        meta: {
          title: 'Regular Rezervasyon Detayı',
          cookies: [10, 15, 20, 30, 35, 40, 80, 85, 100]
        }
      },
      {
        path: 'rezList',
        name: 'regularRezervasyonList',
        component: RegularRezervasyonList,
        meta: {
          title: 'Regular Rezervasyonları',
          cookies: [10, 15, 20, 30, 35, 40, 80, 85, 100]
        }
      },
    ]
  },
  {
    path: '/balonRezervasyon',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'balonRezervasyonList',
        component: BalonRezervasyonList,
        meta: {
          title: 'Balon Rezervasyonları',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'detay/:id',
        name: 'balonRezervasyonDetay',
        component: BalonRezervasyonDetay,
        meta: {
          title: 'Balon Rezervasyon Detayı',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'duzelt/:id',
        name: 'balonRezervasyonDuzelt',
        component: BalonRezervasyonDuzelt,
        meta: {
          title: 'Balon Rezervasyon Düzelt',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'yeni/:id',
        name: 'balonRezervasyonYeni',
        component: BalonRezervasyonYeni,
        meta: {
          title: 'Yeni Balon Rezervasyon',
          cookies: [10, 15, 80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/transfer',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'transferList',
        component: TransferList,
        meta: {
          title: 'Transfer Rezervasyonları',
          cookies: [10, 15, 20, 30, 35, 40, 80, 85, 100]
        }
      },
      {
        path: 'detay/:id',
        name: 'transferDetayDetay',
        component: TransferDetay,
        meta: {
          title: 'Transfer Rezervasyon Detayı',
          cookies: [10, 15, 20, 30, 35, 40, 80, 85, 100]
        }
      },
      {
        path: 'yeni/:id',
        name: 'transferYeni',
        component: TransferYeni,
        meta: {
          title: 'Yeni Transfer Rezervasyonu',
          cookies: [10, 15, 80, 85, 100]
        }
      },
      {
        path: 'tanim',
        name: 'transferTanim',
        component: TransferTanim,
        meta: {
          title: 'Laal Tanımlamalar',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/sehir',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'sehirList',
        component: SehirList,
        meta: {
          title: 'Şehirler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'sehirEkle',
        component: SehirKayit,
        meta: {
          title: 'Yeni Şehir - Şehirler',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'sehirDuzelt',
        component: SehirKayit,
        meta: {
          title: 'Şehir - Şehirler',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/kullanici',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'kullaniciList',
        component: KullaniciList,
        meta: {
          title: 'Kullanıcılar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit',
        name: 'kullaniciEkle',
        component: KullaniciKayit,
        meta: {
          title: 'Yeni Kullanıcı - Kullanıcılar',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'kayit/:id',
        name: 'kullaniciDuzelt',
        component: KullaniciKayit,
        meta: {
          title: 'Kullanıcı - Kullanıcılar',
          cookies: [80, 85, 100]
        }
      }
    ]
  },
  {
    path: '/kullaniciTipi',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'menu',
        name: 'kullaniciTipiMenu',
        component: KullaniciTipiMenu,
        meta: {
          title: 'Panel Menüleri',
          cookies: [100]
        }
      }
    ]
  },
  {
    path: '/parametre',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'guncelle',
        name: 'parametreGuncelle',
        component: ParametreGuncelle,
        meta: {
          title: 'Parametre Güncelleme',
          cookies: [80, 85, 100]
        }
      },
      {
        path: 'turAracFiyat',
        name: 'turAracFiyat',
        component: TurAracFiyat,
        meta: {
          title: 'Standart Tur - Araç Servis Fiyatları',
          cookies: [80, 85, 100]
        }
      }
    ]
  }

];

const router = new Router({
  routes,
  // base: '/endless/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});


router.beforeEach((to, from, next) => {
  if (to.meta.cookies != undefined) {
    //Kullanıcı tipini kontrol edelim
    var kullaniciJson = localStorage.getItem('KullaniciJson')
    if (kullaniciJson == undefined) {
      next('/giris');
    } else {
      let kullanici = JSON.parse(kullaniciJson);
      let kTipi = kullanici.KullaniciTipi;
      if (to.meta.cookies.includes(kTipi) == false) {
        return next('/error/error401');
      }
    }
  }

  const path = ['/giris', 
    '/giris/acentebasvur', 
    '/giris/rehberbasvur', 
    '/giris/aracbasvur', 
    '/giris/sifremiunuttum'];
    
  if (path.includes(to.path.toLocaleLowerCase()) || 
      to.path.toLocaleLowerCase().indexOf('/reservation/tour') > -1 || 
      to.path.toLocaleLowerCase().indexOf('/reservation/regular') > -1 || 
      localStorage.getItem('Token')) {
    return next();
  }
  next('/giris');
});


export default router