<template>
  <div>
    <span :id="'performans-row-' + rowKey">
      <b-badge :variant="variantBul(Yildiz)">{{ Yildiz }}</b-badge>
    </span>
    <b-tooltip :target="'performans-row-' + rowKey" triggers="hover" placement="top">
      Memnun Olmayanlar : {{ MemnunOlmayanlar }}<br>
      Notr : {{ Notr }}<br>
      Savunma Red : {{ SavunmaRedSayisi }}<br>
    </b-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    Yildiz: 0,
    MemnunOlmayanlar: 0,
    Notr: 0,
    SavunmaRedSayisi: 0,
    rowKey: ""
  },
  data() {
    return {
      variant: "default",
    };
  },
  methods: {
    variantBul(newValue) {
      if (newValue > 0 && newValue < 4) {
        return "danger";
      } else if (newValue > 7) {
        return "success";
      } else if(newValue > 3 && newValue < 8) {
        return "warning";
      }else {
        return "dark";
      }
    },
  },
};
</script>
