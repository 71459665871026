<template>
  <div>{{ formatMoney(fiyat) }} {{ simge }}</div>
</template>
<script>
export default {
  props: {
    fiyat: null,
    simge: null,
  },
  mounted() {
    if (this.fiyat === null) this.fiyat = 0;
    if (this.simge === null) this.simge = "TL";
  },
  methods: {
    formatMoney(n) {
      return parseFloat(n)
        .toFixed(2)
        .replace(/(\\d)(?=(\\d{3})+\\.)/g, "$1.")
        .replace(/\\.(\\d+)$/, ",$1");
    },
  },
};
</script>
