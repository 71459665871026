<template>
  <div>
    <div class="button-wrapper mb-3">
      <b-button class="button" @click="$refs.file.click()">
        <input
          type="file"
          ref="file"
          @change="loadImage($event)"
          accept="image/*"
          style="display: none"
        />
        Resim Yükle
      </b-button>
    </div>
    <p class="lead" v-if="mutateImage.src !== null">
      Resmi küçültmek için; resmin üzerindeyken mouseun scroll tuşunu
      kullanabilirsiniz.
    </p>
    <cropper
      v-if="mutateImage.src !== null"
      class="cropper upload-example-cropper"
      :src="mutateImage.src"
      ref="cropper"
      :stencil-props="{
        handlers: {},
        movable: false,
        resizable: false,
      }"
      :resize-image="{
        adjustStencil: false,
      }"
      :stencil-size="{
        width: 440,
        height: 700,
      }"
      image-restriction="stencil"
    />
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  props: {
    image: {
      src: null,
      type: null,
    },
  },
  data() {
    return {
      mutateImage: this.image,
    };
  },
  components: {
    Cropper,
  },
  watch: {
    image(newValue, oldValue) {
      this.mutateImage = {
        src: newValue.src + "?test=111",
        type: newValue.type,
      };
    },
  },
  methods: {
    loadImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.mutateImage.src) {
          URL.revokeObjectURL(this.mutateImage.src);
        }
        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.mutateImage = {
            src: blob,
            type: this.$validationHelper.getMimeType(
              e.target.result,
              files[0].type
            ),
          };
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        // Do something with blob: upload to a server, download and etc.
      }, this.image.type);
    },
    reset() {
      this.mutateImage = {
        src: null,
        type: null,
      };
    },
  },
};
</script>
