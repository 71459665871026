<template>
  <div>
    <Breadcrumbs main="Balon Rezervasyonları" title="Liste" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="default-according">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="div" role="tab" text-variant="dark" header-bg-variant="primary">
                <h5 class="mb-0">
                  <b-button v-b-toggle.simpleaccordion-1>DETAYLI FİLTRELEMEK İÇİN TIKLAYINIZ
                    <small>({{
                      this.$formatHelper.formatDate(filtre.BaslangicTarihi)
                    }}
                      -
                      {{
                        this.$formatHelper.formatDate(filtre.BitisTarihi)
                      }}
                      arası rezervasyonlar listeleniyor)</small>
                  </b-button>
                </h5>
              </b-card-header>
              <b-collapse id="simpleaccordion-1" accordion="myaccordion" role="tabpanel">
                <b-card-body>
                  <b-form @submit="onFiltreSubmit">
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_kelime">Kelime</label>
                        <b-form-input type="text" id="filtre_kelime" v-model="filtre.Kelime"
                          placeholder="Kelime"></b-form-input>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_kod">Rezervasyon Kodu</label>
                        <b-form-input type="text" id="filtre_kod" v-model="filtre.Kod"
                          placeholder="Rezervasyon Kodu"></b-form-input>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3">
                        <label for="filtre_baslangictarihi">Başlangıç Tarihi</label>
                        <datepicker input-class="datepicker-here form-control digits" id="filtre_baslangictarihi"
                          v-model="filtre.BaslangicTarihi" :language="tr" :clear-button="true"
                          clear-button-icon="fa fa-times" placeholder="Başlangıç Tarihi" :format="date_format">
                        </datepicker>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_bitistarihi">Bitiş Tarihi</label>
                        <datepicker input-class="datepicker-here form-control digits" id="filtre_bitistarihi"
                          v-model="filtre.BitisTarihi" :language="tr" :clear-button="true"
                          clear-button-icon="fa fa-times" placeholder="Bitiş Tarihi" :format="date_format"></datepicker>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3" v-if="filtre_durumlar != null">
                        <label for="filtre_durum">Durum</label>
                        <b-form-select id="filtre_durum" v-model="filtre.Durum"
                          :options="filtre_durumlar"></b-form-select>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="filtre_balonFirma">Balon Firmaları</label>
                        <b-form-select id="filtre_balonFirma" @change="turSec" v-model="filtre.BalonFirmaId"
                          :options="filtre_balonFirmalari"></b-form-select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6 mb-3" v-if="filtre_acenteler !== null">
                        <label for="filtre_acente">Acente</label>
                        <vue-bootstrap-typeahead v-model="queryAcente" :data="filtre_acenteler"
                          :serializer="(item) => item.Adi" @hit="filtre.AcenteId = $event.Id"
                          placeholder="Acentelerde Ara" />
                      </div>
                      <div class="col-md-6 mb-3">
                        <b-button type="submit" variant="warning">Listele</b-button>
                      </div>
                    </div>
                  </b-form>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link :to="`/balonRezervasyon/detay/${row.item.Id}`"
                      class="btn btn-success btn-xs">Detay</router-link>
                  </b-btn-group>
                </template>
                <template #cell(DurumStr)="row">
                  <RezervasyonDurum :durum="row.item.DurumStr" :durumInt="row.item.Durum" />
                </template>
                <template #cell(Tarih)="row">
                  <c-tarih-cell :kayitTarihi="row.item.Tarih" :rowKey="row.item.Id"></c-tarih-cell>
                </template>
                <template #cell(SatisToplamTutar)="data">
                  <c-money-cell :fiyat="data.value" simge="€"></c-money-cell>
                </template>
                <template #cell(KisiSayisi)="data">
                  <b-badge variant="primary">{{ data.value }}</b-badge>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import RezervasyonDurum from "@/components/c_turRezervasyon_durum";

export default {
  data() {
    return {
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "AcenteAdi", label: "Acente", sortable: true },
        { key: "BalonFirmaAdi", label: "Balon F.", sortable: true },
        { key: "Kod", label: "Kod", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "Tarih", label: "Tarih", sortable: true },
        { key: "SatisToplamTutar", label: "Toplam", sortable: true },
        { key: "KisiSayisi", label: "Kişi", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
      ],

      items: [],

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre_durumlar: [
        { value: null, text: "Durumlar" },
        { value: "0", text: "Yeni" },
        { value: "1", text: "Onaylı" },
        { value: "2", text: "Tamamlandı" },
        { value: "100", text: "İptal" },
      ],

      queryAcente: "",
      filtre_acenteler: [{ value: null, text: "Acenteler" }],

      filtre_balonFirmalari: [{ value: null, text: "Balon Firmaları" }],

      filtre: {
        AcenteId: null,
        BalonFirmaId: null,
        Kod: null,
        BaslangicTarihi: null,
        BitisTarihi: null,
        Kelime: null,
        Durum: null
      },
    };
  },
  components: {
    Datepicker,
    VueBootstrapTypeahead,
    RezervasyonDurum,
  },
  mounted() {
    this.setKullaniciTipi();

    this.balonFirmaList();
    this.onList();
  },
  methods: {
    onFiltreSubmit(evt) {
      evt.preventDefault();
      this.onList();
    },
    balonFirmaList() {
      let vm = this;
      this.$connection.get("/balonFirmalari", function (response) {
        response = response.map((item) => {
          return { value: item.Id, text: item.Adi };
        });
        vm.filtre_balonFirmalari = vm.filtre_balonFirmalari.concat(response);
      });
    },
    onList() {
      let vm = this;

      this.$connection.post(
        "/balonRezervasyonlari/filtre",
        vm.filtre,
        "post",
        function (response) {
          vm.items = response;
        }
      );
    },
    setKullaniciTipi() {
      let fiyatGoster = false;
      let acenteGoster = false;

      let bitisTarihi = new Date();
      let baslangicTarih = new Date();
      bitisTarihi.setDate(bitisTarihi.getDate() + 15);
      this.filtre.BaslangicTarihi = baslangicTarih;
      this.filtre.BitisTarihi = bitisTarihi;

      if (this.$user.isAcente()) {
        //Giren acente onun kini listeleyeceği sadece
        this.filtre.AcenteId = this.$user.acenteId();

        this.filtre_acenteler = null;

        fiyatGoster = true;
      } else {
        fiyatGoster = true;
        acenteGoster = true;
      }

      this.tablefields = [
        { key: "Islem", label: "İşlemler", sortable: false },
        acenteGoster ? 
          { key: "AcenteAdi", label: "Acente", sortable: true }
          : null,
        { key: "BalonFirmaAdi", label: "Balon F.", sortable: true },
        { key: "Kod", label: "Kod", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "Tarih", label: "Tarih", sortable: true },
        { key: "SatisToplamTutar", label: "Toplam", sortable: true },
        { key: "KisiSayisi", label: "Kişi", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
      ];
    },
  },
};
</script>
