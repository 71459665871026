<template>
    <!-- page-wrapper Start-->
    <div class="page-wrapper">
        <div class="container-fluid">
            <!-- sign up page start-->
            <div class="authentication-main">
                <div class="row">
                    <div class="col-sm-12 p-0">
                        <div class="auth-innerright">
                            <div class="authentication-box">
                                <div class="text-center"><img alt="" src="../assets/images/logo.png"></div>
                                <div class="card mt-4 p-4">
                                    <h4 class="text-center">{{ form_label.baslik }}</h4>
                                    <h6 class="text-center">{{ form_label.aciklama }}</h6>
                                    <b-form @submit="onSubmit" class="theme-form">
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">Yetkili Adı</label>
                                                    <b-form-input type="text" v-model="kayit.YetkiliKisi"
                                                        :state="kayit_yetkilikisi_state"
                                                        placeholder="Yetkili Kişi"></b-form-input>
                                                    <b-form-invalid-feedback :state="kayit_yetkilikisi_state">Yetkili
                                                        kişi
                                                        adını
                                                        giriniz</b-form-invalid-feedback>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">{{ form_label.firma_adi }}</label>
                                                    <b-form-input type="text" v-model="kayit.Adi"
                                                        :state="kayit_adi_state"
                                                        :placeholder="form_label.firma_adi"></b-form-input>
                                                    <b-form-invalid-feedback :state="kayit_adi_state">{{
                                        form_label.firma_adi_validate }}</b-form-invalid-feedback>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Unvan</label>
                                            <b-form-input type="text" v-model="kayit.Unvan" :state="kayit_unvan_state"
                                                :placeholder="form_label.unvan"></b-form-input>
                                            <b-form-invalid-feedback :state="kayit_unvan_state">{{
                                        form_label.unvan_validate }}</b-form-invalid-feedback>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Adres</label>
                                            <b-form-textarea type="text" v-model="kayit.Adres"
                                                :state="kayit_adres_state"
                                                :placeholder="form_label.adres"></b-form-textarea>
                                            <b-form-invalid-feedback
                                                :state="kayit_adres_state">{{ form_label.adres_validate }}</b-form-invalid-feedback>
                                        </div>
                                        <div class="form-group" v-if="kayit.Tipi == 0">
                                            <label class="col-form-label">Şehir</label>
                                            <b-form-select id="kayit_sehirid" v-model="kayit.SehirId"
                                                :options="kayit_sehir_options"
                                                :state="kayit_sehirid_state"></b-form-select>
                                            <b-form-invalid-feedback :state="kayit_sehirid_state">Şehrinizi
                                                seçiniz</b-form-invalid-feedback>
                                        </div>
                                        <div class="form-row" v-if="kayit.Tipi == 10">
                                            <div class="col-md-6">
                                                <div class="form-group m-t-15 custom-radio-ml">
                                                    <div class="radio radio-primary">
                                                        <b-form-radio name="SehirId" v-model="kayit.SehirId"
                                                            value="61">NEVŞEHİR</b-form-radio>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group m-t-15 custom-radio-ml">
                                                    <div class="radio radio-primary">
                                                        <b-form-radio name="SehirId" v-model="kayit.SehirId"
                                                            value="90">KAYSERİ</b-form-radio>
                                                    </div>
                                                </div>
                                            </div>
                                            <b-form-invalid-feedback :state="kayit_sehirid_state">Şehrinizi
                                                seçiniz</b-form-invalid-feedback>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Vergi Dairesi</label>
                                            <b-form-input type="text" v-model="kayit.VergiDairesi"
                                                placeholder="Vergi Dairesi"></b-form-input>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Vergi No</label>
                                            <b-form-input type="text" v-model="kayit.VergiNo"
                                                :state="kayit_vergino_state"
                                                placeholder="T.C. Kimlik No veya Vergi No"></b-form-input>
                                            <b-form-invalid-feedback :state="kayit_vergino_state">Bireyselse 11 haneli
                                                T.C.
                                                kimlik numarası
                                                kurumsal ise 10 haneli vergi numarasını
                                                giriniz</b-form-invalid-feedback>
                                        </div>
                                        <div class="form-group" v-if="kayit.Tipi == 0">
                                            <label class="col-form-label">TURSAB Belge No</label>
                                            <b-form-input type="text" v-model="kayit.TursabBelgeNo"
                                                :state="kayit_tursabbelgeno_state"
                                                placeholder="TURSAB Belge No"></b-form-input>
                                            <b-form-invalid-feedback :state="kayit_tursabbelgeno_state">TURSAB belge
                                                numarasını giriniz</b-form-invalid-feedback>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">Cep Telefonu</label>
                                                    <b-form-input type="text" v-model="kayit.GSM"
                                                        v-mask="'5## ### ## ##'" placeholder="Cep Telefonu"
                                                        :state="kayit_gsm_state"></b-form-input>
                                                    <b-form-invalid-feedback :state="kayit_gsm_state">Cep telefonunu
                                                        giriniz</b-form-invalid-feedback>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">Operasyon Telefonu</label>
                                                    <b-form-input type="text" v-model="kayit.OperasyonTelefonu"
                                                        placeholder="Operasyon Telefonu"></b-form-input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Email</label>
                                            <b-form-input type="text" id="kayit_email" v-model="kayit.Email"
                                                placeholder="Email" :state="kayit_email_state"></b-form-input>
                                            <b-form-invalid-feedback :state="kayit_email_state">Email adresini
                                                giriniz</b-form-invalid-feedback>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Kullanıcı Adı</label>
                                            <b-form-input type="text" id="kayit_kullaniciadi"
                                                v-model="kayit.KullaniciAdi" placeholder="Kullanıcı Adı"
                                                :state="kayit_kullaniciadi_state"></b-form-input>
                                            <b-form-invalid-feedback :state="kayit_kullaniciadi_state">Ana mekan
                                                kullanıcısının kullanıcı adını
                                                giriniz</b-form-invalid-feedback>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Şifre</label>
                                            <b-form-input type="text" id="kayit_sifre" v-model="kayit.Sifre"
                                                placeholder="Şifre" :state="kayit_sifre_state"></b-form-input>
                                            <b-form-invalid-feedback :state="kayit_sifre_state">8 ile 20 karakter
                                                arasında rakam, küçük ve büyük harf bulunan bir
                                                şifre giriniz</b-form-invalid-feedback>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-sm-12 text-center mb-3">
                                                <b-button variant="primary" :disabled="isLoading" type="submit">{{
                                                    submitText }}</b-button>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-sm-12 text-center">
                                                <router-link :to="'/giris'" class="btn-link text-capitalize">Giriş
                                                    Yap</router-link>
                                            </div>
                                        </div>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            kayit: {
                Adi: '',
                Unvan: '',
                YetkiliKisi: '',
                Adres: '',
                SehirId: 0,
                VergiDairesi: '',
                VergiNo: '',
                TursabBelgeNo: '',
                OperasyonTelefonu: '',
                GSM: '',
                Email: '',
                KullaniciAdi: '',
                Sifre: '',
                Tipi: this.$route.query.tipi
            },

            kayit_sehir_options: [
                { value: "0", text: 'Seçiniz' }
            ],

            form_label: {
                baslik: '',
                aciklama: '',
                firma_adi: '',
                firma_adi_validate: '',
                unvan: '',
                unvan_validate: '',
                adres: '',
                adres_validate: ''
            },

            submitText: 'Kayıt Ol',
            isLoading: false,
        }
    },
    mounted() {
        this.onSehirList();

        if (this.kayit.Tipi == 10) {
            this.form_label = {
                baslik: 'OTEL KAYIT',
                aciklama: 'Otel başvurunuzu buradan yapabilirsiniz',
                firma_adi: 'Otel Adı',
                firma_adi_validate: 'Otel adını giriniz',
                unvan: 'Otel Unvanı',
                unvan_validate: 'Otel unvanını giriniz',
                adres: 'Otel Adresi',
                adres_validate: 'Otel adresini giriniz',
                submit_title: 'Otel Başvuru',
                submit_success_message: 'Otel başvurunuz için teşekkür ederiz. En kısa sürede Sms / Mail yoluyla bilgilendirileceksiniz.'
            };
        }else{
            this.form_label = {
                baslik: 'ACENTE KAYIT',
                aciklama: 'Acente başvurunuzu buradan yapabilirsiniz',
                firma_adi: 'Firma Adı',
                firma_adi_validate: 'Acente adını giriniz',
                unvan: 'Firma Unvanı',
                unvan_validate: 'Firma unvanını giriniz',
                adres: 'Firma Adresi',
                adres_validate: 'Firma adresini giriniz',
                submit_title: 'Acente Başvuru',
                submit_success_message: 'Acente başvurunuz için teşekkür ederiz. En kısa sürede Sms / Mail yoluyla bilgilendirileceksiniz.'
            };
        }
    },
    computed: {
        kayit_adi_state() {
            if (this.kayit.Adi == null || this.kayit.Adi == '')
                return false;
            else
                return true;
        },
        kayit_unvan_state() {
            if (this.kayit.Unvan == null || this.kayit.Unvan == '')
                return false;
            else
                return true;
        },
        kayit_yetkilikisi_state() {
            if (this.kayit.YetkiliKisi == null || this.kayit.YetkiliKisi == '')
                return false;
            else
                return true;
        },
        kayit_adres_state() {
            if (this.kayit.Adres == null || this.kayit.Adres == '')
                return false;
            else
                return true;
        },
        kayit_sehirid_state() {
            if (this.kayit.SehirId == '0')
                return false;
            else
                return true;
        },
        kayit_vergino_state() {
            if (this.kayit.VergiNo == null || this.kayit.VergiNo == '' || this.kayit.VergiNo.length < 10 || this.kayit.VergiNo.length > 11)
                return false;
            else
                return true;
        },
        kayit_gsm_state() {
            if (!this.$validationHelper.validGsm(this.kayit.GSM))
                return false;
            else
                return true;
        },
        kayit_email_state() {
            if (!this.$validationHelper.validEmail(this.kayit.Email))
                return false;
            else
                return true;
        },
        kayit_tursabbelgeno_state() {
            if (this.kayit.Tipi == 0 && (this.kayit.TursabBelgeNo == null || this.kayit.TursabBelgeNo == ''))
                return false;
            else
                return true;
        },
        kayit_kullaniciadi_state() {
            if (this.kayit.KullaniciAdi === null || this.kayit.KullaniciAdi === '')
                return false;
            else
                return true;
        },
        kayit_sifre_state() {
            if (!this.$validationHelper.validPassword(this.kayit.Sifre))
                return false;
            else
                return true;
        }
    },
    methods: {
        onSehirList() {
            let vm = this;

            this.$connection.get("/sehirler/select", function (response) {
                vm.kayit_sehir_options = vm.kayit_sehir_options.concat(response);
            });
        },
        gsmUpdate(payload) {
            this.is_gsm_valid = payload.isValid;
        },
        onSubmit(evt) {
            evt.preventDefault()

            let isValid = (this.kayit_adi_state && this.kayit_unvan_state && this.kayit_yetkilikisi_state && this.kayit_adres_state
                && this.kayit_sehirid_state && this.kayit_vergino_state && this.kayit_tursabbelgeno_state && this.kayit_gsm_state && this.kayit_email_state
                && this.kayit_kullaniciadi_state && this.kayit_sifre_state);

            if (isValid) {
                let vm = this;

                let url = '/auth/AcenteBasvur';
                let method = 'post';
                
                vm.isLoading = true;
                vm.submitText = 'Başvuru Yapılıyor...';

                vm.$connection.post(url, vm.kayit, method, function (response) {
                    if (response.Success) {
                        vm.$alert.messageWithFunction(vm.form_label.submit_title, vm.form_label.submit_success_message, 'success', function () {
                            vm.$router.push('/giris');
                        });
                    }
                }, function (error) {
                    vm.isLoading = false;
                    vm.submitText = 'Kayıt Ol';

                    vm.$alert.messageWithTitle(vm.form_label.submit_title, error.Message, 'error');
                });
            }
        }
    }
}
</script>