<template>
  <div>
      {{ formatDate(tarih, "HH:mm") }}
  </div>
</template>
<script>
export default {
  props: {
    tarih: null
  },
  methods: {
    formatDate(value, format) {
      if (value) {
        return this.$moment(String(value)).format(format);
      }
    },
  },
};
</script>
