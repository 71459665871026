<template>
  <div class="table-responsive mb-0">
    <table class="table table-bordernone table-striped">
      <thead>
        <tr>
          <th scope="col">İşlem</th>
          <th scope="col">Kod</th>
          <th scope="col">Durum</th>
          <th scope="col">Kişi</th>
          <th scope="col">Misafir</th>
          <th scope="col">Tarih</th>
          <th scope="col" class="sabitKol">
            <div>Balon Firması</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.Id">
          <td>
            <b-btn-group>
              <router-link :to="`/balonRezervasyon/detay/${item.Id}`" class="btn btn-info btn-xs">Detay</router-link>
            </b-btn-group>
          </td>
          <td><b-badge variant="info">{{ item.Kod }}</b-badge></td>
          <td>
            <RezervasyonDurum :durum="item.DurumStr" :durumInt="item.Durum" />
          </td>
          <td><b-badge variant="info">{{ item.KisiSayisi }}</b-badge></td>
          <td>{{ item.Misafirler }}</td>
          <td>
            <c-tarih-cell :kayitTarihi="item.Tarih" :rowKey="item.Id"></c-tarih-cell>
          </td>
          <td class="sabitKol">
            <div>
              {{ item.BalonFirmaAdi }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import RezervasyonDurum from "./c_balonRezervasyon_durum";

export default {
  props: {
    tarih: null
  },
  data() {
    return {
      items: []
    };
  },
  components: {
    RezervasyonDurum,
  },
  mounted() {
    this.listele();
  },
  watch: {
    tarih(newValue, oldValue) {
      this.listele();
    },
  },
  methods: {
    listele() {
      let vm = this;

      let filtre = {
        Tarih: this.$formatHelper.formatDateApi(this.tarih)
      }

      this.$connection.post(
        "/balonRezervasyonlari/filtreForAcente",
        filtre,
        "post",
        function (response) {
          vm.items = response;

          vm.$emit("onKayitSayisi", vm.items.length);
        }
      );
    },
  },
};
</script>