<template>
  <div class="card">
    <div class="card-header card-header-border">
      <div class="row">
        <div class="col-sm-6">
          <h5>Araç Resimleri</h5>
        </div>
        <div class="col-sm-6">
          <div class="pull-right right-header">
            <b-button v-b-modal.resimEkle variant="success">
              Resim Ekle
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body my-gallery gallery gallery-with-description">
      <draggable v-model="resimler" group="resimler" class="row" itemscope itemprop="thumbnail"
        @change="draggableChange" :move="checkMove" handle=".moveHandle">
        <figure class="col-xl-3 col-sm-6" v-for="(resim, index) in resimler" :key="index">
          <a>
            <div class="moveHandle">
              <feather type="menu"></feather>
            </div>
            <img :src="resim.Resim" class="img-thumbnail" alt="" @click="gindex = index" />
            <div class="caption">
              <h4 v-text="resim.TipiStr"></h4>
              <p>
                Durum : <b-badge variant="info">{{ resim.DurumStr }}</b-badge><br />
                Kullanıcı : {{ resim.KullaniciAdi }}<br />
                Kayıt : {{ formatDate(resim.KayitTarihi) }}<br />
                Güncel : {{ formatDate(resim.GuncelTarih) }}
              </p>
              <b-form-select v-if="!noEdit" v-model="resim.Durum" @change="durumDegistir(resim)" :options="durumlar"
                class="mb-3"></b-form-select>
              <b-button variant="danger" v-if="!noEdit" @click="sil(resim)">Sil</b-button>
            </div>
          </a>
        </figure>

        <VueGallery :images="resimlerGallery" :index="gindex" @close="gindex = null" :options="galleryoptions">
        </VueGallery>
      </draggable>

      <b-modal id="resimEkle" centered title="Resim Kaydet" @ok="onSubmit" ok-title="Kaydet" cancel-title="Kapat"
        class="theme-modal">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="kayit_tipi">Tipi</label>
            <b-form-select id="kayit_tipi" v-model="kayit.Tipi" :state="kayit_tipi_state"
              :options="kayit_tip_options"></b-form-select>
            <b-form-invalid-feedback :state="kayit_tipi_state">Resim tipini seçiniz.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <AracResimCrop @onChange="onKokartChange" />
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import VueGallery from "vue-gallery";
import AracResimCrop from "@/components/c_arac_resim_crop.vue";
import draggable from "vuedraggable";

export default {
  props: {
    aracId: "",
    noEdit: false,
  },
  data() {
    return {
      resimler: [],

      gindex: null,
      resimlerGallery: [],
      galleryoptions: {
        prevClass: "prev",
        nextClass: "next",
      },

      kayit: {
        Tipi: null,
        ResimFl: null,
      },

      canvas: null,

      kayit_tip_options: [
        { value: null, text: "Seçiniz" },
        { value: 1, text: "Şoför Profil Resmi" },
        { value: 2, text: "Araç Resmi" },
      ],

      durumlar: [
        { value: 0, text: "Yeni" },
        { value: 20, text: "Pasif" },
        { value: 30, text: "Onaylı" },
        { value: 100, text: "İptal" },
      ],
    };
  },
  mounted() {
    this.resimListele();
  },
  components: {
    VueGallery,
    AracResimCrop,
    draggable,
  },
  computed: {
    kayit_tipi_state() {
      if (this.kayit.Tipi == null) return false;
      else return true;
    },
    kayit_resim_state() {
      if (this.canvas == null) return false;
      else return true;
    },
  },
  watch: {
    resimler: function () {
      this.resimlerGallery = this.resimler.map((resim) => {
        return resim.Resim;
      });
    },
  },
  methods: {
    formatDate(tarih) {
      return this.$formatHelper.formatDate(tarih);
    },
    resimListele() {
      let vm = this;

      this.$connection.get(
        "/araclar/resim/" + this.aracId,
        function (response) {
          vm.resimler = response;

          vm.resimlerGallery = response.map((resim) => {
            return resim.Resim;
          });
        }
      );
    },
    onKokartChange(canvas) {
      this.canvas = canvas;
    },
    onSubmit(target) {
      target.preventDefault();

      let isValid = this.kayit_resim_state && this.kayit_tipi_state;

      if (isValid) {
        let vm = this;

        if (this.canvas) {
          this.canvas.toBlob((blob) => {
            let kayit = { Tipi: vm.kayit.Tipi, ResimFl: blob };

            vm.$connection.postData(
              "/araclar/resim/" + vm.aracId,
              kayit,
              "post",
              function (response) {
                if (response.Success) {
                  vm.$alert.messageWithFunction(
                    "Resim Ekle",
                    response.Message,
                    "success",
                    function () {
                      vm.$bvModal.hide("resimEkle");

                      vm.image = {
                        src: null,
                        type: null,
                      };

                      vm.kayit = {
                        Tipi: null,
                        ResimFl: null,
                      };

                      vm.resimListele();
                    }
                  );
                }
              },
              function (error) {
                vm.$alert.messageWithTitle(
                  "Resim Ekle",
                  error.Message,
                  "error"
                );
              }
            );
          }, "image/jpeg");
        }
      }
    },
    sil(resim) {
      let vm = this;
      this.$alert.confirm(
        "Resim Sil",
        "Resmi silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete(
            "/araclar/resim/" + resim.Id,
            function (response) {
              vm.$alert.messageWithFunction(
                "Silindi",
                "Resim silindi.",
                "error",
                function () {
                  vm.$listHelper.removeItem(vm.resimler, resim);
                }
              );
            }
          );
        }
      );
    },
    durumDegistir(resim) {
      let vm = this;
      this.$connection.post(
        "/araclar/resim/" + resim.Id + "/durum/" + resim.Durum,
        {},
        "put",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithTitle(
              "Resim Durum",
              response.Message,
              "success"
            );
          } else {
            vm.$alert.messageWithTitle(
              "Resim Durum",
              response.Message,
              "error"
            );
          }
        }
      );
    },
    draggableChange(log) {
      let vm = this;

      let ids = vm.resimler.map((item) => {
        return item.Id;
      });
      let form = {
        Ids: ids,
      };

      vm.$connection.post(
        "/araclar/resimSirala/" + vm.aracId,
        form,
        "put",
        function (response) {
          if (response.Success) {
          } else {
            vm.$alert.messageWithTitle(
              "Sıralama Kaydet",
              response.Message,
              "error"
            );
          }
        }
      );
    },
    checkMove(evt) {
      return (
        evt.relatedContext.element.Tipi !== 1 &&
        evt.draggedContext.element.Tipi !== 1
      );
    },
  },
};
</script>
