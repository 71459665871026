<template>
  <div>
    <Breadcrumbs
      main="Onay Bekleyen Resimler"
      :title="pageTitle"
      :links="breadLinks"
    />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <b-button
                      size="xs"
                      @click="onayla(row.item)"
                      class="btn btn-info btn-xs"
                      >Onayla</b-button
                    >
                    <b-button size="xs" @click="sil(row.item)" variant="danger"
                      >Sil</b-button
                    >
                  </b-btn-group>
                </template>
                <template #cell(Resim)="data">
                  <img :src="data.value" class="img-thumbnail" alt="" />
                </template>
                <template #cell(SoforAdi)="row">
                  {{ row.item.SoforAdi }} / {{ row.item.Plaka }}
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell
                    :kayitTarihi="row.item.KayitTarihi"
                    :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"
                  ></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageTitle: "",

      breadLinks: [],

      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "Resim", label: "Resim", sortable: true },
        { key: "AracFirmaAdi", label: "Araç Firması", sortable: true },
        { key: "SoforAdi", label: "Şoför / Plaka", sortable: true },
        { key: "TipiStr", label: "Resim Tipi", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      this.$connection.get(
        "/araclar/resim/onaybekleyenler",
        function (response) {
          vm.items = response;
        }
      );
    },
    onayla(resim) {
      let vm = this;
      this.$connection.post(
        "/araclar/resim/" + resim.Id + "/durum/30",
        {},
        "put",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithTitle("Onay", response.Message, "success");
            vm.$listHelper.removeItem(vm.items, resim);
          } else {
            vm.$alert.messageWithTitle("Onay", response.Message, "error");
          }
        }
      );
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Resim Sil",
        "Resmi silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete(
            "/araclar/resim/" + item.Id,
            function (response) {
              vm.$alert.messageWithFunction(
                "Silindi",
                "Resim kaydı silindi.",
                "error",
                function () {
                  vm.$listHelper.removeItem(vm.items, item);
                }
              );
            }
          );
        }
      );
    },
  },
};
</script>
