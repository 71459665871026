<template>
  <div>
    <Breadcrumbs main="Parabirimleri" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Parabirimi Kaydı</h5>
              <span
                >Yeni para birimi veya para birimi kaydında değişiklikler için
                aşağıdaki formu kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_tanim">Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_tanim"
                      v-model="kayit.Tanim"
                      :state="kayit_tanim_state"
                      placeholder="Parabirimi Adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_tanim_state"
                      >Para birimi adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_simge">Simge</label>
                    <b-form-input
                      type="text"
                      id="kayit_simge"
                      v-model="kayit.Simge"
                      :state="kayit_simge_state"
                      placeholder="Simge"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_simge_state"
                      >Para birimi simgesini giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_kur">Kur</label>
                    <b-form-input
                      type="text"
                      id="kayit_kur"
                      v-model="kayit.Kur"
                      :state="kayit_kur_state"
                      placeholder="Kur"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_kur_state"
                      >TL karşılığı kur değerini
                      giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <router-link
                  :to="'/parabirimi/list'"
                  class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Para Birimi",

      breadLinks: [
        {
          path: "/parabirimi/list",
          title: "Para Birimleri",
          icon: "chevron-left",
        },
      ],

      kayit: {
        Tanim: "",
        Simge: "",
        Kur: 1,
      },
    };
  },
  mounted() {
    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Para Birimi Düzelt";

      this.$connection.get("/parabirimleri/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle(
            "Yeni Para Birimi",
            response.Message,
            "error"
          );
        }
      });
    }
  },
  computed: {
    kayit_tanim_state() {
      if (this.kayit.Tanim == null || this.kayit.Tanim == "") return false;
      else return true;
    },
    kayit_simge_state() {
      if (this.kayit.Simge == null || this.kayit.Simge == "") return false;
      else return true;
    },
    kayit_kur_state() {
      if (this.kayit.Kur == "" || parseFloat(this.kayit.Kur) <= 0) return false;
      else return true;
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_tanim_state &&
        this.kayit_simge_state &&
        this.kayit_kur_state;

      if (isValid) {
        let vm = this;

        let url = "/parabirimleri";
        let method = "post";
        let messageTitle = "Yeni Para Birimi";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Para Birimi Düzelt";
        }

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/parabirimi/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
