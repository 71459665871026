<template>
  <div class="row">
    <div class="col-md-6">
      <b-card header-tag="div" header-bg-variant="primary" class="card-absolute" v-if="acente.Tipi == 0">
        <h5 slot="header">Belgeler</h5>
        <b-form @submit="onSubmitBelge">
          <h5>Tursab Belgesi</h5>
          <div class="row">
            <div class="col-md-9">
              <b-input-group class="mt-3">
                <b-form-file class="form-control" v-model="tursabForm.BelgeFl" plain></b-form-file>
                <b-input-group-append>
                  <b-button type="submit" variant="info">Kaydet</b-button>
                </b-input-group-append>
              </b-input-group>
              <p>Pdf formatındaki Tursab işletme belgenizi ekleyiniz.</p>
            </div>
            <div class="col-md-3 text-success" v-if="acente.TursabBelge != '' && acente.TursabBelge != null">
              <a :href="acente.TursabBelge" target="_blank">
                <feather type="file"></feather>
              </a><br />
              <feather type="check"></feather> Dosya Ekli
            </div>
            <div class="col-md-3 text-danger" v-if="acente.TursabBelge == '' || acente.TursabBelge == null">
              <feather type="x"></feather> Dosya ekleyiniz
            </div>
          </div>
        </b-form>

        <hr />

        <b-form @submit="onSubmitVergiLevhasi">
          <h5>Vergi Levhası</h5>
          <div class="row">
            <div class="col-md-9">
              <b-input-group class="mt-3">
                <b-form-file class="form-control" v-model="vergiLevhasiForm.BelgeFl" plain></b-form-file>
                <b-input-group-append>
                  <b-button type="submit" variant="info">Kaydet</b-button>
                </b-input-group-append>
              </b-input-group>
              <p>Pdf formatındaki vergi levhanızı ekleyiniz.</p>
            </div>
            <div class="col-md-3 text-success" v-if="acente.VergiLevhasi != '' && acente.VergiLevhasi != null">
              <a :href="acente.VergiLevhasi" target="_blank">
                <feather type="file"></feather>
              </a>
              <feather type="check"></feather> Dosya Ekli
            </div>
            <div class="col-md-3 text-danger" v-if="acente.VergiLevhasi == '' || acente.VergiLevhasi == null">
              <feather type="x"></feather> Dosya ekleyiniz
            </div>
          </div>
        </b-form>

        <hr />

        <h5>Kaşe İmza</h5>
        <div class="row">
          <div class="col-md-6">
            <div class="button-wrapper mb-3">
              <b-button class="button" @click="$refs.file.click()">
                <input type="file" ref="file" @change="loadImage($event)" accept="image/*" style="display: none" />
                İmzalı Kaşe Resmi Yükle
              </b-button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="avatar" v-if="acente.KaseImza != '' && acente.KaseImza != null">
              <img class="img-fluid" :src="acente.KaseImza" alt="#" />
            </div>
          </div>
        </div>
        <div class="col-md-9" v-if="image.src != null">
          <p class="lead" v-if="image.src !== null">
            Resmi küçültmek için; resmin üzerindeyken mouseun scroll tuşunu
            kullanabilirsiniz.
          </p>
          <CropNavigation :zoom="zoom" @change="onZoom" />
          <cropper ref="cropper" class="twitter-cropper" background-class="twitter-cropper__background"
            foreground-class="twitter-cropper__foreground" image-restriction="stencil" :stencil-size="stencilSize"
            :stencil-props="{
          lines: {},
          handlers: {},
          movable: false,
          resizable: false,
          scalable: false,
          previewClass: 'twitter-cropper__stencil',
        }" :transitions="false" :debounce="false" :src="image.src" @change="onChange" />
          <b-button @click="onKaseImzaSubmit()" variant="primary" class="mt-3">Ekle</b-button>
        </div>
        <div class="col-md-12" v-if="image.src == null && acente.KaseImza == ''">
          <b-form-invalid-feedback :state="kayit_resim_state">Minumum 440px genişlik ve 440px yükseklikte imzalı kaşe
            resminizi
            giriniz.</b-form-invalid-feedback>
          <strong class="text-danger">
            <feather type="x"></feather> İmzalı kaşe görselini ekleyiniz.
          </strong><br />
          <img src="../assets/images/kase-imza-ornek.png" />
        </div>
        <div class="col-md-12" v-if="image.src == null && acente.KaseImza != ''">
          <strong class="text-success">
            <feather type="check"></feather> Ekli bir imzalı kaşe görseliniz
            var.
          </strong><br />
        </div>
      </b-card>
    </div>
    <div class="col-md-6">
      <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
        <h5 slot="header">Yorum Ayarları</h5>

        <b-form @submit="onSubmitYorum">
          <div class="row">
            <div class="col-md-9 mb-3">
              <label>QR Kod Yorum Resminiz</label>
              <b-form-file class="form-control" v-model="yorumForm.YorumQrFl" plain></b-form-file>
              <p>QR kod olan yorum resminizi ekleyebilirsiniz.</p>
            </div>
            <div class="col-md-3 text-success" v-if="acente.YorumQr != '' && acente.YorumQr != null">
              <img :src="acente.YorumQr" class="img-thumbnail" />
            </div>
            <div class="col-md-3 text-danger" v-if="acente.YorumQr == '' || acente.YorumQr == null">
              <feather type="x"></feather>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9 mb-3">
              <label>Yorum Linki</label>
              <b-form-input type="text" v-model="yorumForm.YorumLink"
                placeholder="Yorum alacağınız linki girebilirsiniz"></b-form-input>
            </div>
            <div class="col-md-3">
              <feather class="text-success" v-if="yorumForm.YorumLink != '' && yorumForm.YorumLink != null"
                type="check"></feather>
              <feather class="text-danger" v-if="yorumForm.YorumLink == '' || yorumForm.YorumLink == null" type="x">
              </feather>
            </div>
          </div>
          <b-button type="submit" variant="info">Kaydet</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>
<script>
import CropNavigation from "./c_crop_navigation.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  props: {
    acente: null,
  },
  data() {
    return {
      zoom: 0,
      image: {
        src: null,
        type: null,
      },
      canvas: null,

      tursabForm: {
        BelgeFl: null,
      },

      vergiLevhasiForm: {
        BelgeFl: null,
      },

      yorumForm: {
        YorumLink: this.acente.YorumLink,
        YorumQrFl: null,
      },
    };
  },
  mounted() { },
  components: {
    Cropper,
    CropNavigation,
  },
  computed: {
    kayit_resim_state() {
      if (this.canvas == null) return false;
      else return true;
    },
  },
  methods: {
    onSubmitBelge(target) {
      target.preventDefault();

      if (this.tursabForm.BelgeFl != null) {
        let vm = this;

        vm.$connection.postData(
          "/acenteler/tursab/" + this.acente.Id,
          vm.tursabForm,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Tursab Belge",
                response.Message,
                "success",
                function () {
                  vm.$emit("onSubmitBelge");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle("Tursab Belge", error.Message, "error");
          }
        );
      }
    },
    onSubmitVergiLevhasi(target) {
      target.preventDefault();

      if (this.vergiLevhasiForm.BelgeFl != null) {
        let vm = this;

        vm.$connection.postData(
          "/acenteler/vergiLevhasi/" + this.acente.Id,
          vm.vergiLevhasiForm,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Vergi Levhası",
                response.Message,
                "success",
                function () {
                  vm.$emit("onSubmitBelge");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle("Vergi Levhası", error.Message, "error");
          }
        );
      }
    },
    onSubmitYorum(target) {
      target.preventDefault();

      let vm = this;
      vm.$connection.postData(
        "/acenteler/yorum/" + this.acente.Id,
        vm.yorumForm,
        "put",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(
              "Acente Yorum",
              response.Message,
              "success",
              function () {
                vm.$emit("onSubmitYorum");
              }
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle("Acente Yorum", error.Message, "error");
        }
      );
    },
    loadImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: this.$validationHelper.getMimeType(
              e.target.result,
              files[0].type
            ),
          };
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: 440,
        height: 440,
      };
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }

        const { canvas } = this.$refs.cropper.getResult();
        this.canvas = canvas;
      }
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
            (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
            (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        // Do something with blob: upload to a server, download and etc.
      }, this.image.type);
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      };
    },
    onKaseImzaSubmit() {
      let vm = this;

      if (this.canvas) {
        this.canvas.toBlob((blob) => {
          let kayit = {
            BelgeFl: blob,
          };

          this.$connection.postData(
            "/acenteler/kaseImza/" + this.acente.Id,
            kayit,
            "put",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Kaşe İmza",
                  "Kaşe imza resminiz yüklendi.",
                  "success",
                  function () {
                    vm.image = {
                      src: null,
                      type: null,
                    };
                    vm.canvas = null;
                    vm.$emit("onSubmitBelge");
                  }
                );
              }
            },
            function (error) {
              vm.$alert.messageWithTitle("Kaşe İmza", error.Message, "error");
            }
          );
        }, "image/jpeg");
      }
    },
  },
  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
};
</script>
