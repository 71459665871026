<template>
  <div class="row">
    <div class="col-md-12">
      <b-card header-tag="div" header-bg-variant="info" class="card-absolute"
        v-if="showOpsiyonTutarlar && detay.TurDetay.OpsiyonTutarlar.length > 0">
        <h5 slot="header">Opsiyon Tutarları</h5>
        <b-card-body class="p-0">
          <b-table striped hover :fields="opsiyonTutarFields" :items="detay.TurDetay.OpsiyonTutarlar">
            <template #cell(YasStr)="row">
              <b-badge variant="primary">{{ row.item.YasStr }}</b-badge>
            </template>
            <template #cell(Oran)="data">
              <b-badge variant="primary">{{ data.value }}</b-badge>
            </template>
            <template #cell(Fiyat)="data">
              <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="€"></c-money-cell>
            </template>
            <template #cell(ToplamFiyat)="data">
              <c-money-cell :fiyat="data.value" simge="€"></c-money-cell>
            </template>
            <template #cell(AcenteFiyati)="data">
              <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
            </template>
          </b-table>
        </b-card-body>
      </b-card>

      <b-card header-tag="div" header-bg-variant="dark" class="card-absolute" v-if ="showMuzeTutarlar && detay.TurDetay.Muzeler.length > 0">
        <h5 slot="header">Müzeler</h5>
        <b-card-body>
          <b-card class="mb-1" v-for="muze in detay.TurDetay.Muzeler" v-bind:key="muze.MuzeId">
            <b-card-header header-tag="div" class="bg-primary">
              <h5 class="mb-0">
                {{ muze.MuzeAdi }}
                <small class="digits">Fiyat {{ muze.MuzeFiyatiTL }}€</small> /
                <small class="digits">Toplam {{ muze.ToplamTutarTL }}€</small>
              </h5>
            </b-card-header>
            <b-card-body class="p-0">
              <b-table striped hover :fields="muzeTutarFields" :items="muze.Tutarlar"></b-table>
            </b-card-body>
          </b-card>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detay: null,
  },
  data() {
    return {
      showOpsiyonTutarlar: false,
      showMuzeTutarlar: false,
      
      opsiyonTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "Fiyat", label: "Fiyat", sortable: false },
        { key: "ToplamFiyat", label: "Toplam", sortable: false },
        { key: "AcenteFiyati", label: "Acente Ö.F.", sortable: false },
      ],

      muzeTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "FiyatTL", label: "Fiyat", sortable: false },
        { key: "ToplamTutarTL", label: "Toplam", sortable: false },
      ]
    };
  },
  mounted() {
    let user = this.$user.getUser();
    switch (user.Kullanici.KullaniciTipi) {
      //Acente Yönetici
      case 10:
        this.showOpsiyonTutarlar = true;
        break;

      //Acente Kullanıcı
      case 15:
        this.showOpsiyonTutarlar = true;
        break;

      //Merkez
      case 80:
      case 85:
      case 100:
        this.showOpsiyonTutarlar = true;
        this.showMuzeTutarlar = true;
        break;

      default:
        break;
    }
  }
};
</script>
