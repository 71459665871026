<template>
  <div class="card height-equal">
    <div class="card-header">
      <h5>Araç - Onay Bekleyenler</h5>
    </div>
    <div class="card-body">
      <div class="upcoming-event">
        <div class="row">
          <div class="col-md-6" v-for="item in items" :key="item.Id">
            <div class="upcoming-innner media mb-4">
              <div class="bg-primary left m-r-20">
                <feather type="users"></feather>
              </div>
              <div class="media-body">
                <p class="mb-0">
                  {{ item.SoforAdi }} / {{ item.Plaka }}
                  <span class="pull-right">{{ formatDate(item.KayitTarihi) }}</span>
                </p>
                <h6 class="f-w-600">{{ item.AracFirmaAdi }}</h6>
                <p class="mb-0">
                  <strong>Cep Telefonu : </strong> {{ item.GSM }}<br />
                  <strong>Email : </strong> {{ item.Email }}
                </p>
                <b-btn-group>
                  <router-link :to="`/aracFirma/aracDetay/${item.Id}`" class="btn btn-dark btn-xs">Detay</router-link>
                  <router-link :to="`/aracFirma/aracKayit/${item.AracFirmaId}/${item.Id}`"
                    class="btn btn-info btn-xs">Düzelt</router-link>
                  <b-button size="xs" @click="onayla(item)" variant="success">Onayla</b-button>
                  <b-button size="xs" @click="iptalEt(item)" variant="warning">İptal Et</b-button>
                  <b-button size="xs" @click="sil(item)" variant="danger">Sil</b-button>
                </b-btn-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    formatDate(date) {
      return this.$formatHelper.formatDate(date);
    },
    onList() {
      let vm = this;

      this.$connection.get("/araclar/onayBekleyenler", function (response) {
        vm.items = response;

        vm.$emit("rowcount", vm.items.length);
      });
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Araç kaydını silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete("/araclar/" + item.Id, function (response) {
            vm.$alert.messageWithFunction(
              "Silindi",
              "Araç kaydı silindi.",
              "error",
              function () {
                vm.$listHelper.removeItem(vm.items, item);
              }
            );
          });
        }
      );
    },
    onayla(item) {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Aracı onaylamak istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/araclar/durumDegistir/" + item.Id + "/30",
            {},
            "put",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Onaylandı",
                  "Araç onaylandı.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
    iptalEt(item) {
      let vm = this;
      this.$alert.confirm(
        "İptal Et",
        "Aracı aynı bilgilerle bir daha üye olamayacak. İptal etmek istedğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/araclar/durumDegistir/" + item.Id + "/100",
            {},
            "put",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "İptal Edildi",
                  "Araç iptal edildi.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
  },
};
</script>
