<template>
  <div>
    <Breadcrumbs main="Profil" :title="pageTitle" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav nav-tabs border-tab tabs-scoial"
            id="top-tab"
            role="tablist"
          >
            <li class="nav-item" v-on:click="changetab('genel')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'genel' }"
                id="top-genel"
                data-toggle="tab"
                role="tab"
                aria-controls="genel"
                aria-selected="true"
                >Genel
                <b-badge variant="info"
                  >{{ arac.SoforAdi }} - {{ arac.Plaka }}</b-badge
                ></a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('resim')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'resim' }"
                id="top-resim"
                data-toggle="tab"
                role="tab"
                aria-controls="resim"
                aria-selected="false"
                >Araç Resimleri</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('takvim')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'takvim' }"
                id="top-takvim"
                data-toggle="tab"
                role="tab"
                aria-controls="takvim"
                aria-selected="false"
                >Müsaitlik Takvimi</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('folyo')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'folyo' }"
                id="top-folyo"
                data-toggle="tab"
                role="tab"
                aria-controls="folyo"
                aria-selected="false"
                >Folyo</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'genel' }"
          id="genel"
          role="tabpanel"
          aria-labelledby="genel"
        >
          <div class="row">
            <div class="col-md-6">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Bilgiler</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Şoför Adı</th>
                        <td>{{ arac.SoforAdi }}</td>
                      </tr>
                      <tr>
                        <th>Plaka</th>
                        <td>{{ arac.Plaka }}</td>
                      </tr>
                      <tr>
                        <th>T.C. Kimlik No</th>
                        <td>{{ arac.TcKimlikNo }}</td>
                      </tr>
                      <tr>
                        <th>Marka</th>
                        <td>{{ arac.Marka }}</td>
                      </tr>
                      <tr>
                        <th>Model</th>
                        <td>{{ arac.Model }}</td>
                      </tr>
                      <tr>
                        <th>Yetki Belgesi Türü</th>
                        <td>{{ arac.YetkiBelgesiTuru }}</td>
                      </tr>
                      <tr>
                        <th>Yetki Belge No</th>
                        <td>{{ arac.YetkiBelgesiNo }}</td>
                      </tr>
                      <tr>
                        <th>Wifi</th>
                        <td>
                          <c-aktif-cell :isAktif="arac.isWifi"></c-aktif-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Araç Takip</th>
                        <td>
                          <c-aktif-cell
                            :isAktif="arac.isAracTakip"
                          ></c-aktif-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Takip Cihazı</th>
                        <td>
                          {{ arac.TakipCihazStr }}
                        </td>
                      </tr>
                      <tr>
                        <th>Entegrasyon</th>
                        <td>
                          {{ arac.EntegrasyonAyarlariJson }}
                        </td>
                      </tr>
                      <tr>
                        <th>Toplam Alacak</th>
                        <td>
                          <c-money-cell
                            :fiyat="arac.ToplamAlacak"
                            simge="₺"
                          ></c-money-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-card
                header-tag="div"
                header-bg-variant="info"
                class="card-absolute"
              >
                <h5 slot="header">Kayıt Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Kayıt Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="arac.KayitTarihi"
                            :rowKey="arac.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Güncelleme Tarihi</th>
                        <td>
                          <c-tarih-cell
                            :kayitTarihi="arac.GuncelTarih"
                            :rowKey="arac.Id"
                          ></c-tarih-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-list-group class="mb-3">
                    <b-list-group-item
                      v-b-modal.bilgiForm
                      router-component-name="router-link"
                    >
                      <feather type="edit-3"></feather> Güncelle
                    </b-list-group-item>
                    <b-list-group-item
                      v-b-modal.sifreForm
                      router-component-name="router-link"
                    >
                      <feather type="lock"></feather> Şifre Güncelle
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>

              <b-card
                header-tag="div"
                header-bg-variant="warning"
                class="card-absolute"
              >
                <h5 slot="header">İletişim Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Cep Telefonu</th>
                        <td>{{ arac.GSM }}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{{ arac.Email }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
          </div>

          <b-modal
            id="bilgiForm"
            centered
            title="Bilgirimi Güncelle"
            @ok="bilgiKaydetSubmit"
            ok-title="Güncelle"
            cancel-title="Kapat"
            class="theme-modal"
          >
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_soforadi">Ad Soyad</label>
                <b-form-input
                  id="kayit_soforadi"
                  v-model="kayitBilgi.SoforAdi"
                  :state="kayitbilgi_soforadi_state"
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_soforadi_state"
                  >Adınızı ve soyadınızı giriniz.</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_email">Email</label>
                <b-form-input
                  id="kayit_email"
                  v-model="kayitBilgi.Email"
                  :state="kayitbilgi_email_state"
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_email_state"
                  >Geçerli bir email adresinizi
                  giriniz.</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_telefon">Cep Telefonu</label>
                <b-form-input
                  type="text"
                  id="kayit_gsm"
                  v-model="kayitBilgi.GSM"
                  v-mask="'5## ### ## ##'"
                  placeholder="Cep Telefonu"
                  :state="kayitbilgi_gsm_state"
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_gsm_state"
                  >Cep telefonunu giriniz</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_tckimlikno">T.C. Kimlik No</label>
                <b-form-input
                  id="kayit_tckimlikno"
                  :state="kayitbilgi_tckimlikno_state"
                  v-model="kayitBilgi.TcKimlikNo"
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_tckimlikno_state"
                  >11 haneli T.C. kimlik numaranızı
                  giriniz</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_plaka">Plaka</label>
                <b-form-input
                  id="kayit_plaka"
                  :state="kayitbilgi_plaka_state"
                  v-model="kayitBilgi.Plaka"
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_plaka_state"
                  >Araç plakasını giriniz</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_marka">Marka</label>
                <b-form-input
                  id="kayit_marka"
                  :state="kayitbilgi_marka_state"
                  v-model="kayitBilgi.Marka"
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_marka_state"
                  >Araç markasını giriniz</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_model">Model</label>
                <b-form-input
                  id="kayit_model"
                  :state="kayitbilgi_model_state"
                  v-model="kayitBilgi.Model"
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_model_state"
                  >Araç modelini giriniz</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_yetkibelgesituru">Yetki Belgesi Türü</label>
                <b-form-input
                  id="kayit_yetkibelgesituru"
                  v-model="kayitBilgi.YetkiBelgesiTuru"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_yetkibelgesino">Yetki Belge No</label>
                <b-form-input
                  id="kayit_yetkibelgesino"
                  v-model="kayitBilgi.YetkiBelgesiNo"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <div class="checkbox checkbox-dark">
                  <b-form-checkbox v-model="kayitBilgi.isWifi"
                    >Wifi Var</b-form-checkbox
                  >
                </div>
              </div>
            </div>
          </b-modal>

          <b-modal
            id="sifreForm"
            centered
            title="Şifre Güncelle"
            @ok="sifreKaydetSubmit"
            ok-title="Güncelle"
            cancel-title="Kapat"
            class="theme-modal"
          >
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_kullaniciadi">Kullanıcı Adı</label>
                <b-form-input
                  id="kayit_kullaniciadi"
                  v-model="kayitSifre.KullaniciAdi"
                  :state="kayitsifre_kullaniciadi_state"
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitsifre_kullaniciadi_state"
                  >Kullanıcı adınızı giriniz.</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_sifre">Şifre</label>
                <b-form-input
                  id="kayit_sifre"
                  v-model="kayitSifre.SifreYeni"
                  :state="kayitsifre_sifre_state"
                  placeholder="Şifreniz boş ise güncellenmez."
                ></b-form-input>
                <b-form-invalid-feedback :state="kayitsifre_sifre_state"
                  >En az 8 ile 20 karakter arasında rakam, küçük ve büyük harf
                  bulunan bir şifre giriniz</b-form-invalid-feedback
                >
              </div>
            </div>
          </b-modal>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'resim' }"
          id="resim"
          role="tabpanel"
          aria-labelledby="resim"
        >
          <div class="row">
            <div class="col-md-12">
              <AracResim
                :aracId="arac.Id"
                v-if="arac.Id !== undefined"
                :noEdit="true"
              />
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'takvim' }"
          id="takvim"
          role="tabpanel"
          aria-labelledby="takvim"
        >
          <div class="row">
            <div class="col-md-12">
              <AracTakvim :aracId="arac.Id" v-if="arac.Id !== undefined" />
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'show active': showtab == 'folyo' }"
          id="folyo"
          role="tabpanel"
          aria-labelledby="folyo"
        >
          <div class="row">
            <div class="col-md-12">
              <b-card
                header-tag="div"
                header-bg-variant="primary"
                class="card-absolute"
              >
                <h5 slot="header">Folyo</h5>
                <b-card-body class="p-1">
                  <AracFolyo
                    :arac="arac"
                    v-if="isFolyoLoad"
                    :noEdit="true"
                  ></AracFolyo>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AracFolyo from "@/components/c_arac_folyo";
import AracResim from "@/components/c_arac_resim";
import AracTakvim from "@/components/c_arac_takvim";

export default {
  data() {
    return {
      pageTitle: "Profil",

      showtab: "genel",

      arac: {},

      isFolyoLoad: false,

      kayitBilgi: {
        SoforAdi: "",
        Email: "",
        GSM: "",
        TcKimlikNo: "",
        Plaka: "",
        Marka: "",
        Model: "",
        isWifi: null,
        YetkiBelgesiTuru: "",
        YetkiBelgesiNo: "",
      },

      kayitSifre: {
        KullaniciAdi: "",
        SifreYeni: "",
      },
    };
  },
  components: {
    AracFolyo,
    AracResim,
    AracTakvim,
  },
  computed: {
    kayitbilgi_soforadi_state() {
      if (this.kayitBilgi.SoforAdi == null || this.kayitBilgi.SoforAdi == "")
        return false;
      else return true;
    },
    kayitbilgi_email_state() {
      if (!this.$validationHelper.validEmail(this.kayitBilgi.Email))
        return false;
      else return true;
    },
    kayitbilgi_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayitBilgi.GSM)) return false;
      else return true;
    },
    kayitbilgi_tckimlikno_state() {
      if (
        this.kayitBilgi.TcKimlikNo == null ||
        this.kayitBilgi.TcKimlikNo == ""
      )
        return false;
      else return true;
    },
    kayitbilgi_plaka_state() {
      if (this.kayitBilgi.Plaka == null || this.kayitBilgi.Plaka == "")
        return false;
      else return true;
    },
    kayitbilgi_marka_state() {
      if (this.kayitBilgi.Marka == null || this.kayitBilgi.Marka == "")
        return false;
      else return true;
    },
    kayitbilgi_model_state() {
      if (this.kayitBilgi.Model == null || this.kayitBilgi.Model == "")
        return false;
      else return true;
    },
    kayitsifre_kullaniciadi_state() {
      if (
        this.kayitSifre.KullaniciAdi == null ||
        this.kayitSifre.KullaniciAdi == ""
      )
        return false;
      else return true;
    },
    kayitsifre_sifre_state() {
      if (
        this.kayitSifre.SifreYeni != null &&
        this.kayitSifre.SifreYeni != "" &&
        !this.$validationHelper.validPassword(this.kayitSifre.SifreYeni)
      )
        return false;
      else return true;
    },
    kayitozgecmis_ozgecmis_state() {
      if (
        this.kayitOzgecmis.Ozgecmis == null ||
        this.kayitOzgecmis.Ozgecmis == "" ||
        this.kayitOzgecmis.Ozgecmis.length < 50
      )
        return false;
      else return true;
    },
    kayitdil_dil_state() {
      if (this.selectedDiller.length === 0) return false;
      else return true;
    },
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;

      switch (tab) {
        case "folyo":
          if (this.isFolyoLoad === false) this.folyoList();
          break;

        default:
          break;
      }
    },
    onDetay() {
      let vm = this;

      this.$connection.get("/araclar/profil", function (response) {
        if (response.Success) {
          vm.arac = response.Data;

          vm.kayitBilgi = {
            SoforAdi: vm.arac.SoforAdi,
            Email: vm.arac.Email,
            GSM: vm.arac.GSM,
            TcKimlikNo: vm.arac.TcKimlikNo,
            Plaka: vm.arac.Plaka,
            Marka: vm.arac.Marka,
            Model: vm.arac.Model,
            isWifi: vm.arac.isWifi,
            YetkiBelgesiTuru: vm.arac.YetkiBelgesiTuru,
            YetkiBelgesiNo: vm.arac.YetkiBelgesiNo,
          };

          vm.kayitSifre = {
            KullaniciAdi: response.Data.Kullanici.KullaniciAdi,
            SifreYeni: "",
          };
        } else {
          vm.$alert.messageWithTitle("Araç", response.Message, "error");
        }
      });
    },
    folyoList() {
      this.isFolyoLoad = true;
    },
    sifreKaydetSubmit(target) {
      target.preventDefault();

      let isValid =
        this.kayitsifre_kullaniciadi_state && this.kayitsifre_sifre_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/kullanicilar/forArac/" + this.arac.Id,
          vm.kayitSifre,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Bilgi Güncelle",
                response.Message,
                "success",
                function () {
                  vm.$bvModal.hide("sifreForm");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Bilgi Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    },
    bilgiKaydetSubmit(target) {
      target.preventDefault();

      let isValid =
        this.kayitbilgi_soforadi_state &&
        this.kayitbilgi_email_state &&
        this.kayitbilgi_gsm_state &&
        this.kayitbilgi_tckimlikno_state &&
        this.kayitbilgi_plaka_state &&
        this.kayitbilgi_marka_state &&
        this.kayitbilgi_model_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/araclar/bilgi/" + this.arac.Id,
          vm.kayitBilgi,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Bilgi Güncelle",
                response.Message,
                "success",
                function () {
                  vm.$bvModal.hide("bilgiForm");
                  vm.onDetay();
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Bilgi Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    },
  },
};
</script>
