<template>
  <div>
    <Breadcrumbs
      main="Tur Rezervasyonu"
      :title="pageTitle"
      :links="breadLinks"
    />

    <div class="container-fluid tur-rezervasyon-duzelt">
      <TurRezervasyonForm
        v-if="isComponentShow"
        @onComplete="onComplete"
        :id="id"
        :tab="tab"
        :rezervasyon="kayit"
        :acenteId="acenteId"
      >
      </TurRezervasyonForm>
    </div>
  </div>
</template>
<script>
import TurRezervasyonForm from "@/components/c_tur_rezervasyon_form.vue";

export default {
  data() {
    return {
      id: this.$route.params.id,
      tab: this.$route.query.tab,

      pageTitle: "Rezervasyon Düzelt",

      breadLinks: [
        {
          path: "/turRezervasyon/detay/" + this.$route.params.id,
          title: "Rezervasyon Detayı",
          icon: "chevron-left",
        },
      ],

      kayit: {},

      acenteId: "",

      isComponentShow: false,
    };
  },
  mounted() {
    this.onDetay();
    this.$store.dispatch("menu/setToggleSidebar", true);
  },
  components: {
    TurRezervasyonForm,
  },
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/" + this.id,
        function (response) {
          if (response.Success) {
            vm.isComponentShow = true;

            vm.acenteId = response.Data.AcenteId;

            vm.kayit.TurId = response.Data.TurId;
            vm.kayit.EncTurId = response.Data.EncTurId;
            vm.kayit.TurOpsiyonId = response.Data.TurOpsiyonId;
            vm.kayit.EncTurOpsiyonId = response.Data.EncTurOpsiyonId;
            vm.kayit.Tarih = response.Data.Tarih;
            vm.kayit.Saat = response.Data.Saat;
            vm.kayit.Aciklama = response.Data.Aciklama;
            vm.kayit.OtelAdi = response.Data.OtelAdi;
            vm.kayit.OtelAdresi = response.Data.OtelAdresi;
            vm.kayit.DilId = response.Data.DilId;
            vm.kayit.EncDilId = response.Data.EncDilId;
            vm.kayit.RehberId = response.Data.RehberId;
            vm.kayit.EncRehberId = response.Data.EncRehberId;
            vm.kayit.AracId = response.Data.AracId;
            vm.kayit.EncAracId = response.Data.EncAracId;
            vm.kayit.KisiSayisi = response.Data.KisiSayisi;
            vm.kayit.Misafirler = response.Data.Misafirler;
            vm.kayit.RestoranId = response.Data.RestoranId;

            vm.kayit.Mekanlar = [];
            vm.kayit.EkliMekanlar = [];
            for (let i = 0; i < response.Data.TurDetayi.Mekanlar.length; i++) {
              const element = response.Data.TurDetayi.Mekanlar[i];
              vm.kayit.Mekanlar.push(element.MekanId);

              vm.kayit.EkliMekanlar.push({
                  Id: element.MekanId,
                  Adi: element.MekanAdi,
                  KategoriAdi: element.KategoriAdi
                }); 
            }
          } else {
            vm.$alert.messageWithTitle("Tur Detay", response.Message, "error");
          }
        }
      );
    },
    onComplete(kayit) {
      let vm = this;

      let url = "/turRezervasyonlari/" + this.id;
      let method = "put";
      let messageTitle = "Rezervasyon Düzelt";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(
              messageTitle,
              response.Message,
              "success",
              function () {
                vm.$router.push("/turRezervasyon/detay/" + vm.id);
              }
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
  },
};
</script>
