<template>
  <div class="row">
    <div class="col-md-12">
      <carousel :perPageCustom="[
        [1440, 5],
        [1366, 5],
        [600, 3],
        [420, 2],
        [0, 1],
      ]" :perPage="5" :minSwipeDistance="10" :mouse-drag="true" :speed="6000" :paginationEnabled="true"
        :autoplay="true">
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="star"></feather>
                <div><span>Puan</span></div>
                <h3 class="font-primary mb-0 counter">{{ performans.Yildiz }}</h3>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="maximize"></feather>
                <div><span>Toplam Alınabilecek Puan</span></div>
                <h4 class="font-primary mb-0 counter">{{ performans.ToplamPuan }}</h4>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="activity"></feather>
                <div><span>Toplam Alınan Puan</span></div>
                <h4 class="font-primary mb-0 counter">{{ performans.ToplamAlinanPuan }}</h4>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="edit"></feather>
                <div><span>Toplam Yorum Sayısı</span></div>
                <h4 class="font-primary mb-0 counter">{{ performans.ToplamYorumSayisi }}</h4>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="user-check"></feather>
                <div><span>Memnun Olanlar</span></div>
                <h4 class="font-primary mb-0 counter">{{ performans.MemnunOlanlar }}</h4>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="user-minus"></feather>
                <div><span>Nötr</span></div>
                <h4 class="font-primary mb-0 counter">{{ performans.Notr }}</h4>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="user-x"></feather>
                <div><span>Memnun Olmayanlar</span></div>
                <h4 class="font-primary mb-0 counter">{{ performans.MemnunOlmayanlar }}</h4>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="clipboard"></feather>
                <div><span>Savunma Sayısı</span></div>
                <h4 class="font-primary mb-0 counter">{{ performans.SavunmaSayisi }}</h4>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="check"></feather>
                <div><span>Savunma Kabul</span></div>
                <h4 class="font-primary mb-0 counter">{{ performans.SavunmaKabulSayisi }}</h4>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="item">
            <div class="card">
              <div class="card-body ecommerce-icons text-center">
                <feather type="x"></feather>
                <div><span>Savunma Red</span></div>
                <h4 class="font-primary mb-0 counter">{{ performans.SavunmaRedSayisi }}</h4>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>

</template>
<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    performans: {}
  },
  components: {
    Carousel,
    Slide
  }
};
</script>
