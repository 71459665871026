<template>
  <div>
    <Breadcrumbs main="Kategoriler" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Kategori Kaydı</h5>
              <span
                >Yeni kategori veya kategori kaydında değişiklikler için
                aşağıdaki formu kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Kategori Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_tanim"
                      v-model="kayit.Tanim"
                      :state="kayit_tanim_state"
                      placeholder="Kategori Adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_tanim_state"
                      >Kategori adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tipi">Kategori Tipi</label>
                    <b-form-select
                      id="kayit_tipi"
                      v-model="kayit.Tipi"
                      :options="kayit_tipi_options"
                      :state="kayit_tipi_state"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="kayit_tipi_state"
                      >Kategori tipini seçiniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox
                        name="Aktif"
                        v-model="kayit.Aktif"
                        :state="kayit.Aktif"
                        >Aktif</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <router-link
                  :to="'/kategori/list'"
                  class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Kategori",

      breadLinks: [
        { path: "/kategori/list", title: "Kategoriler", icon: "chevron-left" },
      ],

      kayit: {
        Tanim: "",
        Tipi: 0,
        Aktif: true,
      },

      kayit_tipi_options: [
        {
          value: "0",
          text: "Seçiniz",
        },
      ],
    };
  },
  mounted() {
    this.onTipler();

    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Kategori Düzelt";

      this.$connection.get("/kategoriler/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
        } else {
          vm.$alert.messageWithTitle(
            "Yeni Kategori",
            response.Message,
            "error"
          );
        }
      });
    }
  },
  computed: {
    kayit_tanim_state() {
      if (this.kayit.Tanim == null || this.kayit.Tanim == "") return false;
      else return true;
    },
    kayit_tipi_state() {
      if (this.kayit.Tipi == "0") return false;
      else return true;
    },
  },
  methods: {
    onTipler() {
      let vm = this;

      this.$connection.get("/kategoriler/KategoriTipleri", function (response) {
        vm.kayit_tipi_options = vm.kayit_tipi_options.concat(response);
      });
    },
    onSubmit(evt) {
      evt.preventDefault();

      let isValid = this.kayit_tanim_state && this.kayit_tipi_state;

      if (isValid) {
        let vm = this;

        let url = "/kategoriler";
        let method = "post";
        let messageTitle = "Yeni Kategori";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Kategori Düzelt";
        }

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  vm.$router.push("/kategori/list");
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
