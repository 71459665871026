<template>
  <div>
    <Breadcrumbs main="Turlar" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Tur Kaydı</h5>
              <span
                >Yeni tur veya turda değişiklikler için aşağıdaki formu
                kullanabilirsiniz</span
              >
            </div>
            <div class="card-body">
              <b-form @submit="onCustomStyleSubmit">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label for="kayit_adi">Tur Adı</label>
                    <b-form-input
                      type="text"
                      id="kayit_adi"
                      v-model="kayit.Adi"
                      :state="kayit_adi_state"
                      placeholder="Tur adı"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="kayit_adi_state"
                      >Tur adını giriniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kayit_tipi">Tipi</label>
                    <b-form-select
                      id="kayit_tipi"
                      v-model="kayit.Tipi"
                      :options="kayit_tipi_options"
                      :state="kayit_tipi_select_state"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="kayit_tipi_select_state"
                      >Tipi seçiniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="kayit_aciklama">Açıklama</label>
                    <ckeditor
                      :editor="editor"
                      v-model="kayit.Aciklama"
                      :state="kayit_aciklama_state"
                    ></ckeditor>
                    <b-form-invalid-feedback :state="kayit_aciklama_state"
                      >Tur açıklamasını giriniz</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 mb-3">
                    <label for="kayit_renk">Renk</label>
                    <b-form-select
                      id="kayit_renk"
                      v-model="kayit.Renk"
                      :options="kayit_renk_options"
                      :state="kayit_renk_state"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="kayit_renk_state"
                      >Renk seçiniz</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="kayit_sira">Sıra</label>
                    <div class="input-group-square">
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-button
                            class="btn-square bootstrap-touchspin-down"
                            type="button"
                            @click="kayit.Sira--"
                            variant="light"
                            ><i class="fa fa-minus"></i
                          ></b-button>
                        </b-input-group-prepend>
                        <b-input-group-append>
                          <b-button
                            class="btn-square bootstrap-touchspin-down"
                            type="button"
                            @click="kayit.Sira++"
                            variant="dark"
                            ><i class="fa fa-plus"></i
                          ></b-button>
                        </b-input-group-append>
                        <b-form-input
                          class="touchspin"
                          type="text"
                          v-model="kayit.Sira"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label></label>
                    <div class="checkbox checkbox-dark">
                      <b-form-checkbox
                        name="Aktif"
                        v-model="kayit.Aktif"
                        :state="kayit.Aktif"
                        >Aktif</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12 mb-5">
                    <h4 class="f-w-600">
                      Yaş İndirim Oranları
                      <b-button
                        @click="defaultIndirimOranlariAta()"
                        class="pull-right"
                        variant="success"
                        >Standart İndirim Oranlarını Ata</b-button
                      >
                    </h4>
                    <table class="table">
                      <thead>
                        <tr class="bg-warning">
                          <th>
                            <b-form-select
                              v-model="indirimOrani.Yas"
                              :options="yas_options"
                            ></b-form-select>
                          </th>
                          <th>
                            <b-form-input
                              type="text"
                              v-model="indirimOrani.Oran"
                              placeholder="İndirim Oranı"
                            ></b-form-input>
                          </th>
                          <th>
                            <b-button
                              type="button"
                              @click="indirimOraniEkle()"
                              variant="info"
                              >Ekle</b-button
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="kayit.Indirimler.length === 0">
                          <td colspan="3" class="text-center">
                            Bu Tur İçin Henüz Yaş İndirimi Tanımlanmamıştır
                          </td>
                        </tr>
                        <tr
                          v-for="row in kayit.Indirimler"
                          v-bind:key="row.YasMin"
                        >
                          <td>
                            <b-form-select
                              v-model="row.Yas"
                              :options="yas_options"
                            ></b-form-select>
                          </td>
                          <td>
                            <b-form-input
                              type="text"
                              v-model="row.Oran"
                              placeholder="İndirim Oranı"
                            ></b-form-input>
                          </td>
                          <td>
                            <b-button
                              type="button"
                              @click="indirimOraniKaldir(row)"
                              variant="danger"
                              >Kaldır</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <router-link :to="'/tur/list'" class="btn btn-light pull-left"
                  >Geri Dön</router-link
                >
                <b-button type="submit" class="pull-right" variant="primary"
                  >Kaydet</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Yeni Tur",

      breadLinks: [
        { path: "/tur/list", title: "Turlar", icon: "chevron-left" },
      ],

      kayit: {
        Tipi: "",
        Adi: "",
        Aciklama: "",
        Renk: "",
        Sira: 0,
        Aktif: true,
        Indirimler: [],
      },

      indirimOrani: {
        Yas: 0,
        Oran: null,
      },

      yas_options: [],

      defaulIndirimOranlari: [
        {
          Yas: 0,
          Oran: 50,
        },
        {
          Yas: 1,
          Oran: 25,
        },
      ],

      kayit_tipi_options: [],

      kayit_renk_options: [],

      editor: ClassicEditor,
    };
  },
  components: {},
  mounted() {
    this.onYasList();

    let vm = this;

    if (this.id !== undefined) {
      vm.pageTitle = "Tur Düzelt";

      this.$connection.get("/turlar/" + this.id, function (response) {
        if (response.Success) {
          vm.kayit = response.Data;
          vm.kayit.Tipi = vm.kayit.Tipi.toString();
        } else {
          vm.$alert.messageWithTitle("Yeni Tur", response.Message, "error");
        }
      });
    }

    this.$connection.get("/parametreler/renkler", function (response) {
      vm.kayit_renk_options = response;
    });

    this.$connection.get("/turlar/turTipleri", function (response) {
      vm.kayit_tipi_options = response;
    });
  },
  computed: {
    kayit_tipi_select_state() {
      if (this.kayit.Tipi == "") return false;
      else return true;
    },
    kayit_adi_state() {
      if (this.kayit.Adi == null || this.kayit.Adi == "") return false;
      else return true;
    },
    kayit_aciklama_state() {
      if (this.kayit.Aciklama == null || this.kayit.Aciklama == "")
        return false;
      else return true;
    },
    kayit_renk_state() {
      if (this.kayit.Renk == "") return false;
      else return true;
    },
  },
  methods: {
    onYasList() {
      let vm = this;

      this.$connection.get("/parametreler/yaslar", function (response) {
        vm.yas_options = response;
      });
    },
    defaultIndirimOranlariAta() {
      this.kayit.Indirimler = structuredClone(this.defaulIndirimOranlari);
    },
    indirimOraniEkle() {
      if (this.indirimOrani.Yas === null || this.indirimOrani.Oran === null) {
        this.$alert.messageWithTitle(
          "Eksik Alan",
          "Yaş ve indirim oranı alanlarını doldurunuz.",
          "error"
        );
        return;
      }

      this.kayit.Indirimler.push(this.indirimOrani);
      this.indirimOrani = {
        Yas: 0,
        Oran: null,
      };
    },
    indirimOraniKaldir(indirim) {
      this.$listHelper.removeItem(this.kayit.Indirimler, indirim);
    },
    onCustomStyleSubmit(evt) {
      evt.preventDefault();

      let isValid =
        this.kayit_tipi_select_state &&
        this.kayit_adi_state &&
        this.kayit_aciklama_state &&
        this.kayit_renk_state;

      if (isValid) {
        let vm = this;

        let url = "/turlar";
        let method = "post";
        let messageTitle = "Yeni Tur";
        let yeniKayit = vm.id === undefined;
        if (!yeniKayit) {
          url += "/" + vm.id;
          method = "put";
          messageTitle = "Tur Düzelt";
        }

        for (let i = 0; i < vm.kayit.Indirimler.length; i++) {
          const element = vm.kayit.Indirimler[i];
          element.Oran = parseInt(element.Oran);
        }

        vm.$connection.post(
          url,
          vm.kayit,
          method,
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                messageTitle,
                response.Message,
                "success",
                function () {
                  if (yeniKayit) {
                    vm.$router.push("/tur/opsiyonKayit/" + response.Data);
                  } else {
                    vm.$router.push("/tur/list");
                  }
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
          }
        );
      }
    },
  },
};
</script>
