<template>
  <div class="row">
    <div class="col-md-12">
      <b-card header-tag="div" header-bg-variant="info" class="card-absolute"
        v-if="showOpsiyonTutarlar && detay.TurDetayi.OpsiyonTutarlar.length > 0">
        <h5 slot="header">Opsiyon Tutarları</h5>
        <b-card-body class="p-0">
          <b-table striped hover :fields="opsiyonTutarFields" :items="detay.TurDetayi.OpsiyonTutarlar">
            <template #cell(YasStr)="row">
              <b-badge variant="primary">{{ row.item.YasStr }}</b-badge>
            </template>
            <template #cell(Oran)="data">
              <b-badge variant="primary">{{ data.value }}</b-badge>
            </template>
            <template #cell(Fiyat)="data">
              <c-money-cell :fiyat="data.value" v-if="data.value !== 0" simge="₺"></c-money-cell>
            </template>
            <template #cell(ToplamFiyat)="data">
              <c-money-cell :fiyat="data.value" simge="₺"></c-money-cell>
            </template>
            <template #cell(AcenteFiyati)="data">
              <c-aktif-cell :isAktif="data.value"></c-aktif-cell>
            </template>
          </b-table>
        </b-card-body>
      </b-card>

      <b-card header-tag="div" header-bg-variant="success" class="card-absolute"
        v-if="detay.TurDetayi.Mekanlar.length > 0">
        <h5 slot="header">Mekanlar</h5>
        <b-card-body>
          <div class="row">
            <div class="col-md-4" v-for="mekan in detay.TurDetayi.Mekanlar" v-bind:key="mekan.MekanId">
              <b-card class="mb-1">
                <b-card-header header-tag="div" class="bg-primary">
                  <h5 class="mb-0">
                    {{ mekan.MekanAdi }} / {{ mekan.KategoriAdi }}
                  </h5>
                </b-card-header>
                <b-card-body class="p-0" v-if="showMekanKomisyon">
                  <b-list-group tag="ul">
                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Acente
                      Komisyon
                      Toplamı
                      <b-badge variant="primary" class="counter digits">{{
          mekan.AcenteKomisyonToplami
        }}</b-badge>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Acente
                      Komisyon
                      Oranı
                      <b-badge variant="primary" class="counter digits">{{
            mekan.AcenteKomisyonOrani
          }}</b-badge>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Merkez
                      Komisyon
                      Toplamı
                      <b-badge pill variant="success" class="counter digits">{{
            mekan.MerkezKomisyonToplami
          }}</b-badge>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Merkez
                      Komisyon
                      Oranı
                      <b-badge pill variant="success" class="counter digits">{{
            mekan.MerkezKomisyonOrani
          }}</b-badge>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Rehber
                      Komisyon
                      Toplamı
                      <b-badge pill variant="warning" class="counter digits">{{
            mekan.RehberKomisyonToplami
          }}</b-badge>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Rehber
                      Komisyon
                      Oranı
                      <b-badge pill variant="warning" class="counter digits">{{
            mekan.RehberKomisyonOrani
          }}</b-badge>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Şoför Komisyon
                      Toplamı
                      <b-badge pill variant="info" class="counter digits">{{
            mekan.SoforKomisyonToplami
          }}</b-badge>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Şoför Komisyon
                      Oranı
                      <b-badge pill variant="info" class="counter digits">{{
            mekan.SoforKomisyonOrani
          }}</b-badge>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Toplam Satış
                      Tutarı
                      <b-badge pill variant="dark" class="counter digits">{{
            mekan.ToplamSatisTutari
          }}</b-badge>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Hesaplandı
                      <c-aktif-cell :isAktif="mekan.Hesaplandi"></c-aktif-cell>
                    </b-list-group-item>

                    <b-list-group-item tag="li" class="d-flex justify-content-between align-items-center">Dağıtıldı
                      <c-aktif-cell :isAktif="mekan.Dagitildi"></c-aktif-cell>
                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </b-card-body>
      </b-card>

      <b-card header-tag="div" header-bg-variant="dark" class="card-absolute" v-if="detay.TurDetayi.Muzeler.length > 0">
        <h5 slot="header">Müzeler</h5>
        <b-card-body>
          <b-card class="mb-1" v-for="muze in detay.TurDetayi.Muzeler" v-bind:key="muze.MuzeId">
            <b-card-header header-tag="div" class="bg-primary">
              <h5 class="mb-0">
                {{ muze.MuzeAdi }}
                <small class="digits">Fiyat {{ muze.MuzeFiyatiTL }}₺</small> /
                <small class="digits">Toplam {{ muze.ToplamTutarTL }}₺</small>
              </h5>
            </b-card-header>
            <b-card-body class="p-0">
              <b-table striped hover :fields="muzeTutarFields" :items="muze.Tutarlar"></b-table>
            </b-card-body>
          </b-card>
        </b-card-body>
      </b-card>

      <b-card header-tag="div" header-bg-variant="warning" class="card-absolute" v-if="detay.TurDetayi.Restoran != undefined &&
          detay.TurDetayi.Restoran != null
          ">
        <h5 slot="header">Restoran</h5>
        <b-card class="mb-1">
          <h5 slot="header">{{ detay.TurDetayi.Restoran.RestoranAdi }}</h5>
          <p v-if="showRestoranAlis">
            <small class="digits">Menü Alış Fiyatı :
              {{
          $formatHelper.formatMoney(
            detay.TurDetayi.Restoran.MenuAlisFiyati
          )
        }}₺</small>
            /
            <small class="digits">Toplam Alış Tutarı :
              {{
            $formatHelper.formatMoney(detay.TurDetayi.Restoran.ToplamAlis)
          }}₺</small>
          </p>
          <p v-if="showRestoranSatis">
            <small class="digits">Menü Fiyatı :
              {{
          $formatHelper.formatMoney(
            detay.TurDetayi.Restoran.MenuSatisFiyati
          )
        }}₺</small>
            /
            <small class="digits">Toplam Tutar :
              {{
            $formatHelper.formatMoney(detay.TurDetayi.Restoran.ToplamSatis)
          }}₺</small>
          </p>
          <p v-html="detay.TurDetayi.Restoran.RestoranAciklama"></p>
          <b-table v-if="showRestoranTutarlar" striped hover :fields="restoranTutarFields"
            :items="detay.TurDetayi.Restoran.Tutarlar"></b-table>
        </b-card>
      </b-card>

      <div class="row">
        <div class="col-md-6">
          <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
            <h5 slot="header">Rehber</h5>
            <b-card-body class="p-0">
              <table class="product-page-width">
                <tbody>
                  <tr>
                    <th style="width: 30%">Adı</th>
                    <td>{{ detay.TurDetayi.Rehber.RehberAdi }}</td>
                  </tr>
                  <tr>
                    <th>Dil</th>
                    <td>{{ detay.TurDetayi.Rehber.Dil }}</td>
                  </tr>
                  <tr v-if="showRehberAlis">
                    <th>Rehber Satış Ücreti</th>
                    <td>
                      <b-badge variant="warning"><c-money-cell :fiyat="detay.TurDetayi.Rehber.RehberAlisFiyati"
                          simge="₺"></c-money-cell></b-badge>
                    </td>
                  </tr>
                  <tr v-if="showRehberSatis">
                    <th>Sistem Satış Ücreti</th>
                    <td>
                      <b-badge variant="success"><c-money-cell :fiyat="detay.TurDetayi.Rehber.RehberSatisFiyati"
                          simge="₺"></c-money-cell></b-badge>
                    </td>
                  </tr>
                  <tr v-if="showRehberKomisyon">
                    <th>Komisyon Oranı</th>
                    <td>
                      <b-badge variant="info">%{{
          detay.TurDetayi.Rehber.RehberKomisyonOrani
        }}</b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-md-6">
          <b-card header-tag="div" header-bg-variant="danger" class="card-absolute">
            <h5 slot="header">Araç & Şoför</h5>
            <b-card-body class="p-0">
              <table class="product-page-width">
                <tbody>
                  <tr v-if="showAracFirma">
                    <th style="width: 30%">Araç Firması</th>
                    <td>{{ detay.AracFirmaAdi }}</td>
                  </tr>
                  <tr>
                    <th>Şoför</th>
                    <td>{{ detay.TurDetayi.Arac.SoforAdi }}</td>
                  </tr>
                  <tr>
                    <th>Plaka</th>
                    <td>{{ detay.TurDetayi.Arac.Plaka }}</td>
                  </tr>
                  <tr v-if="showAracAlis">
                    <th>Araç Satış Ücreti</th>
                    <td>
                      <b-badge variant="warning"><c-money-cell :fiyat="detay.TurDetayi.Arac.AlisFiyati"
                          simge="₺"></c-money-cell></b-badge>
                    </td>
                  </tr>
                  <tr v-if="showAracSatis">
                    <th>Sistem Satış Ücreti</th>
                    <td>
                      <b-badge variant="success"><c-money-cell :fiyat="detay.TurDetayi.Arac.SatisFiyati"
                          simge="₺"></c-money-cell></b-badge>
                    </td>
                  </tr>
                  <tr v-if="showAracKomisyon">
                    <th>Komisyon Oranı</th>
                    <td>
                      <b-badge variant="info">%{{ detay.TurDetayi.Arac.SoforKomisyonOrani }}</b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detay: null,
  },
  data() {
    return {
      showOpsiyonTutarlar: false,
      showMekanKomisyon: false,
      showRestoranAlis: false,
      showRestoranSatis: false,
      showRestoranTutarlar: false,
      showRehberAlis: false,
      showRehberSatis: false,
      showRehberKomisyon: false,
      showAracFirma: false,
      showAracAlis: false,
      showAracSatis: false,
      showAracKomisyon: false,

      opsiyonTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "Fiyat", label: "Fiyat", sortable: false },
        { key: "ToplamFiyat", label: "Toplam", sortable: false },
        { key: "AcenteFiyati", label: "Acente Ö.F.", sortable: false },
      ],

      muzeTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "FiyatTL", label: "Fiyat", sortable: false },
        { key: "ToplamTutarTL", label: "Toplam", sortable: false },
      ],

      restoranTutarFields: [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "SatisFiyat", label: "Fiyat", sortable: false },
        { key: "ToplamSatisFiyat", label: "Toplam", sortable: false },
      ],
    };
  },
  mounted() {
    let user = this.$user.getUser();
    switch (user.Kullanici.KullaniciTipi) {
      //Acente Yönetici
      case 10:
        this.showOpsiyonTutarlar = true;
        this.showRehberSatis = true;
        this.showAracSatis = true;
        this.showRestoranSatis = true;
        this.showRestoranTutarlar = true;
        break;

      //Acente Kullanıcı
      case 15:
        this.showOpsiyonTutarlar = true;
        this.showRehberSatis = true;
        this.showAracSatis = true;
        this.showRestoranSatis = true;
        this.showRestoranTutarlar = true;
        break;

      //Rehber
      case 20:
        this.showRehberAlis = true;
        break;

      //Araç Firması
      case 30:
      case 35:
        this.showAracAlis = true;
        break;

      //Araç
      case 40:
        this.showAracAlis = true;
        break;

      //Merkez
      case 80:
      case 85:
      case 100:
        this.showOpsiyonTutarlar = true;
        this.showMekanKomisyon = true;
        this.showRestoranAlis = true;
        this.showRestoranSatis = true;
        this.showRestoranTutarlar = true;
        this.showRehberAlis = true;
        this.showRehberSatis = true;
        this.showRehberKomisyon = true;
        this.showAracFirma = true;
        this.showAracAlis = true;
        this.showAracSatis = true;
        this.showAracKomisyon = true;
        break;

      default:
        break;
    }

    if (this.showRestoranAlis) {
      this.restoranTutarFields = [
        { key: "YasStr", label: "Yaş", sortable: false },
        { key: "KisiSayisi", label: "Kişi Sayısı", sortable: false },
        { key: "Oran", label: "İndirim (%)", sortable: false },
        { key: "AlisFiyat", label: "Alış Fiyatı", sortable: false },
        { key: "ToplamAlisFiyat", label: "Toplam Alış", sortable: false },
        { key: "SatisFiyat", label: "Satış Fiyatı", sortable: false },
        { key: "ToplamSatisFiyat", label: "Toplam Satış", sortable: false },
      ];
    }
  },
};
</script>
