<template>
  <div>
    <Breadcrumbs main="Araç - Tur Servis Ücretleri" :title="pageTitle" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Standart Araç - Tur Servis Ücretleri</h5>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md-3">
                  Tur Adı (Tipi)
                </div>
                <div class="col-md-3 mb-3">
                  Alış Ücreti (₺)
                </div>
                <div class="col-md-3 mb-3">
                  Satış Ücreti (₺)
                </div>
                <div class="col-md-3">
                  
                </div>
              </div>
              <div class="form-row" v-for="(tur, index) in turlar" :key="index">
                <div class="col-md-3">
                  {{ tur.Adi }} <i>({{ tur.TipiStr }})</i>
                </div>
                <div class="col-md-3 mb-3">
                  <b-form-input type="text" v-model="tur.AlisUcreti" placeholder="Alış Ücreti"></b-form-input>
                </div>
                <div class="col-md-3 mb-3">
                  <b-form-input type="text" v-model="tur.SatisUcreti" placeholder="Satış Ücreti"></b-form-input>
                </div>
                <div class="col-md-3">
                  <b-button type="button" @click="fiyatKaydet(tur)" class="pull-right"
                    variant="primary">Güncelle</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,

      pageTitle: "Güncelle",

      breadLinks: [],

      fiyatlar: [],

      turlar: [],
    };
  },
  components: {},
  mounted() {
    this.fiyatList();
  },
  methods: {
    fiyatList() {
      let vm = this;

      this.$connection.get("/araclar/turFiyat/standart", function (response) {
        vm.fiyatlar = response;
        vm.turList();
      });
    },
    turList() {
      let vm = this;

      this.$connection.get("/turlar", function (response) {
        for (let index = 0; index < response.length; index++) {
          const element = response[index];

          let alisUcreti = 0, satisUcreti = 0, id = "", fiyatEkle = true;
          let findFiyatlar = vm.fiyatlar.filter(item => { return item.TurId == element.TurId });
          if (findFiyatlar.length > 0) {
            alisUcreti = findFiyatlar[0].AlisUcreti;
            satisUcreti = findFiyatlar[0].SatisUcreti;
            id = findFiyatlar[0].Id;
            fiyatEkle = false;
          }

          vm.turlar.push({
            TurId: element.Id,
            Adi: element.Adi,
            TipiStr: element.TipiStr,
            Id: id,
            AlisUcreti: alisUcreti,
            SatisUcreti: satisUcreti,
            Ekle: fiyatEkle
          });
        }
      });
    },
    fiyatKaydet(tur) {
      let vm = this;

      let kayit = {
        TurId: tur.TurId,
        AracId: "0",
        AracFirmaId: "0",
        AlisUcreti: tur.AlisUcreti,
        SatisUcreti: tur.SatisUcreti
      };

      vm.$connection.post(
        "/araclar/turFiyat/standart" + (tur.Ekle ? "" : "/" + tur.Id),
        kayit,
        tur.Ekle ? "post" : "put",
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithTitle(
              "Fiyat Güncellendi",
              tur.Adi + " fiyatı güncellendi",
              "success"
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(tur.Adi + " Fiyatı Güncelleme", error.Message, "error");
        }
      );
    },
  },
};
</script>
