<template>
  <div class="card rezervasyon-form">
    <div class="card-body">
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="kayit_yolcu_pasaport">Pasaport No / T.C. Kimlik No</label>
          <b-form-input type="text" id="kayit_yolcu_pasaport" v-model="misafirKayit.PasaportNo"
            placeholder="Pasaport No / T.C. Kimlik No" :state="misafirekle_pasaportno_state"></b-form-input>
          <b-form-invalid-feedback :state="misafirekle_pasaportno_state">Pasaport veya T.C. kimlik
            numarasını
            giriniz.</b-form-invalid-feedback>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="kayit_yolcu_adsoyad">Adı Soyadı</label>
          <b-form-input type="text" id="kayit_yolcu_adsoyad" v-model="misafirKayit.AdSoyad" placeholder="Adı Soyadı"
            :state="misafirekle_adsoyad_state"></b-form-input>
          <b-form-invalid-feedback :state="misafirekle_adsoyad_state">Misafirin adını ve soyadını
            giriniz.</b-form-invalid-feedback>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="kayit_yolcu_uyruk">Uyruk</label>
          <multiselect id="kayit_yolcu_uyruk" v-model="misafirKayit.UyrukSelect" tag-placeholder="Uyruk Seçiniz"
            placeholder="Uyruk seçiniz" label="text" selectLabel="" deselectLabel="" selectedLabel="" track-by="value"
            :options="ulkeler"></multiselect>
          <b-form-invalid-feedback :state="misafirekle_uyruk_state">Misafirin uyruğunu
            seçiniz.</b-form-invalid-feedback>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="kayit_yolcu_cinsiyet">Cinsiyet</label>
          <b-form-select id="kayit_yolcu_cinsiyet" v-model="misafirKayit.Cinsiyet" :options="cinsiyet_options"
            :state="misafirekle_cinsiyet_state"></b-form-select>
          <b-form-invalid-feedback :state="misafirekle_cinsiyet_state">Misafirin cinsiyetini
            seçiniz.</b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <b-button @click="onComplete()" variant="success" :disabled="loading" class="pull-right">{{loading ? 'Güncelleniyor' : 'Kaydet'}}</b-button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  props: {
    yolcu: {},
    rezervasyon: {},
    acenteId: "",
    id: undefined
  },
  data() {
    return {
      loading: false,

      mutationAcenteId: this.acenteId,

      misafirKayit: {},

      cinsiyet_options: [
        { text: 'Kadın', value: 'K' },
        { text: 'Erkek', value: 'E' },
        { text: 'Çocuk', value: '3-12' },
        { text: 'Bebek', value: '0-2' },
      ],

      ulkeler: [{ value: "", text: "Uyruk" }],
    };
  },
  mounted() {
    this.misafirKayit = {
      Kod: this.rezervasyon.Kod,
      Id: this.yolcu.Id,
      AdSoyad: this.yolcu.AdSoyad,
      PasaportNo: this.yolcu.PasaportNo,
      UlkeKodu: this.yolcu.UlkeKodu,
      UyrukSelect: { value: this.yolcu.UlkeKodu, text: this.yolcu.Ulke },
      Cinsiyet: this.yolcu.Cinsiyet.substring(0,1)
    };

    if (this.acenteId === 0) {
      this.mutationAcenteId = this.$user.acenteId();
    }

    this.ulkeList();
  },
  components: {
    Multiselect
  },
  computed: {
    misafirekle_pasaportno_state() {
      if (
        this.misafirKayit.PasaportNo == "" ||
        this.misafirKayit.PasaportNo == null
      )
        return false;
      else return true;
    },
    misafirekle_adsoyad_state() {
      if (this.misafirKayit.AdSoyad == "" || this.misafirKayit.AdSoyad == null)
        return false;
      else return true;
    },
    misafirekle_uyruk_state() {
      if (
        this.misafirKayit.UyrukSelect == null ||
        this.misafirKayit.UyrukSelect.value == ""
      )
        return false;
      else return true;
    },
    misafirekle_cinsiyet_state() {
      if (this.misafirKayit.Cinsiyet == "" || this.misafirKayit.Cinsiyet == null)
        return false;
      else return true;
    }
  },
  methods: {
    ulkeList() {
      let vm = this;
      this.$connection.get("/laalTransferler/ulkeler", function (response) {
        let ulkeOptions = response.map((item) => {
          return {
            text: item.Uyruk,
            value: item.Kod,
          };
        });
        vm.ulkeler = vm.ulkeler.concat(ulkeOptions);
      });
    },
    onComplete() {
      let isValid =
        this.misafirekle_pasaportno_state &&
        this.misafirekle_adsoyad_state &&
        this.misafirekle_uyruk_state &&
        this.misafirekle_cinsiyet_state;

      if (!isValid) {
        this.$alert.messageWithTitle(
          "Eksik Bilgi",
          "Lütfen yolcu bilgilerinizi kontrol ediniz.",
          "error"
        );
      } else {
        this.misafirKayit.UlkeKodu = this.misafirKayit.UyrukSelect.value;

        this.loading = true;
        this.$emit("onComplete", this.misafirKayit);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>